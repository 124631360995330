import { Cursor, DataSource, Image, Label, Panel, getDataSourcePropDefinitions } from "@mcleod/components";
import { HorizontalAlignment, Model } from "@mcleod/core";
import { ResourceFileTooltip } from "../common/ResourceFileTooltip";
import { AbstractUIDesigner } from "./AbstractUIDesigner";

export class DesignerDataSource extends Panel {
    designerDataSource: DataSource;
    label: Label;
    dsImage: Image;
    private _customModelId: string;
    private tooltipPanel: Panel;

    constructor(designer: AbstractUIDesigner, dataSource: DataSource) {
        super({ rowBreak: false, color: "primary.light", cursor: Cursor.POINTER });
        this.designerDataSource = dataSource;
        this.dsImage = new Image({ name: "designer/datasource", fillRow: true, align: HorizontalAlignment.CENTER });
        this.setCustomModelIdFromMetadata();
        this.label = new Label({ caption: dataSource.id, fontSize: "small", fillRow: true, align: HorizontalAlignment.CENTER, allowSelect: false });
        this.addClickListener(event => {
            designer.selectComponent(this);
        });
        this.add(this.dsImage, this.label);
    }

    async setCustomModelIdFromMetadata() {
        this.customModelId = await this.designerDataSource?.getMetadata().then(metadata => metadata?.customModelId ?? null);
    }

    public get customModelId(): string {
        return this._customModelId;
    }

    private set customModelId(value: string) {
        if (this.customModelId === value)
            return;
        this._customModelId = value;
        this.tooltipPanel = null;
        this.syncCustomModelId();
    }

    syncCustomModelId() {
        this.dsImage.name = this.customModelId == null ? "designer/datasource" : "formPencil";
        this.tooltipCallback = async ()=> {
            const panel = await this.getTooltipPanel();
            return this.showTooltip(panel, null, { themeKey: "quickInfo" });
        };
    }

    private async getTooltipPanel(): Promise<Panel> {
        if (this.tooltipPanel == null) {
            if (this.customModelId == null) {
                this.tooltipPanel = ResourceFileTooltip.createPanel("model", {path: this.designerDataSource.url})
            } else {
                const data = await Model.searchSingleRecord("common/custom-model-definition", { id: this.customModelId });
                const props =  {
                    path: data.get("name"),
                    customId: data.get("id"),
                    descr: data.get("descr"),
                    createDateTime: new Date(data.get("create_datetime")),
                    updateDateTime: new Date(data.get("update_datetime"))
                }
                this.tooltipPanel = ResourceFileTooltip.createPanel("model", props);
            }
        }
        return Promise.resolve(this.tooltipPanel);
    }

    getPropertyDefinitions() {
        return getDataSourcePropDefinitions() as any;
    }

    updateId() {
        this.label.caption = this.designerDataSource.id;
    }

    public override get selectableInDesigner(): boolean {
        return true;
    }
}
