import { DynamicLoader, ObjectUtil } from "@mcleod/core";
import { Component } from "../..";
import {
    ComponentPropDefinitionUtil, ComponentPropDefinitions,
    ComponentProps, getColorEditor
} from "../../base/ComponentProps";
import { PropType } from "../../base/PropType";
import { ImageType } from "./ImageType";

export interface ImageProps extends ComponentProps {
    name: string;
    fill: string;
    imageBytes: string;
    imageType: ImageType;
    preserveAspectRatio: boolean;
    rotate: boolean;
    rotation: number;
    stroke: string;
    strokeWidth: number;
}

let imagePropDefs: ComponentPropDefinitions;
let containedImagePropDefs: ComponentPropDefinitions;

export class ImagePropDefinitions {
    public static getDefinitions(): ComponentPropDefinitions {
        if (imagePropDefs == null) {
            imagePropDefs = {
                ...ComponentPropDefinitionUtil.getComponentPropertyDefinitions(),
                fill: { type: PropType.string, editor: getColorEditor, description: "This specifies the fill color to be used when drawing this image.  It should rarely be used as color and/or imageColor are the preferred properties for most images." },
                name: { type: PropType.string, editor: ImagePropDefinitions.imagePropertyEditor, description: "This specifies the name of an image (from the images package) to draw." },
                preserveAspectRatio: { type: PropType.bool, defaultValue: true },
                rotate: { type: PropType.bool, description: "When set to true, this image will have an animation to it to rotate.  It's often used to indicate something is busy." },
                rotation: { type: PropType.number, description: "This specifies a number of degrees to rotate the specified image." },
                stroke: { type: PropType.string, editor: getColorEditor, description: "This specifies the stroke color to be used when drawing this image.  It should rarely be used as color and/or imageColor are the preferred properties for most images." },
                strokeWidth: { type: PropType.number, description: "This specifies the stroke width, in pixels, to be used when drawing this image.  There is rarely a need to set this property." },
            };
            ComponentPropDefinitionUtil.populateComponentPropNames(imagePropDefs);
        }
        return imagePropDefs;
    }

    public static getContainedImagePropDefs(): ComponentPropDefinitions {
        if (containedImagePropDefs == null)
            containedImagePropDefs = {
                imageColor: { type: PropType.string, editor: getColorEditor, displayName: "color", category: "Image", description: "This specifies the color to draw the image with.  Usually we would just set the color of the containing component (which would affect the text and border color of the component as well) but there may be cases where the image color and text color may be different." },
                imageName: { type: PropType.string, displayName: "name", category: "Image", editor: ImagePropDefinitions.imagePropertyEditor, description: "This specifies the name of an image (from the images package) to draw." },
                imageFill: { type: PropType.string, editor: getColorEditor, displayName: "fill", category: "Image", description: "This specifies the fill color to be used when drawing this image.  It should rarely be used as color and/or imageColor are the preferred properties for most images." },
                imageHeight: { type: PropType.number, displayName: "height", category: "Image", description: "This specifies the height, in pixels, to draw the image." },
                imageRotation: { type: PropType.number, displayName: "rotation", category: "Image", description: "This specifies a number of degrees to rotate the specified image." },
                imageStroke: { type: PropType.string, editor: getColorEditor, displayName: "stroke", category: "Image", description: "This specifies the stroke color to be used when drawing this image.  It should rarely be used as color and/or imageColor are the preferred properties for most images." },
                imageStrokeWidth: { type: PropType.number, displayName: "strokeWidth", category: "Image", description: "This specifies the stroke width, in pixels, to be used when drawing this image.  There is rarely a need to set this property." },
                imageWidth: { type: PropType.number, displayName: "width", category: "Image", description: "This specifies the width, in pixels, to draw the image." },
            };
        return ObjectUtil.deepCopy(containedImagePropDefs);
    }

    public static imagePropertyEditor(value, prop): Promise<Component> {
        // can't make a direct reference to ImageSelector at compile time
        const cls = DynamicLoader.getClassForPath("designer/ui/ImageSelector");
        return Promise.resolve(new cls());
    }
}
