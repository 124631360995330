import { Component } from "../../base/Component";
import { Layout } from "../../components/layout/Layout";
import { DataSourceMode } from "../../databinding/DataSource";
import { AdvancedDecorator } from "./AdvancedDecorator";
import { CrudDecorator } from "./CrudDecorator";

export class DecoratorRouter {
    /**
     * This determines which decorator should be used for a page given the page's properties and the current browser URL
     * @param {Layout} layout
     * @returns
     */
    public static decoratePage(layout: Layout, searchParams?: string | URLSearchParams): Component {
        if (searchParams === undefined) // check for undefined - allow passing null to indicate "I don't want any searchParams"
            searchParams = window.location.search;
        if (typeof searchParams === "string")
            searchParams = new URLSearchParams(searchParams);
        const props = { layout: layout };
        if (searchParams != null)
            searchParams.forEach((value: string, key: string, parent: URLSearchParams) => props[key] = value);

        let decorator: string;
        if (layout.allowDecoratorOverride !== false)
            decorator = searchParams?.get("mode");
        if (decorator == null)
            decorator = layout.defaultDecorator;
        if (decorator === "advanced")
            return new AdvancedDecorator(props);
        else if (decorator === "search")
            return new CrudDecorator({ mode: DataSourceMode.SEARCH, ...props });
        else if (decorator === "add")
            return new CrudDecorator({ mode: DataSourceMode.ADD, ...props });
        else if (decorator === "update")
            return new CrudDecorator({ mode: DataSourceMode.UPDATE, ...props });
        else if (decorator != null)
            throw new Error("Invalid page decorator " + decorator);
        return layout;
    }
}
