import { ArrayUtil, DOMUtil, getLogger } from "@mcleod/core";
import { ImageName } from "@mcleod/images";
import { ImageProps, Panel } from "../..";
import { Component } from "../../base/Component";
import { Container } from "../../base/Container";
import { Cursor } from "../../base/Cursor";
import { Event, EventListener } from "../../events/Event";
import { Label } from "../label/Label";
import { LabelProps } from "../label/LabelProps";
import { Tree } from "./Tree";
import { TreeNodeProps } from "./TreeNodeProps";

const log = getLogger("components.TreeNode");

export class TreeNode extends Container {
    public children: TreeNode[];
    public _component: Component;
    private _rightComponent: Component;
    private _expanded: boolean;
    private _caption: string;
    /**
     * This is just a place to hold whatever you might want to attach to the TreeNode.  It doesn't affect the TreeNode itself.
     */
    public data: any;

    tree: Tree;
    public onCreateImage: EventListener;
    public expandOnClick: boolean;
    public imageName: ImageName;
    public imageProps: Partial<ImageProps>
    public expandedImageName: ImageName;
    public expandedImageProps: Partial<ImageProps>;
    public collapsedImageName: ImageName;
    public collapsedImageProps: Partial<ImageProps>;
    protected panel: Panel;
    private label: Label;
    private _onlyShowRightComponentWhenHovered: boolean;
    expansionCallback: () => void;
    private calledExpansionCallback: boolean;

    constructor(props?: Partial<TreeNodeProps> | string) {
        if (typeof props === "string")
            props = { caption: props };
        super("li", props);
        const root = props.parent instanceof Tree;
        if (!root)
            this._element.style.marginLeft = DOMUtil.getSizeSpecifier(props?.indent || 12);
        this.children = [];
        this.panel = new Panel({ padding: 0 });
        this.label = new Label({ allowSelect: false, rowBreak: false, fillRow: true, cursor: Cursor.POINTER, imageMarginLeft: 4, ...props });
        this.panel.add(this.label);
        if (props == null || props.component == null)
            this._component = this.panel;
        else
            this._component = props.component;
        if (!root)
            this._element.appendChild(this._component._element);
        this.expanded = root;
        this._element.onclick = (event) => {
            this.getTree()._setSelectedInternal(this, event);
            if (this.expandOnClick !== false)
                this.expanded = !this.expanded;
            event.stopPropagation();
        }
        this._element.ondblclick = (event) => {
            this.expanded = !this.expanded;
            this.getTree().selectedNode = this;
            event.stopPropagation();
        }
        this.setProps({ paddingRight: 0, ...props });
    }

    [Symbol.iterator]() {
        return ArrayUtil.arrayIterator(this.children);
    }

    setProps(props: Partial<TreeNodeProps>) {
        super.setProps(props);
    }

    /**
     * @returns {TreeNode[]}
     */
    get path(): TreeNode[] {
        const result = [];
        let node: TreeNode = this;
        while (node.parent != null && !node.isRoot()) {
            result.push(node);
            node = node.parent;
        }
        return result.reverse();
    }

    getTree(): Tree | undefined {
        if (this.tree != null)
            return this.tree;
        let result = (this.parent as any) as TreeNode;
        while (result != null && !result.isRoot())
            result = result.parent;
        if (result instanceof TreeNode)
            return null;
        else
            return (result as Tree);
    }

    private callExpansionCallback() {
        if (this.expansionCallback != null && !this.calledExpansionCallback) {
            this.calledExpansionCallback = true;
            this.expansionCallback();
        }
    }

    set expanded(value: boolean) {
        if (this.isRoot() && !value)
            return;
        this._expanded = value;
        this.callExpansionCallback();
        if (value === true && this.children.length === 1 && this.children[0].children.length > 0)
            this.children[0].expanded = true;
        this.reRender();
    }

    get expanded(): boolean {
        return this._expanded;
    }

    getChildWithCaption(caption: string): TreeNode {
        for (const child of this.children)
            if (child.caption === caption)
                return child;
        return null;
    }

    public isRoot(): boolean {
        return this.parent == null || this.parent instanceof Tree;
    }

    /**
     *
     * @param {TreeNode} node
     * @returns TreeNode
     */
    addChild(...nodes: TreeNode[]): TreeNode {
        for (const node of nodes) {
            this.children.push(node);
            if (this.parent instanceof Tree) {
                node._element.style.marginLeft = "";
                node.recurseChildren(n => (n as any).tree = this.parent);
                node.tree = this.parent;
            }
            else {
                node.tree = this.tree;
                node.recurseChildren(n => (n as any).tree = this.tree);
            }
            node.parent = this;
        }
        this.reRender();
        return nodes[0];
    }

    toString(depth: number = 0) {
        let result = "";
        for (const node of this.children) {
            result += " ".repeat(depth * 4) + node.caption + "\n";
            result += node.toString(depth + 1);
        }
        return result;
    }

    /**
     *
     * @param {Array.TreeNode} nodes
     */
    setChildren(nodes: TreeNode[]) {
        this.children = nodes;
        for (const node of nodes) {
            node.parent = this;
            if (this.isRoot())
                node._element.style.marginLeft = "";
        }
        this.recurseChildren(node => node.tree = this.tree);
        this.reRender();
    }

    filterChildren(passesFilterFunction) {
        for (let i = this.children.length - 1; i >= 0; i--) {
            const child = this.children[i];
            child.filterChildren(passesFilterFunction);
            if (!passesFilterFunction(child)) {
                log.debug("Filtering", child.caption, child);
                child.parent = undefined;
                this.children.splice(i, 1);
            }
        }
    }

    removeLeafNodes() {
        this.filterChildren(node => node.getChildCount() > 0);
    }

    /**
     *
     * @returns {Array.TreeNode}
     */
    getChildren() {
        return this.children;
    }

    /**
     *
     * @param {TreeNode} child
     * @returns {number}
     */
    indexOfChild(child) {
        for (let i = 0; i < this.children.length; i++)
            if (this.children[i] === child)
                return i;
        return -1;
    }

    getChildCount() {
        this.callExpansionCallback();
        return this.children.length;
    }

    public hasChildren(): boolean {
        return this.getChildCount() > 0;
    }

    getChild(index) {
        return this.children[index];
    }

    /**
     *
     * @param {number} index
     */
    removeChild(index: number) {
        const child = this.children[index];
        child.parent = undefined; // so sad
        this.children.splice(index, 1);
        this.reRender();
    }

    removeAllChildren() {
        this.children = [];
        this.reRender();
    }

    removeChildrenMatching(matchFunction) {
    }

    /**
     * This function will recursively enumerate all the children of this node and call the passed function.  The node being recursed will be passed as a parameter to recurseFunction.
     *
     * @param {function} recurseFunction
     */
    recurseChildren(recurseFunction: (node: TreeNode) => void) {
        for (const node of this.children) {
            recurseFunction(node);
            node.recurseChildren(recurseFunction);
        }
    }

    get parentIndex() {
        return this.parent.indexOfChild(this);
    }

    expandAll() {
        this.recurseChildren(node => node.expanded = true);
    }

    collapseAll() {
        this.recurseChildren(node => node.expanded = false);
    }

    reRender() {
        this._element.innerHTML = "";
        this.callExpansionCallback();
        if (!this.isRoot()) {
            this.updateComponentImage();
            this._element.appendChild(this._component._element);
        }
        if (this.expanded) {
            for (const child of this.children) {
                child.reRender();
                this._element.appendChild(child._element);
            }
        }
    }

    populateChildrenForExpand() {

    }

    updateComponentImage() {
        if (this.onCreateImage != null)
            return this.onCreateImage(new Event(this, null));

        let imageName: ImageName;
        let imageProps = this.imageProps;
        if (this.expanded === true) {
            imageName = this.expandedImageName || this.imageName;
            imageProps = this.expandedImageProps || imageProps;
        }
        else {
            imageName = this.collapsedImageName || this.imageName;
            imageProps = this.collapsedImageProps || imageProps;
        }
        if (imageName == null) {
            const tree = this.getTree();
            if (tree == null) {
                if (this.hasChildren())
                    this.setLabelProps({ imageName: "addInBox" });
                else
                    this.setLabelProps({ imageName: null });
            }
            else
                imageName = tree.getDefaultNodeImageName(this.getChildCount() === 0, this.expanded);
        }
        this.setLabelProps({ imageName: imageName, imageProps: imageProps });
    }

    set caption(value) {
        this._caption = value;
        this.setLabelProps({ caption: value });
    }

    get caption() {
        return this._caption;
    }

    setLabelProps(props: Partial<LabelProps>) {
        this.label.setProps(props);
    }

    set component(value) {
        if (this._component === value)
            return;
        this._component = value;
        this._element.innerHTML = "";
        this._element.appendChild(value._element);
    }

    get component() {
        return this._component;
    }

    get parent(): TreeNode {
        return super.parent as TreeNode;
    }

    set parent(value: TreeNode) {
        super.parent = value;
    }

    get rightComponent(): Component {
        return this._rightComponent;
    }

    set rightComponent(value: Component) {
        this._rightComponent = value;
        this.panel.remove(this._rightComponent);
        this.panel.add(this._rightComponent);
    }

    get onlyShowRightComponentWhenHovered(): boolean {
        return this._onlyShowRightComponentWhenHovered;
    }

    set onlyShowRightComponentWhenHovered(value: boolean) {
        this._onlyShowRightComponentWhenHovered = value;
        this.syncRightComponent();
    }

    protected syncRightComponent() {
        if (this.onlyShowRightComponentWhenHovered && this.rightComponent != null) {
            this.rightComponent.hiddenUnlessMouseOver = this.panel;
        }
        if (!this.panel.contains(this._rightComponent))
            this.panel.add(this._rightComponent);
    }


    override getPropertyDefinitions() {
        return {};
    }
}
