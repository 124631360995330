import { Alignment, Color, DOMUtil, JSUtil, ModuleLogger, StringUtil, getLogger, getThemeColor, getThemeForKey } from "@mcleod/core";
import { Panel, PanelProps, ScreenStack } from "..";
import { Component } from "../base/Component";
import { ComponentProps } from "../base/ComponentProps";
import { MouseEvent } from "../events/MouseEvent";
import { OnScreen } from "./OnScreen";
import { getComponentFromStringOrPropsOrComponent } from "./getComponentFromStringOrPropsOrComponent";

const spacing = 8;
const pointerSize = 14;
const _scrollListener: EventListener = (event: Event) => hideTooltipsOnScroll(event);

export interface TooltipOptions {
    shaking: boolean;
    warning: boolean;
    pointer: boolean;
    timeout: number;
    position: Alignment;
    anchorToMouse: boolean;
    originatingEvent: MouseEvent;
    showPointer: boolean;
    pointerColor: Color | ((position: Alignment) => Color),
}

/**
 *
 * @param {*} anchor
 * @param {*} tipOrComponentOrLabelProps
 * @param {*} options
 * @returns
 */
export function showTooltip(anchor: Component, tipOrComponentOrLabelProps: string, options?: Partial<TooltipOptions>, props?: Partial<PanelProps>): Panel {
    initializeScrollListener();
    const comp = getComponentFromStringOrPropsOrComponent(tipOrComponentOrLabelProps, null, anchor);
    if (comp == null)
        return;
    props = JSUtil.assignIfUndefined(getThemeForKey(props?.themeKey ?? "tooltip"), props);
    //remove theme key from props since we just applied the individual theme key values to props...
    //there is no need to apply the theme key property to the component, it can only apply properties we may not want
    delete props.themeKey;
    if (_newTooltipAnchoredToAnotherTooltip(anchor) !== true)
        hideTooltip();
    const parent = new Panel({ scrollY: false, padding: 0, width: null, height: null, zIndex: 5000 });  //zIndex is a fallback default in case we can't compute a new zIndex later on
    parent._element.style.overflow = "visible";
    const p = new Panel({
        id: "tooltipContentPanel",
        borderRadius: 4,
        paddingLeft: 16,
        paddingRight: 16,
        rowBreak: false,
        borderShadow: true,
        ...props
    });
    if (anchor != null)
        p.addMouseEnterListener(event => anchor.clearTooltipHideTimeout());
    p.addMouseLeaveListener(event => {
        if (anchor == null || !anchor._element.contains(event.domEvent.toElement)) {
            const mouseOverTooltipIndex = _getMouseOverTooltipIndex(event.domEvent.relatedTarget);
            //if the mouse isn't over any tooltip in the stack, hide them all
            if (mouseOverTooltipIndex < 0)
                hideTooltip();
            else {
                //if the mouse is over a tooltip, hide everything newer than that tooltip
                const tooltipStackCopy = [...ScreenStack.getAllTooltips()];
                for (let x = tooltipStackCopy.length - 1; x > mouseOverTooltipIndex; x--) {
                    hideTooltip(tooltipStackCopy[x]);
                }
            }
        }
    });

    p.add(comp);
    let pos = options?.position || getDefaultTooltipPosition(anchor);
    let pointer: Panel;
    if (options?.showPointer !== false) {
        pointer = new Panel({ borderWidth: 7, padding: 0, rowBreak: false });
        pointer._element.style.position = "absolute";
        adjustPointer(pointer, pos, anchor, p, options, props);
        parent.add(pointer);
    }
    parent.add(p);

    parent._element.style.position = "absolute";
    parent._element.style.backgroundColor = "transparent";
    parent._element.style.visibility = "hidden";
    document.body.appendChild(parent._element);
    if (anchor != null) //seed the parent's top/left values so that it starts in the right position
        setComponentTopLeft(parent, pos, anchor._element.getBoundingClientRect(), parent._element.getBoundingClientRect());
    if (options?.anchorToMouse === false && options?.originatingEvent != null) {
        //try to get MouseEvent x/y to use as tooltip anchor
        //have seen where options.originatingEvent.domEvent can be null; possibly a timing issue where Component._lastMouseOverEvent isn't getting set fast enough?
        //if that happens, fallback to the anchored tooltip
        const domEvent: any = options.originatingEvent.domEvent;
        if (domEvent != null && domEvent['clientX'] != null && domEvent['clientY'] != null) {
            parent.left = domEvent.clientX + 2;
            parent.top = domEvent.clientY + 2;
        }
    }
    pos = alignTooltip(parent, anchor, pos);
    if (pointer != null)
        adjustPointer(pointer, pos, anchor, p, options, props);
    let lastSize = parent._element.getBoundingClientRect();
    parent.addResizeListener(() => {
        const thisSize = parent._element.getBoundingClientRect();
        // this condition prevents tooltips from shrinking but that seems valid to want to do in some cases.  So far, we only need to grow tooltips, though, so this is a workaround.
        // ths condition was added because a tooltip was shrinking horizontally.  Even though this resize handler doesn't specifically change the
        // size, it does change the position.  Somehow (I'm sure it's explainable), setting the left of an element near the edge of the page will change the size.
        // This shrink caused it to grow vertically, which triggered a resize again.
        // The tooltip with the problem the "Save" button in the upper-right of CrudDecorator when it had a disabled reason (You can't save because you haven't changed anything)
        // **** If this button was not on the edge of the page, this effect didn't happen.
        if (lastSize == null || thisSize.height > lastSize.height || thisSize.width > lastSize.width) {
            Tooltip.log.debug(() => ["Tooltip resized", comp, pos, parent._element.getBoundingClientRect()]);
            pos = alignTooltip(parent, anchor, pos);
            if (pointer != null)
                adjustPointer(pointer, pos, anchor, p, options, props);
        }
        lastSize = thisSize;
    });
    //if the tooltip's anchor is no longer part of the document's body, don't show the tooltip and return null
    //(not showing the tooltip means we don't need any references to it...thus return null and don't assign the tooltip to '_currentTooltip')
    if (anchor != null && document.body.contains(anchor._element) !== true)
        return null;
    if (StringUtil.isEmptyString(parent.id) === true)
        parent.id = "tooltip_" + new Date().getTime();
    parent.zIndex = ScreenStack.getNewHighestZIndex();
    parent._element.style.visibility = "visible";
    ScreenStack.pushTooltip(parent);
    if (options != null) {
        if (options.timeout != null)
            setTimeout(hideTooltip, options.timeout);
        if (options.shaking === true)
            shake(parent, 8);
    }
    return parent;
}

function initializeScrollListener() {
    Tooltip.log.debug("Adding scroll listener for the purpose of hiding all tooltips");
    const options = { capture: true, once: true };
    window.addEventListener("scroll", _scrollListener, options);
}

function getDefaultTooltipPosition(anchor: Component): Alignment {
    if (anchor != null) {
        const anchorBounds = anchor._element.getBoundingClientRect();
        const anchorCenterX = anchorBounds.left + (anchorBounds.width / 2);
        const anchorCenterY = anchorBounds.top + (anchorBounds.height / 2);
        if (anchorCenterY > window.innerHeight * .8) {
            return anchorCenterX < window.innerWidth * .8 ? Alignment.RIGHT : Alignment.LEFT;
        }
    }
    return Alignment.BOTTOM;
}

/**
 * Hides all visible tooltips, unless the element being scrolled is in one of the visible tooltips.
 * @param event The scroll event
 */
function hideTooltipsOnScroll(event: Event) {
    const scrollTarget = event.target;
    const tooltipStackCopy = [...ScreenStack.getAllTooltips()];
    for (const tooltip of tooltipStackCopy) {
        if (DOMUtil.isOrContains(tooltip._element, scrollTarget as Element)) {
            Tooltip.log.debug("Not hiding tooltip(s) due to scroll that occurred inside a tooltip %o", event);
            return;
        }
    }
    Tooltip.log.debug("Hiding tooltip(s) due to scroll that occurred outside of tooltip %o", event);
    hideTooltip();
}

function _newTooltipAnchoredToAnotherTooltip(anchor: Component): boolean {
    if (anchor == null)
        return false;
    for (const existingTooltip of ScreenStack.getAllTooltips()) {
        if (existingTooltip.isOrContains(anchor))
            return true;
    }
    return false;
}

function _getMouseOverTooltipIndex(mouseOverElement: HTMLElement): number {
    const tooltipStackCopy = [...ScreenStack.getAllTooltips()];
    for (let x = 0; x <= tooltipStackCopy.length - 1; x++) {
        if (DOMUtil.isOrContains(tooltipStackCopy[x]._element, mouseOverElement))
            return x;
    }
    return -1;
}

function adjustPointer(pointer: Panel, position: Alignment, anchor: Component, tooltipContentPanel: Panel, options: Partial<TooltipOptions>, wrapperProps?: Partial<ComponentProps>) {
    Tooltip.log.debug(() => ["adjustPointer", position, anchor, options, wrapperProps]);
    let color = JSUtil.evaluateProp(options?.pointerColor, position) || wrapperProps?.backgroundColor;
    color = getThemeColor(color);
    if (position === Alignment.LEFT) {
        pointer.top = "50%";
        pointer.left = "100%";
        setPointerMarginTop(pointer, anchor, tooltipContentPanel);
        pointer._element.style.borderColor = "transparent transparent transparent " + color;
        tooltipContentPanel.borderShadow = true;
    }
    else if (position === Alignment.RIGHT) {
        pointer.top = "50%";
        pointer.left = 0;
        pointer.marginLeft = (pointerSize * -1) + 2;
        setPointerMarginTop(pointer, anchor, tooltipContentPanel);
        pointer._element.style.borderColor = "transparent " + color + " transparent transparent";
        tooltipContentPanel.borderShadow = true;
    }
    else if (position === Alignment.TOP) {
        pointer.top = "100%";
        pointer.left = "50%";
        pointer.marginTop = 0;
        setPointerMarginLeft(pointer, anchor, tooltipContentPanel);
        pointer._element.style.borderColor = color + " transparent transparent transparent";
        tooltipContentPanel.borderShadow = false;
    }
    else {
        pointer.top = 0;
        pointer._element.style.left = "50%";
        pointer.marginTop = pointerSize * -1 + 2;
        setPointerMarginLeft(pointer, anchor, tooltipContentPanel);
        pointer._element.style.borderColor = "transparent transparent " + color + " transparent";
        tooltipContentPanel.borderShadow = true;
    }
}

function setPointerMarginLeft(pointer: Panel, anchor: Component, tooltipContentPanel: Panel) {
    // this is an embarrassing implementation, but it tries to make sure that the pointer is
    // pointing to the center of the component that the tooltip is anchored to
    const pointerBounds = pointer._element.getBoundingClientRect();
    const anchorBounds = anchor?._element.getBoundingClientRect();
    const tooltipBounds = tooltipContentPanel?._element.getBoundingClientRect();
    const anchorMiddle = anchorBounds.left + (anchorBounds.width / 2);
    const pointerMiddle = pointerBounds.left + (pointerBounds.width / 2);
    const difference = anchorMiddle - pointerMiddle;
    let offset = 0;
    if (pointerMiddle - difference > tooltipBounds.left)
        offset = difference;
    pointer.marginLeft = (pointerSize / -2) + offset;
}

function setPointerMarginTop(pointer: Panel, anchor: Component, tooltipContentPanel: Panel) {
    // similarly embarrassing to above...I'm so sorry
    const pointerBounds = pointer._element.getBoundingClientRect();
    const anchorBounds = anchor?._element.getBoundingClientRect();
    const tooltipBounds = tooltipContentPanel?._element.getBoundingClientRect();
    const anchorMiddle = anchorBounds.top + (anchorBounds.height / 2);
    const pointerMiddle = pointerBounds.top + (pointerBounds.height / 2);
    const difference = anchorMiddle - pointerMiddle;
    let offset = 0;
    if (pointerMiddle - difference > tooltipBounds.top)
        offset = difference;
    pointer.marginTop = (pointerSize / -2) + offset;
}


function shake(component: Component, times: number) {
    if (times > 0)
        setTimeout(() => {
            const degrees = (times % 2) === 0 ? -1 : 1;
            component._element.style.transform = "rotate(" + degrees + "deg)";
            shake(component, times - 1);
        }, 50);
    else
        component._element.style.transform = "rotate(0deg)";
}

export function hideTooltip(tooltip?: Panel) {
    const oldestTooltipToHide = tooltip == null ? ScreenStack.getOldestTooltip() : tooltip;
    if (oldestTooltipToHide == null)
        return;
    const tooltipStackCopy = [...ScreenStack.getAllTooltips()];
    const tooltipToHideIndex = tooltipStackCopy.indexOf(oldestTooltipToHide);
    if (tooltip != null && tooltipToHideIndex < 0)
        return;
    for (let x = tooltipStackCopy.length - 1; x >= tooltipToHideIndex; x--) {
        const t = tooltipStackCopy[x];
        ScreenStack.popTooltip(t);
        if (t != null && document.body.contains(t._element))
            document.body.removeChild(t._element);
    }
}

function alignTooltip(componentToAlign: Component, anchor: Component, position: Alignment): Alignment {
    // position = componentToAlign["_tooltipPosition"] || position;
    let anchorElement: HTMLElement;
    if (anchor._getTooltipAnchor != null)
        anchorElement = anchor._getTooltipAnchor();
    else
        anchorElement = anchor._element;
    const anchorRect = anchorElement.getBoundingClientRect();
    let compRect = componentToAlign._element.getBoundingClientRect();
    const onScreen = OnScreen.ensureRectOnScreen(compRect, { anchor: anchor, align: Alignment.CENTER, position: position});
    position = onScreen.newAnchor.position;
    compRect = onScreen.newRect;
    setComponentTopLeft(componentToAlign, position, anchorRect, compRect);
    return position;
}

function setComponentTopLeft(componentToAlign: Component, position: Alignment, anchorRect: DOMRect, compRect: DOMRect) {
    if (position === Alignment.TOP) {
        componentToAlign.top = anchorRect.top - compRect.height - spacing;
        componentToAlign.left = (anchorRect.left + (anchorRect.width / 2)) - (compRect.width / 2);
    } else if (position === Alignment.RIGHT) {
        componentToAlign.top = (anchorRect.top + (anchorRect.height / 2)) - (compRect.height / 2);
        componentToAlign.left = anchorRect.left + anchorRect.width + spacing;
    } else if (position === Alignment.LEFT) {
        componentToAlign.top = (anchorRect.top + (anchorRect.height / 2)) - (compRect.height / 2);
        componentToAlign.left = anchorRect.left - compRect.width - spacing;
    } else {
        componentToAlign.top = anchorRect.bottom + spacing;
        componentToAlign.left = (anchorRect.left + (anchorRect.width / 2)) - (compRect.width / 2);
    }
    if (componentToAlign.left < 4)
        componentToAlign.left = 4;
    if (componentToAlign.top < 4)
        componentToAlign.top = 4;
}

export class Tooltip {
    private static _log: ModuleLogger;

    public static get log() {
        if (Tooltip._log == null)
            Tooltip._log = getLogger("components/page/Tooltip");
        return Tooltip._log;
    }
}
