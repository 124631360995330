import { AutogenModelPermsSecureItem, AutogenRowPermsSecureItem } from "./autogen/AutogenModelPermsSecureItem";

export type PermsSecurityType = "V" | "E";

export class ModelPermsSecureItem extends AutogenModelPermsSecureItem<RowPermsSecureItem> {
    constructor() {
        super(RowPermsSecureItem);
    }
}

export class RowPermsSecureItem extends AutogenRowPermsSecureItem {
}
