import { CurrencyUtil, DisplayType, NumberUtil, StringUtil, isDisplayTypeNumeric } from "@mcleod/core";
import { ValidationResult } from "../../base/ValidationResult";
import { AbstractInputParser } from "./AbstractInputParser";
import { Textbox } from "./Textbox";
import { TextboxValidator } from "./TextboxValidator";

const comparisonOperators = ["!=", "><", "<>", ">=", "<=", ">", "<", "!"];

export class SearchInputParser extends AbstractInputParser {
    public operator: "|" | "&";
    public expressions: SearchExpression[] = [];
    private _validationResult: ValidationResult;

    constructor(public textbox: Textbox, displayValue?: string) {
        super(textbox, displayValue);
        this.determineOperator();
        if (this._validationResult == null) {
            this.addExpressions();
        }
    }

    private determineOperator() {
        const includesOr = this.textbox.text.includes("|");
        const includesAnd = this.textbox.text.includes("&");

        if (includesAnd && includesOr) {
            this.setValidationResult("Cannot have both 'or' and 'and' in a single field.");
            return;
        }

        if (includesOr || includesAnd) {
            this.operator = includesOr ? "|" : "&";
            if (!this.displayTypeSupportsOperator()) {
                this.setValidationResult(`'Or' and 'and' queries are not supported for ${this.textbox.displayType.toLowerCase()} field.`);
            }
        } else {
            this.operator = null;
        }
    }

    private displayTypeSupportsOperator(): boolean {
        return this.textbox.displayType != DisplayType.DATERANGE;
    }

    private setValidationResult(message: string) {
        this._validationResult = { component: this.textbox, isValid: false, validationMessage: message };
    }

    private addExpressions() {
        if (this.operator) {
            this.textbox.text.split(this.operator).forEach(part => this.addExpression(part));
        } else {
            this.addExpression(this.textbox.text);
        }
    }

    private addExpression(expression: string) {
        if (!StringUtil.isEmptyString(expression)) {
            const searchExpression = new SearchExpression(this.textbox, expression);
            this.expressions.push(searchExpression);
        }
    }

    validate(checkRequired: boolean): ValidationResult {
        if (checkRequired !== false) {
            this._validationResult = TextboxValidator.validateRequired(this.textbox);
        }
        if (this._validationResult?.isValid !== false)
            return this.expressions?.find(expression => expression.validationResult != null)?.validationResult;
        return this._validationResult;
    }

    getDisplayValue() {
        return this.expressions?.map(expression => expression.displayValue).join(this.operator) || "";
    }

    getDataValue() {
        return this.expressions?.map(expression => expression.searchValue).join(this.operator) || undefined;
    }
}

export class SearchExpression {
    public readonly comparisonOperator: string;
    public readonly operand: string;
    public readonly displayValue: string;
    private _searchValue: string;
    private _validationResult: ValidationResult;

    constructor(private textbox: Textbox, private expression: string, private displayType = textbox.displayType) {
        if (!this.isDateRange) {
            this.comparisonOperator = comparisonOperators.find(op => expression.startsWith(op));
        }

        const unformattedOperand = this.comparisonOperator ? expression.substring(this.comparisonOperator.length) : expression;
        this.operand = this.textbox.inputFormatter.getDisplayValue(unformattedOperand, displayType) ?? unformattedOperand;
        this.displayValue = `${this.comparisonOperator ?? ""}${this.operand ?? ""}`;
    }

    private get isDateRange(): boolean {
        return DisplayType.DATERANGE === this.displayType;
    }

    public get searchValue(): string {
        if (this._searchValue === undefined && !StringUtil.isEmptyString(this.displayValue)) {
            if (this.isDateRange) {
                this._searchValue = this.createDateRangeSearchValue();
            } else {
                this._searchValue = this.createSearchString(this.displayValue);
            }
        }
        return this._searchValue;
    }

    private createDateRangeSearchValue(): string {
        if(this.textbox.validationWarning != null)
            return this.displayValue;
        const dates = this.displayValue.split("-");
        return `>=${dates[0]}&<=${dates[1] || dates[0]}`;
    }

    private createSearchString(value: string): string {
        value = this.removeFormatting(value);
        if (["!=", "><", "!"].includes(this.comparisonOperator)) {
            value = value.replace(new RegExp(this.comparisonOperator), "<>");
        }
        return value;
    }

    private removeFormatting(value: string) {
        if (isDisplayTypeNumeric(this.displayType))
            return CurrencyUtil.removeFormatting(value); //also calls NumberUtil.removeFormatting()
        return value;
    }

    public get validationResult(): ValidationResult {
        if (this._validationResult == null) {
            this._validationResult = TextboxValidator.validateSearchText(this.textbox, this.operand,
                this.textbox.minValue, this.textbox.maxValue);
        }
        return this._validationResult;
    }

}
