import { getLogger } from "./Logger";
import { UserSettings } from "./settings/UserSettings";

const log = getLogger("core.LogicResponsibilityFiltering");

export class LogicResponsibilityFiltering {
    public static userHasResponsibility(testCodes: string[]): boolean {
        const userRfCodes = UserSettings.get()?.responsibility_codes;
        log.debug("Evaluating if user has responsibility using Test Codes: %o  User RF Codes: %o", testCodes, userRfCodes);
        if (userRfCodes == null) { //null indicates that the user is not a filtered user
            log.debug("User is not an RF filtered user")
            return true;
        }
        if (testCodes != null) {
            for (const testCode of testCodes) {
                if (userRfCodes.includes(testCode))
                    return true;
            }
        }
        return false;
    }
}