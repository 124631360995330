import { Label, Panel, PanelProps, Switch } from "../..";
import { Cursor } from "../base/Cursor";
import { MultiSwitch } from "../components/multiswitch/MultiSwitch";
import { Tab } from "../components/tabset/Tab";
import { Tabset } from "../components/tabset/Tabset";
import { TabStyle } from "../components/tabset/TabsetProps";

export interface TabsetConfigPanelProps extends PanelProps {
    selectedStyle: TabStyle;
    tabsset: Tabset;
}

export class TabsetConfigPanel extends Panel implements TabsetConfigPanel {
    switchView: MultiSwitch;
    panelTabs: Panel;
    _tabset: Tabset;
    dragPanel: Panel;

    constructor(props?: Partial<TabsetConfigPanelProps>) {
        super({ id: props?.id, padding: 24, paddingTop: 8 });
        this.switchView = new MultiSwitch({ caption: "View as", items: ["Outlined", "Underlined", "Accordion", "Scrolly thing"], marginBottom: 12 })
        this.add(this.switchView);
        this.add(new Label({ caption: "Tab order (drag items to change the order)", fontSize: "large" }))
        this.panelTabs = new Panel({ fillHeight: true, fillRow: true });
        this.add(this.panelTabs);
        this.setProps(props);
        this.switchView.addChangeListener(event => this.tabset.tabStyle = this.selectedStyle);
    }

    get selectedStyle(): TabStyle {
        return Object.values(TabStyle)[this.switchView.selectedIndex];
    }

    set selectedStyle(value: TabStyle) {
        const index = Object.values(TabStyle).indexOf(value);
        if (index >= 0)
            this.switchView.selectedIndex = index;
    }

    get tabset() {
        return this._tabset;
    }

    set tabset(value: Tabset) {
        this._tabset = value;
        this.panelTabs.removeAll();
        for (const comp of value.components) {
            const tab = comp as Tab;
            const tabPanel = new Panel({
                rowBreakDefault: false,
                fillRow: true,
                borderWidth: 1,
                borderColor: "subtle.darker",
                draggable: true,
                marginBottom: 4,
                borderRadius: 4,
                cursor: Cursor.GRAB,
            });
            (tabPanel as any).tab = tab;
            const label = new Label({ caption: tab.caption, fillRow: true, imageName: "hamburger", color: "success" });
            const switchOpen = new Switch({ leftCaption: "Closed", rightCaption: "Open" });
            tabPanel.add(label, switchOpen);
            tabPanel.addMouseDownListener(event => {
                tabPanel.backgroundColor = "background4";
                tabPanel.cursor = Cursor.GRABBING;
            });
            tabPanel.addMouseUpListener(event => {
                tabPanel.cursor = Cursor.GRAB;
                tabPanel.backgroundColor = "transparent";
            });
            tabPanel.addDragStartListener(event => {
                this.dragPanel = tabPanel;
            });
            tabPanel.addDragEndListener(event => {
                tabPanel.cursor = Cursor.GRAB;
                tabPanel.backgroundColor = "transparent";
            });
            tabPanel.addDragOverListener(event => {
                if (this.dragPanel != tabPanel) {
                    this.tabset.swap((tabPanel as any).tab, (this.dragPanel as any).tab)
                    this.panelTabs.swap(tabPanel, this.dragPanel);
                }
                event.preventDefault();
            });
            this.panelTabs.add(tabPanel);
        }
    }
}
