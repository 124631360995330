import { McLeodMainPage, Router } from "@mcleod/common";
import { ServiceAddresses } from "@mcleod/core";
import { TenantSettingsManager } from "../auth/src/settings/TenantSettingsManager";
import { Layout } from "@mcleod/components";

const rootElement = document.getElementById("root");
if (rootElement == null)
    throw new Error("Could not mount component because DOM element named root could not be found.");
provideAppSpecificConfiguration();
const mainPage = new McLeodMainPage({
    settingsRoot: "tenant/",
    apiContext: "mcleod-api-tenant",
    modules: [
        { path: "designer", context: require["context"]("@mcleod/designer/src", true) },
        { path: "common", context: require["context"]("@mcleod/common/src", true) },
        { path: "auth", context: require["context"]("@mcleod/auth/src", true) },
    ]
});
rootElement.appendChild(mainPage._element);

function provideAppSpecificConfiguration() {
    ServiceAddresses.setLocalHostServerPort(8009);
    TenantSettingsManager.initializeSettings();
    Layout.shouldLoadVideoItemHelpLinks = false;
}
