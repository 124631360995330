import { AuthType, DOMUtil, ModelRow, ServerError, StringUtil, UserSettings, getLogger } from "@mcleod/core";
import { Layout } from "../layout/Layout";
import { LayoutProps } from "../layout/LayoutProps";
import { SearchFilterVisible, Table } from "./Table";
import { Label } from "../label/Label";
import { TableRow, TableRowMode } from "./TableRow";
import { TableCell } from "./TableCell";
import { Button } from "../button/Button";
import { ClickEvent } from "../../events/ClickEvent";
import { Component } from "../../base/Component";
import { ButtonProps } from "../button/ButtonProps";
import { DialogProps } from "../../page/Dialog";
import { TableConfig } from "./TableConfig";
import { DataSource } from "../../databinding/DataSource";
import { Panel } from "../panel/Panel";
import { List } from "../list/List";
import { PanelProps } from "../panel/PanelProps";
import { Checkbox } from "../checkbox/Checkbox";
import { ChangeEvent } from "../../events/ChangeEvent";
import { SelectionEvent } from "../../events/SelectionEvent";
import { Textbox } from "../textbox/Textbox";
import { ReflectiveDialogs } from "../../base/ReflectiveDialogs";
import { ReflectiveSnackbars } from "../../base/ReflectiveSnackbars";
import { SelectionMode } from "../../base/SelectionMode";
import { ListType } from "../list/ListType";
import { ButtonVariant } from "../button/ButtonVariant";
import { Switch } from "../switch/Switch";
import { ValidationResult } from "../../base/ValidationResult";
import { TextboxProps } from "../textbox/TextboxProps";

const log = getLogger("components.table.TableConfigurations");

export class TableConfigurations extends Layout {
    private sourceTable: Table;
    private _activeConfig: TableConfig;
    private layoutPath: string;
    private tableId: string;
    closeMethod: () => void;
    private panelHeader: Panel;
    private listConfig: List;
    private panelCommands: Panel;
    private textboxConfigName: Textbox;
    private buttonDelete: Button;
    private buttonSaveAs: Button;
    private buttonAddNew: Button;
    private checkboxDefault: Checkbox;
    private checkboxActive: Checkbox;
    private table: Table;
    private buttonTableRow: TableRow;
    private sourceTableConfig: DataSource;
    private switchConfigs: Switch;

    private commandButtonProps: Partial<ButtonProps> = {
        variant: ButtonVariant.round,
        color: "subtle.darker",
        marginTop: 12,
        marginLeft: 2,
        marginRight: 2,
        imageHeight: 20,
        imageWidth: 20
    };
    private moveButtonProps: Partial<ButtonProps> = {
        preventCollapse: true,
        width: 25,
        height: 18,
        color: "primary",
        paddingLeft: 0,
        paddingRight: 0,
        marginLeft: 2,
        marginRight: 2,
        rowBreak: false
    };
    private moveLastButtonProps: Partial<ButtonProps> = {
        preventCollapse: true,
        width: 14,
        height: 18,
        color: "subtle.dark",
        borderWidth: 0,
        paddingLeft: 0,
        paddingRight: 0,
        marginLeft: 2,
        marginRight: 2,
        rowBreak: false
    };

    constructor(table: Table, parentLayoutPath: string, configToSelect?: TableConfig, props?: Partial<LayoutProps>) {
        super({
            auth: AuthType.ANY,
            title: "Configure Table",
            fillRow: true,
            needsServerLayout: false,
            ...props
        });
        this.sourceTable = table;
        this.sourceTableConfig = new DataSource({ id: "sourceTableConfig", url: "common/table-config" });
        this.layoutPath = parentLayoutPath;
        this.tableId = table.id;
        this.setupPanelHeader();
        this.setupTable(table);
        this.setupMountListener();
        this.loadConfigs(configToSelect);
    }

    private setupMountListener() {
        //we want to fix the table's height so it can't shrink when we apply a config to it
        //if we did let it shrink, it grows back immediately (when switching between existing configs)
        //the result is the overall layout's size changes, which causes a jumping effect
        //this is why we populated each TableRow's DOM early
        this.addMountListener(() => {
            this.table.height = DOMUtil.getElementHeight(this.table._element);
        });
    }

    getDialogProps(): Partial<DialogProps> {
        return {
            title: "Configure Table",
            okVisible: false,
            panelTitleProps: { backgroundColor: "primary.darker", color: "primary.reverse" }
        };
    }

    private setupPanelHeader() {
        this.panelHeader = new Panel({ id: "configTablePanelHeader", fillRow: true });
        this.add(this.panelHeader);
        this.setupSwitchConfigs();
        this.setupListConfig();
        this.setupPanelCommands();
    }

    private setupSwitchConfigs() {
        this.switchConfigs = new Switch({
            id: "switchConfigs",
            captionVisible: false,
            leftCaption: "All Configs",
            rightCaption: "My Configs",
            marginLeft: 10,
            marginBottom: 10
        });
        this.switchConfigs.addChangeListener((event: ChangeEvent) => this.swtichConfigOnChange(event));
        this.panelHeader.add(this.switchConfigs);
    }

    private swtichConfigOnChange(event: ChangeEvent) {
        if (!event.userInitiatedChange)
            return;
        const showAllConfigs = event.newValue === false;
        this.listConfig.filter(item => {
            const listItem = item.suppliedInput as ListConfigItem;
            return showAllConfigs || listItem.tableConfig.isCurrentUsersConfig();
        });
        this.listConfig.selectFirstItem();
    }

    private setupListConfig() {
        this.listConfig = new List({
            id: "listTableConfigs",
            type: ListType.PERSISTENT,
            selectionMode: SelectionMode.SINGLE,
            height: 125,
            width: 500,
            borderColor: "subtle",
            borderWidth: 1,
            borderRadius: 4,
            rowBreak: false
        });
        this.listConfig.addSelectionListener((event: SelectionEvent) => {
            this.loadFromSelection(event.newSelection as ListConfigItem);
        });
        this.panelHeader.add(this.listConfig);
    }

    private loadFromSelection(selectedItem: ListConfigItem) {
        const tableConfig = selectedItem.tableConfig;
        this.applyConfig(tableConfig);
        this.textboxConfigName.text = tableConfig.name;
        this.checkboxDefault.checked = tableConfig.isDefault === "Y";
        this.checkboxActive.checked = tableConfig.isActive;
        this.syncPanelCommands();
    }

    private getCurrentSelection(): ListConfigItem {
        return this.listConfig.selectedItem as ListConfigItem;
    }

    private setupPanelCommands() {
        this.panelCommands = new Panel({ id: "configTablePanelCommands", fillHeight: true, paddingTop: 0 });
        this.panelHeader.add(this.panelCommands);
        this.setupTextboxConfigName();
        this.setupButtonDelete();
        this.setupButtonSaveAs();
        this.setupButtonSave();
        this.setupButtonAddNew()
        this.setupCheckboxDefault();
        this.setupCheckboxActive();
    }

    private setupTextboxConfigName() {
        this.textboxConfigName = this.createConfigNameTextbox({
            id: "configTableTextboxName",
            caption: "Name",
            width: 325,
            paddingTop: 0,
            paddingLeft: 20,
            rowBreak: false
        });

        this.panelCommands.add(this.textboxConfigName);
    }

    private createConfigNameTextbox(props: Partial<TextboxProps>): Textbox {
        const textbox = new Textbox({...props});

        this.sourceTableConfig.getMetadata().then(metadata => {
            const nameLength = metadata.output?.name?.length;
            if (nameLength != null)
                textbox._input.maxLength = nameLength;
        });

        return textbox;
    }

    private resetTextboxConfigNamePlaceholder() {
        const selectedConfig = this.getCurrentSelection()
        this.textboxConfigName.placeholder = selectedConfig != null ? "" : "<New Configuration>";
    }

    private setupButtonDelete() {
        this.buttonDelete = new Button({
            ...this.commandButtonProps,
            id: "configTableButtonDelete",
            tooltip: "Delete the selected configuration",
            imageName: "delete",
            enabled: false,
            rowBreak: false,
            onClick: () => this.delete()
        });
        this.panelCommands.add(this.buttonDelete);
    }

    private setupButtonSaveAs() {
        this.buttonSaveAs = new Button({
            ...this.commandButtonProps,
            id: "configTableButtonSaveAs",
            tooltip: "Save a new copy of the selected configuration",
            imageName: "duplicateDisk",
            enabled: false,
            rowBreak: false,
            onClick: () => this.saveAs()
        });
        this.panelCommands.add(this.buttonSaveAs);
    }

    private setupButtonSave() {
        const buttonSave = new Button({
            ...this.commandButtonProps,
            id: "configTableButtonSave",
            tooltip: "Save the selected configuration",
            imageName: "disk",
            rowBreak: false,
            onClick: () => this.save()
        });
        this.panelCommands.add(buttonSave);
    }

    private setupButtonAddNew() {
        this.buttonAddNew = new Button({
            ...this.commandButtonProps,
            id: "configTableButtonAdd",
            tooltip: "Add a new/blank configuration",
            imageName: "add",
            onClick: () => this.addNew()
        });
        this.panelCommands.add(this.buttonAddNew);
    }

    private syncPanelCommands() {
        const currentConfig = this.getCurrentSelection()?.tableConfig;
        const isOwner = currentConfig?.isCurrentUsersConfig();
        this.buttonDelete.enabled = currentConfig != null;
        this.buttonSaveAs.enabled = currentConfig != null;
        this.textboxConfigName.enabled = true;

        if (currentConfig != null) {
            [this.buttonDelete, this.textboxConfigName].forEach(comp => {
                comp.enabled = currentConfig != null && isOwner;
                comp.disabledTooltip = !isOwner ? "You are not the owner of this configuration." : undefined;
            });
        }
    }

    private setupCheckboxDefault() {
        this.checkboxDefault = new Checkbox({
            caption: "Default",
            fontSize: "medium",
            marginLeft: 12,
            rowBreak: false
        });
        this.checkboxDefault.addChangeListener((event: ChangeEvent) => {
            const listItem = this.getCurrentSelection();
            if (listItem != null)
                this.updateIsDefault(listItem.tableConfig);

        });
        this.panelCommands.add(this.checkboxDefault);
    }

    private setupCheckboxActive() {
        this.checkboxActive = new Checkbox({
            caption: "Active",
            fontSize: "medium",
            marginLeft: 12
        });
        this.checkboxActive.addChangeListener((event: ChangeEvent) => {
            const listItem = this.getCurrentSelection();
            if (listItem != null)
                this.updateIsActive(listItem.tableConfig);

        });
        this.panelCommands.add(this.checkboxActive);
    }

    private updateIsDefault(tableConfig: TableConfig) {
        const value = this.checkboxDefault.checked ?
            this.checkboxDefault.valueChecked : this.checkboxDefault.valueUnchecked;
        tableConfig.isDefault = value;
    }

    private updateIsActive(tableConfig: TableConfig) {
        tableConfig.isActive = this.checkboxActive.checked;
    }

    private async delete() {
        const listItem = this.getCurrentSelection();
        const tableConfig = listItem.tableConfig;
        const message = `Table configuration '${tableConfig.name}' will be deleted.\n\nDo you wish to continue?`;
        if (await ReflectiveDialogs.showDestructive(message, "Delete Table Configuration?") === true) {
            tableConfig.delete().then(response => {
                if (this._activeConfig === tableConfig)
                    this._activeConfig = null;
                this.listConfig.removeItem(listItem);
                this.resetPanelCommands();
                this.listConfig.selectFirstItem();
                if (this.listConfig.selectedIndex === -1) {
                    this.syncPanelCommands();
                    this.applyConfig(this.sourceTable.baseConfig);
                }
                this.sortConfigs();
                ReflectiveSnackbars.showSnackbar(
                    "Table configuration '" + tableConfig.name + "' has been deleted.");
            }).catch(error => {
                ReflectiveSnackbars.showWarningSnackbar(
                    "An error occurred while deleting the table configuration.");
            });
        }
    }

    private async saveAs() {
        //ask user for new name
        //create a new TableConfig object with that name
        //set all settings from visible table
        //post that modelRow
        //add it to the list
        const newConfigName = await this.getConfigName();
        if (StringUtil.isEmptyString(newConfigName))
            return;
        const newConfig = this.table.buildConfig();
        newConfig.ownerId = UserSettings.getUserId();
        newConfig.name = newConfigName;
        newConfig.layoutPath = this.layoutPath;
        newConfig.tableId = this.tableId;
        newConfig.post().then(row => {
            this.doAfterSave(newConfig, row, true);
        }).catch(error => {
            this.handleSaveError(error, "An error occurred while saving the new table configuration.");
        });
    }

    private async save() {
        //update the existing TableConfig object (that lives inside the list) with the changed columns/sort info,
        //or save a new one if one isn't selected
        //then post that TableConfig
        if (this.validateName(this.textboxConfigName, this.getCurrentSelection()?.tableConfig?.id) === false) {
            return;
        }
        const listItem = this.getCurrentSelection();
        let savingNewConfig = false;
        let configToSave: TableConfig;
        if (listItem != null)
            configToSave = listItem.tableConfig;
        else {
            savingNewConfig = true;
            configToSave = new TableConfig();
            configToSave.layoutPath = this.layoutPath;
            configToSave.tableId = this.tableId;
            configToSave.isActive = this.checkboxActive.checked;
            this.updateIsDefault(configToSave);
        }
        const changedConfig = this.table.buildConfig();
        configToSave.name = this.textboxConfigName.text;
        configToSave.columnDescriptors = [...changedConfig.columnDescriptors];
        configToSave.orderByInfo = [...changedConfig.orderByInfo];
        configToSave.post().then(row => {
            this.doAfterSave(configToSave, row, savingNewConfig);
            ReflectiveSnackbars.showSnackbar("Table configuration '" + configToSave.name + "' has been saved.");

        }).catch(error => {
           this.handleSaveError(error, "An error occurred while saving the table configuration.");
        });
    }

    handleSaveError(error: any, message: string) {
        if (error instanceof ServerError && error.errorTag == null && error.messages?.length == 1)
            message = error.messages[0]
        ReflectiveSnackbars.showWarningSnackbar(message);
    }

    private validateName(textboxName: Textbox, configId?: string): boolean {
        const name = textboxName.text;
        if (StringUtil.isEmptyString(name) === true) {
            ReflectiveDialogs.showMessage("Please provide a name for the table configuration before saving.", "Name Missing");
            return false;
        }

        if (Array.isArray(this.listConfig.items) === true) {
            for (const item of this.listConfig.items) {
                const listItem = item.suppliedInput as ListConfigItem;
                if (configId !== listItem.tableConfig.id && name.toLowerCase() === listItem.tableConfig?.name?.toLowerCase()) {
                    const msg = "The name \"" + name + "\" is already used by another configuration for this table. Please choose a different, unique name";

                    ReflectiveDialogs.showMessage(msg, "Duplicate Name");
                    return false;
                }
            }
        }
    }

    private doAfterSave(config: TableConfig, row: ModelRow, savingNewConfig: boolean) {
        if (savingNewConfig === true)
            this.doAfterSaveNew(config, row);
        this.enforceSingleConfigOptions(config);
        this.sortConfigs();
    }

    private addNew() {
        this.clearConfig();
    }

    private clearConfig() {
        this.listConfig.selectedIndex = -1;
        this.applyConfig(this.sourceTable.baseConfig);
        this.resetPanelCommands();
        this.syncPanelCommands();
    }

    private resetPanelCommands() {
        this.textboxConfigName.text = "";
        this.checkboxDefault.checked = false;
        this.checkboxActive.checked = false;
        this.resetTextboxConfigNamePlaceholder();
    }

    private doAfterSaveNew(config: TableConfig, row: ModelRow) {
        config.id = row.get("id", null);
        const listConfigItem = new ListConfigItem(config);
        this.listConfig.addItem(listConfigItem);
        this.listConfig.selectedItem = listConfigItem;
        this.loadFromSelection(listConfigItem);
    }

    /**
     * This method makes sure that the 'is default' and 'active' options
     * are only eligible for one configuration at a time
     * @param config
     */
    private enforceSingleConfigOptions(config: TableConfig) {
        const savedConfigIsDefault = config.isDefault === "Y";
        const savedConfigIsActive = config.isActive;
        this._activeConfig = null;
        let defaultConfig = null;
        if (Array.isArray(this.listConfig.items) === true) {
            for (const item of this.listConfig.items) {
                const listItem = item.suppliedInput as ListConfigItem;
                if (config.id !== listItem.tableConfig.id) {
                    if (savedConfigIsDefault === true)
                        listItem.tableConfig.isDefault = "N";
                    if (savedConfigIsActive === true)
                        listItem.tableConfig.isActive = false;
                }
                if (listItem.tableConfig.isDefault === "Y")
                    defaultConfig = listItem.tableConfig;
                if (listItem.tableConfig.isActive === true)
                    this._activeConfig = listItem.tableConfig;
                listItem.resetDisplay();
            }
        }
        if (defaultConfig != null)
            UserSettings.getSingleton().updateDefaultTableConfig(defaultConfig.modelRow);
        else
            UserSettings.getSingleton().removeDefaultTableConfig(config.modelRow);
    }

    private async getConfigName(): Promise<string> {
        const content: SaveAsDialogPanel = new SaveAsDialogPanel({ margin: 0, padding: 0, minWidth: 400, rowBreakDefault: true });
        const message = "Please enter a name for the new configuration and click Save.";
        const labelMessage = new Label({ caption: message });
        const descr = this.createConfigNameTextbox({caption: "Name", fillRow: true, marginTop: 7 });
        content.validationCallback = () => {
            return {component: descr, isValid: this.validateName(descr)}
        };
        content.add(labelMessage, descr);
        const dialogProps = { title: "New Configuration Name", noButtonCaption: "Cancel", yesButtonCaption: "Save" };
        const save = await ReflectiveDialogs.showYesNo(content, null, dialogProps);
        return save === true ? descr.text : null;
    }

    private setupTable(sourceTable: Table) {
        const dataSource = new DataSource({ id: "configDS", url: sourceTable.dataSource?.url }, this);
        dataSource.layoutName = this.layoutName;
        this.table = new Table({
            id: "configTable",
            emptyCaption: "",
            dataSource: dataSource
        });
        this.table.searchFilterVisible = SearchFilterVisible.NEITHER;
        this.table.data = [];
        let index = 0;
        for (const column of sourceTable.columns) {
            const newColumn = column.getCopyForTableConfig();
            this.table.addColumn(newColumn, index === 0, index === sourceTable.columns.length - 1);
            index++;
        }
        for (let x = 0; x < sourceTable.rows.length && x < 2; x++) {
            const sourceTableRow = sourceTable.rows[x];
            this.table.addRow(sourceTableRow.data,
                { expandable: false, virtualized: false },
                { addToData: true, display: true });
            //populate DOM early so we know the table's height when it's mounted
            this.table.rows[x].populateDOMIfNeeded();
        }
        for (const row of this.table.allRows) {
            row.forEveryChildComponent((comp: Component) => comp.setProps({ enabled: false, visible: true }));
        }
        this.addTableButtonRow();
        this.add(this.table);
    }

    private addTableButtonRow() {
        this.buttonTableRow = new TableRow(this.table, {});
        this.table.addNonStandardRow(this.buttonTableRow);
        this.buttonTableRow.setDesigner(this.getDesigner());
        this.buttonTableRow.owner = this.owner;
        const props = {
            ...this.table.rowProps,
            id: this.table.id + "ButtonRow",
            data: new ModelRow(null),
            index: 0,
            expanded: false,
            expandable: false,
            virtualized: false
        };
        this.buttonTableRow.index = 0;
        this.buttonTableRow.setProps(props);
        this.buttonTableRow.mode = TableRowMode.NONE;
        this.buttonTableRow._element.tabIndex = -1;
        this.loadButtonRowCells();
        this.table._insertBelowTableHeader(this.buttonTableRow._element);
    }

    private loadButtonRowCells() {
        this.buttonTableRow.clear();
        this.buttonTableRow.populateDOM();
        for (const cell of this.buttonTableRow.cells) {
            cell.removeAll();
            cell.add(...this.getCellComponents());
        }
        this.evaluateMoveButtonAvailability();
    }

    private getCellComponents(): Component[] {
        const isBaseConfig = this.table.configInUse === this.sourceTable.baseConfig;
        const configOwnedByUser = this.table.configInUse?.isCurrentUsersConfig() === true;

        if (this.table.columns.length > 1 && (isBaseConfig || configOwnedByUser)) {
            return [
                this.getMoveFirstButton(),
                this.getMoveLeftButton(),
                this.getMoveRightButton(),
                this.getMoveLastButton(),
            ];
        }
        return [];
    }

    private getMoveLeftButton(): Button {
        const moveLeft = new Button({
            ...this.moveButtonProps,
            id: "moveLeft",
            caption: "<",
            tooltip: "Move column one to the left"
        });
        moveLeft.addClickListener((event: ClickEvent) => {
            const enclosingCell = moveLeft.findParentOfType("cell") as TableCell;
            const columnIndex = enclosingCell.col.index;
            this.table.switchColumns(columnIndex - 1, columnIndex);
            this.evaluateMoveButtonAvailability();
        });
        return moveLeft;
    }

    private getMoveRightButton(): Button {
        const moveRight = new Button({
            ...this.moveButtonProps,
            id: "moveRight",
            caption: ">",
            tooltip: "Move column one to the right"
        });
        moveRight.addClickListener((event: ClickEvent) => {
            const enclosingCell = moveRight.findParentOfType("cell") as TableCell;
            const columnIndex = enclosingCell.col.index;
            this.table.switchColumns(columnIndex, columnIndex + 1);
            this.evaluateMoveButtonAvailability();
        });
        return moveRight;
    }

    private getMoveFirstButton(): Button {
        const moveFirst = new Button({
            ...this.moveLastButtonProps,
            id: "moveFirst",
            caption: "<<",
            tooltip: "Move to be the first column"
        });
        moveFirst.addClickListener((event: ClickEvent) => {
            const enclosingCell = moveFirst.findParentOfType("cell") as TableCell;
            this.table.moveColumn(enclosingCell.col.index, 0);
            this.evaluateMoveButtonAvailability();
        });
        return moveFirst;
    }

    private getMoveLastButton(): Button {
        const moveLast = new Button({
            ...this.moveLastButtonProps,
            id: "moveLast",
            caption: ">>",
            tooltip: "Move to be the last column"
        });
        moveLast.addClickListener((event: ClickEvent) => {
            const enclosingCell = moveLast.findParentOfType("cell") as TableCell;
            this.table.moveColumn(enclosingCell.col.index, this.table.columns.length - 1);
            this.evaluateMoveButtonAvailability();
        });
        return moveLast;
    }

    private evaluateMoveButtonAvailability() {
        const lastCellIndex = this.buttonTableRow.cells.length - 1;
        for (let x = 0; x < this.buttonTableRow.cells.length; x++) {
            const cell = this.buttonTableRow.cells[x];
            cell.forEveryTopLevelChildComponent((component: Component) => {
                switch (component.id) {
                    case "moveLeft":
                    case "moveFirst":
                        component.visible = x != 0;
                        break;
                    case "moveRight":
                    case "moveLast":
                        component.visible = x != lastCellIndex;
                        break;
                    default:
                        break;
                }
            });
        }
    }

    private async loadConfigs(configToSelect: TableConfig) {
        const filter = {
            layout_path: this.layoutPath,
            table_id: this.tableId,
            retrieve_all: true
        };
        this.sourceTableConfig.search(filter).then(response => {
            this.loadList(configToSelect);
        }).catch(error => {
            log.error("An error occurred while retrieving table configuration records");
        });
    }

    /**
     * Load the List component using the queried TableConfig objects.
     *
     * @param configToSelect The table configuration that should be initially selected.  If not provided, the active
     *     configuration will be used.
     */
    private loadList(configToSelect: TableConfig) {
        log.debug("%o table configuration records were found", this.sourceTableConfig.data.length);
        const listConfigs: ListConfigItem[] = [];
        let initialSelection: ListConfigItem;
        const configInUse = this.sourceTable.configInUse;
        for (const row of this.sourceTableConfig.data) {
            const tableConfig = new TableConfig(row);
            if (configInUse?.id === tableConfig.id)
                tableConfig.isActive = true;
            const listConfigItem = new ListConfigItem(tableConfig);
            listConfigs.push(listConfigItem);
            if (configToSelect?.id === tableConfig.id)
                initialSelection = listConfigItem;
            if (tableConfig.isActive === true) {
                initialSelection ??= listConfigItem;
                this._activeConfig = tableConfig;
            }
        }
        this.listConfig.items = listConfigs;
        this.sortConfigs();
        if (initialSelection != null) {
            this.listConfig.selectedItem = initialSelection;
            this.loadFromSelection(initialSelection);
        }
        this.resetTextboxConfigNamePlaceholder();
    }

    private applyConfig(tableConfig: TableConfig) {
        this.table.configInUse = tableConfig;
        this.evaluateMoveButtonAvailability();
        this.loadButtonRowCells(); //reset button row since normal table columns were all removed and re-added
    }

    public get activeConfig(): TableConfig {
        return this._activeConfig;
    }

    private sortConfigs() {
        this.listConfig.sortItems((a, b) => {
            const listConfigA = a.suppliedInput as ListConfigItem;
            const listConfigB = b.suppliedInput as ListConfigItem;
            // Prioritize selected items over non-selected
            const selectedComparison = Number(listConfigB.isSelected()) - Number(listConfigA.isSelected());
            if (selectedComparison !== 0) return selectedComparison;

            // Prioritize default items over non-default
            const defaultComparison = Number(listConfigB.isDefault()) - Number(listConfigA.isDefault());
            if (defaultComparison !== 0) return defaultComparison;

            return StringUtil.compare(listConfigA.tableConfig.name, listConfigB.tableConfig.name);
        });
    }
}

class ListConfigItem extends Panel {
    private _tableConfig: TableConfig;
    private labelName: Label;
    private labelOwnerName: Label;
    private labelStatus: Label;

    constructor(tableConfig: TableConfig, props?: Partial<PanelProps>) {
        super(props);
        this._tableConfig = tableConfig;
        this.setupLabelName();
        this.setupLabelStatus();
        this.setupLabelOwnerName();
        this.resetDisplay();
    }

    private setupLabelName() {
        this.labelName = new Label({
            caption: this.tableConfig.name,
            rowBreak: false,
            width: 275
        });
        this.add(this.labelName);
    }

    private setupLabelOwnerName() {
        this.labelOwnerName = new Label({
            caption: this.tableConfig.ownerName,
            fontSize: "small",
            rowBreak: false
        });
        this.add(this.labelOwnerName);
    }

    private setupLabelStatus() {
        this.labelStatus = new Label({ caption: "", rowBreak: false, width: 110 });
        this.labelStatus.style.fontStyle = "italic";
        this.add(this.labelStatus);
    }

    resetDisplay() {
        this.labelName.caption = this.tableConfig.name;
        this.labelOwnerName.caption = this.tableConfig.ownerName;
        const statuses: string[] = [];
        if (this.isDefault())
            statuses.push("Default");
        if (this.isSelected())
            statuses.push("Active");
        this.labelStatus.caption = statuses.join(" / ");
    }

    isDefault(): boolean {
        return this.tableConfig.isDefault === "Y";
    }

    isSelected(): boolean {
        return this.tableConfig.isActive === true;
    }

    get tableConfig(): TableConfig {
        return this._tableConfig;
    }
}

class SaveAsDialogPanel extends Panel {
    validationCallback?: () => ValidationResult;
    constructor(props?: Partial<PanelProps>){
        super(props);
    }

    override validate(checkRequired: boolean, showErrors: boolean = true): ValidationResult[] {
        if (this.validationCallback != null) {
            super.validate(checkRequired, showErrors).push(this.validationCallback());
        }
        return super.validate(checkRequired, showErrors);
    }
}
