import { ArrayUtil, getLogger } from "@mcleod/core";

const log = getLogger("components.MountListener");
const TEN_MINUTES = 1000 * 60 * 10;

type MountCallback = () => void;

enum MountType {
    MOUNT,
    UNMOUNT
}

interface MountListener {
    element: Element;
    //Used to track that an element was put in the DOM (so that we don't fire unmount listeners for an element that
    //was never really mounted)
    elementAddedToDOM?: boolean;
    callback: MountCallback;
    addMillis: number;
    mountType: MountType;
}

const mountListeners: MountListener[] = [];

let observer: MutationObserver;

export class MountUtil {
    public static addMountListener(element: Element, callback: MountCallback) {
        MountUtil.internalAddMountListener(element, callback, MountType.MOUNT);
    }

    public static removeMountListener(element: Element, callback: MountCallback) {
        this.internalRemoveMountListener(MountType.MOUNT, element, callback);
    }

    public static addUnmountListener(element: Element, callback: MountCallback) {
        MountUtil.internalAddMountListener(element, callback, MountType.UNMOUNT);
    }

    public static removeUnmountListener(element: Element, callback: MountCallback) {
        this.internalRemoveMountListener(MountType.UNMOUNT, element, callback);
    }

    private static handleMutation() {
        const nowMillis = new Date().getTime();
        for (let i = mountListeners.length - 1; i >= 0; i--) {
            const listener = mountListeners[i];
            const contains = document.body.contains(listener.element);
            //Track when the element was added to the DOM, so that we don't fire unmount listeners too early
            if (contains && listener.elementAddedToDOM == null)
                listener.elementAddedToDOM = true;
            if ((contains && listener.mountType === MountType.MOUNT) ||
                (listener.elementAddedToDOM === true && !contains && listener.mountType === MountType.UNMOUNT)) {
                mountListeners.splice(i, 1);
                listener.callback();
                log.debug(() => ["Mounted", listener.mountType, listener.element, "New mount listeners", mountListeners]);
            }
            else if (nowMillis - listener.addMillis > TEN_MINUTES) {
                mountListeners.splice(i, 1);
                log.debug(() => ["Mount listener expired", listener.element, "New mount listeners", mountListeners]);
            }
        }
        if (mountListeners.length === 0) {
            log.debug(() => ["Disconnected mount listener observer"]);
            observer.disconnect();
        }
    }

    private static internalAddMountListener(element: Element, callback: MountCallback, mountType: MountType) {
        mountListeners.push({ element, callback, addMillis: new Date().getTime(), mountType: mountType });
        log.debug(() => ["Add mount listener", mountListeners]);
        if (observer == null)
            observer = new MutationObserver((_mutations, _inObserver) => MountUtil.handleMutation());
        if (mountListeners.length === 1)
            observer.observe(document.body, { childList: true, subtree: true });
    }

    public static internalRemoveMountListener(mountType: MountType, element: Element, callback: MountCallback) {
        log.debug("Received request to remove %o listener for element %o, callback method %o", mountType, element, callback);
        const existingListener = MountUtil.getMountListener(mountType, element, callback);
        if (existingListener != null) {
            log.debug("Removing listener %o", existingListener);
            const index = mountListeners.indexOf(existingListener);
            if (index >= 0)
                mountListeners.splice(index, 1);
        }
        if (ArrayUtil.isEmptyArray(mountListeners)) {
            log.debug("Disconnected mount listener observer");
            observer.disconnect();
        }
    }

    private static getMountListener(mountType: MountType, element: Element, callback: MountCallback): MountListener {
        for (const ml of mountListeners) {
            if (ml.mountType === mountType && ml.element === element && ml.callback === callback) {
                return ml;
            }
        }
        return null;
    }
}
