import { Button, Label, Layout, List, Panel, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutPanelPermsGrant extends Layout {
    buttonToggleUsers: Button;
    labelGroups: Label;
    labelHeading: Label;
    labelSelectItem: Label;
    labelUsers: Label;
    listGroups: List;
    listUsers: List;
    panel1: Panel;
    panel2: Panel;
    panelLeft: Panel;
    panelSecures: Panel;
    panelUserGroups: Panel;
    textSearchGroups: Textbox;
    textSearchUsers: Textbox;
}
