/**
 * This class is a Promise that can be resolved or rejected from outside the Promise.
 * It can be useful when you want a promise to be resolved by a user acttion,
 * such as a button click.
 */
export class ResolvablePromise<T> {
    private _resolve: (value?: T | PromiseLike<T>) => void;
    private _reject: (reason?: any) => void;
    private _promise: Promise<T>;

    constructor() {
        this._promise = new Promise((res, rej) => {
            this._resolve = res;
            this._reject = rej;
        });
    }

    public get promise() {
        return this._promise;
    }

    public resolve(value?: T | PromiseLike<T>) {
        this._resolve(value);
    }

    public reject(reason?: any) {
        this._reject(reason);
    }
}
