import { RowConstructor, UrlUtil } from ".";
import { Api } from "./Api";
import { ArrayUtil } from "./ArrayUtil";
import { Collection } from "./Collection";
import { ErrorHandler } from "./ErrorHandler";
import { LOOKUP_MODEL_PREFIX, ModelRow, ModelRowType } from "./ModelRow";
import { OrderByInfo } from "./OrderByInfo";

export const Model = {
    searchSingleRecord: searchSingleRecordModel,
    search: searchModel,
    getDefaultRow: getDefaultModelRow
}

async function getDefaultModelRow<RowType>(modelPath: string, fieldList?: string): Promise<RowType> {
    if (fieldList != null)
        modelPath = `${modelPath}?_field_list=${fieldList}`;
    const result = await Api.get(modelPath);
    return result.data[0];
}

async function searchSingleRecordModel<RowType>(modelPath: string, filter?: any, fieldList?: string | object, errorHandler?: ErrorHandler, rowConstructor?: RowConstructor): Promise<ModelRow> {
    const response = await searchModel<RowType>(modelPath, filter, fieldList, errorHandler, rowConstructor, 0);
    if (response.modelRows.length === 0)
        return null;
    else
        return response.getSingleModelRow();
}

function getFullBody(body?: any, fieldList?: object): string {
    const stringBody = body == null ? undefined : JSON.stringify(body);
    if (fieldList == null || stringBody == null)
        return stringBody;
    const resultObject = JSON.parse(stringBody); // gross.  There's gotta be a better way.
    resultObject["_field_list"] = fieldList;
    return JSON.stringify(resultObject);
}

async function searchModel<RowType>(modelPath: string, filter?: any, fieldList?: string | object, errorHandler?: ErrorHandler, rowConstructor?: RowConstructor, index?: number): Promise<ModelSearchResult> {
    filter = _cleanSearchFilter(filter);
    fieldList = _cleanFieldList(fieldList);
    addLayoutId(fieldList);
    const body = getFullBody(filter, fieldList);
    return Api.search<ModelSearchResult>(modelPath, body, null, errorHandler).then(response => {
        const result: ModelSearchResult = new ModelSearchResult();

        if (index != null && response?.data.length > 0)
            result.modelRows.push(buildModelRow(response.data[index], modelPath, rowConstructor, filter));
        else
            for (const data of response.data)
                result.modelRows.push(buildModelRow(data, modelPath, rowConstructor, filter))

        result.orderBy = _fillOrderBy(response.order_by);

        if (response.actual_row_count != null)
            result.actualRowCount = response.actual_row_count;

        if (response.summary_data != null)
            result.summaryData = new ModelRow<any>("", false, response.summary_data);

        return result;
    });
}

function _cleanSearchFilter(filter: any): any {
    if (filter instanceof ModelRow) {
        filter.removeLookupModelData();
    }
    return filter;
}

function _cleanFieldList(fieldList: string | object): object {
    if (fieldList == null)
        return null;
    if (typeof fieldList === "object")
        return fieldList;
    return { layoutName: fieldList };
}

function addLayoutId(fieldList: object) {
    if (fieldList == null)
        return;
    const layoutId = UrlUtil.getSearchParamFromUrl("layoutId");
    if (layoutId != null)
        fieldList["layoutId"] = layoutId;
}

function _fillOrderBy(serverOrderBy: string[]): OrderByInfo[] {
    if (ArrayUtil.isEmptyArray(serverOrderBy))
        return [];
    const result = [];
    for (const singleOrderBy of serverOrderBy) {
        singleOrderBy.trim();
        let field = singleOrderBy;
        let sort = "asc";
        const indexOfSpace = singleOrderBy.indexOf(" ");
        if (indexOfSpace > 0) {
            field = singleOrderBy.substring(0, indexOfSpace);
            sort = singleOrderBy.substring(indexOfSpace + 1).toLowerCase();
        }
        result.push({ field: field, sort: sort });
    }
    return result;
}

export class ModelSearchResult {
    modelRows: ModelRow<any>[];
    orderBy: OrderByInfo[];
    actualRowCount?: number;
    summaryData: ModelRow;

    constructor() {
        this.modelRows = [];
        this.orderBy = [];
        this.actualRowCount = 0;
    }

    getSingleModelRow() {
        return this.modelRows[0];
    }

    mapRowsByField(fieldName: string): Collection<ModelRow> {
        const result = {};
        this.modelRows.forEach(modelRow => result[modelRow.get(fieldName)] = modelRow);
        return result;
    }
}

function buildModelRow<RowType>(data: any, modelPath: string, rowConstructor?: RowConstructor, filter?: any): ModelRow<RowType> {
    const row = rowConstructor == null ? new ModelRow<RowType>(modelPath) : new rowConstructor();
    row._appending = false;
    if (filter != null && "lm_search" in filter)
        row.type = ModelRowType.LOOKUP_MODEL_DATA;
    if (rowConstructor == null)
        row.setValues(data);
    else
        row.set(data);
    _convertLookupModelData(row);
    return row as ModelRow<RowType>;
}

function _convertLookupModelData(row: ModelRow) {
    const rowMetaData = row.getMetadata();
    if (rowMetaData == null || row.data == null)
        return;
    for (const field of Object.keys(row.data)) {
        if (field.startsWith(LOOKUP_MODEL_PREFIX) !== true)
            continue;
        const fieldMetaData = rowMetaData.getFieldFromOutput(field.substring(LOOKUP_MODEL_PREFIX.length));
        if (fieldMetaData?.lookupModel == null)
            continue;
        //get first lookup model data from the row
        //at this point we could only have one (or none) since we are only able to get one
        //lookup model selection's values from the server as part of a standard QueryModelEndpoint search
        const lmDataFromRow = row.getFirstLookupModelData(field);
        if (lmDataFromRow == null)
            continue;
        if (lmDataFromRow instanceof ModelRow)
            continue;
        const lmModelRow = new ModelRow(fieldMetaData?.lookupModel, false, lmDataFromRow);
        lmModelRow.type = ModelRowType.LOOKUP_MODEL_DATA;
        row.setLookupModelData(field, lmModelRow);
    }
}
