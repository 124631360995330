import { ArrayUtil, CurrencyUtil, ModelRow, StringUtil } from "@mcleod/core";
import { Chart as ChartJS } from "chart.js/auto";
import { Component } from "../../base/Component";
import { ComponentPropDefinitions } from "../../base/ComponentProps";
import { ComponentTypes } from "../../base/ComponentTypes";
import { DesignerInterface } from "../../base/DesignerInterface";
import { Event } from "../../events/Event";
import { ChartDataset } from "./ChartDataset";
import { ChartPropDefinitions, ChartProps } from "./ChartProps";
import { sampleChartData } from "./SampleChartData";

export type ChartType = "bar" | "bubble" | "doughnut" | "line" | "pie" | "polarArea" | "radar" | "scatter";
export type ChartLegendPosition = "none" | "top" | "left" | "bottom" | "right";
export type ChartFillType = "none" | "origin" | "start" | "end";

export interface ChartDataPoint {
    x: any;
    y: any;
    tooltipValue?: any;
    backgroundColor?: string;
}

/*
ChartValue is a different data point type that we might use with GaugeCharts or other charts that aren't based on x/y values
It would probably be better if we baked this into ChartDataPoint instead of making a new interface
export interface ChartValue {
  value: any;
  tooltipValue?: any;
}
 */
export interface ChartSeries {
    label: string;
    xAxisValues: string[];
    fill: ChartFillType;
    tension: number;
    data: number[];
    xAxisID: string;
    yAxisID: string;
}

export class Chart extends Component implements ChartProps {
    private chart: ChartJS;
    private _chartData: ChartDataPoint[];
    private renderEnabled = true;
    private _title: string;
    private _chartType: ChartType;
    private _animate: boolean;
    private _legendPosition: ChartLegendPosition;
    private _showTooltips: boolean;
    private _showGridLines: boolean;
    private _smooth: boolean;
    private _datasetLabel: string;
    private _xAxisField: string;
    private _fillType: ChartFillType;
    private datasets: ChartDataset[] = [];
    public renderDefaultData = true;
    public xAxisProps: any = { ticks: {}, grid: { display: this.showGridLines } };
    public yAxisProps: any = { ticks: {}, grid: { display: this.showGridLines } };
    private _scaleProps: any;
    private _suggestedMinPercentage: number;
    private _suggestedMaxPercentage: number;
    private _smallestXValue: number;
    private _largestXValue: number;
    private _smallestYValue: number;
    private _largestYValue: number;
    private _dataTooltipCallback: (context: any) => string;

    constructor(props?: Partial<ChartPropDefinitions>) {
        super("canvas", props);
        this.renderEnabled = false;
        this.setProps(props);
        this.renderEnabled = true;
        this.renderChart();
    }

    public get title(): string {
        return this._title;
    }

    public set title(value: string) {
        this._title = value;
        this.renderChart();
    }

    public get animate(): boolean {
        return this._animate == null ? true : this._animate;
    }

    public set animate(value: boolean) {
        this._animate = value;
        this.renderChart();
    }

    public get smooth(): boolean {
        return this._smooth == null ? false : this._smooth;
    }

    public set smooth(value: boolean) {
        this._smooth = value;
        this.renderChart();
    }

    public get legendPosition(): ChartLegendPosition {
        return this._legendPosition || "top"
    }

    public set legendPosition(value: ChartLegendPosition) {
        this._legendPosition = value;
        this.renderChart();
    }

    public get fillType(): ChartFillType {
        return this._fillType;
    }

    public set fillType(value: ChartFillType) {
        this._fillType = value;
        this.renderChart();
    }

    public get showTooltips(): boolean {
        return this._showTooltips == null ? true : this._showTooltips;
    }

    public set showTooltips(value: boolean) {
        this._showTooltips = value;
        this.renderChart();
    }

    public get showGridLines(): boolean {
        return this._showGridLines == null ? true : this._showGridLines;
    }

    public set showGridLines(value: boolean) {
        this._showGridLines = value;
        this.renderChart();
    }

    public get chartType(): ChartType {
        return this._chartType || "line";
    }

    public set chartType(value: ChartType) {
        this._chartType = value;
        this.renderChart();
    }

    public get xAxisField(): string {
        return this._xAxisField;
    }

    public set xAxisField(value: string) {
        this._xAxisField = value;
    }

    public get scaleProps(): any {
        return this._scaleProps || { x: this.xAxisProps, y: this.yAxisProps };
    }

    public set scaleProps(value: any) {
        this._scaleProps = value;
    }

    private get chartData(): ChartDataPoint[] {
        if (this._chartData != null)
            return this._chartData;
        else if (this._designer != null)
            return sampleChartData;
        else
            return null;
    }

    public displayData(data: ModelRow, allData: ModelRow[], rowIndex: number) {
        this._chartData = this.transformModelRows(allData);
        this.renderChart();
        super.displayData(data, allData, rowIndex);
    }

    public transformModelRow(row: ModelRow, xAxisField: string = this.xAxisField, yAxisField: string = this.field): ChartDataPoint {
        return { x: row.get(xAxisField), y: row.get(yAxisField) };
    }

    protected transformModelRows(data: ModelRow[], xAxisField?: string, yAxisField?: string): ChartDataPoint[] {
        const result = [];
        for (const row of data)
            result.push(this.transformModelRow(row, xAxisField, yAxisField));
        return result;
    }

    public renderChart(datasets?: Partial<ChartSeries>[]) {
        if (!this.renderEnabled)
            return;
        if (datasets == null)
            datasets = this.getDatasets();
        if (this.chart != null)
            this.chart.destroy();
        this.handleSmallestLargest(this.datasets);
        this.chart = new ChartJS(this._element as HTMLCanvasElement, {
            type: this.chartType,
            options: this.chartOptions,
            data: {
                labels: datasets?.[0]?.xAxisValues,
                datasets: datasets as any
            }
        });
    }

    private getDatasets(): Partial<ChartSeries>[] {
        const result: Partial<ChartSeries>[] = [];
        if (this.renderDefaultData !== false)
            result.push({
                label: this.datasetLabel || "Values",
                xAxisValues: this.chartData?.map(row => row.x),
                data: this.chartData?.map(row => row.y),
                fill: this.fillType,
                tension: this.smooth ? 0.15 : 0
            });
        for (const dataset of this.datasets) {
            const datasetObject = {
                label: dataset.label || "Values",
                fill: dataset.fillType,
                yAxisID: dataset.axisId,
                tension: dataset.smooth ? 0.15 : 0,
                xAxisValues: null,
                data: null,
                backgroundColor: undefined
            };
            if (dataset.chartData?.[0]?.["y"] != null) { //this is really testing if the chartData element is a ChartDataPoint or a ChartValue
                datasetObject.xAxisValues = dataset.chartData?.map(row => row.x);
                datasetObject.data = dataset.chartData?.map(row => row.y);
                datasetObject.backgroundColor = this.getDatasetBackgroundColors(dataset.chartData);
            }
            //this would be put back if we start using ChartValue again
            // else
            //   datasetObject.data = dataset.chartData?.map(row => row.value);
            result.push(datasetObject);
        }
        return result;
    }

    private getDatasetBackgroundColors(chartData: ChartDataPoint[]): string[] {
        // only return bac
        let anyHasBackgroundColor = false;
        chartData.forEach(dataPoint => anyHasBackgroundColor = anyHasBackgroundColor || dataPoint.backgroundColor != null);
        if (!anyHasBackgroundColor)
            return undefined;
        const result = [];
        for (const dataPoint of chartData)
            result.push(dataPoint.backgroundColor || this.getNextColor());
        return result;
    }

    private nextColorIndex = 0;
    // this is chartjs's default color scheme
    private readonly colors = [
        "#36a2eb",
        "#ff6384",
        "#ff9f40",
        "#ffcd56",
        "#4bc0c0",
        "#9966ff",
        "#c9cbcf",
    ];
    private getNextColor(): string {
        const result = this.colors[this.nextColorIndex];
        this.nextColorIndex++;
        if (this.nextColorIndex >= this.colors.length)
            this.nextColorIndex = 0;
        return result;
    }

    public get datasetLabel(): string {
        return this._datasetLabel;
    }

    public set datasetLabel(value: string) {
        this._datasetLabel = value;
        this.renderChart();
    }

    get chartOptions(): any {
        const options: any = { responsive: true };
        options.animation = this.animate;
        options.plugins = {};
        options.plugins.legend = { display: this.legendPosition !== "none" };
        options.plugins.legend.position = this.legendPosition;
        options.plugins.tooltip = this.getTooltipOptions();
        options.plugins.title = { text: this.title };
        options.scales = this.scaleProps;
        return options;
    }

    private getTooltipOptions(): {} {
        if (this.showTooltips === false)
            return { enabled: false };
        return {
            callbacks: {
                label: (context) => this.getTooltipText(context)
            }
        }
    }

    private getTooltipText(chartContext: any): string {
        if (this.dataTooltipCallback != null)
            return this.dataTooltipCallback(chartContext);
        const tooltipValue = this.getDataPointValue(chartContext)?.tooltipValue;
        if (StringUtil.isEmptyString(tooltipValue) === true)
            return; //returning nothing results in the default tooltip behavior
        let label = chartContext.dataset.label || '';
        if (label)
            label += ': ';
        label += tooltipValue;
        return label;
    }

    private getDataPointValue(chartContext: any): ChartDataPoint/*|ChartValue*/ {
        for (const dataset of this.datasets) {
            if (dataset.label === chartContext.dataset.label)
                return dataset.chartData[chartContext.dataIndex];
        }
    }

    public getDatasetPointsForEvent(event: Event) {
        return this.chart.getElementsAtEventForMode(event.domEvent, "index", { intersect: false }, true);
    }

    public addDataset(dataset: ChartDataset) {
        this.adjustFromDataset(dataset);
        this.datasets.push(dataset);
        dataset.dataSource?.addBoundComponent(dataset);
    }

    public removeDataset(dataset: ChartDataset) {
        ArrayUtil.removeFromArray(this.datasets, dataset);
    }

    public removeAllDatasets() {
        this.datasets = [];
    }

    private adjustFromDataset(dataset: ChartDataset) {
        for (const dataPoint of dataset.chartData) {
            this.adjustFromDataPoint(dataPoint, true);
            this.adjustFromDataPoint(dataPoint, false);
        }
    }

    private adjustFromDataPoint(dataPoint: ChartDataPoint/*|ChartValue*/, xAxis: boolean) {
        if (dataPoint["x"] == null) //this is really testing if the dataPoint is a ChartValue
            return;
        const xOrY = xAxis === true ? "x" : "y";
        const axisProps = xAxis === true ? this.xAxisProps : this.yAxisProps;
        if (CurrencyUtil.isCurrency(dataPoint[xOrY])) {
            axisProps.tickPrefix = dataPoint[xOrY].symbol;
            if (axisProps.ticks?.callback == null) {
                axisProps.ticks = {
                    ...axisProps.ticks,
                    callback: (value, index, ticks) => this.adjustTicks(value, axisProps)
                }
            }
            dataPoint.tooltipValue = CurrencyUtil.formatCurrency(dataPoint[xOrY]);
            dataPoint[xOrY] = dataPoint[xOrY].amount;
        }
    }

    private handleSmallestLargest(datasets: ChartDataset[]) {
        if (this.suggestedMinPercentage != null || this.suggestedMaxPercentage != null) {
            this.resetSmallestLargestValues();
            for (const dataset of datasets) {
                for (const dataPoint of dataset.chartData) {
                    if (dataPoint["x"] == null) //this is really testing if the dataPoint is a ChartValue
                        continue;
                    this.setSmallestLargestValues(dataPoint as ChartDataPoint);
                }
            }
            this.updateAxisPropsForSmallestLargest();
        }
    }

    private resetSmallestLargestValues() {
        this._smallestXValue = Number.MAX_SAFE_INTEGER;
        this._largestXValue = Number.MIN_SAFE_INTEGER;
        this._smallestYValue = Number.MAX_SAFE_INTEGER;
        this._largestYValue = Number.MIN_SAFE_INTEGER;
    }

    private setSmallestLargestValues(dataPoint: ChartDataPoint) {
        if (!isNaN(dataPoint.x)) {
            if (dataPoint.x > this._largestXValue)
                this._largestXValue = dataPoint.x;
            if (dataPoint.x < this._smallestXValue)
                this._smallestXValue = dataPoint.x;
        }
        if (!isNaN(dataPoint.y)) {
            if (dataPoint.y > this._largestYValue)
                this._largestYValue = dataPoint.y;
            if (dataPoint.y < this._smallestYValue)
                this._smallestYValue = dataPoint.y;
        }
    }

    private updateAxisPropsForSmallestLargest() {
        if (this.suggestedMinPercentage != null &&
            this._smallestXValue !== Number.MAX_SAFE_INTEGER &&
            this.xAxisProps.suggestedMin == null) {
            this.xAxisProps.suggestedMin = this._smallestXValue - (Math.abs(this._smallestXValue) * (this.suggestedMinPercentage / 100));
        }
        if (this.suggestedMaxPercentage != null &&
            this._largestXValue !== Number.MIN_SAFE_INTEGER &&
            this.xAxisProps.suggestedMax == null) {
            this.xAxisProps.suggestedMax = this._largestXValue + (Math.abs(this._largestXValue) * (this.suggestedMaxPercentage / 100));
        }
        if (this.suggestedMinPercentage != null &&
            this._smallestYValue !== Number.MAX_SAFE_INTEGER &&
            this.yAxisProps.suggestedMin == null) {
            this.yAxisProps.suggestedMin = this._smallestYValue - (Math.abs(this._smallestYValue) * (this.suggestedMinPercentage / 100));
        }
        if (this.suggestedMaxPercentage != null &&
            this._largestYValue !== Number.MIN_SAFE_INTEGER &&
            this.yAxisProps.suggestedMax == null) {
            this.yAxisProps.suggestedMax = this._largestYValue + (Math.abs(this._largestYValue) * (this.suggestedMaxPercentage / 100));
        }
    }

    private adjustTicks(tickValue: any, axisProps: any): string {
        let result = "";
        if (StringUtil.isEmptyString(axisProps?.tickPrefix) !== true)
            result += axisProps.tickPrefix;
        result += tickValue;
        if (StringUtil.isEmptyString(axisProps?.tickSuffix) !== true)
            result += axisProps.tickSuffix;
        return result;
    }

    public get suggestedMinPercentage(): number {
        return this._suggestedMinPercentage || ChartPropDefinitions.getDefinitions().suggestedMinPercentage.defaultValue;
    }

    /**
     * Setting the suggested min percentage value causes the chart's recommended smallest scale value to be
     * less than the smallest value in the dataset.  The default value is 5 percent.  Setting the value to zero will result
     * in the smallest value in the dataset taking up the full height/width of the chart,
     * (in that direction on the scale).
     *
     * Example, a chart where the smallest value is -15, with a suggested min percentage of 10,
     * will result in the chart's smallest scale value being approximately -16.5.  This is calculated as -15 - (15*.1) = -16.5,
     * which rounds down to -9.
     *
     * Note that the suggested min value provided to the chart is just that, a suggestion.  The smallest scale
     * value displayed on the chart is not guaranteed to be the exact value provided.
     *
     * Also note that the suggested min percentage only affects charts displaying numerical values (including currency).
     */
    public set suggestedMinPercentage(value: number) {
        if (value < 0)
            throw new Error("Suggested max percentage values must be greater than or equal to zero.");
        this._suggestedMinPercentage = value;
    }

    public get suggestedMaxPercentage(): number {
        return this._suggestedMaxPercentage || ChartPropDefinitions.getDefinitions().suggestedMaxPercentage.defaultValue;
    }

    /**
     * Setting the suggested max percentage value causes the chart's recommended largest scale value to be
     * greater than the largest value in the dataset.  The default value is 5 percent.  Setting the value to zero will result
     * in the largest value in the dataset taking up the full height/width of the chart,
     * (in that direction on the scale).
     *
     * Example, a chart where the largest value is 15, with a suggested max percentage of 10,
     * will result in the chart's largest scale value being approximately 16.5.  This is calculated as 15 + (15*.1) = 16.5,
     * which rounds up to 9.
     *
     * Note that the suggested max value provided to the chart is just that, a suggestion.  The largest scale
     * value displayed on the chart is not guaranteed to be the exact value provided.
     *
     * Also note that the suggested min percentage only affects charts displaying numerical values (including currency).
     */
    public set suggestedMaxPercentage(value: number) {
        if (value < 0)
            throw new Error("Suggested max percentage values must be greater than or equal to zero.");
        this._suggestedMaxPercentage = value;
    }

    public get dataTooltipCallback(): (context: any) => string {
        return this._dataTooltipCallback;
    }

    public set dataTooltipCallback(value: (context: any) => string) {
        this._dataTooltipCallback = value;
    }

    override get _designer(): DesignerInterface {
        return super._designer;
    }

    override set _designer(value: DesignerInterface) {
        super._designer = value;
        this.renderChart();
    }

    override get serializationName() {
        return "chart";
    }

    override get properName(): string {
        return "Chart";
    }

    override getPropertyDefinitions(): ComponentPropDefinitions {
        return ChartPropDefinitions.getDefinitions();
    }
}

ComponentTypes.registerComponentType("chart", Chart.prototype.constructor);
