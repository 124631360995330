import { Label, LabelProps } from "@mcleod/components";
import { StringUtil } from "@mcleod/core";

export class KeyValuePair {
    public key: string | Partial<LabelProps>;
    public value: string | Partial<LabelProps>;

    constructor(key: string | Partial<LabelProps>, value: string | Partial<LabelProps>) {
        this.key = key;
        this.value = value;
    }

    public createKeyLabel(captionSuffix: string): Label {
        const props: Partial<LabelProps> = { fontBold: true, ...this.createProps(this.key) };
        this.handleCaptionSuffix(captionSuffix, props);
        return new Label(props);
    }

    public createValueLabel(): Label {
        const props: Partial<LabelProps> = { wrap: true, ...this.createProps(this.value) };
        return new Label(props);
    }

    private createProps(value: string | Partial<LabelProps>): Partial<LabelProps> {
        return (typeof value === "string") ? { caption: value } : value;
    }

    private handleCaptionSuffix(captionSuffix: string, props: Partial<LabelProps>) {
        if (StringUtil.isEmptyString(captionSuffix) !== true)
            props.caption = (props.caption != null ? props.caption : "") + captionSuffix;
    }

    public equals(otherKeyValuePair: KeyValuePair): boolean {
        if (otherKeyValuePair == null)
            return false;
        return this.testKey === otherKeyValuePair.testKey;
    }

    private get testKey(): string {
        if (typeof this.key === "string")
            return this.key;
        return this.key.caption;
    }
}