import { getLogger, Keys, Point, setClassIncluded, VerticalAlignment } from "@mcleod/core";
import { Button, ButtonProps, Component, Label, Panel, ScreenStack } from "..";
import { Cursor } from "../base/Cursor";
import { LabelProps } from "../components/label/LabelProps";
import { PanelProps } from "../components/panel/PanelProps";
import { DomEventListener } from "../events/DomEvent";
import { KeyEvent, KeyListener } from "../events/KeyEvent";
import { KeyHandler } from "../events/KeyHandler";
import { DialogStyles } from "./DialogStyles";
import { Overlay } from "./Overlay";

const log = getLogger("components/page/Dialog");

export interface DialogProps extends PanelProps {
    centerInParent?: boolean;
    modal?: boolean;
    movable?: boolean;
    okDefault?: boolean;
    okVisible?: boolean;
    okCaption?: string;
    okProps?: Partial<ButtonProps>;
    resizable?: boolean;
    titleVisible?: boolean;
    title?: string;
    titleProps?: Partial<LabelProps>;
    parentElement?: HTMLElement;
    xVisible?: boolean;
    panelTitleProps?: Partial<PanelProps>;
    panelContentProps?: Partial<PanelProps>;
}

export class Dialog extends Panel implements DialogProps {
    protected panelTitle: Panel;
    protected labelTitle: Label;
    protected buttonClose: Button;
    protected content: Panel;
    protected buttonRow: Panel;
    protected spacer: Panel;
    protected buttonOK: Button;
    private _overlay: Overlay;
    private promise: Promise<unknown>;
    private _keyListener: KeyListener;
    private resolvePromise: (value: unknown) => void;
    centerInParent: boolean = true;
    modal: boolean = true;
    movable: boolean = false;
    okDefault: boolean = true;
    okVisible: boolean = true;
    resizable: boolean = false;
    titleVisible: boolean = true;
    title: string;
    xVisible: boolean = true;
    parentElement: HTMLElement = document.body;
    mouseDownLocation: Point;
    mouseMoveHandler: DomEventListener;
    mouseUpHandler: DomEventListener;
    private _wasCancelled: boolean;

    constructor(props?: Partial<DialogProps>) {
        super(props);
        if (props == null)
            props = {};
        this.cursor = Cursor.POINTER;
        this.setProps({ padding: 0, minWidth: 300, themeKey: "dialog.window", ...props });
        this.panelTitle = new Panel({ fillRow: true, themeKey: "dialog.header", marginBottom: 16, paddingLeft: 0, paddingRight: 8, verticalAlign: VerticalAlignment.CENTER });
        this.labelTitle = new Label({ fillRow: true, marginLeft: 16, rowBreak: false, allowSelect: false });
        this.buttonClose = new Button({ imageName: "x", borderWidth: 0, borderRadius: "50%", padding: 12, marginTop: 6, marginBottom: 6 });
        this.buttonClose.addClickListener(event => this.close(undefined));
        this.content = new Panel({ id: "dlgContent", fillRow: true, fillHeight: true, paddingLeft: 24, paddingRight: 24, paddingBottom: 12, ...props.panelContentProps });
        this.buttonRow = new Panel({ id: "dlgButtonRow", fillRow: true, paddingRight: 20, paddingBottom: 12 });
        this.spacer = new Panel({ fillRow: true, rowBreak: false });
        this.buttonOK = new Button({ caption: "OK", default: props.okDefault == null ? true : props.okDefault, width: 84, color: "primary", borderWidth: 1 });
        this.buttonOK.addClickListener(event => {
            if (this.validateSimple())
                this.close(true);
            event.preventDefault();
        });
        this.panelTitle.add(this.labelTitle);
        if (props.xVisible !== false)
            this.panelTitle.add(this.buttonClose);
        if (props.title == null)
            this.labelTitle.caption = "LoadMaster";
        else
            this.labelTitle.caption = props.title;
        this.labelTitle.setProps(props.titleProps);
        this.panelTitle.setProps(props.panelTitleProps);
        this.add(this.panelTitle);
        this.add(this.content);
        this.buttonRow.add(this.spacer);
        this.buttonRow.add(this.buttonOK);
        if (props.okVisible !== false)
            this.add(this.buttonRow);
        this.add = this._add;
    }

    setProps(props: Partial<DialogProps>) {
        super.setProps(props);
    }

    _add(...components: Component[]): Component {
        return this.content.add(...components);
    }

    get okButtonCaption(): string {
        return this.buttonOK.caption;
    }

    set okButtonCaption(value: string) {
        this.buttonOK.caption = value;
    }

    setOkButtonProps(value: Partial<ButtonProps>) {
        this.buttonOK.setProps(value);
    }

    show(): Promise<any> {
        if (this.modal === true)
            this._overlay = Overlay.showInOverlay(this, { closeOnClickOff: false, greyedBackground: true, centered: true });
        else {
            setClassIncluded(this, DialogStyles.centered);
            this.zIndex = ScreenStack.getNewHighestZIndex();
            this.parentElement.appendChild(this._element);
        }
        if (this.movable === true)
            this.makeMovable();
        if (this.resizable === true) {
            this._element.style.overflow = "auto";
            this._element.style.resize = "both";
        }
        if (this.modal === false)
            this.addToKeyHandlerStack();
        this.focus();
        this.promise = new Promise((resolve, reject) => {
            this.resolvePromise = resolve;
        });
        return this.promise;
    }

    getKeyHandlers(): KeyHandler[] {
        const result = [];
        if (this.xVisible === true) {
            this._keyListener = (event: KeyEvent) => this.close(undefined);
            result.push({ key: Keys.ESCAPE, listener: this._keyListener, element: this._element });
        }
        return result;
    }

    focus() {
        if (this.content?.isFocusable())
            this.content?.focus?.();
        else
            this.buttonRow?.focus();
    }

    makeMovable() {
        this.labelTitle._element.addEventListener("mousedown", event => {
            this.mouseDownLocation = { x: event.offsetX, y: event.offsetY };
            this.mouseMoveHandler = event => {
                this._element.style.transform = "unset";
                this.left = (event as MouseEvent).clientX - this.mouseDownLocation.x;
                this.top = (event as MouseEvent).clientY - this.mouseDownLocation.y;
            }
            this.mouseUpHandler = event => {
                document.removeEventListener("mousemove", this.mouseMoveHandler);
                document.removeEventListener("mouseUp", this.mouseUpHandler);
            };
            document.addEventListener("mousemove", this.mouseMoveHandler);
            document.addEventListener("mouseup", this.mouseUpHandler);
        });
    }

    close(promiseResult?: any) {
        this.removeFromKeyHandlerStack();
        if (this.modal === true)
            Overlay.hideOverlay(this._overlay);
        else
            this.parentElement.removeChild(this._element);
        this._wasCancelled = promiseResult === undefined;
        this.resolvePromise(promiseResult);
    }

    /**
     * Make it easy for someone to check any Dialog (after it has been shown to the user) was cancelled (via the X button or the ESCAPE key).
     */
    get wasCancelled(): boolean {
        return this._wasCancelled;
    }
}
