import { CurrencySettings } from "./settings/CurrencySettings";
import { CurrencyUtil } from "./CurrencyUtil";

export interface CurrencyProps {
    amount: number;
    base_amount?: number;
    currency_code?: string;
    conversion_rate?: number;
    conversion_date?: Date;
    operand?: string;
    symbol?: string;
}

export class Currency {
    public amount: number;
    public base_amount: number;
    public currency_code: string;
    public conversion_rate: number;
    public conversion_date: Date;
    public operand: string;
    public symbol: string;

    constructor(props: CurrencyProps | number) {
        const settings = CurrencySettings.get();
        if (typeof props === 'number') {
            this.amount = props;
        } else {
            Object.assign(this, props);
        }
        this.currency_code ??= settings.effective_currency_code;
        this.conversion_date ??= settings.override_conversion_date;
        this.symbol ??= settings.currency_symbols?.[this.currency_code];
    }

    update(amount: number): void {
        this.amount = Number.parseFloat(amount.toFixed(2));
        this.base_amount = undefined;
        this.conversion_rate = undefined;
    }

    toString(formatted: boolean = true): string {
        if (!formatted)
            return this.amount.toString();

        return CurrencyUtil.formatCurrency(this);
    }

    getCurrencyCode(): string { 
        if (CurrencySettings.getSingleton().multiCurrencyEnabled()) {
            return this.currency_code;
        }
        else {
            return "USD";
        }
    }

    setCurrencyCode(code: string) {
        if (code != null && code === this.currency_code) {
            return;
        }
        this.currency_code = code;
        if (code != null && code === CurrencySettings.getFunctionalCurrency()) {
            this.conversion_rate = 1.0;
            this.base_amount = null;
            
        }
        else {
            this.conversion_rate = null;
            this.base_amount = null;
        }
        this.symbol = CurrencySettings.get().currency_symbols?.[this.currency_code];
    }

    getConversionDate(): Date {
        this.conversion_date ??= new Date();
        return this.conversion_date;
    }

    setConversionDate(date: Date) {
        this.conversion_date = date;
        this.conversion_rate = null;
    }

    async getBaseAmount(): Promise<number> {
        return this.base_amount ?? this.calculateBaseAmount();
    }

    setBaseAmount(baseAmount: number) {
        this.base_amount = baseAmount;
    }

    getAmount(): number {
        return this.amount;
    }

    setAmount(amount: number) {
        this.amount = Number.parseFloat(amount.toFixed(2));
        this.base_amount = null;
        this.conversion_rate = null;
    }

    async getConversionRate(): Promise<number> {
        const currencyFrom: string = this.getCurrencyCode();
        const conversionDate: Date = this.getConversionDate();
        if (currencyFrom === CurrencySettings.getFunctionalCurrency()) {
            this.conversion_rate = 1.0;
        }
        if (this.conversion_rate == null) {
            this.conversion_rate = await CurrencyUtil.getConversionRate(conversionDate, currencyFrom, 
                CurrencySettings.getFunctionalCurrency(), false);
        }
        return this.conversion_rate;
    }

    setConversionRate(rate: number) {
        this.conversion_rate = rate;
        this.base_amount = null;
    }

    async calculateBaseAmount(): Promise<number> {
        if (this.getAmount() == null) {
            throw Error("Could not calculate base amount without a native amount.");
        }
        const rate: number = await this.getConversionRate();
        if (rate == null) {
            throw Error("Could not calculate base amount without a conversion rate.");
        }
        return rate * this.getAmount();
    }

    async add(amount: Currency, useExactDate: boolean = false): Promise<number> {
        if (amount && amount.getAmount()) {
            if (amount.getCurrencyCode() === this.getCurrencyCode()) {
                this.setAmount(this.getAmount() + amount.getAmount());
            }
            else {
                const otherAmount: number = await CurrencyUtil.calculateConvertedAmount(amount.getAmount(),
                    amount.getConversionDate(), amount.getCurrencyCode(), this.getCurrencyCode(), useExactDate);
                this.setAmount(this.getAmount() + otherAmount);
            }
            return this.getAmount();
        }
    }

    async addKeepRate(amount: Currency): Promise<number> {
        if (amount) {
            if (this.conversion_rate != null) {
                const otherAmount: number = await CurrencyUtil.calculateConvertedAmount(amount.getAmount(),
                    amount.getConversionDate(), amount.getCurrencyCode(), this.getCurrencyCode(), false, this.conversion_rate);
                this.amount = Number.parseFloat((this.getAmount() + otherAmount).toFixed(2));
                this.base_amount = this.conversion_rate * this.amount;
            }
            else {
                this.add(amount);
            }
            return this.getAmount();
        }
    }

    subtract(amount: Currency): Promise<number> {
        if (amount) {
            const clone: Currency = amount.getClone(amount.getAmount());
            clone.multiply(-1.0);
            return this.add(clone);
        }
    }

    subtractKeepRate(amount: Currency): Promise<number> {
        if (amount) {
            const clone: Currency = amount.getClone(amount.getAmount());
            clone.multiply(-1.0);
            return this.addKeepRate(clone);
        }
    }

    multiply(value: number): number {
        if (value === -1) {
            this.negate(false);
        }
        else {
            this.setAmount(this.getAmount() * value);
        }
        return this.getAmount();
    }

    divide(value: number): number {
        if (value != null) {
            this.setAmount(this.getAmount() / value);
        }
        return this.getAmount();
    }

    negate(clearRateAndBaseAmt: boolean = true) {
        if (clearRateAndBaseAmt || !CurrencySettings.getSingleton().multiCurrencyEnabled() || this.base_amount == null) {
            this.setAmount(this.getAmount() * -1);
        }
        else {
            this.amount = Number.parseFloat((this.getAmount() * -1).toFixed(2));
            this.base_amount = Number.parseFloat((this.base_amount * -1).toFixed(2));
        }
    }

    getClone(cloneValue: number, keepRate: boolean = false): Currency {
        const clone: Currency = new Currency(cloneValue);
        clone.setCurrencyCode(this.getCurrencyCode());
        clone.setConversionDate(this.getConversionDate());
        if (keepRate) {
            clone.setConversionRate(this.conversion_rate);
        }
        return clone;
    }

    isLessThan(amount: Currency): boolean {
        return this.amount < amount.getAmount();
    }

    isGreaterThan(amount: Currency): boolean {
        return this.amount > amount.getAmount();
    }

    isZero(): boolean {
        return this.getAmount() == 0;
    }

    isPositive(): boolean {
        return this.getAmount() > 0;
    }

    isNegative(): boolean {
        return this.getAmount() < 0;
    }
    
    static createCurrencyWithDefaults(amount: number | string): Currency {
        if (amount == null) {
            return null;
        }
        if (typeof amount === "string") {
            amount = Number.parseFloat(CurrencyUtil.removeFormatting(amount));
            if (isNaN(amount))
                return null;
        }
        return new Currency(amount);
    }
}
