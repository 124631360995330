import { AbstractSettings, AbstractSettingsDef } from "./AbstractSettings";
import { SettingsManager } from "./SettingsManager";

export interface MapSettingsDef extends AbstractSettingsDef {
    //unauth
    google_api_key: string;
    google_maps_url: string;

    //auth
    always_show_maps: boolean;
    distance_calc_vendor?: string;
    request_location_vendor?:string;
    findnear_vendor?: string;
    google_greedy?: boolean;
    movement_map_vendor?: string;
    single_point_vendor?: string;
    trimble_settings?: {
        api_key1?: string;
        api_key2?: string;
    }
}

export class MapSettings extends AbstractSettings {
    constructor() {
        super("map_settings");
    }

    public static getSingleton(): MapSettings {
        return SettingsManager.getSingleton("map_settings") as MapSettings;
    }

    public static get(): MapSettingsDef {
        return MapSettings.getSingleton().values as MapSettingsDef;
    }

    public override get values(): MapSettingsDef {
        return super.values as MapSettingsDef;
    }

    protected getValuesInternal(): MapSettingsDef {
        return this._values as MapSettingsDef;
    }

    public getDistanceCalcVendor(): string {
        return this.values?.distance_calc_vendor;
    }

    public noDistanceCalcVendor(): boolean {
        return this.getDistanceCalcVendor() === "N";
    }

    public getTrimbleApiKey(): string {
        const values = this.values;
        const key1 = values?.trimble_settings.api_key1;
        const key2 = values?.trimble_settings.api_key2;
        return key1 ? key1 : key2;
    }
}
