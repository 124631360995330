import { Label, Panel, TreeNode } from "@mcleod/components";
import { ReflectiveDialogs } from "@mcleod/components/src/base/ReflectiveDialogs";
import { Api } from "@mcleod/core";
import { AutogenLayoutModelTester } from "../autogen/AutogenLayoutModelTester";

export class ModelTester extends AutogenLayoutModelTester {
    onLoad() {
        this.textModel.text = this["model"];
        this.textParams.text = this["params"];
    }

    buttonExecuteOnClick(event) {
        this.buttonExecute.busy = true;
        this.treeResults.getRootNode().removeAllChildren();
        Api.search(this.textModel.text + "?" + this.textParams.text).then(response => {
            this.buttonExecute.busy = false;
            this.addTreeNodes(this.treeResults.getRootNode(), response.data, "Record ");
            if (response.actual_row_count === undefined)
                this.labelCount.caption = "Results: " + response.data.length;
            else
                this.labelCount.caption = "Results: " + response.actual_row_count;
        }).catch(err => {
            this.buttonExecute.busy = false;
            ReflectiveDialogs.showError(err);
        });
    }

    addTreeNodes(target: TreeNode, obj: any, prefix: string): void {
        let keys = Object.keys(obj);
        if (!Array.isArray(obj))
            keys = keys.sort();
        for (const key of keys) {
            const value = obj[key];
            let child: TreeNode;
            if (this.isLeaf(value)) {
                const panel = new Panel();
                panel.add(new Label({ caption: key + " :", fontBold: true, width: 280, rowBreak: false, color: "success" }));
                panel.add(new Label({ caption: value }));
                child = new TreeNode({ component: panel, tree: this.treeResults });
            }
            else {
                child = new TreeNode({ caption: prefix == null ? key : prefix + key, tree: this.treeResults });
                this.addTreeNodes(child, value, null);
            }
            target.addChild(child);
        }
    }

    private isLeaf(obj: any): boolean {
        return obj == null || typeof obj === "string" || typeof obj === "number" || obj instanceof Date;
    }
}
