import { Api, ArrayUtil, Collection, getLogger } from "@mcleod/core";
import { DataSource } from "../databinding/DataSource";
import { TailorExtensionParam } from "./TailorExtensionParam";
import { ReflectiveDialogs } from "../base/ReflectiveDialogs";
import { Panel } from "../components/panel/Panel";

const log = getLogger("components.page.TailorExtension");
const _cachedExtensionMetadata: Collection<any> = {};

export class TailorExtension {
    private id: string;
    private descr: string;
    private params: TailorExtensionParam[];

    constructor(id: string, descr: string, paramDefs?: any[]) {
        this.id = id;
        this.descr = descr;
        this.createParams(paramDefs);
    }

    private createParams(paramDefs: any[]) {
        this.params = [];
        if (paramDefs == null)
            return;
        for (const paramDef of paramDefs) {
            const extensionParam = new TailorExtensionParam(paramDef.id, paramDef.value);
            this.params.push(extensionParam);
        }
    }

    public async invoke(dataSource: DataSource): Promise<void> {
        const proceed = await this.finalizeParams(dataSource);
        if (proceed === false)
            return;
        const requestBody = this.buildRequestBody();
        log.debug("Invoking Tailor Extension with body: %o", requestBody);
        const response = await Api.post("tailor/invoke-extension", requestBody);
        const data = ArrayUtil.isEmptyArray(response?.data) !== true ? response.data[0] : null;
        log.debug("Response from Tailor Extension: %o", data);
    }

    private async finalizeParams(dataSource: DataSource): Promise<boolean> {
        if (ArrayUtil.isEmptyArray(this.params) === true)
            return true;
        await this.verifyParamAttributes();
        const manualInputParams: TailorExtensionParam[] = [];
        for (const param of this.params) {
            param.evaluateValue(dataSource);
            if (param.needsManualInput() === true)
                manualInputParams.push(param);
        }
        if (ArrayUtil.isEmptyArray(manualInputParams) === false)
            return await this.getUserInput(manualInputParams);
        return true;
    }

    private async verifyParamAttributes() {
        const extensionMetadata = await this.getExtensionMetadata();
        if (extensionMetadata == null) {
            log.debug("Tailor Extension metadata not found");
            throw new Error("Unable to invoke Tailor Extension");
        }
        PARAM_LOOP:
        for (const param of this.params) {
            for (const paramMetadata of extensionMetadata.params) {
                if (paramMetadata.id === param.id) {
                    param.updateFromMetadata(paramMetadata);
                    continue PARAM_LOOP;
                }
            }            
        }
    }

    private async getUserInput(manualInputParams: TailorExtensionParam[]): Promise<boolean> {
        if (ArrayUtil.isEmptyArray(manualInputParams) === true)
            return true;
        const panel = new Panel({
            margin: 0,
            padding: 0,
            minWidth: 450,
            maxHeight: 450,
            fillRow: true,
            fillHeight: true,
            scrollY: true
        });
        for (const param of manualInputParams) {
            param.createInputComponent();
            panel.add(param.inputComponent);
        }
        const yesNoDialogProps = {
            yesButtonCaption: "Proceed",
            noButtonCaption: "Cancel"
        };
        const result = await ReflectiveDialogs.showYesNo(panel, "Review Values", yesNoDialogProps);
        if (result !== true) {
            //user cancelled out of input entry, so don't run the extension
            return false;
        }
        for (const param of manualInputParams) {
            param.updateFromInputComponent();
        }
        return true;
    }

    /**
     * Create the data necessary to invoke the extension.  The major difference between this and toJSON() is that
     * this method includes the evaluated value for each parameter, not the configuration value.
     * @returns any
     */
    private buildRequestBody(): any {
        const result: any = { id: this.id };
        if (ArrayUtil.isEmptyArray(this.params) === false) {
            const paramData = [];
            for (const param of this.params) {
                paramData.push(param.buildRequestData());
            }
            result.params = paramData;
        }
        return result;
    }

    /**
     * Implement toJSON so that we only serialize the parts of the extension that need to be saved in a layout file.
     * @returns any
     */
    public toJSON(): any {
        const result: any = {
            id: this.id
        };
        if (ArrayUtil.isEmptyArray(this.params) === false)
            result.params = this.params;
        return result;
    }

    private async getExtensionMetadata() {
        if (_cachedExtensionMetadata[this.id] == null) {
            const response = await Api.search("tailor/extension-metadata", { id: this.id });
            _cachedExtensionMetadata[this.id] = response?.data?.[0]?.extensions?.[0];
        }
        return _cachedExtensionMetadata[this.id];
    }
}
