import { Image, Label, Panel } from "@mcleod/components";
import { ExtendedDataType, MetadataField, StringUtil, VerticalAlignment } from "@mcleod/core";
import { AbstractUIDesigner } from "./AbstractUIDesigner";

export class DesignerTool extends Panel {
    private _image: Image;
    private _serializationName: string;
    private _displayName: string;
    private _componentProps: any;
    private _readOnly: boolean;

    constructor(designer: AbstractUIDesigner, serializationName: string, displayName: string,
        metadataField?: MetadataField, componentProps?: any, imageName?: string, readOnly?: boolean) {
        super({
            fillRow: true,
            draggable: true,
            allowSelect: false,
            wrap: false,
            color: "subtle.darker",
            verticalAlign: VerticalAlignment.CENTER
        });
        this.setSerializationName(serializationName, metadataField);
        this._displayName = displayName;
        this._readOnly = readOnly;
        this.setComponentProps(designer, metadataField, componentProps);
        this.createImage(imageName, serializationName);
        this.add(this.image);
        this.add(new Label({ caption: displayName, fontSize: "small" }));
    }

    public get image(): Image {
        return this._image;
    }

    public get serializationName(): string {
        return this._serializationName;
    }

    public get displayName(): string {
        return this._displayName;
    }

    public get componentType(): string {
        return this.readOnly === true ? "label" : this.serializationName;
    }

    public get componentCaption(): string {
        return this._componentProps.caption;
    }

    public get componentProps(): any {
        const result = { ...this._componentProps };
        if (this.readOnly === true) {
            //this is how we avoid setting the caption of the printable label with the textbox's caption
            delete result.caption;
        }
        return result;
    }

    public get readOnly(): boolean {
        return this._readOnly || false;
    }

    private setSerializationName(serializationName: string, metadataField: MetadataField) {
        let nameFromMetadata: string;
        if (metadataField != null) {
            if (metadataField.dataType === "bool")
                nameFromMetadata = "checkbox";
            else if (metadataField.dataType === "list")
                nameFromMetadata = "table";
            else if (metadataField.extDataType === ExtendedDataType.CITY)
                nameFromMetadata = "citystate";
        }
        this._serializationName = nameFromMetadata || serializationName;
    }

    private setComponentProps(designer: AbstractUIDesigner, metadataField: MetadataField, providedComponentProps: any) {
        this._componentProps = {
            ...providedComponentProps,
            ...this.getComponentPropsFromMetadata(designer, metadataField)
        };
    }

    private getComponentPropsFromMetadata(designer: AbstractUIDesigner, metadataField: MetadataField) {
        const result: any = {};
        if (metadataField != null) {
            result.field = metadataField.name;
            //result.name = metadataField.name;
            result.dataSource = designer.getActiveTab().lastSelectedDataSource;
            result.caption = metadataField.caption;
        }
        return result;
    }

    private createImage(imageName: string, serializationName: string) {
        const imageNameToUse = "designer/" +
            (StringUtil.isEmptyString(imageName) !== true ? imageName.toLowerCase() : serializationName.toLowerCase());
        this._image = new Image({ name: imageNameToUse, height: 20, width: 20, rowBreak: false });
    }
}
