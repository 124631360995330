import { CommonDialogs, YesNoDialogProps } from "@mcleod/common";
import { Label, Panel, ResourceFileProps, Tab, TabProps, Tabset, Textbox, Tree, TreeNode } from "@mcleod/components";
import { ReflectiveDialogs } from "@mcleod/components/src/base/ReflectiveDialogs";
import { Alignment, Api, DateUtil, GeneralSettings, HorizontalAlignment, StringUtil } from "@mcleod/core";
import { DesignerTabDescriptor } from "../ui/DesignerTabDescriptor";
import { ResourceFileTooltip } from "./ResourceFileTooltip";

type ResourceType = "layout" | "model";

export abstract class ResourceDesignerTab extends Tab implements Required<ResourceFileProps> {
    firstSaveOfCustomResource: boolean;
    private _path: string;
    private _modified: boolean;
    private _baseVersion: boolean = true;
    private _customId: string;
    descr: string;
    createDateTime: Date;
    updateDateTime: Date;
    readonly resourceType: ResourceType;
    private _layoutRefences: string[];
    private _definition: any;


    constructor(type: ResourceType, props: ResourceFileProps, tabProps?: Partial<TabProps>) {
        super();
        this.resourceType = type;
        this._shouldAddDesignerContainerProperties = false;
        this.setProps({ fillHeight: true, fillRow: true, minHeight: 80, closeable: true, ...tabProps, ...props});
    }

    abstract getSearchRequestEndpointAndBody(): [string, any];
    abstract designerPanel: Panel;

    getSearchRequestBody(): any {
        return {
            path: this.path,
            require_base_version: this.baseVersion,
            id: this.customId,
        }
    }

    async initilaizeAndGetDefinition(): Promise<any> {
        if (this._definition == null) {
            try {
                const [endpoint, body] = this.getSearchRequestEndpointAndBody();
                const response = await Api.search(endpoint, body);
                const responseData = response.data[0];
                if (responseData.definition == null)
                    throw new Error(`No definition found for resource: ${this.path}`);
                this._definition = JSON.parse(responseData.definition);
                this.updateFromServerResponse(responseData);
                return this.definition;
            } catch (error) {
                let msg = error?.toString();
                const index = msg.indexOf("FileNotFoundException");
                if (index >= 0) {
                    msg = msg.substring(index + "FileNotFoundException: ".length, msg.indexOf("\n", index));
                }
                this.addErrorLabel(msg, true);
            }
        }
    }

    updateFromServerResponse(serverResponse: any) {
        this.baseVersion = serverResponse.base_version ?? this.baseVersion;
        this.customId = serverResponse.id ?? this.customId;
        this.descr = serverResponse.descr ?? this.descr;
        if (serverResponse.create_datetime)
            this.createDateTime = DateUtil.parseDateTime(serverResponse.create_datetime);
        if (serverResponse.update_datetime)
            this.updateDateTime = DateUtil.parseDateTime(serverResponse.update_datetime);
        this.syncTooltip();
    }

    public get definition(): any {
        return this._definition;
    }


    addErrorLabel(message: string, removeAll: boolean = false) {
        if (removeAll)
            this.designerPanel.removeAll();
        this.designerPanel.add(new Label({ caption: message, fillRow: true, align: HorizontalAlignment.CENTER, marginTop: 32 }));
    }

    get baseVersion(): boolean {
        return this._baseVersion;
    }

    set baseVersion(value: boolean) {
        this._baseVersion = value;
        this.imageName = value === false ? "formPencil" : null;
    }

    public get customId(): string {
        return this._customId;
    }

    public set customId(value: string) {
        this._customId = value;
    }

    get isCustom(): boolean {
        return this.baseVersion === false;
    }

    public get path(): string {
        return this._path;
    }

    set path(value: string) {
        this._path = value;
        this._syncCaption();
    }

    get modified() {
        return this._modified;
    }

    set modified(value: boolean) {
        if (this._modified === value)
            return;
        this._modified = value;
        this._syncCaption();
    }

    private _syncCaption() {
        let caption = this.getTabTitle(this.path);
        if (this._modified)
            caption += " *";
        this.caption = caption;
    }

    private getTabTitle(path: string): string {
        if (path == null)
            return `New ${this.resourceType}`;
        else {
            const slashPos = path.lastIndexOf("/");
            if (slashPos >= 0)
                return path.substring(slashPos + 1);
            else
                return path;
        }
    }

    tabsetContainsDuplicate(tabset: Tabset): boolean {
        for (const tab of tabset) {
            if (tab instanceof ResourceDesignerTab && tab.descriptorsMatch(this))
                return true;
        }
    }

    get descriptor(): DesignerTabDescriptor {
        return DesignerTabDescriptor.createFromObject(this);
    }

    descriptorsMatch(other: ResourceDesignerTab) {
        return this.descriptor.equals(other.descriptor);
    }

    override createHeading() {
        super.createHeading();
        this.heading.id = "designerTabHeading";
        this.heading["_allowTooltipInDesigner"] = true;
        this.syncTooltip();
    }

    syncTooltip() {
        if (this.heading != null) {
            this.heading.tooltipCallback = async () => {
                return this.createTooltipPanel().then(panel => this.heading.showTooltip(panel, null, { themeKey: "quickInfo" }));
            };
        }
    }

    async createTooltipPanel(): Promise<Panel> {
        const footer = await this.createLayoutReferencesPanel();
        return ResourceFileTooltip.createPanel(this.resourceType, {
            ...this.descriptor,
            createDateTime: this.createDateTime,
            updateDateTime: this.updateDateTime,
            descr: this.descr,
        }, footer);
    }

    // panel contains a list of layouts the resource is referenced in
    // type == "layout": List of layouts that this layout is nested in
    // type == "model":  List of layouts that contain a datasource that references this model
    async createLayoutReferencesPanel(openTabOnClick: boolean = true): Promise<Panel> {
        let panel = null;
        const  layoutRefences = await this.getLayoutRefences();
        if (layoutRefences.length > 0) {
            panel = new Panel({ margin: 0, padding: 0 });
            panel.add(new Label({ caption: `Changes to this ${this.resourceType} will affect:`, paddingTop: 12 }));
            layoutRefences.forEach(path => {
                const labelRef = new Label({ caption: path });
                if (openTabOnClick)
                    labelRef.setProps({ color: "primary", onClick: () => this._designer.openTab({path}) });
                panel.add(labelRef);
            })
        }
        return panel;
    }

    async getLayoutRefences(): Promise<string[]> {
        if (this._layoutRefences == null && this.path != null && !GeneralSettings.getSingleton().isRunningInIDE()) {
            const response = await Api.search("layout/resource-parent-layouts", { path: this.path, type: this.resourceType });
            // If there is only a single reference, we don't have to display it
            // because they navigated here from that parent layout
            this._layoutRefences = [];
            if (response?.data?.length > 1)
                this._layoutRefences = response?.data?.map(layout => layout.path);
        }
        return this._layoutRefences ?? [];
    }

    async promptForVersionDescription(): Promise<string> {
        const content: Panel = new Panel({ margin: 0, padding: 0, minWidth: 400, rowBreakDefault: true });
        const message = new Label();
        const descr = new Textbox({ caption: "*Name", required: true, fillRow: true, marginTop: 7 });
        content.add(message, descr);
        const panelAffectedLayouts = await this.createLayoutReferencesPanel(false);
        if (panelAffectedLayouts != null)
            content.add(panelAffectedLayouts);
        const dialogProps: Partial<YesNoDialogProps> = { noButtonCaption: "Cancel", yesButtonCaption: "Save" };
        dialogProps.title = `Create Custom ${StringUtil.toProperCase(this.resourceType)}?`;
        message.caption = `By saving, you will be creating a custom version of this ${this.resourceType}.\n\nIf you wish to continue, enter a name for this custom ${this.resourceType}, and click Save.`;
        if (await CommonDialogs.showYesNo(content, null, dialogProps)) {
            return descr.text;
        }
        return null;
    }

    async promptForResourceName(resourceListEndpoint: string): Promise<string>{
        const response = await Api.search(resourceListEndpoint);
        const tree = new Tree({ height: 300, width: 400, borderWidth: 1, borderRadius: 4, borderColor: "strokeSecondary", nodeLeafImageName: "folder" });
        const node = tree.makeTreeNodesFromObject(response.data[0], "name", "children");
        this.removeLeafNodes(node);
        node.expanded = true;
        tree.getRootNode().setChildren(node.getChildren());
        const label = new Label({ caption: "Select save folder", fontBold: true });
        const type = StringUtil.toProperCase(this.resourceType);
        const savePath = new Textbox({ caption: type + ' name', required: true, fillRow: true });
        const panel = new Panel({ components: [label, tree, savePath] });
        await ReflectiveDialogs.showDialog(panel, { title: "Save " + type });
        const sel = tree.selectedNode;
        if (sel == null) {
            tree.showTooltip(`You must select a folder to save this ${this.resourceType}.`, {
                timeout: 3000, shaking: true, position: Alignment.RIGHT
            });
            return null;
        }
        let path = "";
        for (const n of sel.path)
            path += n.caption + "/";
        return path += savePath.text;
    }

    removeLeafNodes(node: TreeNode) {
        for (let i = node.getChildCount() - 1; i >= 0; i--) {
            const child = node.getChild(i);
            if (child.getChildCount() === 0)
                node.removeChild(i);
            else
                this.removeLeafNodes(child);
        }
    }
}
