import { Attachment, Button, Chart, ClearButtonVisible, Component, Label, LabelProps, Layout, Panel, PanelProps, Textbox } from "@mcleod/components";
import { SupportPromptAnswer, SupportPromptData } from "./DiagnosticData";
import { Model, ModelRow, StringUtil, getThemeColor } from "@mcleod/core";

export class SupportPrompt extends Panel {
    data: SupportPromptData;
    selectedAnswer: SupportPromptAnswer;
    panelExtra: Panel;
    chart: Chart;
    textNotes: Textbox;

    constructor(data: SupportPromptData, answerCallback: (answer: SupportPromptAnswer) => void, props?: Partial<PanelProps>) {
        super({ fillRow: true, marginBottom: 8, ...props});
        this.data = data;
        if (this.data.layout == null) {
            this.add(this.createPromptLabel());
            this.addExtraComponents();
            this.addAnswers(answerCallback);
        } else {
            const layout = Layout.getLayout(this.data.layout, this.data.layoutProps);
            this.add(layout);
        }

    }

    private addExtraComponents() {
        if (this.data.notesPrompt != null || this.data.hasAttachments) {
            this.panelExtra = new Panel({ fillRow: true, marginBottom: 8, padding: 0 });
            if (this.data.notesPrompt != null) {
                this.textNotes = new Textbox({ 
                    caption: this.data.notesPrompt, 
                    height: this.data.notesHeight | 200, 
                    clearButtonVisible: ClearButtonVisible.NO,
                    multiline: true, 
                    fillRow: true, 
                    spellcheck: false, // should spellcheck lots of usages of notes
                    marginBottom: 8, 
                    rowBreak: false 
                });
                if (this.data.notesMonospace) {
                    this.textNotes.fontFamily = "courier";
                }
                this.textNotes.addChangeListener(event => this.analyzeNotes());
                this.panelExtra.add(this.textNotes);
            }
            if (this.data.hasAttachments) {
                const attachment = new Attachment({ showSVG: true, height: 220, width: 120 });
                this.panelExtra.add(attachment);
            }
            this.add(this.panelExtra);
        }
    }

    private analyzeNotes() {
        if (this.chart == null) {
            this.chart = new Chart({ 
                field: "value",
                height: "300",
                width: "300",
                maxHeight: 300,
                maxWidth: 300,
                chartType: "bar",
                datasetLabel: "Number of database statements",
                legendPosition: "none",
                showGridLines: false,
                xAxisField: "label"
            });
            this.chart.xAxisProps.title = { text: "Execution time(ms)", display: true };
            this.chart.yAxisProps.title = { text: "Number of statements", display: true };
            this.panelExtra.add(this.chart);
            Model.search("common/log-reader", { log_name: "unused", log_contents: btoa(this.textNotes.text) }).then(response => {
                const logData = response.modelRows[0];
                const stmtsByExecTime = logData.get("statements_by_execution_time");
                const rows = [] as ModelRow[];
                let start = 0;
                for (const [threshold, value] of Object.entries(stmtsByExecTime)) {
                    if (start > 0) {
                        const label = threshold.length < 8 ? start + " - " + threshold + "ms" : start + "+ ms";
                        rows.push(new ModelRow(null).setValues({ label, value }));
                    }
                    start = parseInt(threshold) + 1;
                }
                this.chart.displayData(rows[0], rows, 0);
                this.highlightExecs();
            });    
        }
    }

    highlightExecs() {
        // let text = this.textNotes.text;
        let text = "";
        const panel = new Panel({ fillRow: true, scrollY: true, height: 240, rowBreak: false, borderWidth: 1, borderColor: "default.lightest", borderRadius: 4, padding: 0 });
        this.panelExtra.replace(this.textNotes, panel);
        let lineStart = 0;
        let lineEnd = 0;
        while ((lineEnd = text.indexOf("\n", lineStart)) >= 0) {
            if (lineEnd < 0)
                lineEnd = text.length;
            const line = text.substring(lineStart, lineEnd);
            const execIndex = line.indexOf("[ExecTime]");
            if (execIndex >= 0) {
                const afterExec = StringUtil.stringAfter(line, "Execution time: ", false, null);
                if (afterExec != null) {
                    const execString = StringUtil.stringBefore(afterExec, " (");
                    const seconds = parseInt(StringUtil.stringBefore(execString, ":"));
                    const millis = parseFloat(StringUtil.stringAfter(execString, ":"));
                    const execTime = seconds * 1000 + millis;
                    let color = null;
                    if (execTime > 100)
                        color = getThemeColor("error.lightest");
                    else if (execTime > 50)
                        color = getThemeColor("warning.lighter");
                    else if (execTime > 10)
                        color = getThemeColor("caution.lighter");
                    if (color != null) {
                        const replacedLine = "<span style=\"background-color:" + color + "\">" + line + "</span>";
                        text = text.replace(line, replacedLine);
                    }
                }
            }
            lineStart = lineEnd + 1;
        }
        const label = new Label({ caption: text, fillRow: true, backgroundColor: "background5", fontFamily: "courier" });
        label.style.display = "block";
        label.element.innerHTML = text;
        panel.add(label);

    }

    private addAnswers(answerCallback: (answer: SupportPromptAnswer) => void) {
        for (const answer of this.data.answers) {
            const button = new Button({ 
                caption: answer.text, 
                fontSize: "xlarge",
                color: "primary.reverse",
                backgroundColor: "primary",
                rowBreak: false
            });
            button.addClickListener(event => {
                this.questionAnswered(answer)
                answerCallback(answer);
            });
            this.add(button);
        }
    }

    private questionAnswered(answer: SupportPromptAnswer) {
        this.selectedAnswer = answer;
        this.removeAll();
        this.add(this.createPromptLabel( { marginRight: 16, rowBreak: false } ));
        if (answer.text !== "Next")
            this.add(new Label({ caption: answer.text, fontBold: true, marginBottom: 8, fontSize: "xlarge" }));
    }

    private createPromptLabel(props?: Partial<LabelProps>) {
        return new Label({ caption: this.data.prompt, fontSize: "xlarge", marginBottom: 8, ...props });
    }
}