import { Navigation } from ".";

export class ExternalLink {
    private _caption: string;
    private _url: string;
    private _relatedElement: HTMLElement;

    constructor(caption: string, url: string, relatedElement?: HTMLElement) {
        this._caption = caption;
        this._url = url;
        this._relatedElement = relatedElement;
    }

    public get caption(): string {
        return this._caption;
    }

    protected get url(): string {
        return this._url;
    }

    protected set url(value: string) {
        this._url = value;
    }

    public get relatedElement(): HTMLElement {
        return this._relatedElement;
    }

    protected urlIsValid(): boolean {
        return this.url?.startsWith("https://") === true || this.url?.startsWith("http://") === true;
    }

    //override this method to alter the url before it is used
    //this could involve getting a finalized url from the server
    protected async resolveURL(): Promise<void> {
    }

    public async open(): Promise<boolean> {
        await this.resolveURL();
        if (this.urlIsValid() === true) {
            Navigation.navigateTo(this.url, { newTab: true });
            return true;
        }
        return false;
    }

    public equals(otherExternalLink: ExternalLink): boolean {
        if (otherExternalLink == null)
            return false;
        return this.caption === otherExternalLink.caption && this.url === otherExternalLink.url;
    }
}
