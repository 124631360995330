import { Component, DialogProps, Panel, PanelProps, Table, TableColumn, Textbox } from "@mcleod/components";
import { SelectionMode } from "@mcleod/components/src/base/SelectionMode";
import { ArrayUtil, ModelRow, StringUtil } from "@mcleod/core";

export class StringArrayEditor extends Panel {
    private _returnAsArray: boolean;
    private _values: ModelRow[];
    private _table: Table;
    dialogProps: Partial<DialogProps> = { okDefault: false, cursor: null };

    constructor(props: Partial<PanelProps> = {}) {
        super({ fillRow: true, fillHeight: true, minWidth: 500, minHeight: 500, ...props });
        this._createTable();
        this.add(this._table)
    }

    private _createTable() {
        this._table = new Table({
            id: "itemsTable",
            headerVisible: false,
            allowEdit: true,
            allowAdd: true,
            selectionMode: SelectionMode.MULTI,
            fillRow: true,
            fillHeight: true,
            rowBreak: false,
            allowDbSearch: false,
            emptyCaption: "Items can be defined in this table",

        });
        const column = new TableColumn({
            id: "one",
            index: 0,
            headingDef: {
                caption: "Item",
                isHeading: true
            },
            cell: this._createCellComponents
        });
        this._table.addColumn(column, true, true);
        this._table.data = [];
        for (const row of this._values) {
            this._table.addRow(row, {}, { display: true, addToData: true });
        }
    }

    private _createCellComponents(): Component {
        return new Textbox({ caption: "Item", field: "value", id: "textboxValue", captionVisible: false, fillRow: true });
    }

    get returnAsArray(): boolean {
        return this._returnAsArray;
    }

    set returnAsArray(value: boolean) {
        this._returnAsArray = value;
    }

    set value(value: string[] | string) {
        this._values = [];
        if (typeof value === "string") {
            if (StringUtil.isEmptyString(value) === true)
                return;
        }
        else if (ArrayUtil.isEmptyArray(value) === true)
            return;
        const valueArray = parseValues(value);
        if (ArrayUtil.isEmptyArray(valueArray) === true)
            return;
        for (const v of valueArray) {
            this._values.push(new ModelRow(null, false, { value: v }));
        }
    }

    getValue() {
        const array = [];
        for (const row of this._table.rows) {

        }
        return this.returnAsArray === true ? array : array.join(",");
    }
}

export function parseValues(value: string[] | string): string[] {
    if (typeof value === "string")
        return value.trim().split(",");
    return value;
}


