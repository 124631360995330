import { ChangeEvent, DomDragEvent, DragEvent, Label, Layout, Snackbar } from "@mcleod/components";
import { DOMUtil, StringUtil, createClass, getLogger } from "@mcleod/core";
import { CommonDialogs } from "@mcleod/common";
import { ThreadDumpParser, ThreadDumpType } from "./ThreadDumpParser";
import { AutogenLayoutAnalyzer } from "./autogen/AutogenLayoutAnalyzer";
import { ZipUtil } from "@mcleod/core/src/ZipUtil";

const log = getLogger("support.analyzer.Analyzer");

export class Analyzer extends AutogenLayoutAnalyzer {
    onLoad() {
        createClass("@keyframes slowTyping", "from { width: 0; };", true);
        createClass("@keyframes blinker", "  50% { color: red; opacity: 0; };", true);
        this.textInput.addDropListener((event: DragEvent) => this.fileDropped(event));
        this.textInput.addDragOverListener((event: DragEvent) => this.fileDragOver(event));
        this.textInput.addDragLeaveListener((event: DragEvent) => this.textInput.backgroundColor = undefined);
    }

    private analyze(contents: string) {
        this.panelAnalysis.removeAll();
        if (StringUtil.isEmptyString(contents))
            return;
        if (contents.startsWith("{")) {
            const parsedContents = JSON.parse(contents);
            if (parsedContents["usedHeap"] != null) {
                const heapAnalyzer = Layout.getLayout("auth/support/analyzer/HeapAnalyzer", {heapInfo: parsedContents} as any);
                this.panelAnalysis.add(heapAnalyzer);
            }
        }
        else if (ThreadDumpParser.getThreadDumpType(contents) != ThreadDumpType.INVALID) {
            const dumpAnalyzer = Layout.getLayout("auth/support/analyzer/ThreadDumpAnalyzer", {contents: contents} as any);
            this.panelAnalysis.add(dumpAnalyzer);
        } else if (contents.indexOf("Bisquik") >= 0) {
            this.panelAnalysis.add(this.createFunnyLabel("This appears to be the recipe for sausage balls.  This seems like it will taste pretty good,"));
            setTimeout(() => this.panelAnalysis.add(this.createFunnyLabel("but the ratio of Bisquik to sausage seems like these could be a little dry.")), 2000);
            setTimeout(() => this.panelAnalysis.add(this.createFunnyLabel("I recommend reducing the Bisquik to 1¼ cups.", true)), 4000);
        } else {
            const logAnalyzer = Layout.getLayout("auth/support/analyzer/LogAnalyzer", {contents: contents} as any);
            this.panelAnalysis.add(logAnalyzer);
        }
    }

    private createFunnyLabel(caption: string, blink: boolean = false) {
        const label = new Label({caption: caption, width: 800});
        label.style.animation = "slowTyping 2500ms steps(60, end)"
        label.style.whiteSpace = "nowrap";
        label.style.overflow = "hidden";
        if (blink) {
            label.fontBold = true;
            label.fontSize = "xlarge";
            setTimeout(() => label.style.animation += ", blinker 1500ms 8", 1500);
        }
        return label;
    }

    private textInputOnChange(event: ChangeEvent) {
        if (this.textInput.text.length > 2000000)
            Snackbar.showWarningSnackbar("Pasting large amounts of diagnostic information doesn't perform well.  To improve performance, drag and drop the file instead.",
                { millisUntilDimissal: 8000 });
        this.analyze(this.textInput.text);
    }

    private fileDropped(event: DragEvent) {
        this.textInput.backgroundColor = undefined;
        event.consume();
        const files = DOMUtil.getDraggedFiles(event.domEvent as DomDragEvent);
        if (files.length !== 1)
            return;
        this.textInput.text = "";
        this.textInput.placeholder = "File [" + files[0].name + "] was dropped here.  Analysis is below.";
        this.splitterMain.position = 68;
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = async () => {
            let contents = StringUtil.stringAfter(reader.result as string, ",");
            contents = atob(contents);
            if (files[0].name.endsWith(".zip")) {
                contents = await ZipUtil.getSingleFileContents(contents);
            }
            this.analyze(contents);
        }
        reader.onerror = error => {
            log.error("Error", error);
            if (error.total > 2 * 1024 * 1024 * 1024) {
                CommonDialogs.showDialog("The browser has a file size limit of 2GB.  Please use a smaller file.");
            } else {
                CommonDialogs.showDialog("Error uploading " + files[0].name);
            }
        }
    }

    private fileDragOver(event: DragEvent) {
        const files = DOMUtil.getDraggedFiles(event.domEvent as DomDragEvent);
        if (files.length === 1) {
            event.consume();
            this.textInput.backgroundColor = "default.lightest";
        }
    }
}
