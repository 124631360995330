import { getLogger, HorizontalAlignment, Keys, StringUtil, VerticalAlignment } from "@mcleod/core";
import { Image, Panel } from "..";
import { Component } from "../base/Component";
import { McLeodMainPageUtil } from "../base/McLeodMainPageUtil";
import { Label } from "../components/label/Label";
import { PanelProps } from "../components/panel/PanelProps";
import { ScreenStack } from "../components/panel/ScreenStack";
import { KeyHandler } from "../events/KeyHandler";
import { getComponentFromStringOrPropsOrComponent, StringOrPropsOrComponent } from "./getComponentFromStringOrPropsOrComponent";
import { HorizontalSpacer } from "./HorizontalSpacer";

export interface ToastOptions {
    persist?: boolean;
    millisUntilDismissal?: number;
    onDismiss?: () => void;
    id?: string;
    targetPanel?: Panel;
}

const _log = getLogger("components.page.Toast");

export class Toast extends Panel {
    private _alreadyDismissed = false;
    private _dismissTimeoutIds: number[];
    private _onDismiss: () => void;
    private _toastId: string;

    constructor(toastOptions?: ToastOptions, props?: Partial<PanelProps>, callSetProps: boolean = true) {
        super(props, callSetProps);
        this._onDismiss = toastOptions?.onDismiss;
        this._toastId = toastOptions?.id;
        this.fillKeyHandlerGroup();
    }

    public get toastId(): string {
        return this._toastId
    }

    private static _shouldDisplay(toastId: string): boolean {
        if (StringUtil.isEmptyString(toastId))
            return true;
        if (ScreenStack.getNewestToast(toastId) != null) {
            _log.debug(() => ["Not displaying Toast, Toast with ID ", toastId, " already in view"]);
            return false;
        }
        return true;
    }

    public static showToast(message: StringOrPropsOrComponent, props: Partial<PanelProps> = {}, options?: ToastOptions): Toast {
        const shouldDisplay = Toast._shouldDisplay(options?.id);
        if (shouldDisplay === false)
            return;
        if (props == null)
            props = {};
        //if background color wasn't provided in props, and either a Component or LabelProps was provided,
        //use the background color of the Component/LabelProps for the entire toast
        if (props?.backgroundColor == null) {
            if (message instanceof Component && message.backgroundColor != null)
                props.backgroundColor = message.backgroundColor;
            if (typeof message === "object" && message.backgroundColor != null)
                props.backgroundColor = message.backgroundColor;
        }
        const toast = Toast.createToastPanel(message, props, options);
        Toast.displayToast(toast, options);
        return toast;
    }

    public static showSuccessToast(title: string, message?: string, imageName?: string, options?: ToastOptions): Toast {
        const shouldDisplay = Toast._shouldDisplay(options?.id);
        if (shouldDisplay === false)
            return;
        const successPanel = new Panel({ wrap: false });
        if (imageName != null)
            successPanel.add(new Image({ name: imageName, themeKey: "toast.success.image", rowBreak: false }));
        const messagePanel = new Panel({
            padding: 0,
            margin: 0,
            rowBreak: false,
            align: imageName != null ? HorizontalAlignment.LEFT : HorizontalAlignment.CENTER,
            verticalAlign: VerticalAlignment.CENTER
        });
        messagePanel.add(new Label({ caption: title, themeKey: "toast.success.titleLabel", rowBreak: true }));
        if (message != null)
            messagePanel.add(new Label({ caption: message, themeKey: "toast.success.messageLabel" }));
        successPanel.add(messagePanel);
        const toast = Toast.createToastPanel(successPanel, { themeKey: "toast.success" }, options);
        this.displayToast(toast, options);
        return toast;
    }

    private static createToastPanel(message: StringOrPropsOrComponent, panelProps: Partial<PanelProps> = {}, options?: ToastOptions): Toast {
        const panel = new Toast(options, { themeKey: "toast", width: "100%", ...panelProps });
        const component = Toast.createComponentFromStringOrPropsOrComponent(message);
        const closeButton = new Image({ themeKey: "toast.closeImage", rowBreak: false });
        closeButton.addClickListener(() => panel.dismiss());
        panel.add(new HorizontalSpacer());
        panel.add(component);
        panel.add(new HorizontalSpacer());
        panel.add(closeButton);
        return panel;
    }

    /**
     * Dismisses a Toast, both in user-dismissed scenarios (when the Toast should be dismissed immediately),
     * and in auto-dismissed scenarios (when the Toast will be displayed for some number of milliseconds and then dismissed automatically).
     * In user-dismissed scenarios, the timeToWait value will be zero (indicating that the Toast should be dismissed right away).
     * 
     * @param timeToWait A number that indicates the time to wait before dismissing the Toast (in milliseconds).
     */
    dismiss(timeToWait: number = 0, animate: boolean = true) {
        if (this._alreadyDismissed === true)
            return;

        //clear any existing timeouts.  this would come into play when a user clicks X to close a non-persisted toast
        //in that case we'd want to not run dismiss again in a few seconds (based on the timed dismissal)
        this._clearDismissTimeouts();

        this._dismissTimeoutIds = [];
        this._dismissTimeoutIds.push(setTimeout(() => McLeodMainPageUtil.overridePageHeaderZIndex(0), timeToWait));
        this._dismissTimeoutIds.push(setTimeout(() => {
            this._alreadyDismissed = true;
            ScreenStack.popToast(this);
        }, timeToWait));
        this._dismissTimeoutIds.push(setTimeout(() => this.top = 0 - this._element.clientHeight, timeToWait));
        this._dismissTimeoutIds.push(setTimeout(() => {
            if (document.body.contains(this._element))
                document.body.removeChild(this._element)
            McLeodMainPageUtil.resetPageHeaderZIndex();
            if (this._onDismiss != null)
                this._onDismiss();
        }, timeToWait + (animate === true ? 1000 : 0)));
    }

    private _clearDismissTimeouts() {
        if (this._dismissTimeoutIds == null)
            return;
        for (const timeoutId of this._dismissTimeoutIds) {
            clearTimeout(timeoutId);
        }
    }

    /**
     * Displays a Toast and relates it to the 'current Toast target'.
     * Snackbars slide in from the top of the screen (below the header bar), and stack newest on top.
     * @param panel The Toast to display.
     * @param options A ToastOptions object.
     */
    private static displayToast(panel: Toast, options?: ToastOptions) {
        McLeodMainPageUtil.overridePageHeaderZIndex(1000);
        panel._element.style.position = "absolute";
        panel.top = -9999;
        document.body.appendChild(panel._element);
        const router = McLeodMainPageUtil.getRouterPanel();
        const routerTop = router._element.getBoundingClientRect().top;
        panel.top = routerTop - panel._element.clientHeight;
        let endTop: string | number = 0;
        if (router != null)
            endTop = routerTop;
        panel._element.style.transition = "top 300ms, opacity 300ms, bottom 300ms";
        setTimeout(() => panel.top = endTop, 300);
        ScreenStack.pushToast(panel, options?.targetPanel);
        if (options?.persist !== true) {
            const millisUntilDimissal = Toast.getMillisUntilDimissal(options);
            panel.dismiss(millisUntilDimissal);
        }
        setTimeout(() => McLeodMainPageUtil.resetPageHeaderZIndex(), 1000);
    }

    private static createComponentFromStringOrPropsOrComponent(message: StringOrPropsOrComponent): Component {
        const component = getComponentFromStringOrPropsOrComponent(message, { themeKey: "toast.defaultLabel" });
        component.rowBreak = false;
        return component;
    }

    /**
     * Get the amount of time that a Toast should be visible.
     * This should only be used when the Toast is set to not persist (when it is user-dismissed).
     * If the provided ToastOptions object is null or does not contain a millisUntilDimissal value, the default value of 7000 milliseconds will be returned.
     * 
     * @param options A ToastOptions object, which may or may not contain a millisUntilDimissal value
     * @returns A number, which is the amount of time that the Toast should be visible (in milliseconds).
     */
    private static getMillisUntilDimissal(options: ToastOptions): number {
        return options?.millisUntilDismissal || 7000;
    }

    getKeyHandlers(): KeyHandler[] {
        return [{ key: Keys.ESCAPE, listener: (event) => this.dismiss(), element: this._element }];
    }
}
