import { getLogger } from "@mcleod/core";
import { Label } from "..";
import { Component } from "../base/Component";
import { LabelProps } from "../components/label/LabelProps";

const log = getLogger("component.page.getComponentFromStringOrPropsOrComponent");

export type StringOrPropsOrComponent =
    string | ((args?: any) => string) |
    number | ((args?: any) => number) |
    Partial<LabelProps> | ((args?: any) => Partial<LabelProps>) |
    Component | ((args?: any) => Component);

/**
 * This function accepts either a function, a string, a props object or a component and returns
 * a component.  Here is the behavior when it is passed each of those things.
 *
 * A function: This function is called before evaluating the other types.  The function should return
 * a string, a props object, or a component.  If that function needs arguments, they can be passed as varargs.
 *
 * A string: a label is returned with the caption set to the props passed
 *
 * A props object: a label is returned with all the props passed
 *
 * A component: the component itself is returned
 * @param {} funtionStringPropsOrComponent
 * @param  {...any} argsIfFunction
 */
export function getComponentFromStringOrPropsOrComponent(functionStringPropsOrComponent: StringOrPropsOrComponent, defaultLabelProps?: Partial<LabelProps>, ...argsIfFunction) {
    let value = functionStringPropsOrComponent;
    if (typeof value === "function")
        value = value(...argsIfFunction);
    if (typeof value === "string")
        value = { caption: value };
    if (typeof value === "number")
        value = { caption: value.toString() };
    if (value instanceof Component)
        return value;
    if (typeof value === "object")
        return new Label({ ...defaultLabelProps, ...value });
    if (value == null)
        return null;
    log.info("Could not create component from passed value", functionStringPropsOrComponent);
    throw new Error("Could not create component from passed value.");
}

