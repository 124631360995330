import {
    ChangeEvent, ClickEvent, Component, DialogProps, KeyEvent, Panel, PanelProps, Textbox, ValidationResult
} from "@mcleod/components";
import { Alignment, HorizontalAlignment, Keys } from "@mcleod/core";

interface SingleItem {
    name: string;
    displayPanel: Panel;
}

export abstract class AbstractDisplayPanelSelector extends Panel {
    dialogProps: Partial<DialogProps> = { scrollY: false, width: 648, cursor: null, ...this.getDialogProps() };
    displayedItems: Panel;
    search: Textbox;
    allItems: SingleItem[] = [];
    _selectedItem: SingleItem;
    protected abstract getDialogProps(): Partial<DialogProps>;
    protected abstract getValidationMessage(): string;
    protected abstract discoverItemNames(): string[];
    protected abstract getSingleItemPanelComponents(name: string): Component[];

    constructor(props: Partial<PanelProps>) {
        super(props);
        this.scrollY = false;
        this.displayedItems = new Panel({ maxHeight: 345, scrollY: true });
        this.createSearch();
        this.add(this.search, this.displayedItems);
        this.loadItems();
    }

    private createSearch() {
        this.search = new Textbox({
            width: 260,
            captionVisible: false,
            marginLeft: 8,
            marginBottom: 12,
            fillRow: true,
            placeholder: "Search",
        });
        this.search.addKeyDownListener((event: KeyEvent) => this.searchKeyDown(event));
        this.search.addChangeListener((event: ChangeEvent) => this.displayItems());
    }

    private loadItems() {
        const itemNames = this.discoverItemNames();
        for (const name of itemNames) {
            this.allItems.push(this.createSingleItem(name));
        }
        this.displayItems();
    }

    protected createSingleItemPanel(): Panel {
        return new Panel({
            rowBreak: false,
            width: 170,
            margin: 8,
            borderRadius: 8,
            align: HorizontalAlignment.CENTER
        });
    }

    private createSingleItem(name: string): SingleItem {
        const singleItemPanel = this.createSingleItemPanel();
        singleItemPanel.add(...this.getSingleItemPanelComponents(name));
        const singleItem: SingleItem = { name: name, displayPanel: singleItemPanel };
        singleItemPanel.addClickListener((event: ClickEvent) => this.selectedItem = singleItem);
        return singleItem;
    }

    protected displayItems() {
        this.displayedItems.removeAll();
        this.selectedItem = null;
        for (const singleItem of this.allItems) {
            if (this.search.isEmpty() === true ||
                singleItem.name.toLowerCase().includes(this.search.text.toLowerCase())) {
                if (this.selectedItem == null)
                    this.selectedItem = singleItem;
                this.displayedItems.add(singleItem.displayPanel);
            }
        }
    }

    protected getItemsPerRow(): number {
        return 3;
    }

    private searchKeyDown(event: KeyEvent) {
        const key = event.key;
        const itemsPerRow = this.getItemsPerRow();
        if (this.selectedItem == null)
            return;
        let curr = this.displayedItems.indexOf(this.selectedItem.displayPanel);
        if (key === Keys.ARROW_UP)
            curr -= itemsPerRow;
        else if (key === Keys.ARROW_DOWN)
            curr += itemsPerRow;
        else if (key === Keys.ARROW_LEFT)
            curr--;
        else if (key === Keys.ARROW_RIGHT)
            curr++;
        if (curr < 0)
            curr = 0;
        if (curr >= this.displayedItems.getComponentCount())
            curr = this.displayedItems.getComponentCount() - 1;
        this.selectedItem = this.findSingleItem(this.displayedItems.getComponent(curr) as Panel);
        if (this.selectedItem != null)
            this.selectedItem.displayPanel.scrollIntoView();
    }

    private findSingleItem(displayPanel: Panel): SingleItem {
        for (const singleItem of this.allItems) {
            if (displayPanel === singleItem.displayPanel)
                return singleItem;
        }
        return null;
    }

    private get selectedItem(): SingleItem {
        return this._selectedItem;
    }

    private set selectedItem(value: SingleItem) {
        if (this._selectedItem != null) {
            this._selectedItem.displayPanel.backgroundColor = this.getUnselectedBackgroundColor();
            this._selectedItem.displayPanel.color = this.getUnselectedColor();
            this._selectedItem.displayPanel.borderColor = this.getUnselectedBorderColor();
            this._selectedItem.displayPanel.borderWidth = this.getUnselectedBorderWidth();
        }
        this._selectedItem = value;
        if (value != null) {
            value.displayPanel.backgroundColor = this.getSelectedBackgroundColor();
            value.displayPanel.color = this.getSelectedColor();
            value.displayPanel.borderColor = this.getSelectedBorderColor();
            value.displayPanel.borderWidth = this.getSelectedBorderWidth();
        }
    }

    protected getUnselectedColor(): string {
        return "unset";
    }

    protected getUnselectedBackgroundColor(): string {
        return "unset";
    }

    protected getUnselectedBorderColor(): string {
        return "unset";
    }

    protected getUnselectedBorderWidth(): number {
        return null;
    }

    protected getSelectedColor(): string {
        return "unset";
    }

    protected getSelectedBackgroundColor(): string {
        return "unset";
    }

    protected getSelectedBorderColor(): string {
        return "primary.light";
    }

    protected getSelectedBorderWidth(): number {
        return 1;
    }

    override validate(): ValidationResult[] {
        if (this.selectedItem == null) {
            const message = this.getValidationMessage();
            this.displayedItems.showTooltip(message, { position: Alignment.RIGHT, shaking: true });
            return [{ component: this, isValid: false, validationMessage: message }];
        }
    }

    getValue(): string {
        return this.selectedItem.name;
    }

    focus() {
        this.search.focus();
    }
}
