import { ArrayUtil, DOMUtil, HorizontalAlignment, ModelRow, Navigation, StringUtil, VerticalAlignment, WindowTitle, getLogger } from "@mcleod/core";
import { Component, Overlay, SaveButton, SearchButton, deserializeComponents, serializeComponents } from "../..";
import { ComponentPropDefinition, ComponentPropDefinitions } from "../../base/ComponentProps";
import { ComponentTypes } from "../../base/ComponentTypes";
import { DataSource, DataSourceMode } from "../../databinding/DataSource";
import { DataSourceModeChangeListener } from "../../events/DataSourceModeChangeEvent";
import { refreshKeyListeners } from "../../events/GlobalKeyListener";
import { HorizontalSpacer } from "../../page/HorizontalSpacer";
import { ComponentCreationCallback } from "../../serializer/ComponentDeserializer";
import { Button } from "../button/Button";
import { ButtonVariant } from "../button/ButtonVariant";
import { Label } from "../label/Label";
import { Layout } from "../layout/Layout";
import { Panel } from "../panel/Panel";
import { DataHeaderPropDefinitions, DataHeaderProps } from "./DataHeaderProps";
import { EllipsisPopup } from "./EllipsisPopup";

const log = getLogger("components/DataHeader");

export class DataHeader extends Panel implements DataHeaderProps {
    private labelTitle: Label;
    private _panelTitle: Panel;
    private _addlPanelLeft: Panel;
    private _addlPanelRight: Panel;
    private _designerAddlPanelLeftComponents: Component[];
    private _designerAddlPanelRightComponents: Component[];
    private panelTools: Panel;
    private _title: string;
    private _layout: Layout;
    private saveButton: SaveButton;
    private closeButton: Button;
    private searchButton: SearchButton;
    private _showSave: boolean;
    private _showClose: boolean;
    private _showSaveAndClose: boolean;
    private _showExecute: boolean;
    public onClose: () => void;
    public onExecute: (row: ModelRow<any>) => void;
    private _dataSourceModeChangeListener: DataSourceModeChangeListener;
    public onSaveButtonClose: () => void; //handles any needed close action when the user clicks Save & Close in the DataHeader's SaveButton
    private _ellipsisButton: Button;
    private _ellipsisPopup: EllipsisPopup;
    private _ellipsisPopupOverlay: Overlay;
    public sortLabels: (labels: Label[]) => void = (labels)=>{ labels.sort((a, b) => a.caption.localeCompare(b.caption));};

    constructor(props?: Partial<DataHeaderProps>) {
        super(props, false);
        const title = this.title;
        this._panelTitle = new Panel({ align: HorizontalAlignment.LEFT, verticalAlign: VerticalAlignment.CENTER, rowBreak: false, paddingLeft: 0 });
        this.labelTitle = new Label({
            themeKey: "label.pageTitle",
            id: "labelTitle",
            caption: title,
            rowBreak: false
        });
        this._panelTitle.add(this.labelTitle);
        this._addlPanelLeft = new Panel({ id: "panelDataHeaderAddlLeft", align: HorizontalAlignment.LEFT, verticalAlign: VerticalAlignment.CENTER, rowBreak: false, margin: 0, padding: 0, _designer: this._designer });
        this._addlPanelRight = new Panel({ id: "panelDataHeaderAddlRight", align: HorizontalAlignment.RIGHT, verticalAlign: VerticalAlignment.CENTER, rowBreak: false, margin: 0, padding: 0, _designer: this._designer });
        this.closeButton = this.createCloseButton();
        this.panelTools = new Panel({ align: HorizontalAlignment.RIGHT, verticalAlign: VerticalAlignment.CENTER, paddingRight: 0 });
        this.add(this._panelTitle, this._addlPanelLeft, new HorizontalSpacer(), this._addlPanelRight, this.panelTools);
        this.forEveryChildComponent((component: Component) => component.deserialized = false);
        this._dataSourceModeChangeListener = event => this.syncTitle();
        this.addMountListener(() => this.syncTitle());
        this.setProps({ fillRow: true, marginBottom: 12, ...props });
    }

    public get showTitle(): boolean {
        return this.layout?.hideTitle != null ? !this.layout?.hideTitle : true;
    }

    private _showHideTitle() {
        if (this.showTitle !== false) {
            if (!this.contains(this._panelTitle))
                this.insert(this._panelTitle, 0);
        }
        else
            this.remove(this._panelTitle);
    }

    public get title(): string {
        return this._title || this.getLayoutTitle(this.dataSource?.mode);
    }

    public set title(value: string) {
        this._title = value;
        this.syncTitle();
    }

    public get layout(): Layout {
        return this._layout || this.findParentLayout();
    }

    public set layout(value: Layout) {
        this._layout = value;
        this._showHideTitle();
        this.syncTitle();
    }

    private findParentLayout() {
        let result = this.parent;
        while (result != null) {
            if (result instanceof Layout)
                return result;
            result = result.parent;
        }
        return null;
    }

    private getLayoutTitle(mode: DataSourceMode): string {
        const layout = this.layout;
        return layout == null ? null : layout.getTitleForMode(mode);
    }

    public get dataSource(): DataSource {
        return super.dataSource;
    }

    public set dataSource(value: DataSource) {
        if (this.dataSource != null)
            this.dataSource.removeAfterModeChangeListener(this._dataSourceModeChangeListener);
        super.dataSource = value;
        if (this.saveButton != null)
            this.saveButton.dataSource = value;
        this.syncTitle();
        if (value != null)
            value.addAfterModeChangeListener(this._dataSourceModeChangeListener);
    }

    public syncTitle(mode?: DataSourceMode) {
        mode = mode || this?.layout?.mainDataSource?.mode;
        const title = this.__designer == null ? (this._title || this.getLayoutTitle(mode)) : "Dynamic Title";
        this.labelTitle.caption = title;
        if (this.showTitle !== false && StringUtil.isEmptyString(title) !== true && this.__designer == null)
            WindowTitle.set(title);
        this.addTools(mode);
    }

    private addTools(mode: DataSourceMode) {
        this.panelTools.removeAll();
        if (this.showExecute !== false) {
            if (mode === DataSourceMode.SEARCH)
                this.configureToolsForSearch();
            else
                this.configureToolsForAddOrUpdate();
        }
        if (this.showClose === true)
            this.panelTools.add(this.closeButton);
        refreshKeyListeners(this);
    }

    private configureToolsForSearch() {
        if (this.saveButton != null && this.saveButton.primaryButton != null)
            this.saveButton.primaryButton.default = true;
        this._buildAddlLeftComponents();
        this._buildAddlRightComponents();
        this.searchButton = new SearchButton({
            default: true,
            backgroundColor: "primary",
            color: "primary.reverse",
            width: 100,
            marginRight: 0,
            rowBreak: false
        });
        this.searchButton.addClickListener(event => {
            const values = this.layout.mainDataSource.getSearchFilter(true);
            if (this.layout.mainDataSource.validate(true)) {
                if (this.onExecute == null)
                    this.dataSource.search(values);
                else
                    this.onExecute(values);
                this.closeChildLayout();
            }
        });
        this.panelTools.add(this.searchButton);
        this._buildEllipsisActions();
    }

    private configureToolsForAddOrUpdate() {
        if (this.searchButton != null)
            this.searchButton.default = false;
        this._buildAddlLeftComponents();
        this._buildAddlRightComponents();
        this.saveButton?.removeFromHasChangedComponents();
        const additionalSaveOptions: Label[] = this.saveButton?.addlActions;
        this.saveButton = new SaveButton({
            id: "dhSaveCloseButton",
            backgroundColor: "primary",
            color: "primary.reverse",
            minWidth: 128,
            borderWidth: 0,
            allowSave: this.showSave,
            allowSaveAndClose: this.showSaveAndClose,
            rowBreak: false,
            dataSource: this.dataSource,
        });
        this.saveButton._interactionEnabled = this.__designer == null;
        this.setSaveButtonAddlActions(additionalSaveOptions);
        this.saveButton.primaryButton.default = true;
        if (this.onSaveButtonClose == null)
            this.saveButton.onClose = () => Navigation.navigateBack();
        else
            this.saveButton.onClose = () => this.onSaveButtonClose();
        if (this.onExecute != null)
            this.saveButton.onSave = (postedRow) => this.onExecute(postedRow);
        this.panelTools.add(this.saveButton);
        this._buildEllipsisActions();
    }

    private createCloseButton(): Button {
        const cancelButton = new Button({
            id: "dhCloseButton",
            cancel: true,
            rowBreak: false,
            borderWidth: 0,
            padding: 2,
            variant: ButtonVariant.round,
            imageProps: { name: "x", height: 32, width: 32, color: "primary" }
        });
        cancelButton._interactionEnabled = this._designer == null;
        cancelButton.addClickListener(() => this.closeParent());
        return cancelButton;
    }

    private closeParent() {
        if (this.onClose == null)
            Navigation.navigateBack();
        else
            this.onClose();
    }

    closeChildLayout() {
        if (this.onClose != null)
            this.onClose();
    }

    save() {
        const childDs = this.layout.mainDataSource;
        const childMode = childDs.mode;
        childDs.execute().then(postedRow => {
            if (postedRow != null) {
                const thisDs = this.dataSource;
                if (childMode === DataSourceMode.ADD)
                    thisDs.addRow(postedRow, 0);
                else
                    thisDs.replaceRow(this.layout.mainDataSource.activeRow, postedRow);
                if (this.onExecute != null)
                    this.onExecute(postedRow);
            }
        });
    }

    public get showClose(): boolean {
        return this._showClose == null ? DataHeaderPropDefinitions.getDefinitions().showClose.defaultValue : this._showClose;
    }

    public set showClose(value: boolean) {
        this._showClose = value;
        this.addTools(this.layout?.mainDataSource?.mode);
    }

    public get showSave(): boolean {
        return this._showSave == null ? DataHeaderPropDefinitions.getDefinitions().showSave.defaultValue : this._showSave;
    }

    public set showSave(value: boolean) {
        this._showSave = value;
        this.addTools(this.layout?.mainDataSource?.mode);
    }

    public get showSaveAndClose(): boolean {
        return this._showSaveAndClose == null ? DataHeaderPropDefinitions.getDefinitions().showSaveAndClose.defaultValue : this._showSaveAndClose;
    }

    public set showSaveAndClose(value: boolean) {
        this._showSaveAndClose = value;
        this.addTools(this.layout?.mainDataSource?.mode);
    }

    public get showExecute(): boolean {
        return this._showExecute == null ? DataHeaderPropDefinitions.getDefinitions().showExecute.defaultValue : this._showExecute;
    }

    public set showExecute(value: boolean) {
        this._showExecute = value;
        this.addTools(this.layout?.mainDataSource?.mode);
    }

    private _buildAddlLeftComponents() {
        this._addlPanelLeft.removeAll();
        if (this._designerAddlPanelLeftComponents != null) {
            for (const c of this._designerAddlPanelLeftComponents) {
                this._addlPanelLeft.add(c);
            }
        }
        const components = this.layout?.getDataHeaderAddlLeftComponents();
        if (components == null)
            return;
        for (const component of components) {
            this._addlPanelLeft.add(component);
        }
    }

    private _buildAddlRightComponents() {
        this._addlPanelRight.removeAll();
        if (this._designerAddlPanelRightComponents != null) {
            for (const c of this._designerAddlPanelRightComponents) {
                this._addlPanelRight.add(c);
            }
        }
        const components = this.layout?.getDataHeaderAddlRightComponents();
        if (components == null)
            return;
        for (const component of components) {
            this._addlPanelRight.add(component);
        }
    }

    private _buildEllipsisActions() {
        const actions = this.layout?.getDataHeaderEllipsisActions();
        if (ArrayUtil.isEmptyArray(actions))
            return;
        this.sortLabels(actions);
        this._ellipsisButton = new Button({
            focusable: false,
            padding: 6,
            height: DOMUtil.getElementHeight(this.saveButton?._element),
            borderWidth: 0,
            rowBreak: false,
            color: "primary",
            imageProps: {
                name: "ellipsis",
                height: 18,
                width: 18
            }
        });
        this._ellipsisButton.addClickListener(event => this._toggleEllipsisPopup(this._ellipsisButton, actions));
        this.panelTools.add(this._ellipsisButton);
    }

    private _toggleEllipsisPopup(ellipsisButton: Button, labels: Label[]) {
        if (this._ellipsisPopup == null) {
            this._ellipsisPopup = new EllipsisPopup(labels, this._ellipsisButton, () => this._hideEllipsisPopup(this._ellipsisPopupOverlay));
            this._showEllipsisPopup(this._ellipsisPopup, ellipsisButton);
        }
        else
            this._hideEllipsisPopup(this._ellipsisPopupOverlay);
    }

    private _showEllipsisPopup(ellipsisPopup: EllipsisPopup, anchor: Button) {
        let popupWidth;
        try {
            ellipsisPopup._element.style.position = "absolute";
            ellipsisPopup.left = -9999;
            document.body.appendChild(ellipsisPopup._element);
            popupWidth = DOMUtil.convertStyleAttrToNumber(DOMUtil.getComputedStyle("width", ellipsisPopup._element));
        }
        finally {
            document.body.removeChild(ellipsisPopup._element);
            ellipsisPopup._element.style.position = "";
            ellipsisPopup.left = null;
        }
        const anchorBounds = anchor._element.getBoundingClientRect();
        this._ellipsisPopupOverlay = Overlay.showInOverlay(ellipsisPopup, {
            onClose: () => this._ellipsisPopup = null,
            style: {
                position: "absolute",
                top: DOMUtil.getSizeSpecifier(anchorBounds.top),
                left: DOMUtil.getSizeSpecifier(anchorBounds.right - popupWidth - 1)
            }
        });
    }

    private _hideEllipsisPopup(overlayToClose: Overlay) {
        Overlay.hideOverlay(overlayToClose);
        this._ellipsisPopup = null;
    }

    getPropertyDefaultValue(prop: ComponentPropDefinition) {
        if (prop.name === "title")
            return this.getLayoutTitle(DataSourceMode.NONE);
        return super.getPropertyDefaultValue(prop);
    }

    override getPropertyDefinitions(): ComponentPropDefinitions {
        return DataHeaderPropDefinitions.getDefinitions();
    }

    override get serializationName() {
        return "dataheader";
    }

    override get properName(): string {
        return "Data Header";
    }

    setSaveButtonAddlActions(labels: Label[]) {
        if (this.saveButton != null) {
            log.debug("Adding additional actions to save button: %o", labels);
            this.saveButton.addlActions = labels;
        }
        else
            log.debug("Not adding additional actions to save button, as button is configured to not be present %o", labels);
    }

    get _designer() {
        return super._designer;
    }

    set _designer(value) {
        this._shouldAddDesignerContainerProperties = false;
        super._designer = value;
        if (value != null) {
            this.labelTitle.caption = "Dynamic Title";
            this._addlPanelLeft._designer = value;
            this._addlPanelRight._designer = value;
        }
        if (value?.addDesignerContainerProperties != null)
            value.addDesignerContainerProperties(this, 80, 34, (tool) => false);
    }

    _serializeNonProps(): string {
        let result = "";
        let addlPanelLeft = "";
        let addlPanelRight = "";
        if (ArrayUtil.isEmptyArray(this._addlPanelLeft?.components) !== true)
            addlPanelLeft += serializeComponents(this._addlPanelLeft, null);
        if (ArrayUtil.isEmptyArray(this._addlPanelRight?.components) !== true)
            addlPanelRight += serializeComponents(this._addlPanelRight, null);
        if (StringUtil.isEmptyString(addlPanelLeft) !== true || StringUtil.isEmptyString(addlPanelRight) !== true) {
            result += "\"components\": [\n";
            if (StringUtil.isEmptyString(addlPanelLeft) !== true)
                result += addlPanelLeft + ",\n";
            if (StringUtil.isEmptyString(addlPanelRight) !== true)
                result += addlPanelRight + ",\n";
            result = result.substring(0, result.length - 2);
            result += "\n],\n";
        }
        return result;
    }

    _deserializeSpecialProps(componentOwner, compDef, defaultPropValues, dataSources, componentCreationCallback: ComponentCreationCallback) {
        if (compDef.dataSource != null && dataSources != null)
            this.dataSource = dataSources[compDef.dataSource];
        this.labelTitle._interactionEnabled = this._designer == null;
        if (this.saveButton != null)
            this.saveButton._interactionEnabled = this._designer == null
        if (this.closeButton != null)
            this.closeButton._interactionEnabled = this._designer == null
        const components = deserializeComponents(componentOwner, compDef.components, this.__designer, null, dataSources, componentCreationCallback);
        if (components != null) {
            for (const component of components) {
                if (component.id === "panelDataHeaderAddlLeft") {
                    component.deserialized = false;
                    this.replace(this._addlPanelLeft, component);
                    this._addlPanelLeft = component;
                    this._designerAddlPanelLeftComponents = [...this._addlPanelLeft.components];
                    if (this.__designer != null)
                        this._addlPanelLeft.minWidth = 25;
                }
                if (component.id === "panelDataHeaderAddlRight") {
                    component.deserialized = false;
                    this.replace(this._addlPanelRight, component);
                    this._addlPanelRight = component;
                    this._designerAddlPanelRightComponents = [...this._addlPanelRight.components];
                    if (this.__designer != null)
                        this._addlPanelRight.minWidth = 25;
                }
            }
        }
        return ["dataSource", "components"];
    }

    public override discoverIncludedComponents(): Component[] {
        return [this._addlPanelLeft, this._addlPanelRight];
    }
}

ComponentTypes.registerComponentType("dataheader", DataHeader.prototype.constructor);
