import { getLogger } from "./Logger";

const log = getLogger("core.Clipboard");

export class Clipboard {
    /**
     * Copies the specified text to the user's clipboard so it is available to be pasted later.
     *
     * @param text The value to be copied to the clipboard.
     * @returns boolean true if copying was success
     */
    public static async copyText(text: string, isHTML: boolean = false): Promise<void> {
        if (isHTML) {
            const blob = new Blob([text], { type: 'text/html' });
            return navigator.clipboard.write([ new ClipboardItem({ 'text/html': blob }) ]);
        } else {
            return await navigator.clipboard.writeText(text);
        }
    }
}
