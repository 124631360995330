import { Component, Container } from "@mcleod/components";
import { StringUtil } from "@mcleod/core";
import { AbstractUIDesigner } from "../AbstractUIDesigner";
import { ActionAddToContainer } from "./ActionAddToContainer";
import { ActionAddToContainerResult } from "./ActionAddToContainerResult";

export class ActionAddComponent implements ActionAddToContainer {
    component: Component;
    parent: Container;
    compIndex: number;
    designer: AbstractUIDesigner;

    constructor(component: Component, parent: Container, compIndex?: number) {
        this.component = component;
        this.parent = parent;
        this.compIndex = compIndex;
    }

    execute(): ActionAddToContainerResult {
        if (this.parent != null) {
            if (this.compIndex != null)
                this.parent.insert(this.component, this.compIndex);
            else
                this.parent._designerDrop(this.component);

            if (!this.designer?.selectedComponents?.includes(this.component))
                this.designer.selectComponent(this.component);
        }
        return { success: true, container: this.parent, componentsAdded: [this.component] };
    }

    undo() {
        this.parent.remove(this.component);
        if (this.designer?.selectedComponents?.includes(this.component))
            this.designer.selectComponent(null);
    }

    toString() {
        return `Add ${StringUtil.toProperCase(this.component.serializationName)} - ${this.component.id}`;
    }
}
