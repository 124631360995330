import { getThemeColor, makeStyles } from "@mcleod/core";
import { Cursor } from "../../base/Cursor";

export const splitterStyles = makeStyles("split", () => {
    return {
        splitBase: {
            display: "flex",
            flexDirection: "row"
        },
        splitContainer: {
            maxWidth: "100%",
            maxHeight: "100%",
            overflow: "auto"
        },
        splitDivider: {
            flex: "0 0 4px",
            backgroundColor: getThemeColor("primary.darker"),
            cursor: Cursor.EW_RESIZE,
            zIndex: 2,
            display: "flex",
            justifyContent: "center",
            "&:hover:enabled": { backgroundColor: getThemeColor("#ccc") },
        }
    };
});
