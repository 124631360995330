import {
    Api, ArrayUtil, AuthToken, AuthType, Collection, DisplayValue, DynamicLoader, getLogger, getThemeColor,
    HelpLink, HorizontalAlignment, makeStyles, ModelRow, PermissionsUtil, ServerError, StringUtil, UserSettings
} from "@mcleod/core";
import {
    Button, Container, DataHeader, DataSource, DataSourceExecutionEvent, DataSourceMode, McLeodMainPageUtil,
    PropType, ScreenStack, Table
} from "../..";
import { Component } from "../../base/Component";
import { ComponentPropDefinition } from "../../base/ComponentProps";
import { ComponentTypes } from "../../base/ComponentTypes";
import { ListenerListDef } from "../../base/ListenerListDef";
import { ReflectiveDialogs } from "../../base/ReflectiveDialogs";
import { TransitionOptions } from "../../base/TransitionOptions";
import { createDataSourcesFromDef } from "../../databinding/DataSource";
import { Event, EventListener } from "../../events/Event";
import { RowLayoutFields } from "../../models/autogen/AutogenModelLayout";
import { ModelLayout } from "../../models/ModelLayout";
import { Decorator } from "../../page/decorators/Decorator";
import { deserializeComponents } from "../../serializer/ComponentDeserializer";
import { Label } from "../label/Label";
import { Panel } from "../panel/Panel";
import { Spinner } from "../spinner/Spinner";
import { LayoutPropDefinitions, LayoutProps } from "./LayoutProps";
import { WidgetTitleBar } from "./WidgetTitleBar";

const cachedLayouts: Collection<string> = {};
const inFlightApiCalls: Collection<Promise<ModelRow<any>>> = {};

const log = getLogger("components/LayoutPanel");

const _loadListenerDef: ListenerListDef = { listName: "_loadListeners" };
const _loadErrorDef: ListenerListDef = { listName: "_errorListeners" };
const _dataLoadListenerDef: ListenerListDef = { listName: "_dataLoadListeners" };

const classes = makeStyles("lyt", () => {
    return {
        nestedDesignerOverlay: {
            width: "100%", height: "100%", position: "absolute", zIndex: 25, display: "flex",
            justifyContent: "center", flexDirection: "row", alignItems: "center",
            backgroundColor: "#5555000B", color: getThemeColor("primary") + "28"
        }
    }
});

// These props need to be set before getting the definition from the server
const priorityProps: (keyof LayoutProps)[] = [
    "_designer",
    "applyFieldLevelPermissions",
    "applyFieldLevelLicensing",
    "applyFieldLevelCompanyType",
    "applyFieldLevelLtlType",
    "applyFieldLevelExperiment",
    "applyFieldLevelMulitCurrency",
    "needsServerLayout",
    "removedByServer",
    "isNested"
];


/**
 * A Layout is a Panel subclass that gets its basic structure from a server-defined API call.  The server reads a .layout
 * file (in JSON format) and returns it to this class.  This class parses the result and adds the components defined in
 * the .layout.
 *
 * This .layout file is almost always created by the UI Designer page.
 *
 */
export class Layout extends Panel implements LayoutProps {
    public static shouldLoadVideoItemHelpLinks: boolean = true;

    private _layoutName: string;
    private _loaded: boolean;
    private _loadError: string;
    private _dataLoaded: boolean;
    private _dataLoadDataSources: DataSource[];
    public auth: string;
    private _allowDecoratorOverride: boolean;
    public defaultDecorator: string;
    private _mainDataSource: DataSource;
    public needsServerLayout: boolean = true;
    private _title: string;
    private _titleImage: string;
    private _titleAddModeSuffix: string;
    private _titleEditModeSuffix: string;
    private _titleSearchModeSuffix: string;
    private _hideTitle: boolean;
    private _hideSuffix: boolean;
    public applyFieldLevelPermissions: boolean;
    public applyFieldLevelLicensing: boolean;
    public applyFieldLevelCompanyType: boolean;
    public applyFieldLevelLtlType: boolean;
    public applyFieldLevelExperiment: boolean;
    public applyFieldLevelMulitCurrency: boolean;
    public dataSourceIDs: string[];
    private _isNested: boolean;
    private _inheritParentDataSource: string;
    private _loadPromise: Promise<any>;
    private _titleSinglular: string;
    private _titlePlural: string;
    private displayedLoadError;
    private _dataHeader: DataHeader;
    private _helpLinks: HelpLink[];
    private _videoItemHelpLinks: HelpLink[];
    private _displayingFromToolbox: boolean;
    private _renderAsWidget: boolean;
    private _displayRefresh: boolean;
    private _widgetGroup: string;
    private _homePageSize: string;
    private _titleBar: WidgetTitleBar;
    private _widgetContentPanel: Panel;
    private _busy: boolean;
    private _originalPropsBeforeWidget: any;
    private _layoutId: string;
    private _descr: string;
    private loadLayoutWithoutDefError: boolean;

    constructor(props: Partial<LayoutProps> = {}) {
        super(props, false);
        this.setPriorityProps(props);
        this.setProps({
            fillRow: true,
            fillHeight: true,
            padding: 0,
            scrollX: LayoutPropDefinitions.getDefinitions().scrollX.defaultValue,
            scrollY: LayoutPropDefinitions.getDefinitions().scrollY.defaultValue,
            ...props
        });

        if (this.needsServerLayout === false) {
            this.ensureLoaded().then(() => {
                if (this.loadLayoutWithoutDefError !== true)
                    this.loaded();
            });
        }
    }

    private setPriorityProps(props: Partial<LayoutProps>) {
        priorityProps.forEach(prop => {
            if (props[prop] != null) {
                this[prop] = props[prop];
                delete props[prop];
            }
        });
    }

    set layoutName(value: string) {
        if (this.layoutName === value)
            return;
        const oldValue = this._layoutName;
        this._layoutName = value;
        this.loadLayout();
        if (this._designer != null && this.isNested && this.deserialized) {
            const oldVal = StringUtil.stringAfterLast(oldValue, "/", false, oldValue);
            const newVal = StringUtil.stringAfterLast(value, "/", false, value);
            this._matchIdToValue(oldVal, newVal);
        }
    }

    get layoutName(): string {
        return this._layoutName;
    }

    set layoutId(value: string) {
        this._layoutId = value;
    }

    private testingSpecificLayoutVersion(): boolean {
        return StringUtil.isEmptyString(this._layoutId) === false;
    }

    setProps(props: Partial<LayoutProps>) {
        super.setProps(props);
    }

    private loadLayout() {
        if (this.needsServerLayout === false) {
            this._loadPromise = this.loadLayoutWithoutDef().catch(error => {
                this.handleLoadError(error);
                this.loadLayoutWithoutDefError = true;
            });
        } else {
            this.add(new Spinner());
            this._loadPromise = this.getLayoutDefinition().then(def => {
                return this.displayLayout(def);
            }).catch((error) => {
                if (this.isNested && this._designer != null)
                    this.removeAll();
                else {
                    this.handleLoadError(error);
                }
            });
        }
    }

    private async getLayoutDefinition(): Promise<string> {
        const def = (this._designer == null && this.testingSpecificLayoutVersion() === false) ? cachedLayouts[this.layoutName] : null;
        if (def != null) {
            log.debug("Not Requesting def because layout was cached:", this.layoutName);
            return def;
        }
        const rowLayout = await this.getLayoutDefinitionFromServer();
        log.debug("Requesting def from server", this.layoutName);
        if (this._designer == null) {
            if (this.testingSpecificLayoutVersion() === false) {
                cachedLayouts[this.layoutName] = rowLayout.get("definition");
            } else {
                this.setDescrFromResponse(rowLayout);
            }
        }
        return rowLayout.get("definition");
    }

    private async getLayoutDefinitionFromServer(): Promise<ModelRow<any>> {
        const searchFilter = this.createSearchFilter();
        const searchKey = JSON.stringify(searchFilter);

        if (!inFlightApiCalls[searchKey]) {
            log.debug("Requesting layout definition from server for", this.layoutName);
            inFlightApiCalls[searchKey] = new ModelLayout().searchSingle(searchFilter).finally(() => {
                delete inFlightApiCalls[searchKey];
            });
        } else {
            log.debug(`Not requesting layout definition for ${this.layoutName}. A request has already been made, waiting for response.`);
        }

        return inFlightApiCalls[searchKey];
    }

    private handleLoadError(error: any) {
        this.removeAll();
        log.info(error);
        this.displayLoadErrorPanel(error);
        this.fireListeners(_loadErrorDef, new Event(this));
    }

    private async loadLayoutWithoutDef() {
        if (!this.validateAuthType()) {
            throw Error("User is not authorized to view this layout")
        }

        if (PermissionsUtil.isUserDeniedLayout(this.layoutName)) {
            throw Error("Permission denied for this layout");
        }

        this.retrieveVideoItemHelpLinks();
    }

    private validateAuthType(): boolean {
        const authType = this.auth ?? AuthType.LME;
        const userAuthType = UserSettings.getUserAuthType();
        return authType === AuthType.UNAUTH || authType === AuthType.ANY ||
            authType === userAuthType || userAuthType === AuthType.MCLEOD;
    }

    private displayLoadErrorPanel(error) {
        const cls = DynamicLoader.getClassForPath("components/page/PanelStaticError");
        if (cls != null) {
            let errorSummary = "Sorry, there was an error loading this page.";
            if (error instanceof ServerError && error.errorTag == null && error.messages?.length == 1)
                errorSummary = error.messages[0];
            const inst = new cls({ errorSummary })
            this.add(inst);
        }
    }

    private createSearchFilter(): Partial<RowLayoutFields> {
        const filter: Partial<RowLayoutFields> = {
            path: this.layoutName,
            removed_by_server: this.removedByServer,
            apply_field_level_perms: this.applyFieldLevelPermissions,
            apply_field_level_licensing: this.applyFieldLevelLicensing,
            apply_field_level_company_type: this.applyFieldLevelCompanyType,
            apply_field_level_ltl_type: this.applyFieldLevelLtlType,
            apply_field_level_experiment: this.applyFieldLevelExperiment,
            apply_field_level_multi_currency: this.applyFieldLevelMulitCurrency
         };
        if (this.testingSpecificLayoutVersion() === true) {
            if (this._layoutId !== "base")
                filter.id = this._layoutId;
            else
                filter.require_base_version = true;
        }
        return filter;
    }

    private setDescrFromResponse(rowLayout: ModelRow) {
        if (this._layoutId === "base")
            this._descr = "Base"
        else
            this._descr = rowLayout.get("descr", null);
    }

    private async loaded() {
        if (!this._loaded) {
            if (this.isNested && this.inheritParentDataSource != null)
                this.replaceMainDatasource(this.owner?.[this.inheritParentDataSource]);
            if (this._designer == null) {
                for (const source of this._getDataSources()) {
                    if (source.defaultMode != DataSourceMode.NONE) {
                        source.mode = source.defaultMode;
                    }
                }
            }
            this._loaded = true;
            await this.onLoad();
            if (this.renderAsWidget === true)
                this.syncRenderAsWidget();
            this.fireListeners(_loadListenerDef, new Event(this));
            if (this.isNested != true && this.parent != null)
                ScreenStack.fireCurrentLayoutListeners();
        }
    }

    /**
     * This method is meant to be overridden by subclasses that might need to do something once all the components
     * are added to the screen.
     */
    onLoad(): void | Promise<void> {
    }

    addLayoutLoadListener(value: EventListener) {
        value.runsOnce = true;
        this.addEventListener(_loadListenerDef, value);
        if (this._loaded === true)
            this.fireListeners(_loadListenerDef, new Event(this));
    }

    removeLayoutLoadListener(value: EventListener) {
        this.removeEventListener(_loadListenerDef, value);
    }

    addLoadErrorListener(value: EventListener) {
        this.addEventListener(_loadErrorDef, value);
        if (this._loadError != null)
            this.fireListeners(_loadErrorDef, new Event(this));
    }

    removeLoadErrorListener(value: EventListener) {
        this.removeEventListener(_loadErrorDef, value);
    }

    /**
     * A Layout's data load listeners are meant to fire after the layout has been loaded, AND
     * all DataSources within the layout (including nested layouts) have finished searching.
     * This is done by:
     *  -> Gathering all DataSources (including those from nested layouts)
     *  -> Determining which of those DataSources are top-level DataSources
     *  -> See if any top-level DataSources are currently searching.
     *     Each DataSource still searching has an after execute listener added to it so it can notify us when it's done.
     *  -> DataSources that are not at the top-level (those that are a child of another DataSource) are handled by the DataSource class
     *     (since we use the afterSearchPlusChildren listener)
     *  -> DataSources that begin to search after this point are added to the list of DataSources and get a similar listener.
     *     This is the purpose of the DataSource's trackingLayout; it lets the DataSource be tied to these functions in the Layout.
     *  -> When no DataSources are still executing (when the array is empty), fire the data load listeners.
     *
     * Note that data load listeners added after the above process has taken place will run immediately.
     *
     * @param value the method to call when both the layout and the layout's data has been loaded
     */
    addLayoutDataLoadListener(value: EventListener) {
        value.runsOnce = true;
        const isFirstListener = !this.hasListeners(_dataLoadListenerDef) && this._dataLoaded !== true;
        this.addEventListener(_dataLoadListenerDef, value);
        if (this._loaded === true && this._dataLoaded === true) {
            this.fireListeners(_dataLoadListenerDef, new Event(this));
            return;
        }
        if (isFirstListener === true) {
            const topLevelDataLoadDataSources = DataSource.getTopLevelDataSources(this._dataLoadDataSources);
            this._dataLoadDataSources = null;
            if (ArrayUtil.isEmptyArray(topLevelDataLoadDataSources) === true) {
                this._dataLoaded = true;
                this._fireDataLoadedListeners();
                return;
            }
            for (const dataSource of topLevelDataLoadDataSources) {
                dataSource.trackingLayout = this;
                if (dataSource.isSearchingOrChildrenSearching === true)
                    this.addDataLoadDataSource(dataSource);
            }
        }
    }

    removeLayoutDataLoadListener(value: EventListener) {
        this.removeEventListener(_dataLoadListenerDef, value);
    }

    addDataLoadDataSource(dataSource: DataSource) {
        if (this._dataLoaded === true) {
            dataSource.trackingLayout = null;
            return;
        }
        if (this._dataLoadDataSources == null)
            this._dataLoadDataSources = [];
        if (ArrayUtil.addNoDuplicates(this._dataLoadDataSources, dataSource) === true)
            this._addDataLoadDataSourceListener(dataSource);
    }

    private _addDataLoadDataSourceListener(dataSource: DataSource) {
        const callback: EventListener = (event: DataSourceExecutionEvent) => this._removeDataLoadDataSource(this, event.dataSource);
        callback.runsOnce = true;
        dataSource.addAfterSearchPlusChildrenListener(callback);
    }

    private _removeDataLoadDataSource(layout: Layout, dataSource: DataSource) {
        ArrayUtil.removeFromArray(layout._dataLoadDataSources, dataSource);
        if (layout._dataLoaded === true)
            return;
        if (ArrayUtil.isEmptyArray(layout._dataLoadDataSources) === true) {
            layout._dataLoaded = true;
            this._fireDataLoadedListeners(layout);
        }
    }

    private _fireDataLoadedListeners(layout: Layout = this) {
        layout.addLayoutLoadListener(event => layout.fireListeners(_dataLoadListenerDef, new Event(layout)));
    }

    override getPropertyDefinitions() {
        if (this.isNested)
            return {
                ...LayoutPropDefinitions.getDefinitions(), id: {
                    type: PropType.string,
                    description: "The identifier for this nested layout."
                }
            }
        return LayoutPropDefinitions.getDefinitions();
    }

    search(filter: any): Promise<any> {
        if (this.mainDataSource == null)
            throw new Error("Can't search a layout without a mainDataSource.");
        return this.mainDataSource.search(filter);
    }

    ensureLoaded(): Promise<any> {
        if (this._loadPromise != null)
            return this._loadPromise;
        else
            return Promise.resolve(null);
    }

    async displayLayout(defStr: string) {
        try {
            this.removeAll();
            const def = JSON.parse(defStr);
            let dataSources: Collection<DataSource>;
            const dataSourceIDs = [];
            if (def.dataSources != null) {
                const sources = createDataSourcesFromDef(def.dataSources, this, this.layoutName);
                dataSources = {};
                for (const dataSource of sources) {
                    dataSources[dataSource.id] = dataSource;
                    dataSourceIDs.push(dataSource.id);
                    this[dataSource.id] = dataSource;
                    await dataSource.getMetadata();
                }
            }
            for (const key in LayoutPropDefinitions.getDefinitions()) {
                let value = def[key];
                if (value != null) {
                    if (key === "mainDataSource")
                        value = dataSources[value];
                    this[key] = value;
                }
            }

            const defaultProps = this.removedByServer ? { removedByServer: true } : null;
            const components = deserializeComponents(this, def.components, this._designer, defaultProps, dataSources, null);

            await this.deserializeRemovedComponents(def);

            for (const comp of components) {
                if (!(comp instanceof DataHeader))
                    this.add(comp);
                else
                    this.dataHeader = comp;
            }

            if (this._designer == null) {
                for (const dataSourceID of dataSourceIDs) {
                    const dataSource = dataSources[dataSourceID];
                    if (dataSource.autoSearch === true) {
                        dataSource.mode = DataSourceMode.SEARCH;
                    }
                }
            }

            this.dataSourceIDs = [...dataSourceIDs];
            this._dataLoadDataSources = this._getDataSources();
            await this.loadComponentMetadata();
            const childLayoutVideoItemHelpLinks: HelpLink[] = [];
            const tablesByLayout: any = {};
            for (const comp of this.getRecursiveChildren()) {
                if (comp instanceof Layout) {
                    await comp.ensureLoaded();
                    this._dataLoadDataSources.push(...comp._getDataSources());
                    if (comp.videoItemHelpLinks != null)
                        childLayoutVideoItemHelpLinks.push(...comp.videoItemHelpLinks);
                }
                else if (comp instanceof Table) {
                    //discover tables so we can use the user's default config (if one is present)
                    const layout = comp.getParentLayout();
                    if (tablesByLayout[layout.layoutName] == null)
                        tablesByLayout[layout.layoutName] = [];
                    tablesByLayout[layout.layoutName].push(comp);
                }
            }
            this.deserializeVideoItemHelpLinks(def.videoItemHelpLinks);
            for (const childHelpLink of childLayoutVideoItemHelpLinks) {
                this.addVideoItemHelpLink(childHelpLink);
            }
            for (const layoutName of Object.keys(tablesByLayout)) {
                const tables = tablesByLayout[layoutName] as Table[];
                for (const table of tables) {
                    table.applyDefaultConfig(layoutName);
                }
            }
            await this.loaded();
        }
        catch (err) {
            log.error("Error loading page", err);
            if (this.displayedLoadError !== true) {
                this.displayedLoadError = true;
                ReflectiveDialogs.showError(err);
            }
        }
    }

    private async deserializeRemovedComponents(def: any) {
        const removed = deserializeComponents(this, def.removed, this._designer, {removedByServer: true}, null, null);
        for (const comp of removed) {
            await this.ensureRemovedComponentLoaded(comp);
        }
    }

    private async ensureRemovedComponentLoaded(component: Component) {
        if (component instanceof Layout) {
           await component.ensureLoaded();
        } else if (component instanceof Container) {
            for (const comp of component.components)
                await this.ensureRemovedComponentLoaded(comp);
        }
    }

    override  add(...components: Component[]): Component {
        if (this.shouldAddToContentPanel(...components))
            return this._widgetContentPanel.add(...components)
        return super.add(...components);
    }

    shouldAddToContentPanel(...components: Component[]): boolean {
        return this._widgetContentPanel != null && components?.length > 0 && components[0] != this._widgetContentPanel && components[0] != this._titleBar;
    }

    private _getDataSources(): DataSource[] {
        const result: DataSource[] = [];
        if (this.dataSourceIDs != null) {
            for (const dataSourceID of this.dataSourceIDs) {
                result.push(this[dataSourceID] as DataSource);
            }
        }
        return result;
    }

    private async loadComponentMetadata() {
        for (const comp of this.getRecursiveChildren())
            await comp.loadMetadata();
    }

    get title(): string {
        const overriddenTitle = this.overrideTitle();
        if (overriddenTitle != null)
            return overriddenTitle;
        return this.getTitleForMode(this.mainDataSource?.mode);
    }

    set title(value: string) {
        this._title = value;
    }

    get windowTitle(): string {
        if (this.testingSpecificLayoutVersion() === true && StringUtil.isEmptyString(this._descr) === false)
            return this.title + " - " + this._descr;
        return this.title;
    }

    public getTitleForMode(mode: DataSourceMode): string {
        if (mode == null)
            mode = DataSourceMode.NONE;
        const row = this.mainDataSource?.activeRow;
        let result = DisplayValue.getFormattedDataString(this._title, row);
        let suffix = null;
        switch (mode) {
            case DataSourceMode.UPDATE: suffix = DisplayValue.getFormattedDataString(this.titleEditModeSuffix, row); break;
            case DataSourceMode.ADD: suffix = DisplayValue.getFormattedDataString(this.titleAddModeSuffix, row); break;
            case DataSourceMode.SEARCH: suffix = DisplayValue.getFormattedDataString(this.titleSearchModeSuffix, row); break;
        }
        if (!this._hideSuffix && !StringUtil.isEmptyString(suffix))
            result += " - " + suffix;
        return result;
    }

    get titleImage(): string {
        return this._titleImage;
    }

    set titleImage(value: string) {
        this._titleImage = value;
    }

    overrideTitle(): string {
        return null;
    }

    get titleAddModeSuffix(): string {
        return this._titleAddModeSuffix || this.getDefaultTitleAddModeSuffix();
    }

    set titleAddModeSuffix(value: string) {
        this._titleAddModeSuffix = value;
    }

    private getDefaultTitleAddModeSuffix() {
        return "New " + (this.titleSingular || "record");
    }

    get titleEditModeSuffix(): string {
        return this._titleEditModeSuffix || this.getDefaultTitleEditModeSuffix();
    }

    set titleEditModeSuffix(value: string) {
        this._titleEditModeSuffix = value;
    }

    private getDefaultTitleEditModeSuffix(): string {
        let result = "Edit " + (this.titleSingular || "record");
        if (this.mainDataSource instanceof DataSource) {
            let displayFormat = this.mainDataSource?.getMetadataFromCache()?.displayField;
            if (displayFormat != null && displayFormat.indexOf("{") < 0)
                displayFormat = "{" + displayFormat + "}";
            if (displayFormat != null)
                result += " - " + displayFormat;
        }
        return result;
    }

    get titleSearchModeSuffix() {
        return this._titleSearchModeSuffix || this.getDefaultTitleSearchModeSuffix();
    }

    set titleSearchModeSuffix(value: string) {
        this._titleSearchModeSuffix = value;
    }

    private getDefaultTitleSearchModeSuffix(): string {
        return "Advanced Search";
    }

    get hideTitle(): boolean {
        return this._hideTitle;
    }

    set hideTitle(value: boolean) {
        this._hideTitle = value;
    }

    get hideSuffix(): boolean {
        return this._hideSuffix;
    }

    set hideSuffix(value: boolean) {
        this._hideSuffix = value;
    }

    override get serializationName() {
        return "layout";
    }

    override get properName(): string {
        return "Layout";
    }

    public setAllDataSourcesToMode(mode: DataSourceMode): void {
        this.dataSourceIDs.forEach((id) => {
            if ((this.mainDataSource == null) || (id !== this.mainDataSource.id))
                (this[id] as DataSource).mode = mode;
        });
        if (this.mainDataSource != null)
            this.mainDataSource.mode = mode;
    }

    public clearNonMainDataSources() {
        for (const dataSourceID of this.dataSourceIDs) {
            if (dataSourceID === this.mainDataSource.id)
                continue;
            const dataSource = this[dataSourceID] as DataSource;
            dataSource.clear();
        }
    }

    public get titleSingular() {
        return this._titleSinglular || this.getDefaultTitleSingular();
    }

    public set titleSingular(value: string) {
        this._titleSinglular = value;
    }

    private getDefaultTitleSingular(): string {
        if (this._titlePlural != null || this._title != null) {
            const plural = this._titlePlural || this._title;
            if (plural.endsWith("es"))
                return plural.substring(0, plural.length - 2);
            if (plural.endsWith("s"))
                return plural.substring(0, plural.length - 1);
        }
        return undefined;
    }

    public get titlePlural() {
        return this._titlePlural || this.getDefaultTitlePlural();
    }

    public set titlePlural(value: string) {
        this._titlePlural = value;
    }

    private getDefaultTitlePlural(): string {
        if (this._title != null)
            return this._title;
        if (this._titleSinglular != null)
            return this._titleSinglular + "s";
        return undefined;
    }

    _serializeNonProps(): string {
        if (this.components != null && this.components.length > 0 && this.isNested !== true)
            return super._serializeNonProps();
        return "";
    }

    _serializeProp(key: string, value: string): string {
        if ("videoItemHelpLinks" === key) //never serialize videoItemHelpLinks; they come from the database
            return null;
        if (this.isNested && "helpLinks" === key)
            return null;
        if (this._originalPropsBeforeWidget != null && key in this._originalPropsBeforeWidget)
            return this._originalPropsBeforeWidget[key];
        return value;
    }

    public get isNested(): boolean {
        return this._isNested;
    }

    public set isNested(value: boolean) {
        this._isNested = value;
    }

    layoutRows() {
        super.layoutRows();
        if (this.isNested && this._designer != null && !this.owner?.isNested)
            this._element.appendChild(this.createNestedDesignerOverlay());
    }

    private createNestedDesignerOverlay() {
        const result = new Panel({ id: "nested-designer-overlay", className: classes.nestedDesignerOverlay });
        const button = new Button({ caption: "Nested Layout ", borderWidth: 0, imageName: "detail", tooltip: "Open " + this.layoutName + " in another designer tab", imageHeight: 32, imageWidth: 32, align: HorizontalAlignment.CENTER, fontSize: 40 });
        if (this._designer?.openTab != null)
            button.addClickListener(() => this._designer.openTab({path: this.layoutName}, true));
        result.add(button);
        return result._element;
    }

    public get inheritParentDataSource(): string {
        return this._inheritParentDataSource;
    }

    public set inheritParentDataSource(value: string) {
        this._inheritParentDataSource = value;
    }

    override getListenerDefs(): Collection<ListenerListDef> {
        return {
            ...super.getListenerDefs(),
            "loadListener": { ..._loadListenerDef },
            "loadError": { ..._loadErrorDef }
        };
    }

    public get mainDataSource(): DataSource {
        return this._mainDataSource;
    }

    public set mainDataSource(value: DataSource) {
        this._mainDataSource = value;
    }

    override getPropertyDefaultValue(prop: ComponentPropDefinition) {
        if (prop.name === "titleSingular")
            return this.getDefaultTitleSingular();
        if (prop.name === "titlePlural")
            return this.getDefaultTitlePlural();
        if (prop.name === "titleAddModeSuffix")
            return this.getDefaultTitleAddModeSuffix();
        if (prop.name === "titleEditModeSuffix")
            return this.getDefaultTitleEditModeSuffix();
        if (prop.name === "titleSearchModeSuffix")
            return this.getDefaultTitleSearchModeSuffix();
        return super.getPropertyDefaultValue(prop);
    }

    override _deserializeSpecialProps(componentOwner, compDef, defaultPropValues, dataSources, callback): string[] {
        if (compDef.mainDataSource != null && dataSources != null)
            this.mainDataSource = dataSources[compDef.mainDataSource];
        return ["mainDataSource", ...super._deserializeSpecialProps(componentOwner, compDef, defaultPropValues, dataSources, callback)];
    }

    public replaceMainDatasource(newDatasource: DataSource) {
        if (newDatasource != null) {
            this.mainDataSource.rebindComponentsTo(newDatasource);
            newDatasource.attachListeners(this.mainDataSource);
            for (const dataSourceID of this[this.mainDataSource.id].getChildDataSourceIds())
                this[dataSourceID].parentDataSource = newDatasource;

            this.mainDataSource = newDatasource;
            this[this.mainDataSource.id] = newDatasource;
        }
    }

    private retrieveVideoItemHelpLinks() {
        if (!Layout.shouldLoadVideoItemHelpLinks || AuthToken.isAuthenticated() !== true)
            return;
        Api.search("video-item-help-links", { path: this.layoutName }).then(response => {
            const helpLinkDefs = response.data[0]?.video_help_links;
            this.deserializeVideoItemHelpLinks(helpLinkDefs);
            const currentContainer = ScreenStack.getCurrentLayoutContainer();
            const currentLayouts = ScreenStack.getCurrentLayouts(currentContainer);
            //have to tell the page header that help links were added (after the layout was added to the screen stack)
            McLeodMainPageUtil.getPageHeader()["evaluateExternalLinkVisibility"](currentContainer, currentLayouts);
        }).catch(error => log.debug("Unable to retrieve video item help links for client-defined layout %o", this.layoutName));
    }

    private deserializeVideoItemHelpLinks(videoItemHelpLinksDef: any[]) {
        if (videoItemHelpLinksDef == null)
            return;
        for (const def of videoItemHelpLinksDef) {
            if (StringUtil.isEmptyString(def.caption) === false && StringUtil.isEmptyString(def.id) === false)
                this.addVideoItemHelpLink(new HelpLink(def.caption, def.id, this._element));
        }
    }

    public static clearCachedLayout(name: string): void {
        delete cachedLayouts[name];
    }

    public static getLayout(layoutName: string, props?: Partial<LayoutProps>): Layout {
        let constructorProps: Partial<LayoutProps> = null;
        if (props != null && props["layoutId"] != null) {
            //user is testing a specific version of a custom layout
            //load that specific version of the layout (and don't pull from or update the layout cache)
            //this requires setting the layoutId before setting the layoutName, since setting the layoutName causes the layout to be loaded
            const layoutId = props["layoutId"];
            delete props["layoutId"];
            constructorProps = { layoutId: layoutId, layoutName: layoutName, ...props };
        }
        else
            constructorProps = { layoutName: layoutName, ...props };

        const cls = DynamicLoader.getClassForPath(layoutName);
        if (cls == null)
            return new Layout(constructorProps)
        else
            return new cls(constructorProps);
    }

    get dataHeader(): DataHeader {
        return this._dataHeader;
    }

    set dataHeader(value: DataHeader) {
        this._dataHeader = value;
    }

    public getDataHeaderAddlLeftComponents(): Component[] {
        return null;
    }

    public getDataHeaderAddlRightComponents(): Component[] {
        return null;
    }

    public getDataHeaderEllipsisActions(): Label[] {
        return null;
    }

    public doAfterDataHeaderSetup(dataHeader: DataHeader) {}

    /**
     * Override so that a layout can first check to see if it's a child of a Decorator...if it is, slide that decorator out instead
     * @param options
     * @param componentToFocusOnClose
     * @returns
     */
    public override slideOut(options?: TransitionOptions, componentToFocusOnClose?: Component): Promise<any> {
        const decorator = this._getParentDecorator();
        if (decorator == null)
            return super.slideOut(options, componentToFocusOnClose);
        else
            return decorator.slideOut(options, componentToFocusOnClose);
    }

    private _getParentDecorator(): Decorator {
        let parent = this.parent;
        while (parent != null) {
            if (parent instanceof Decorator)
                return parent;
            parent = parent.parent;
        }
        return null;
    }

    public get activeRow(): ModelRow<any> {
        return this.mainDataSource?.activeRow;
    }

    //help links are handled oddly only because of the limitations of how PropertiesTable
    //handles property values from specialty editors.  we can eventually make this look not-so-dodgy.
    public get helpLinks(): string {
        if (this._helpLinks == null)
            return null;
        return JSON.stringify(this._helpLinks);
    }

    public set helpLinks(value: string) {
        if (StringUtil.isEmptyString(value) === true)
            this._helpLinks = null;
        const defs = JSON.parse(value);
        if (ArrayUtil.isEmptyArray(defs) === false) {
            this._helpLinks = [];
            for (const def of defs) {
                this.addHelpLink(new HelpLink(def.caption, def.url, this._element));
            }
        }
        else
            this._helpLinks = null;
    }

    public addHelpLink(value: HelpLink) {
        if (this._helpLinks == null)
            this._helpLinks = [];
        this._helpLinks.push(value);
    }

    public get videoItemHelpLinks(): HelpLink[] {
        return this._videoItemHelpLinks;
    }

    public set videoItemHelpLinks(value: HelpLink[]) {
        this._videoItemHelpLinks = value;
    }

    public addVideoItemHelpLink(value: HelpLink) {
        if (this.videoItemHelpLinks == null)
            this.videoItemHelpLinks = [];
        this.videoItemHelpLinks.push(value);
    }

    public getAllHelpLinks(): HelpLink[] {
        const result = this.videoItemHelpLinks == null ? [] : [...this.videoItemHelpLinks];
        ArrayUtil.addAllNoDuplicates(result, this._helpLinks);
        return result;
    }

    public get displayingFromToolbox(): boolean {
        return this._displayingFromToolbox == null ? false : this._displayingFromToolbox;
    }

    public set displayingFromToolbox(value: boolean) {
        this._displayingFromToolbox = value;
    }

    public get renderAsWidget(): boolean {
        return this._renderAsWidget == null ? false : this._renderAsWidget;
    }
    public set renderAsWidget(value: boolean) {
        this._renderAsWidget = value;
        this.syncRenderAsWidget();
    }
    public get displayRefresh(): boolean {
        return this._displayRefresh;
    }
    public set displayRefresh(value: boolean) {
        this._displayRefresh = value;
    }
    public get widgetGroup(): string {
        return this._widgetGroup;
    }
    public set widgetGroup(value: string) {
        this._widgetGroup = value;
    }
    public get homePageSize(): string {
        return this._homePageSize || this.getPropertyDefinitions().homePageSize.defaultValue;
    }
    public set homePageSize(value: string) {
        this._homePageSize = value;
    }
    public get titleBar(): WidgetTitleBar {
        return this._titleBar;
    }

    get busyWhenDataSourceBusy(): boolean {
        return this._renderAsWidget === true;
    }

    private syncRenderAsWidget() {
        if (this._loaded !== true) return;

        if (this._renderAsWidget != true && this._titleBar != null) {
            this.setProps({ ...this._originalPropsBeforeWidget });
            this._originalPropsBeforeWidget = null;
            this.remove(this._titleBar);
            this.remove(this._widgetContentPanel);
            const components = this._widgetContentPanel.components;
            this._widgetContentPanel = null;
            this.add(...components);
            this._titleBar = null;
            this.mainDataSource?.removeBusyComponent(this);
        } else if (this._renderAsWidget === true && this._titleBar == null) {
            this.mainDataSource?.addBusyComponent(this);
            this._originalPropsBeforeWidget = this.replaceProps({ borderColor: "strokeSecondary", borderShadow: false, borderRadius: 10, borderWidth: 1, padding: 0, scrollY: this.scrollY });
            this.style.overflowY = "hidden";
            const components = this.components;
            this.removeAll();
            this._widgetContentPanel = new Panel({ padding: 0, fillRow: true, fillHeight: true, components: [...components] });
            this.add(this._widgetContentPanel);
            this._titleBar = new WidgetTitleBar(this);
            this.insert(this._titleBar, 0);
        }
    }

    set busy(value: boolean) {
        if (this._busy === value || this.renderAsWidget === false)
            return;
        this._busy = value;
        if (value === true) {
            this._widgetContentPanel._element.innerHTML = "";
            const spinner = new Spinner();
            spinner.spinnerImage.setProps({ color: "primary", height: 24, width: 24 })
            this._widgetContentPanel._element.appendChild(spinner._element);
        }
        else {
            this._widgetContentPanel.layoutRows();
        }
    }

    public get allowDecoratorOverride(): boolean {
        return this._allowDecoratorOverride != null ? this._allowDecoratorOverride : this.getPropertyDefinitions().allowDecoratorOverride.defaultValue;
    }

    public set allowDecoratorOverride(value: boolean) {
        this._allowDecoratorOverride = value;
    }
}

ComponentTypes.registerComponentType("layout", Layout.prototype.constructor, false, ["layoutName", "isNested"]);
