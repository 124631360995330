import { Base64Util, BlobUtil, getLogger, getThemeColor, MimeTypes } from "@mcleod/core";
import { getImageModule } from "@mcleod/images";
import { Component } from "../../base/Component";
import { ComponentPropDefinitions } from "../../base/ComponentProps";
import { ComponentTypes } from "../../base/ComponentTypes";
import { ImagePropDefinitions, ImageProps } from "./ImageProps";
import { ImageStyles } from "./ImageStyles";
import { ImageType } from "./ImageType";
import TIFF from 'tiff.js';

const log = getLogger("components.Image");

export class Image extends Component implements ImageProps {
    private _fill: string;
    private _imageBytes: string;
    private _imageType: ImageType;
    private _name: string;
    private _rotation: number;
    private _stroke: string;
    private _strokeWidth: number;
    private _rotate: boolean;
    private _busy: boolean;
    private _nameBeforeBusy: string;
    private path: SVGPathElement;
    private tiff: TIFF;
    private _numPages: number;
    private pageIndex: number;

    constructor(props?: Partial<ImageProps>) {
        if (props != null && (props.imageType === ImageType.IMG || props.imageType === ImageType.TIFF)) {
            super("img", props);
            this._imageType = props.imageType;
            delete props.imageType;
        }
        else if (props != null && (props.imageType === ImageType.PDF)) {
            super("object", props);
            this._imageType = props.imageType;
            delete props.imageType;
        }
        else {
            super("div");
            (this as any)._element = document.createElementNS("http://www.w3.org/2000/svg", "svg");
            this._initElement(props);
        }
        this._element.setAttribute("class", ImageStyles.imageBase);
        this.setProps(props);
    }

    setProps(props: Partial<ImageProps>) {
        super.setProps(props);
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this.resetMultiPageAttributes();
        if (this._name === value)
            return;
        this._name = value;
        if (this._nameBeforeBusy != null && !this.busy)
            this._nameBeforeBusy = value;
        if (value == null || value.length === 0) {
            this._element.innerHTML = "";
            return;
        }
        const module = getImageModule(value);
        if (module == null) {
            log.info("Could not find module for image " + value);
            return;
        }
        const props = (module as any).props;
        if (props != null)
            for (const key in props) {
                if (key === "fillRule")
                    this._element.setAttribute("fill-rule", props[key]);
                else if (key !== "innerHTML")
                    this._element.setAttribute(key, props[key]);
            }
        if (props == null || props.viewBox == null)
            this._element.setAttribute("viewBox", "0 0 24 24");
        if ((module as any).innerHTML != null)
            this._element.innerHTML = (module as any).innerHTML;
        else {
            this._element.innerHTML = "";
            this.path = document.createElementNS("http://www.w3.org/2000/svg", "path");
            this.path.setAttribute("d", module.path);
            this._element.appendChild(this.path);
            if (module.pathAttributes != null) {
                for (const key in module.pathAttributes) {
                    const value = module.pathAttributes[key];
                    this.path.setAttribute(key, value);
                }
            }
        }
    }

    set rotation(degrees: number) {
        this._rotation = degrees;
        if (degrees == null)
            this._element.style.transform = "";
        else
            this._element.style.transform = "rotate(" + degrees + "deg)";
    }

    get rotation(): number {
        return this._rotation;
    }

    get stroke(): string {
        return this._stroke;
    }

    set stroke(value: string) {
        this._stroke = value;
        this.syncStroke();
    }

    get fill(): string {
        return this._fill;
    }

    set fill(value: string) {
        this._fill = value;
        this._element.style.fill = getThemeColor(value);
    }

    get strokeWidth(): number {
        return this._strokeWidth;
    }

    set strokeWidth(value: number) {
        this._strokeWidth = value;
        this.syncStroke();
    }

    private syncStroke() {
        if (this.path == null) {
            return;
        }
        if (this._strokeWidth != null) {
            const stroke = this._stroke || "currentcolor";
            this.path.setAttribute("stroke", getThemeColor(stroke));
            this.path.setAttribute("stroke-width", this._strokeWidth.toString());
        } else if (this._strokeWidth == null && this._stroke == null) {
            this.path.removeAttribute("stroke");
            this.path.removeAttribute("stroke-width");
        } else if (this._strokeWidth == null) {
            this.path.setAttribute("stroke", getThemeColor(this._stroke));
            this.path.removeAttribute("stroke-width");
        } 
    }

    set preserveAspectRatio(value: boolean) {
        if (value)
            this._element.style.objectFit = "contain";
        else
            this._element.style.objectFit = "";
    }

    get imageBytes(): string {
        return this._imageBytes;
    }

    set imageBytes(value: string) {
        this.resetMultiPageAttributes();
        this._imageBytes = value;
        if (this.imageType === ImageType.IMG) {
            if (!value.startsWith("data:image"))
                value = "data:image/jpeg;base64," + value;
            this._element.setAttribute("src", value);
        }
        else if (this.imageType === ImageType.TIFF) {
            const arrayBuffer = Base64Util.toArrayBuffer(this._imageBytes);
            this.tiff = new TIFF({ buffer: arrayBuffer });
            this.numPages = this.tiff.countDirectory();
            this.pageIndex = 0;
            this.displayCurrentPage();
        }
        else if (this.imageType === ImageType.PDF) {
            const blob = BlobUtil.toBlob(this._imageBytes, MimeTypes.PDF)
            let fileURL = URL.createObjectURL(blob);
            fileURL += "#toolbar=0&navpanes=0";
            this._element.setAttribute("data", fileURL);
        }
        else
            throw new Error("Cannot set imageBytes on an SVG image.");
    }

    get imageType(): ImageType {
        return this._imageType;
    }

    private resetMultiPageAttributes() {
        this.tiff = null;
        this.numPages = null;
        this.pageIndex = null;
    }

    get numPages(): number {
        return this._numPages == null ? 1 : this._numPages;
    }

    private set numPages(value: number) {
        this._numPages = value;
    }

    get isMultiPage(): boolean {
        return this.numPages > 1;
    }

    goToFirstPage(): number {
        if (this.isMultiPage !== true)
            return;
        if (this.pageIndex !== 0) {
            this.pageIndex = 0;
            this.displayCurrentPage();
        }
        return 0;
    }

    goToLastPage(): number {
        if (this.isMultiPage !== true)
            return;
        const lastPageIndex = this.getLastPageIndex();
        if (this.pageIndex !== lastPageIndex) {
            this.pageIndex = lastPageIndex;
            this.displayCurrentPage();
        }
        return lastPageIndex;
    }

    advancePage(delta: number) {
        if (this.isMultiPage !== true)
            return;
        const oldPageIndex = this.pageIndex;
        this.updatePageIndex(delta);
        if (this.pageIndex !== oldPageIndex)
            this.displayCurrentPage();
        return this.pageIndex;
    }

    updatePageIndex(delta: number) {
        this.pageIndex += delta;
        if (this.pageIndex < 0)
            this.pageIndex = 0;
        else {
            const lastPageIndex = this.getLastPageIndex();
            if (this.pageIndex > lastPageIndex)
                this.pageIndex = lastPageIndex;
        }
    }

    getLastPageIndex(): number {
        if (this.isMultiPage !== true)
            return 0;
        return Math.max(0, (this.numPages - 1));
    }

    private displayCurrentPage() {
        this.tiff.setDirectory(this.pageIndex);
        const canvas = this.tiff.toCanvas();
        const dataUrl = canvas.toDataURL();
        this._element.setAttribute("src", dataUrl);
    }

    public override getPropertyDefinitions(): ComponentPropDefinitions {
        return ImagePropDefinitions.getDefinitions();
    }

    get rotate() {
        return this._rotate == null ? false : this._rotate;
    }

    set rotate(value: boolean) {
        this._rotate = value;
        if (value)
            this._element.classList.add(ImageStyles.rotate);
        else
            this._element.classList.remove(ImageStyles.rotate);
    }

    get busy(): boolean {
        return this._busy == null ? false : this._busy;
    }

    set busy(value: boolean) {
        this._busy = value;
        if (value === true) {
            this._nameBeforeBusy = this._name;
            this.name = "spinner";
        }
        else {
            this.name = this._nameBeforeBusy;
            delete this._nameBeforeBusy;
        }
        this.rotate = value;
    }

    override get serializationName() {
        return "image";
    }

    override get properName(): string {
        return "Image";
    }

    override getBasicValue(): any {
        return this.name;
    }
}

ComponentTypes.registerComponentType("image", Image.prototype.constructor);
