import { Button, Component, DataSource, DesignerInterface, Panel } from "@mcleod/components";
import { ReflectiveDialogs } from "@mcleod/components/src/base/ReflectiveDialogs";
import { removeFromMetadataCache } from "@mcleod/core";
import { DesignerDataSource } from "./DesignerDataSource";
import { PanelOpenModel } from "./PanelOpenModel";
import { McLeodTailor } from "./custom/McLeodTailor";
import { LayoutDesignerTab } from "./LayoutDesignerTab";

export class DesignerDataSourcePanel extends Panel {
    private targetPanel: Panel;
    private buttonAdd: Button;
    private buttonModelView: Button;

    constructor(tab: LayoutDesignerTab, props?) {
        super(null, false);
        this._shouldAddDesignerContainerProperties = false;
        this.setProps({ fillRow: true, borderColor: "strokeSecondary", borderWidth: 1, marginTop: 16, ...props });
        this.targetPanel = new Panel({ fillRow: true });
        this.add(this.targetPanel);
        if ((tab.designer instanceof McLeodTailor) !== true) {
            this.minHeight = 80;
            this.buttonAdd = new Button({ width: 140, height: 26, padding: 4, color: "primary", caption: "Add datasource", imageName: "add", rowBreak: false });
            this.buttonAdd.addClickListener(event => {
                tab.modified = true;
                let dsName: string;
                for (let nextNum = 1; dsName == null && nextNum < 1000; nextNum++)
                    if (tab.dataSources["source" + nextNum] == null)
                        dsName = "source" + nextNum;
                const dataSource = new DataSource({ id: dsName }, null, tab._designer, tab);
                tab.dataSources[dataSource.id] = dataSource;
                const dsPanel = new DesignerDataSource(tab.designer, dataSource);
                this.addDesignerDataSource(dsPanel);
                tab.designer.selectComponent(dsPanel, false);
                this.showEndpointSelector(dataSource, tab._designer);
            });
            this.add(this.buttonAdd);
        }
        this.buttonModelView = new Button({ width: 140, height: 26, padding: 4, color: "primary", caption: "View model", imageName: "magnifyingGlass", enabled: false });
        this.buttonModelView.addClickListener(() => {
            const comp = tab.designer.selectedComponents[0];
            if (comp instanceof DesignerDataSource && comp.designerDataSource.url != null)
                tab.designer.openModelDesigner(comp.designerDataSource.url);
        });
        this.add(this.buttonModelView);
    }

    addDesignerDataSource(value: DesignerDataSource) {
        this.targetPanel.add(value);
    }

    getDesignerDataSourceRecursiveChildren(): Component[] {
        return this.targetPanel.getRecursiveChildren();
    }


    setButtonModelViewVisibile(value: boolean) {
        if (this.buttonModelView != null)
            this.buttonModelView.visible = value;
    }

    enableButtonModelView(value: boolean) {
        if (this.buttonModelView != null)
            this.buttonModelView.enabled = value;
    }

    updateIds() {
        for (const dsPanel of this.targetPanel)
            (dsPanel as DesignerDataSource).updateId();
    }

    syncMetadata(url: string): Promise<any> {
        removeFromMetadataCache(url);
        const promises = [];
        for (const dsPanel of this.targetPanel) {
            if (dsPanel instanceof DesignerDataSource && dsPanel?.designerDataSource.url === url) {
                dsPanel.designerDataSource["metadata"] = null;
                promises.push(dsPanel.setCustomModelIdFromMetadata());
            }
        }
        return Promise.all(promises);
    }

    showEndpointSelector(dataSource: DataSource, designer: DesignerInterface) {
        const sel = new PanelOpenModel();
        ReflectiveDialogs.showDialog(sel, { height: 600, width: 500, title: "Select Endpoint" }).then(() => {
            const result = sel.getValue();
            if (result !== undefined) {
                dataSource.url = result;
                designer.redisplayProp("url", result);
                designer.displayDataSourceTools();
            }
        });
    }
}
