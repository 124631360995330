import { DataDisplayEvent, Label } from "@mcleod/components";
import { CityUtil } from "@mcleod/core";
import { AutogenLayoutCityLookup } from "./autogen/AutogenLayoutCityLookup";

export class CityLookup extends AutogenLayoutCityLookup {
    cityOnDataDisplay(event: DataDisplayEvent) {

        if (event?.rowData != null)
            (event.target as Label).caption = CityUtil.formatCityStateZip(event.rowData.get("name"), event.rowData.get("state_id"), event.rowData.get("zip_code"));
    }
}
