import {
    Api, ArrayUtil, Collection, JSUtil, Keys, LocationComponentSettings, LocationUtil, ModelRow, StringUtil
} from "@mcleod/core";
import {
    ChangeEvent, ChangeListener, ClearButtonVisible, ClickEvent, Component, DataSourceMode, KeyHandler, Layout,
    LookupModelSearchEvent, LookupModelSelectionEvent, LookupModelSelectionListener, PrintableEvent, PrintableListener, TextboxProps, ValidationResult
} from "../..";
import { Captioned } from "../../base/CaptionedComponent";
import { getCurrentDataSourceMode, getRelevantModelRow } from "../../base/ComponentDataLink";
import { ComponentTypes } from "../../base/ComponentTypes";
import { ListenerListDef } from "../../base/ListenerListDef";
import { Printable, printableListenerDef } from "../../base/PrintableComponent";
import { CompoundComponent } from "../compound/CompoundComponent";
import { TableRow } from "../table/TableRow";
import { Textbox } from "../textbox/Textbox";
import { LocationPropDefinitions, LocationProps } from "./LocationProps";

const _changeListenerDef: ListenerListDef = { listName: "_changeListeners" };

export interface GooglePlacesProps {
    enableSearch: boolean;
    createLocations: boolean;
    customerId: string;
    doBeforeGoogleLocationCreated: () => void;
    doAfterGoogleLocationCreated: (modelRow?: ModelRow<any>) => void;
}

export class Location extends CompoundComponent implements LocationProps {
    _cityField: string;
    _stateField: string;
    _zipField: string;
    _cityIdField: string;
    _locationIdField: string;
    _locationNameField: string;
    _addressField: string
    _searchWebLocation: boolean;
    _filterOwner: boolean;
    _isForStop: boolean;
    latitudeField: string
    longtiudeField: string;
    _apptRequired: boolean;

    _lookup_cityField = "city_name";
    _lookup_stateField = "state";
    _lookup_zipField = "zip_code";
    _lookup_cityIdField = "city_id";
    _lookup_locationIdField = "id";
    _lookup_locationNameField = "name";
    _lookup_addressField = "address1";
    _lookup_apptRequired = "appt_required";

    textCombined: Textbox;
    textCity: Textbox;
    textState: Textbox;
    textZip: Textbox;
    textLocationId: Textbox;
    textLocationName: Textbox;
    textAddress: Textbox;

    protected _lookupModelDataForUpdate: ModelRow;
    private _searchingGooglePlaces = false;
    private lastGoogleSearch: string;
    private _googlePlacesProps: GooglePlacesProps;
    private _googleKeyHandler: KeyHandler;
    private _quickInfoLayout: string;

    //We store the lookup model selection listeners in this class, instead of attaching them directly to
    //the textCombined Textbox, so that we can control when they fire.  They should not fire when we are
    //searching for Google Places, but they should fire after a Google Place has been selected/applied to the record.
    private textCombinedOnLookupModelSelectionListeners: ((event: LookupModelSelectionEvent) => void)[] = [];
    private textCombinedOnLookupModelSeletionListenersActive = false;

    get textCombinedProps(): Partial<TextboxProps> {
        return {
            id: "locationCombined",
            fillRow: true,
            lookupModel: undefined,
            lookupModelLayout: "lme/dispatch/LocationLucene",
            quickInfoLayout: this.quickInfoLayout,
            lookupModelResultField: "id",
            lookupModelAllowSearchAll: false,
            lookupModelMaxResults: 10,
            buttonProps: { imageName: "pinOutlined" },
            padding: 0,
            placeholder: "Type location code, name, address, city, or zip code",
            manualAddLayout: "lme/dispatch/LocationManualAdd",
            lookupModelInputDelay: null,
            clearButtonVisible: ClearButtonVisible.NO
        }
    }

    get googleSearchProps(): Partial<TextboxProps> {
        return {
            caption: "Google Places Search",
            lookupModel: "dispatch/google-places", lookupModelLayout: "lme/dispatch/GooglePlacesSearch",
            lookupModelAllowSearchAll: false, lookupModelResultField: "name",
            placeholder: "Type name, address, city, zip code, or airport code",
            lookupModelInputDelay: LocationComponentSettings.get()?.google_places_entry_delay,
            clearButtonVisible: ClearButtonVisible.NO
        }
    }

    public constructor(props?: Partial<LocationProps>) {
        super(props, false);
        this._shouldAddDesignerContainerProperties = false;
        this.createTextsIfNeeded();
        this.dataSourceModeChanged(DataSourceMode.NONE);
        this.setProps({ rowBreakDefault: false, ...props });
        this._syncEnabled();
    }

    private createTextsIfNeeded() {
        if (this.textCombined == null) {
            this.textCombined = new Textbox(this.textCombinedProps);
            this.textCombined.quickInfoLayout = this.quickInfoLayout;
            this.textCombined["getQuickInfoLayout"] = (baseTooltip: Component): Component => {
                const row = getRelevantModelRow(this);
                const value = row?.get(this.field);
                if (value == null)
                    return null;
                const layout = Layout.getLayout(this.quickInfoLayout);
                layout.onLoad = () => {
                    if (layout.mainDataSource != null)
                        layout.mainDataSource.search({ search: value });
                };
                return layout;
            }
            this.textCombined["_syncHoverCallback"]();
            this.textCombined.addChangeListener(async event => {
                if (event.userInitiatedChange) {
                    let data = this.textCombined.getFirstLookupModelData();
                    let googlePlacesDataSelected = false;

                    if (this.isGooglePlaceData(data)) {
                        this.textCombinedOnLookupModelSeletionListenersActive = false;
                        this.textCombined.enabled = false;
                        await this.getLookupModelDataFromGooglePlaces(data).then(result => {
                            data = result;
                            googlePlacesDataSelected = true;
                        }).finally(() => this.textCombined.enabled = true);
                    }
                    const minChars = this.textCombined.lookupModelMinChars;
                    this._lookupModelDataForUpdate = data;
                    if (data == null) {
                        this.textCombined.text = event.newValue;
                        this._lookupModelDataForUpdate = new ModelRow(this.textCombined.lookupModel, false);
                    }
                    else if (StringUtil.isEmptyString(event.newValue) && data != null && !this.textCombined.isDropdownVisible() && !googlePlacesDataSelected) {
                        this.textCombined.text = event.newValue;
                        Object.keys(data.data).forEach(key => data.set(key, null));
                    }
                    else if (event.oldValue.length >= minChars)
                        this.textCombined.text = LocationUtil.formatLocation(data.get(this._lookup_locationNameField), null, data.get(this._lookup_addressField), data.get(this._lookup_cityField), data.get(this._lookup_stateField), data.get(this._lookup_zipField));
                    const modelRow = getRelevantModelRow(this);
                    if (modelRow != null)
                        this.updateBoundData(modelRow, getCurrentDataSourceMode(this));

                    if (googlePlacesDataSelected) {
                        this.textCombinedOnLookupModelSeletionListenersActive = true;
                        this.fireTextCombinedLookupModelSelectionListeners(
                            new LookupModelSelectionEvent(this.textCombined, data),
                            this.textCombinedOnLookupModelSeletionListenersActive);
                    }
                }
            });
            this.textCombined.addBeforeLookupModelSearchListener((event: LookupModelSearchEvent) => this.beforeLookupModelSearch(event));
            this.textLocationName = new Textbox({ fillRow: true, padding: 0 });
            this.textCity = new Textbox({ fillRow: true, padding: 0 });
            this.textState = new Textbox({ width: 100, padding: 0, marginLeft: 8 });
            this.textZip = new Textbox({ width: 100, padding: 0, marginLeft: 8 });
            this.textLocationId = new Textbox({ width: 100, padding: 0, marginLeft: 8 });
            this.textAddress = new Textbox({ fillRow: true, padding: 0 });
        }
    }

    private getAllTextboxes(): Textbox[] {
        this.createTextsIfNeeded();
        return [this.textCombined, this.textLocationName, this.textCity, this.textState, this.textZip, this.textLocationId, this.textAddress];
    }

    private getSearchModeTextboxes(): Textbox[] {
        return this.getAllTextboxes().filter(textbox => textbox != this.textCombined);
    }

    beforeLookupModelSearch(event: LookupModelSearchEvent) {
        if (this._searchingGooglePlaces) {
            event.filter.include_loc_data = true;
        } else {
            if (!this._filterOwner)
                event.filter.filter_owner = false;
            //Would like a beforeLookupModelSearch event in AddOrder, but object has no owner in EventListenerList. Need to figure that out.
            if (this._isForStop) {
                const appliesTo: Textbox = event.target as Textbox;
                const row: TableRow = TableRow.getContainingTableRow(appliesTo);
                const stopType = row?.data?._data["stop_type"];
                if (LocationComponentSettings.get()?.enforce_shipper_id === "Y" && stopType === "PU") {
                    event.filter.locations_only = true;
                    this.textCombined.manualAddLayout = null;
                }
                else
                    event.filter.lme_search = true; //to search location and not web_location
            }
            else if (!this.searchWebLocation)
                event.filter.locations_only = true;
        }
    }

    public override updateBoundData(data: ModelRow, mode: DataSourceMode) {
        if (this.printable === true)
            return;
        const value = this.textCombined.text;
        if (mode === DataSourceMode.SEARCH && this.contains(this.textCity)) {
            this.getSearchModeTextboxes().forEach(textbox => textbox.updateBoundData(data, mode));
        }
        else if (data != null) {
            let oldData = null;
            if (this._lookupModelDataForUpdate != null) {
                oldData = { ...data };
                const locationIdChanged = this._lookupModelDataForUpdate.get("id") !== data.get(this.locationIdField, "");
                if (this.cityField != null)
                    data.set(this.cityField, this._lookupModelDataForUpdate.get("city_name"), this);
                if (this.stateField != null)
                    data.set(this.stateField, this._lookupModelDataForUpdate.get("state"), this);
                if (this.zipField != null)
                    data.set(this.zipField, this._lookupModelDataForUpdate.get("zip_code"), this);
                if (this.locationIdField != null)
                    data.set(this.locationIdField, this._lookupModelDataForUpdate.get("id"), this);
                if (this.locationNameField != null && (locationIdChanged || data.isNull(this.locationNameField)))
                    data.set(this.locationNameField, this._lookupModelDataForUpdate.get("name"), this);
                if (this.addressField != null && (locationIdChanged || data.isNull(this.addressField)))
                    data.set(this.addressField, this._lookupModelDataForUpdate.get("address1"), this);
                if (this.cityIdField != null)
                    data.set(this.cityIdField, this._lookupModelDataForUpdate.get("city_id"), this);
                if (this._isForStop) {
                    data.set("location_id", this._lookupModelDataForUpdate.get("id"), this);
                    if (locationIdChanged || data.isNull("location_name"))
                        data.set("location_name", this._lookupModelDataForUpdate.get("name"), this);
                    if (locationIdChanged || data.isNull("address2"))
                        data.set("address2", this._lookupModelDataForUpdate.get("address2"), this);
                }
                const event = new ChangeEvent(this, oldData, { ...data });
                this.fireListeners(_changeListenerDef, event)
            }
        }
    }

    override _serializeNonProps(): string {
        return "";
    }

    override displayData(row: ModelRow, allData: ModelRow[], rowIndex: number) {
        if (row == null) {
            this.getAllTextboxes().forEach(textbox => textbox.text = "");
        }
        else
            this.textCombined.text = LocationUtil.formatLocation(row.get(this.locationNameField), null, row.get(this.addressField), row.get(this.cityField), row.get(this.stateField), row.get(this.zipField));
    }

    override dataSourceModeChanged(mode: DataSourceMode) {
        super.dataSourceModeChanged(mode);
        this.removeAll();
        if (mode === DataSourceMode.SEARCH) {
            this.add(this.textCity, this.textState);
            if (this.zipField != null)
                this.add(this.textZip);
        }
        else
            this.add(this.textCombined)
        this["_syncPrintable"]();
    }

    protected _applyPrintable(value: boolean) {
        this.createTextsIfNeeded();
        const oldPrintableValue = this.textCombined.printable;
        this.getAllTextboxes().forEach(textbox => textbox.printable = value);
        if (oldPrintableValue === true && value === false)
            this.reattachListeners();
        this._syncDesignerView();
        this._syncEnabled();
        this.fireListeners(printableListenerDef, new PrintableEvent(this));
    }

    override validate(checkRequired: boolean = true, showErrors: boolean = true): ValidationResult[] {
        let result: ValidationResult = null;
        for (const component of this.components) {
            const compResult = component.validate(checkRequired, showErrors);
            result ??= compResult?.length > 0 ? compResult[0] : null;
        }
        return result ? [{ ...result, component: this }] : null;
    }

    override resetValidation() {
        this.getAllTextboxes().forEach(textbox => textbox.resetValidation());
    }

    get caption(): string {
        return this["_mixin-Captioned-caption"];
    }

    set caption(value: string) {
        if (this["captionValueMatches"](value) === true) {
            return;
        }
        this["_mixin-Captioned-caption"] = value;
        this.syncCaption();
    }

    get required(): boolean {
        return super.required;
    }

    set required(value: boolean) {
        super.required = value;
        this.textCombined.required = this.required;
    }

    syncCaption() {
        [this.textCity, this.textCombined].forEach(textbox => textbox.caption = this.caption);
    }

    get captionVisible() {
        this.createTextsIfNeeded();
        return this.textCombined.captionVisible;
    }

    set captionVisible(value: boolean) {
        this.getAllTextboxes().forEach((textbox) => textbox.captionVisible = value);
    }

    override _applyEnabled(value: boolean): void {
        this.getAllTextboxes().forEach((textbox) => textbox._applyEnabled(value));
    }

    override _syncRequired(): void {
        this.textCombined.required = this.required;
    }

    get printable(): boolean {
        this.createTextsIfNeeded();
        return this["_mixin-Printable-printable"];
    }

    set printable(value: boolean) {
        this["_mixin-Printable-printable"] = value;
    }

    get printableDuringAdd(): boolean {
        return this["_mixin-Printable-printableDuringAdd"];
    }

    set printableDuringAdd(value: boolean) {
        this["_mixin-Printable-printableDuringAdd"] = value;
    }

    get printableDuringSearch(): boolean {
        return this["_mixin-Printable-printableDuringSearch"];
    }

    set printableDuringSearch(value: boolean) {
        this["_mixin-Printable-printableDuringSearch"] = value;
    }

    get printableDuringUpdate(): boolean {
        return this["_mixin-Printable-printableDuringUpdate"];
    }

    set printableDuringUpdate(value: boolean) {
        this["_mixin-Printable-printableDuringUpdate"] = value;
    }

    private _syncDesignerView() {
        if (this._designer != null && this._cityField != null) {
            const text = LocationUtil.formatLocation(this._locationNameField, null, this._addressField, this._cityField, this._stateField, this._zipField);
            if (this.printable)
                this.textCombined["_printableLabel"].caption = text;
            else
                this.textCombined.placeholder = text;
        }
    }

    override getFieldNames(): string[] {
        const result = [];
        if (this.cityField != null)
            result.push(this.cityField);
        if (this.stateField != null)
            result.push(this.stateField);
        if (this.zipField != null)
            result.push(this.zipField);
        if (this.locationIdField != null)
            result.push(this.locationIdField);
        if (this.locationNameField != null)
            result.push(this.locationNameField);
        if (this.addressField != null)
            result.push(this.addressField);
        return result;
    }

    get cityField(): string {
        return this._cityField;
    }

    set cityField(value: string) {
        this._cityField = value;
        this.textCity.field = value;
        this._syncDesignerView();
    }

    get stateField(): string {
        return this._stateField;
    }

    set stateField(value: string) {
        this._stateField = value;
        this.textState.field = value;
        this._syncDesignerView();
    }

    get zipField(): string {
        return this._zipField;
    }

    set zipField(value: string) {
        this._zipField = value;
        this.textZip.field = value;
        this._syncDesignerView();
    }

    get cityIdField(): string {
        return this._cityIdField;
    }

    set cityIdField(value: string) {
        this._cityIdField = value;
    }

    get locationIdField(): string {
        return this._locationIdField;
    }

    set locationIdField(value: string) {
        this._locationIdField = value;
        this.textLocationId.field = value;
        this._syncDesignerView();
    }

    get locationNameField(): string {
        return this._locationNameField;
    }

    set locationNameField(value: string) {
        this._locationNameField = value;
        this.textLocationName.field = value;
        this._syncDesignerView();
    }

    get searchWebLocation(): boolean {
        return this._searchWebLocation;
    }

    set searchWebLocation(value: boolean) {
        this._searchWebLocation = value;
    }

    get filterOwner(): boolean {
        return this._filterOwner;
    }

    set filterOwner(value: boolean) {
        this._filterOwner = value;
    }

    get isForStop(): boolean {
        return this._isForStop;
    }

    set isForStop(value: boolean) {
        this._isForStop = value;
        if (this._designer != null) return;
    }

    get addressField(): string {
        return this._addressField;
    }

    set addressField(value: string) {
        this._addressField = value;
        this.textAddress.field = value;
        this._syncDesignerView();
    }

    get googlePlacesProps(): GooglePlacesProps {
        return this._googlePlacesProps;
    }

    set googlePlacesProps(value: GooglePlacesProps) {
        this._googlePlacesProps = value;
        this.syncGooglePlacesSearch();
    }

    private get googleKeyHandler(): KeyHandler {
        if (this._googleKeyHandler == null) {
            this._googleKeyHandler = {
                key: Keys.G,
                modifiers: { ctrlKey: true },
                listener: () => this.showGoogleSearch(!this._searchingGooglePlaces),
                element: this.textCombined._element,
                scope: this.textCombined._element
            }
        }
        return this._googleKeyHandler;
    }

    private syncGooglePlacesSearch() {
        if (this.googlePlacesProps?.enableSearch === true) {
            this.syncGoogleSearchButton();
        } else {
            this.showGoogleSearch(false);
            this.textCombined.buttonProps = { imageName: "pinOutlined"};
            if (this._googleKeyHandler != null)
                this.textCombined.removeKeyHandler(this.googleKeyHandler);
        }
    }

    private syncGoogleSearchButton() {
        this.textCombined.buttonProps =  {
            color: "primary",
            tooltip: this._searchingGooglePlaces ? "Return to Location Search": "Search locations using Google Places",
            imageName: this._searchingGooglePlaces ? "pinArrow" : "googlePlaces",
            onClick: (event: ClickEvent) => this.showGoogleSearch(!this._searchingGooglePlaces, event)
        }
    }

    get apptRequired(): boolean {
        const result = this.textCombined.getFirstLookupModelData()?.get(this._lookup_apptRequired);
        return result ? result : false;
    }

    public addLookupModelSelectionListener(value: LookupModelSelectionListener) {
        ArrayUtil.addNoDuplicates(this.textCombinedOnLookupModelSelectionListeners, value);
        this.textCombinedOnLookupModelSeletionListenersActive = true;
        this.textCombined.addLookupModelSelectionListener(this.fireTextCombinedLMSelectionRef);
    }

    public removeLookupModelSelectionListener(value: LookupModelSelectionListener) {
        ArrayUtil.removeFromArray(this.textCombinedOnLookupModelSelectionListeners, value);
    }

    private fireTextCombinedLMSelectionRef = (event: LookupModelSelectionEvent) => this.fireTextCombinedLookupModelSelectionListeners(event, this.textCombinedOnLookupModelSeletionListenersActive);

    private fireTextCombinedLookupModelSelectionListeners(event: LookupModelSelectionEvent, listenersAreActive: boolean) {
        if (listenersAreActive === true) {
            for (const listener of this.textCombinedOnLookupModelSelectionListeners) {
                listener(event);
            }
        }
    }

    override getPropertyDefinitions() {
        return LocationPropDefinitions.getDefinitions();
    }

    public addChangeListener(value: ChangeListener) {
        this.addEventListener(_changeListenerDef, value);
    }

    public removeChangeListener(value: ChangeListener) {
        this.removeEventListener(_changeListenerDef, value);
    }

    public addPrintableListener(value: PrintableListener) {
        this.addEventListener(printableListenerDef, value);
    }

    public removePrintableListener(value: PrintableListener) {
        this.removeEventListener(printableListenerDef, value);
    }

    override get serializationName() {
        return "location";
    }

    override get properName(): string {
        return "Location";
    }

    public override getEventTarget(): HTMLElement {
        return this.textCombined.getEventTarget();
    }

    getListenerDefs(): Collection<ListenerListDef> {
        return {
            ...this.textCombined.getListenerDefs()
        };
    }

    override getBasicValue(): any {
        return this.textCombined.text;
    }

    //I want these to work, but they don't. Get error object has no owner from EventListenerList
    // addBeforeLookupModelSearchListener(value: LookupModelSearchListener): Textbox {
    //   return <Textbox>this.addEventListener(_lookupListenerDef, value);
    // }

    // removeBeforeLookupModelSearchListener(value: LookupModelSearchListener): Textbox {
    //   return <Textbox>this.removeEventListener(_lookupListenerDef, value);
    // }

    public get lookupModelDataForUpdate(): ModelRow {
        return this._lookupModelDataForUpdate;
    }

    private showGoogleSearch(value: boolean, event?:ClickEvent) {
        this._searchingGooglePlaces = value;
        this.textCombined["_clearLookupModelData"]();
        if (value) {
            this.textCombined.setProps(this.googleSearchProps);
            if (this.textCombined.text != this.lastGoogleSearch)
                this.textCombined["updateLookupModelDropdown"]();
        } else {
            this.textCombined.setProps({ ...this.textCombinedProps });
            this.syncCaption();
        }
        if (this.isForStop)
            this.textCombined.manualAddLayout = undefined;
        if (event != null)  {
            this.syncGoogleSearchButton();
            this.textCombined.focus();
        }
    }

    private isGooglePlaceData(data: ModelRow): boolean {
        return data?._modelPath === "dispatch/google-places" && this._searchingGooglePlaces === true;
    }

    private getLookupModelDataFromGooglePlaces(googleData: ModelRow<any>): Promise<ModelRow<any>> {
        if (googleData != null) {
            this.textCombinedOnLookupModelSeletionListenersActive = false;
            if (googleData.get("location") != null) {
                const result = new ModelRow("lme/dispatch/location", false, googleData.get("location"));
                return Promise.resolve(result);
            } else if (this.googlePlacesProps.createLocations === true) {
                googleData.set("customer_id", this.googlePlacesProps.customerId);
                return this.createLocationFromGooglePlaceData(googleData);
            }
        }
        return Promise.resolve(googleData);
    }

    private createLocationFromGooglePlaceData(googleData: ModelRow<any>): Promise<ModelRow<any>> {
        return new Promise((resolve) => {
            if (this.googlePlacesProps.doBeforeGoogleLocationCreated)
                this.googlePlacesProps.doBeforeGoogleLocationCreated();
            Api.search("dispatch/google-places-create-location", { "google_place_data": googleData }).then(response => {
                let location = null;
                if (response?.data?.[0]?.location != null)
                    location = new ModelRow("lme/dispatch/location", false, response?.data?.[0]?.location);
                if (this.googlePlacesProps.doAfterGoogleLocationCreated)
                    this.googlePlacesProps.doAfterGoogleLocationCreated(location);
                resolve(location);
            })
        })
    }

    public get quickInfoLayout(): string {
        return this._quickInfoLayout || this.getPropertyDefinitions().quickInfoLayout.defaultValue;
    }

    public set quickInfoLayout(value: string) {
        this._quickInfoLayout = value;
    }
}

JSUtil.applyMixins(Location, [Captioned, Printable]);
ComponentTypes.registerComponentType("location", Location.prototype.constructor, true);
