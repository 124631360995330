export class DbDisplayValue {
    private _dbValue: string;
    private _displayValue: string;

    constructor(dbValue: string, displayValue: string) {
        this.dbValue = dbValue;
        this.displayValue = displayValue;
    }

    get dbValue(): string {
        return this._dbValue;
    }

    set dbValue(value: string) {
        this._dbValue = value;
    }

    get displayValue(): string {
        return this._displayValue;
    }

    set displayValue(value: string) {
        this._displayValue = value;
    }
}
