import { ModelRow, getThemeForKey } from "@mcleod/core";
import { ImageName } from "@mcleod/images";
import { Component } from "../../base/Component";
import { getCurrentDataSourceMode, getRelevantModelRow } from "../../base/ComponentDataLink";
import { ComponentTypes } from "../../base/ComponentTypes";
import { DesignerInterface } from "../../base/DesignerInterface";
import { ListenerListDef } from "../../base/ListenerListDef";
import { DataSourceMode } from "../../databinding/DataSource";
import { ChangeEvent, ChangeListener } from "../../events/ChangeEvent";
import { ClickEvent, ClickListener } from "../../events/ClickEvent";
import { Event } from "../../events/Event";
import { Image } from "../image/Image";
import { Panel } from "../panel/Panel";
import { StarRatingPropDefinitions, StarRatingProps } from "./StarRatingProps";

const _changeListenerDef: ListenerListDef = { listName: "_changeListeners", eventCreatorFunction: (component, event) => new ChangeEvent(component, (component as StarRating).value, (component as StarRating).value, event) };

export class StarRating extends Panel implements StarRatingProps {
    private _value: number;
    private _imageName: ImageName;
    private _maxStars: number;
    private stars: Image[];
    private clickListener: ClickListener;

    constructor(props?: Partial<StarRatingProps>) {
        super(props, false);
        this._shouldAddDesignerContainerProperties = false;
        this.stars = [];
        this.setProps(props);
        this.clickListener = event => this.starClicked(event.target as Image, event);
        if (props.maxStars == null)
            this.syncMaxStars();
    }

    private syncMaxStars() {
        this.removeAll();
        for (let i = 0; i < this.maxStars; i++)
            this.add(this.stars[i] = this.createStar());
    }

    private createStar(): Image {
        const result = new Image({
            name: this.imageName,
            fillRow: true,
            fillHeight: true,
            rowBreak: false,
            themeKey: "starRating.all",
            fill: getThemeForKey("starRating.unfilledColor"),
            marginRight: 1,
            marginLeft: 1,
            strokeWidth: 1.5
        });
        result.addClickListener(this.clickListener);
        return result;
    }

    public get imageName(): ImageName {
        return this._imageName || this.getPropertyDefinitions().imageName.defaultValue;
    }

    public set imageName(value: ImageName) {
        this._imageName = value;
        this.syncMaxStars();
    }

    _applyEnabled(value: boolean): void {
        this._syncClickListener();
    }

    get _designer() {
        return super._designer;
    }

    set _designer(value: DesignerInterface) {
        super._designer = value;
        this._syncClickListener();
    }

    private _syncClickListener(): void {
        const addListeners = this._designer == null && this.enabled;
        for (const star of this.stars)
            addListeners ? star.addClickListener(this.clickListener) : star.removeClickListener(this.clickListener);
    }

    starClicked(which: Image, event: ClickEvent) {
        let newValue = this.stars.indexOf(which) + 1;
        if (this.value === newValue)
            newValue = 0;
        this.internalSetValue(newValue, true, event);
    }

    get maxStars() {
        return this._maxStars == null ? 5 : this._maxStars;
    }

    set maxStars(value: number) {
        this._maxStars = value;
        this.syncMaxStars();
    }

    get value() {
        return this._value;
    }

    set value(value: number) {
        this.internalSetValue(value, true, null);
    }

    private internalSetValue(value: number, updateBoundField: boolean, originatingEvent: Event) {
        const origValue = this._value;
        this._value = value;
        this.storeUserChoiceIfRemembered();
        if (updateBoundField === true && this._boundField != null) {
            const row = getRelevantModelRow(this);
            const mode = getCurrentDataSourceMode(this);
            this.updateBoundData(row, mode);
        }
        for (let i = 0; i < this.maxStars; i++)
            this.stars[i].fill = i < this.value ? getThemeForKey("starRating.filledColor") : getThemeForKey("starRating.unfilledColor");
        const changeEvent = new ChangeEvent(this, origValue, value, originatingEvent == null ? null : originatingEvent.domEvent);
        this.fireListeners(_changeListenerDef, changeEvent);
    }

    addChangeListener(value: ChangeListener) {
        this.addEventListener(_changeListenerDef, value);
    }

    removeChangeListener(value: ChangeListener) {
        this.removeEventListener(_changeListenerDef, value);
    }

    override displayData(data: ModelRow<any>, allData: ModelRow<any>[], rowIndex: number): void {
        this.internalSetValue(data == null ? 0 : data.get(this.field), false, null);
    }

    override updateBoundData(row: ModelRow<any>, mode: DataSourceMode): void {
        if (row != null)
            row.set(this.field, this.value, this);
    }

    override getPropertyDefinitions() {
        return StarRatingPropDefinitions.getDefinitions();
    }

    override _serializeNonProps(): string {
        return "";
    }

    override get serializationName() {
        return "starrating";
    }

    override get properName(): string {
        return "Star Rating";
    }

    public override discoverIncludedComponents(): Component[] {
        //return null here so that the components that make up the star rating aren't included
        return null;
    }
}

ComponentTypes.registerComponentType("starrating", StarRating.prototype.constructor, true);
