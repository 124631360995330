import { ReflectiveDialogs } from "../base/ReflectiveDialogs";
import { ValidationResult } from "../base/ValidationResult";
import { DataSource } from "./DataSource";

export class ValidationUtil {
    static showFailedValidationDialog(results: ValidationResult[], useBoundSearchComponents: boolean = false, dataSource?: DataSource) {
        if (results.length > 0) {
            let message = "Unable to save this record because data is missing or invalid.";
            if (useBoundSearchComponents === true)
                message = "Unable to perform search because data is missing or invalid.";
            const lineProps = [];
            lineProps.push({ caption: message, paddingBottom: 12 });
            let componentToFocus = null;
            results.forEach(result => {
                if (result.component) {
                    if (result.caption == null)
                        result.caption = result.component?.["caption"] ?? result.component?.displayLabel ?? result.component?.field;
                    if (dataSource != null && dataSource.componentValidationFailedCallback)
                        dataSource.componentValidationFailedCallback(result);
                    if (result.caption) {
                        componentToFocus ??= result.componentToFocus ?? result.component;
                        lineProps.push({ caption: result.caption, fontBold: true });
                    }
                }
            });
            ReflectiveDialogs.showDialog(lineProps, { title: "Invalid Data", panelContentProps: { maxHeight: 400, scrollY: true } }).then(() => {
                if (componentToFocus != null)
                    componentToFocus.focus()
            });
        }
    }

    static checkValidationResults(results: ValidationResult[]): boolean {
        if (results != null){
            for (const result of results){
                if (!result.isValid){
                    return false;
                }
            }
        }
        return true;
    }
}
