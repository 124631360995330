import { ArrayUtil, DOMUtil, getLogger, ObjectUtil } from "@mcleod/core";
import { KeyEvent, KeyListener } from "./KeyEvent";
import { haveSameScope, KeyHandler } from "./KeyHandler";
import { KeyModifiers, ModifierKeys } from "./KeyModifiers";

interface KeyHandlerCollection {
    [key: string]: KeyHandler[];
}

const log = getLogger("components/events/KeyHandlerGroup");

export class KeyHandlerGroup {
    private _handlersByKey: KeyHandlerCollection = {};

    addKeyHandler(keyHandler: KeyHandler) {
        if (keyHandler.key == null)
            keyHandler.key = "ALL";
        keyHandler.modifiers = this.populateNullModifiers(keyHandler.modifiers);
        let handlersForKey = this._handlersByKey[keyHandler.key];
        if (keyHandler.listener === null && handlersForKey != null) {
            const currIndex = this.indexOfModifiersAndScope(handlersForKey, keyHandler);
            if (currIndex >= 0)
                handlersForKey.splice(currIndex, 1);
            if (handlersForKey.length === 0)
                delete this._handlersByKey[keyHandler.key];
        }
        else {
            if (handlersForKey == null) {
                handlersForKey = [];
                this._handlersByKey[keyHandler.key] = handlersForKey;
            }
            handlersForKey.push(keyHandler);
        }
    }

    populateNullModifiers(modifiers: ModifierKeys) {
        if (modifiers == null)
            modifiers = {};
        if (modifiers.altKey == null)
            modifiers.altKey = false;
        if (modifiers.ctrlKey == null)
            modifiers.ctrlKey = false;
        if (modifiers.shiftKey == null)
            modifiers.shiftKey = false;
        return modifiers;
    }

    indexOfModifiersAndScope(handlersForKey: KeyHandler[], searchHandler: KeyHandler) {
        for (let i = 0; handlersForKey != null && i < handlersForKey.length; i++) {
            const handler = handlersForKey[i];
            if (KeyModifiers.hasModifiers(handler.modifiers, searchHandler.modifiers) &&
                haveSameScope(handler, searchHandler) === true) {
                return i;
            }
        }
        return -1;
    }

    removeKeyHandler(listener: KeyListener) {
        for (const key in this._handlersByKey) {
            const handlerArray = this._handlersByKey[key];
            for (let i = 0; i < handlerArray.length; i++) {
                if (handlerArray[i].listener === listener) {
                    handlerArray.splice(i, 1);
                    if (handlerArray.length === 0)
                        delete this._handlersByKey[key];
                    return;
                }
            }
        }
    }

    handleKey(inputEvent: KeyboardEvent | KeyEvent): boolean {
        const keyboardEvent = inputEvent instanceof KeyEvent ? inputEvent.domEvent : inputEvent;
        const key = keyboardEvent?.key?.length === 1 ? keyboardEvent.key.toUpperCase() : keyboardEvent.key;
        const code = this._handlersByKey[key] || [];
        const all = this._handlersByKey.ALL || [];
        let handlersForKey = [...code, ...all];

        //we may have valid key handlers registered under the pressed key and under 'ALL'
        //if we have both, sort the combined group of key hanlders to make sure we are 
        //trying to use the handler with the narrowest scope first
        if (ArrayUtil.isEmptyArray(code) !== true && ArrayUtil.isEmptyArray(all) !== true)
            handlersForKey = this._sortByScope(handlersForKey);

        if (handlersForKey != null) {
            for (const handler of handlersForKey) {
                if ((KeyModifiers.hasModifiers(keyboardEvent, handler.modifiers) || handler.key === "ALL") && this._focusInScope(handler) === true) {
                    if (handler.element != null && !document.body.contains(handler.element)) {// element was removed.  Remove this 'stale' key listener
                        log.info("Removed key listener because its element is no longer present.", handler);
                        this.removeKeyHandler(handler.listener);
                    }
                    else {
                        const keyEvent = inputEvent instanceof KeyEvent ? inputEvent : new KeyEvent(null, keyboardEvent);
                        keyEvent.shouldAutomaticallyStopPropagation = true;
                        handler.listener(keyEvent);
                        if (keyEvent.shouldAutomaticallyStopPropagation)
                            keyEvent.consume();
                        return true;
                    }
                }
            }
        }
        return false;
    }

    public sort() {
        if (this._handlersByKey == null)
            return;
        for (const key of Object.keys(this._handlersByKey)) {
            this._sortByScope(this._handlersByKey[key]);
        }
    }

    /**
     * This method sorts elements based on their scope.  More granular elements will be ordered first in the array.
     * For example, if the provided array included elements for a TableRow and a TableCell (that exists inside the TableRow),
     * then the TableCell will come first in the sorted array.
     * 
     * Note that elements that are not nested inside each other could be returned in any order.
     * For example, two Panels that are peers of each other on the page will be returned in an unguaranteeed order.
     * 
     * @param keyHandlers An array of objects meeting the KeyHandler interface
     * @returns void
     */
    private _sortByScope(keyHandlers: KeyHandler[]) {
        log.debug("Key handlers before sorting", keyHandlers);
        if (keyHandlers != null) {
            keyHandlers.sort((a, b) => {
                if (a.scope == null && b.scope != null)
                    return 1;
                if (b.scope == null && a.scope != null)
                    return -1;
                if (a.scope == null && b.scope == null)
                    return 0;
                if (DOMUtil.isOrContains(a.scope, b.scope))
                    return 1;
                if (DOMUtil.isOrContains(b.scope, a.scope))
                    return -1;
                return 0;
            });
        }
        log.debug("Key handlers after sorting", keyHandlers);
        return keyHandlers;
    }

    private _focusInScope(handler: KeyHandler): boolean {
        if (handler.scope == null)
            return true;
        return DOMUtil.isOrContains(handler.scope, document.activeElement);
    }

    isEmpty(): boolean {
        return ObjectUtil.isEmptyObject(this._handlersByKey);
    }

    clear() {
        for (const key of Object.keys(this._handlersByKey)) {
            delete this._handlersByKey[key];
        }
    }
}