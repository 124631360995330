import { GeneralSettings } from "..";
import { InstanceIdentifier } from "../InstanceIdentifier";
import { getLogger } from "../Logger";
import { ClientInfoReply } from "../client/ClientInfoReply";
import { ClientInfoRequest } from "../client/ClientInfoRequest";
import { MessageSender } from "./MessageSender";
import { MessageSubscriber } from "./MessageSubscriber";

const log = getLogger("core.messaging.BroadcastMessageHandler");
let broadcastSubscriber: MessageSubscriber;

export class BroadcastMessageHandler {
    public static async init() {
        if (broadcastSubscriber == null && GeneralSettings.getSingleton().isMessagingEnabled()) {
            broadcastSubscriber = new MessageSubscriber("broadcastMessage", false, message => BroadcastMessageHandler.handleBroadcastMessage(message as ClientInfoRequest));
        }
    }

    private static handleBroadcastMessage(message: ClientInfoRequest) {
        log.debug(() => ["Received broadcast message", message, "from", message?.originator]);
        if (!BroadcastMessageHandler.isMessageForMe(message)) {
            log.debug("Message was not intended for this instance.");
            return;
        }
        if (message.replyToQueue != null) {
            const response = new ClientInfoReply(message.isDetail);
            MessageSender.getInstance().send(message.replyToQueue, false, response, "com.tms.common.lib.clientadmin.ClientInfoReply");
        }
    }

    private static isMessageForMe(message: ClientInfoRequest): boolean {
        if (message?.swingOnly === true) {
            // this messages are intended to be handled by Classic clients only
            return false;
        }
        if (message?.targetClients == null)
            return true;
        for (const client of message.targetClients)
            if (client.vmIdentifier === InstanceIdentifier.get())
                return true;
        return false;
    }

}
