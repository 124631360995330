import { Snackbar } from "@mcleod/components";
import { DesignerAction } from "./actions/DesignerAction";

export function doDesignerAction(designer, action: DesignerAction) {
    action["designer"] = designer;
    const result = action.execute();
    if (result?.success === true && designer.notifyActionHistory != false) {
        const tab = designer.getActiveTab();
        if (tab.undoableActions == null)
            tab.undoableActions = [];
        tab.undoableActions.push(action);
        tab.modified = true;
        tab.redoableActions = [];
        if (designer._notifyAction != null)
            designer._notifyAction(result);
    } else if (result?.success === false && result.reason) {
        Snackbar.showWarningSnackbar(result.reason)
    }
}

export function undoDesignerAction(designer) {
    const tab = designer.getActiveTab();
    if (tab.undoableActions != null && tab.undoableActions.length > 0) {
        const action = tab.undoableActions.pop();
        action.undo();
        if (tab.redoableActions == null)
            tab.redoableActions = [];
        tab.redoableActions.push(action);
        if (designer._notifyAction != null)
            designer._notifyAction();
    }
}

export function redoDesignerAction(designer) {
    const tab = designer.getActiveTab();
    if (tab.redoableActions != null && tab.redoableActions.length > 0) {
        const action = tab.redoableActions.pop();
        const result = action.execute();
        if (tab.undoableActions == null)
            tab.undoableActions = [];
        tab.undoableActions.push(action);
        if (designer._notifyAction != null)
            designer._notifyAction(result);
    }
}

export function getDesignerUndoAction(designer) {
    const tab = designer.getActiveTab();
    if (tab.undoableActions != null && tab.undoableActions.length > 0)
        return tab.undoableActions[tab.undoableActions.length - 1];
    return null;
}

export function getDesignerRedoAction(designer) {
    const tab = designer.getActiveTab();
    if (tab.redoableActions != null && tab.redoableActions.length > 0)
        return tab.redoableActions[tab.redoableActions.length - 1];
    return null;
}
