import { Layout, PanelProps, PermissionsDefinition } from "@mcleod/components";
import { Collection, GeneralSettings, Model } from "@mcleod/core";
import { PermsSecurityType, RowPermsSecureItem } from "../models/ModelPermsSecureItem";
import { ModelUserGroup, RowUserGroup } from "../models/ModelUserGroup";
import { ModelUsers, RowUsers } from "../models/ModelUsers";
import { PanelIndividualSecure, PanelIndividualSecureProps } from "./PanelIndividualSecure";
import { PermsContainer } from "./PermsContainer";
import { AutogenLayoutPanelPermsGrant } from "./autogen/AutogenLayoutPanelPermsGrant";

export interface PanelPermsGrantProps extends PanelProps {
}

export class PanelPermsGrant extends AutogenLayoutPanelPermsGrant implements PanelPermsGrantProps {
    permsContainer: PermsContainer;
    pageIdentifier: string;
    itemName: string;
    securityType: PermsSecurityType;
    users: Collection<RowUsers>;
    userGroups: Collection<RowUserGroup>;
    private _selectedChild: PanelIndividualSecure;

    constructor(props?: Partial<PanelPermsGrantProps>) {
        super({ layoutName: "common/permissions/PanelPermsGrant", ...props }); // don't like having to set the layoutName here, but doing it for now
        this.style.transition = "width 200ms";
        this.setProps({ width: 348, fillHeight: true, borderWidth: 1, borderColor: "strokeSecondary", padding: 0, fillRow: false, ...props });
    }

    async onLoad() {
        if (GeneralSettings.get()?.onPremise === false)
            this.users = (await Model.search("auth/auth-users")).mapRowsByField("id");
        else
            this.users = (await new ModelUsers().search({}, "common/UserLookup")).mapRowsByField("id");
        this.userGroups = (await new ModelUserGroup().search()).mapRowsByField("id");
        this.setItemBeingEdited(null, null, null, undefined);
        this.childChanged(null, false);
    }

    public setItemBeingEdited(pageIdentifier: string, itemName: string, permsDefs: PermissionsDefinition[], existingRows: RowPermsSecureItem[]) {
        this.pageIdentifier = pageIdentifier;
        this.itemName = itemName;
        this.panelSecures.removeAll();
        this.labelSelectItem.visible = existingRows === undefined;
        this.panelUserGroups.visible = false;
        if (permsDefs === null || permsDefs.length === 0)
            this.panelSecures.add(this.labelSelectItem);
        else {
            for (const def of permsDefs) {
                const itemName = this.itemName;
                const props: Partial<PanelIndividualSecureProps> = {
                    pageIdentifier: this.pageIdentifier,
                    itemName: itemName,
                    permsGrant: this,
                    secureItem: this.findRowForItem(itemName, def.permsType, existingRows),
                    def,
                };
                this.panelSecures.add(Layout.getLayout("common/permissions/PanelIndividualSecure", props));
            }
        }
    }

    childChanged(row: RowPermsSecureItem, deleted: boolean) {
        let childSecured = false;
        for (const panel of this.panelSecures)
            childSecured = childSecured || panel.switchSecured?.checked;
        this.buttonToggleUsers.visible = childSecured;
        if (!childSecured) {
            this.panelUserGroups.visible = false;
            this.updateToggleUsersButton();
        }
        if (!childSecured && this.panelUserGroups.visible)
            this.panelUserGroups.visible = false;
        this.permsContainer?.permsChanged(row, deleted);
    }


    findRowForItem(itemName: string, permsType: string, rows: RowPermsSecureItem[]): RowPermsSecureItem {
        for (const row of rows)
            if (row.get("item_name") === itemName && row.get("security_type") === permsType)
                return row;
        return null;
    }

    textSearchGroupsOnChange(_event) {
        this.listGroups.filter(this.textSearchGroups.text);
    }

    textSearchUsersOnChange(_event) {
        this.listUsers.filter(this.textSearchUsers.text);
    }

    buttonToggleUsersOnClick(_event) {
        this.panelUserGroups.visible = !this.panelUserGroups.visible;
        this.updateToggleUsersButton();
    }

    private updateToggleUsersButton() {
        if (this.panelUserGroups.visible) {
            this.width = 680;
            this.buttonToggleUsers.setProps({ caption: "Hide users and groups", imageRotation: 90 });
        } else {
            this.width = 348;
            this.buttonToggleUsers.setProps({ caption: "Add allowed users or groups", imageRotation: 270 });
        }
    }

    set selectedChild(child: PanelIndividualSecure) {
        if (this._selectedChild != null)
            this._selectedChild.backgroundColor = "defaultBackground";
        this._selectedChild = child;
        child.backgroundColor = "primary.lightest";
        child.fillUserList();
    }
}
