import { Component, DialogProps, Label, Panel, PanelProps } from "@mcleod/components";
import { AbstractDisplayPanelSelector } from "./AbstractDisplayPanelSelector";
import { DOMUtil, getThemeKeys } from "@mcleod/core";

export class ColorSelector extends AbstractDisplayPanelSelector {
    private displayedItemsBackgroundColor: string = null;

    constructor(props: Partial<PanelProps>) {
        super(props);
        this.addMountListener(() => this.outlineInvisibleItems());
    }

    protected override getDialogProps(): Partial<DialogProps> {
        return {
            title: "Select Color"
        };
    }

    protected override getValidationMessage(): string {
        return "Select a color.";
    }

    protected override discoverItemNames(): string[] {
        return getThemeKeys();
    }

    protected override getSingleItemPanelComponents(colorName: string): Component[] {
        const color = new Panel({
            width: 64,
            height: 64,
            padding: 6,
            borderRadius: 4,
            backgroundColor: colorName
        });
        const label = new Label({
            caption: colorName,
            color: colorName,
            wrap: true,
            marginTop: 2,
            borderRadius: 4
        });
        return [color, label];
    }

    protected displayItems() {
        super.displayItems();
        this.outlineInvisibleItems();
    }

    private outlineInvisibleItems() {
        this.displayedItemsBackgroundColor =
            DOMUtil.getStyleValueFromElementOrAncestor("background-color", this.displayedItems._element);
        for (const displayedItem of this.displayedItems.components) {
            const displayedPanel = displayedItem as Panel;
            const colorPanel = displayedPanel.components[0];
            const label = displayedPanel.components[1];
            const itemBgColor = DOMUtil.getStyleValueFromElementOrAncestor("background-color", colorPanel._element);
            if (itemBgColor === this.displayedItemsBackgroundColor) {
                colorPanel.borderWidth = 1;
                colorPanel.borderColor = "default";
                label.backgroundColor = "default";
            }
        }
    }
}
