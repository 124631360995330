import { Collection, HorizontalAlignment, getLogger } from "@mcleod/core";
import { Component, DataSource, deserializeComponents, serializeComponents } from "../..";
import { ComponentTypes } from "../../base/ComponentTypes";
import { Container } from "../../base/Container";
import { ListenerListDef } from "../../base/ListenerListDef";
import { StepEvent, StepListener } from "../../events/StepEvent";
import { ComponentCreationCallback } from "../../serializer/ComponentDeserializer";
import { Button } from "../button/Button";
import { ButtonVariant } from "../button/ButtonVariant";
import { Panel } from "../panel/Panel";
import { Step } from "./Step";
import { StepperPropDefinitions, StepperProps } from "./StepperProps";

const log = getLogger("components.Stepper");

const _beforeStepListenerDef: ListenerListDef = { listName: "_beforeStepListener" };

export class Stepper extends Container implements StepperProps {
    private panelHeader: Panel;
    private panelFooter: Panel;
    private panelMain: Panel;
    private panelContent: Container;
    private panelTools: Panel;
    private panelHeaderContent: Panel;
    private panelSteps: Panel;
    private buttonBack: Button;
    private buttonNext: Button;
    private buttonCancel: Button;
    private buttonAddStep: Button;
    private _enforceStepSequence: boolean;
    private _footerVisible: boolean;
    private _selectedIndex: number;
    private _enforceForwardValidation: boolean;
    private _enforceBackwardValidation: boolean;

    constructor(props: Partial<StepperProps>) {
        super("div", props);
        this._selectedIndex = -1;
        this.panelMain = new Panel({ fillHeight: true });
        this.panelContent = new Panel({ padding: 0, fillRow: true, fillHeight: true });
        this.panelHeader = new Panel({ fillRow: true, padding: 0 });
        this.panelTools = new Panel({ padding: 0 });
        this.buttonBack = new Button({ caption: "Back", id: "buttonBack", color: "subtle.darker", borderWidth: 1, width: 120, rowBreak: false });
        this.buttonCancel = new Button({ caption: "Cancel", color: "subtle.darker", borderWidth: 1, width: 120, rowBreak: false });
        this.buttonNext = new Button({ caption: "Next", id: "buttonNext", color: "primary", borderWidth: 1, width: 120, rowBreak: false });
        this.buttonBack.addClickListener(() => this.selectedIndex = this._selectedIndex - 1);
        this.buttonCancel.addClickListener(() => this.cancel());
        this.buttonNext.addClickListener(() => this.selectedIndex++);
        this.panelFooter = new Panel({ fillRow: true, padding: 0, components: [this.buttonBack, this.buttonCancel, this.buttonNext], align: HorizontalAlignment.RIGHT });
        this.panelHeaderContent = new Panel({ fillRow: true, padding: 0, rowBreak: false });
        this.panelSteps = new Panel({ fillRow: true, padding: 0 });
        this.panelHeader.add(this.panelHeaderContent);
        this.panelHeader.add(this.panelTools);
        this.panelFooter.add(this.panelSteps);
        this.panelMain.add(this.panelHeader);
        this.panelMain.add(this.panelContent);
        this.panelMain.add(this.panelFooter);
        this._element.appendChild(this.panelMain._element);
        this.setProps(props);
    }

    override setProps(props: Partial<StepperProps>) {
        super.setProps(props);
    }

    override add(step: Step) {
        if (step == null)
            return;
        log.debug("Add step", step);
        this.components.push(step);
        step.index = this.components.length - 1;
        step.parent = this;
        step.owner = this;
        if (step.index > 0)
            this.panelHeaderContent.add(this.createConnector(step.index));
        this.panelHeaderContent.add(step.heading);
        if (this.components.length === 1)
            this.selectedIndex = 0;
        this.syncButtons();
    }

    override insert(component: Component, index: number): Component {
        return undefined;
    }

    createConnector(index: number) {
        return new Panel({
            borderTopWidth: 1,
            borderTopColor: "strokeSecondary",
            rowBreak: false,
            fillRow: true,
            marginTop: 10,
            marginRight: 8,
            id: "panelConnector" + index
        });
    }

    override indexOf(step: Step) {
        return this.components.indexOf(step);
    }

    override reLayout() {
        const oldSteps = [...this.components];
        this.components = [];
        this.panelHeaderContent.removeAll();
        for (const step of oldSteps)
            this.add(step as Step);
    }

    removeStep(step: Step) {
        const index = this.indexOf(step);
        if (index >= 0)
            this.removeAt(index);
    }

    override removeAt(index: number) {
        if (index < 0 || index >= this._components.length)
            return;
        if (this.selectedIndex >= this._components.length - 1)
            this._selectedIndex -= 1;
        const step = this.components[index] as Step;
        step.heading.removeAll();
        this._components.splice(index, 1);
        const connector = this.panelHeaderContent.findComponentById("panelConnector" + index);
        if (connector != null) {
            this.panelHeaderContent.remove(connector);
        }
        for (let i = index; i < this._components.length; i++) {
            const test = this.components[i] as Step;
            test.index = index;
        }
        this._setSelectedInternal(this._selectedIndex);
    }

    getActiveStep() {
        return this.components[this._selectedIndex];
    }

    get selectedIndex() {
        return this._selectedIndex;
    }

    set selectedIndex(value: number) {
        if (value !== this._selectedIndex && value == this.components.length) {
            const event = new StepEvent(this, this._selectedIndex, this.getComponent(this._selectedIndex), value, this.getComponent(value));
            this.fireListeners(_beforeStepListenerDef, event);
        }
        if (value === this._selectedIndex || value >= this.components.length)
            return;
        if (this._designer == null) {
            if (value > this.maxVisitIndex + 1)
                return;
        }
        this._setSelectedInternal(value);
    }

    validateForNextStep(nextStep: number): boolean {
        if (this.__designer != null)
            return true;
        const forwardStep = nextStep > this._selectedIndex;
        if (this.enforceStepSequence && (forwardStep && nextStep - this.selectedIndex != 1))
            return false;
        if ((forwardStep && this.enforceForwardValidation) || (!forwardStep && this.enforceBackwardValidation == true))
            return this.components[this._selectedIndex].validateSimple();
        return true;
    }

    _setSelectedInternal(value: number) {
        if (this.selectedIndex >= 0 && !this.validateForNextStep(value))
            return;
        const event = new StepEvent(this, this._selectedIndex, this.getComponent(this._selectedIndex), value, this.getComponent(value));
        this.fireListeners(_beforeStepListenerDef, event);
        if (event.defaultPrevented)
            return;
        let component = this.getComponent(value);
        if (component != null && this.getComponentCount() != null && this.getComponentCount() === 1)
            component = this.getComponent(0);
        if (this._selectedIndex >= 0) {
            const component = this.components[this._selectedIndex] as Step;
            component.selected = false;
        }
        if (this.panelContent.getComponentCount() > 0)
            this.panelContent.removeAt(0);
        this._selectedIndex = value;
        if (value >= 0) {
            const component = this.components[this._selectedIndex] as Step;
            component.selected = true;
            this.panelContent.add(this.components[value]);
            this._components[value].parent = this;
            if (this._designer != null)
                this._designer.selectComponent(this.components[value]);
        }
        this.syncButtons();
    }

    private syncButtons() {
        this.buttonBack.enabled = this._selectedIndex > 0;
        this.buttonNext.caption = this._selectedIndex < this.components.length - 1 ? "Next" : "Submit";
    }

    override _serializeNonProps(): string {
        return "\"components\": " + serializeComponents(this.components, null) + ",\n";
    }

    _deserializeSpecialProps(componentOwner, compDef, defaultPropValues, dataSources: DataSource, componentCreationCallback: ComponentCreationCallback) {
        if (compDef.components != null) {
            const children = deserializeComponents(componentOwner, compDef.components, this._designer, defaultPropValues, dataSources, componentCreationCallback);
            for (let i = 0; i < children.length; i++)
                this.add(children[i]);
        }
        return ["components"];
    }

    get _designer() {
        return super._designer;
    }

    set _designer(value) {
        super._designer = value;
        if (value != null && value.addDesignerContainerProperties != null)
            value.addDesignerContainerProperties(this, 100, 100, tool => tool === "Step");
        if (value?.allowsDrop) {
            this.buttonAddStep = new Button({
                imageName: "add",
                color: "primary",
                variant: ButtonVariant.round,
                tooltip: "Add a new step to this stepper",
                margin: 0,
                marginTop: -16
            });
            this.buttonAddStep.addClickListener(event => this.addDesignerStep());
            this.panelTools.add(this.buttonAddStep);
        }
    }

    private addDesignerStep(): void {
        const designer = this._designer as any;
        const step = designer.addTool("step", "Step", this);
        step.owner = this;
        step.fillHeight = true;
        this.selectedIndex = this.components.length - 1;
    }

    addBeforeStepListener(value: StepListener) {
        this.addEventListener(_beforeStepListenerDef, value);
    }

    removeBeforeStepListener(value: StepListener) {
        this.removeEventListener(_beforeStepListenerDef, value);
    }

    getPropertyDefinitions() {
        return StepperPropDefinitions.getDefinitions();
    }

    cancel() {
    }
    get footerVisible(): boolean {
        return this._footerVisible !== false;
    }

    set footerVisible(value: boolean) {
        this._footerVisible = value;
        this.footerVisible ? this.panelMain.add(this.panelFooter) : this.panelMain.remove(this.panelFooter);
    }

    get enforceStepSequence(): boolean {
        return this._enforceStepSequence !== false;
    }

    set enforceStepSequence(value: boolean) {
        this._enforceStepSequence = value;
    }

    get maxVisitIndex() {
        for (let i = this.components.length - 1; i >= 0; i--)
            if ((this.components[i] as Step).visited)
                return i;
        return -1;
    }

    override get serializationName() {
        return "stepper";
    }

    override get properName(): string {
        return "Stepper";
    }

    override getListenerDefs(): Collection<ListenerListDef> {
        return {
            ...super.getListenerDefs(),
            "beforeStep": { ..._beforeStepListenerDef }
        };
    }

    get enforceForwardValidation(): boolean {
        return this._enforceForwardValidation !== false;
    }

    set enforceForwardValidation(value: boolean) {
        this._enforceForwardValidation = value;
    }

    get enforceBackwardValidation(): boolean {
        return this._enforceBackwardValidation !== false;
    }

    set enforceBackwardValidation(value: boolean) {
        this._enforceBackwardValidation = value;
    }

    get stepCount(): number {
        return this.components.length;
    }
}

ComponentTypes.registerComponentType("stepper", Stepper.prototype.constructor);
