import { HorizontalAlignment, VerticalAlignment, getLogger } from "@mcleod/core";
import { Panel } from "../panel/Panel";
import { VendorMap } from "./Map";
import { MapPin } from "./MapPin";
import { VendorMapProps } from "./MapProps";
import { Label } from "../label/Label";

const log = getLogger("components/Map");

export enum RouteType {
    None
}

export class NoMap extends Panel implements VendorMap {
    constructor(props?: Partial<VendorMapProps>) {
        super({ borderRadius: 4, ...props });
        const labelNoConfig = new Label({
            caption: "Mapping not configured",
            fillRow: true,
            fillHeight: true,
            imageProps: { name: "designer/map", height: 32, width: 32, color: "primary", align: HorizontalAlignment.LEFT },
            align: HorizontalAlignment.CENTER,
            verticalAlign: VerticalAlignment.CENTER,
            borderWidth: 1,
            borderRadius: 4,
            borderColor: "strokeSecondary",
            fontSize: "xxxlarge",
            fontBold: true,
            color: "primary"
        });
        this.add(labelNoConfig);
    }

    setCenter: (latitude: number, longitude: number) => void;
    addPin(pin: MapPin): void { }
    removeAllPins() { }
    focusOnPin: (pin: MapPin, mapPinIndex: number) => void;
    defocusOnPin: (pin: MapPin, mapPinIndex: number) => void;
    fitPins() { }
    setZoom(level: number) { }
    createRoute(routeData: any[], routeType: RouteType) { }
    setStopZoomLevel: (isTrafficEnabled: string, routeData: any[]) => void;
    clearRoute() { }
}
