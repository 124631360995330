import { ClickEvent } from "@mcleod/components";
import { Api } from "@mcleod/core";
import { CommonDialogs } from "./CommonDialogs";
import { AutogenLayoutNewDevice } from "./autogen/AutogenLayoutNewDevice";

export class NewDevice extends AutogenLayoutNewDevice {
    override onLoad() {
        if (this["id"] == null)
            throw new Error("Transaction Id must be provided in the URL.");
        if (this["action"] == "approve")
            this.labelApprovalDescription.caption = "There was a login attempt from a new device that was blocked based on " +
                "your user settings.  If you wish to allow future logins from this device, click the button below.";
        else if (this["action"] == "block") {
            this.labelApprovalDescription.caption = "There was a login attempt from a new device that was allowed based on " +
                "your user settings.  If you did not initiate this login and you wish to block future logins from this device, " +
                "click the button below.  NOTE: For your security, this will also update your user settings to block logins from " +
                "other new devices."
            this.buttonApproveThisDevice.caption = "Block this Device";
        }
        else
            throw new Error("Unrecognized action.");
    }

    /** This is an event handler for the onClick event of buttonApproveThisDevice.  */
    async buttonApproveThisDeviceOnClick(event: ClickEvent) {
        this.buttonApproveThisDevice.busy = true;
        try {
            await Api.post("common/new-device", { action: this["action"], id: this["id"] });
            CommonDialogs.showDialog(this["action"] == "approve" ? "This device is now approved." : "This device and future unrecognized devices are now blocked.");
            this.buttonApproveThisDevice.enabled = false;
        } finally {
            this.buttonApproveThisDevice.busy = false;
        }
    }
}
