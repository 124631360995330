import { Button } from "../button/Button";
import { ButtonProps } from "../button/ButtonProps";
import { ButtonVariant } from "../button/ButtonVariant";
import { Image } from "../image/Image";
import { Label } from "../label/Label";
import { Panel } from "../panel/Panel";
import { PanelProps } from "../panel/PanelProps";

export class ImageNavControls extends Panel {
    private buttonFirstPage: Button;
    private buttonPrevPage: Button;
    private labelPages: Label;
    private buttonNextPage: Button;
    private buttonLastPage: Button;
    private _image: Image;

    constructor(props?: Partial<PanelProps>) {
        super({ margin: 0, padding: 0, rowBreakDefault: false, ...props });
        this.labelPages = new Label();
        const sharedButtonProps = this.getSharedButtonProps();
        this.buttonFirstPage = new Button({
            ...sharedButtonProps,
            tooltip: "Jump to first page",
            imageName: "doubleChevron",
            imageRotation: 180,
            onClick: () => {
                const newPageIndex = this.image?.goToFirstPage();
                this.updateForNewPageIndex(newPageIndex);
            }
        });
        this.buttonPrevPage = new Button({
            ...sharedButtonProps,
            tooltip: "Display previous page",
            imageName: "chevron",
            imageRotation: 90,
            onClick: () => {
                const newPageIndex = this.image?.advancePage(-1);
                this.updateForNewPageIndex(newPageIndex);
            }
        });
        this.buttonNextPage = new Button({
            ...sharedButtonProps,
            tooltip: "Display next page",
            imageName: "chevron",
            imageRotation: 270,
            onClick: () => {
                const newPageIndex = this.image?.advancePage(1);
                this.updateForNewPageIndex(newPageIndex);
            }
        });
        this.buttonLastPage = new Button({
            ...sharedButtonProps,
            tooltip: "Jump to last page",
            imageName: "doubleChevron",
            imageRotation: 0,
            onClick: () => {
                const newPageIndex = this.image?.goToLastPage();
                this.updateForNewPageIndex(newPageIndex);
            }
        });
        this.add(this.buttonFirstPage, this.buttonPrevPage, this.labelPages, this.buttonNextPage, this.buttonLastPage);
    }

    get image(): Image {
        return this._image;
    }

    set image(value: Image) {
        this._image = value;
        this.reset();
    }

    reset() {
        const newPageIndex = 0;
        this.enableButtons(newPageIndex);
        this.setLabelPagesCaption(newPageIndex);
    }

    private updateForNewPageIndex(pageIndex: number) {
        this.enableButtons(pageIndex);
        this.setLabelPagesCaption(pageIndex);
    }

    private enableButtons(pageIndex: number) {
        const notOnFirstPage = pageIndex !== 0;
        this.buttonFirstPage.enabled = notOnFirstPage;
        this.buttonPrevPage.enabled = notOnFirstPage;
        const notOnLastPage = pageIndex !== this.image.getLastPageIndex();
        this.buttonNextPage.enabled = notOnLastPage;
        this.buttonLastPage.enabled = notOnLastPage;
    }

    private setLabelPagesCaption(pageIndex: number) {
        this.labelPages.caption = "Page " + (pageIndex + 1) + " of " + this.image.numPages;
    }

    private getSharedButtonProps(): Partial<ButtonProps> {
        return {
            variant: ButtonVariant.round,
            marginLeft: 0,
            marginRight: 0,
            paddingLeft: 0,
            paddingRight: 0
        };
    }
}