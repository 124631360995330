import { Collection } from "@mcleod/core";
import { Component } from "..";
import { BlurEvent } from "../events/BlurEvent";
import { ClickEvent } from "../events/ClickEvent";
import { DragEvent } from "../events/DragEvent";
import { FocusEvent } from "../events/FocusEvent";
import { KeyEvent } from "../events/KeyEvent";
import { MouseEvent } from "../events/MouseEvent";
import { ResizeEvent } from "../events/ResizeEvent";
import { ListenerListDef } from "./ListenerListDef";

export const ComponentListenerDefs: Collection<ListenerListDef> = {
    click: { listName: "_clickListeners", domEventName: "click", activeWhenDisabled: false, eventCreatorFunction: (component, event) => new ClickEvent(component, event) },
    dblclick: { listName: "_dblclickListeners", domEventName: "dblclick", activeWhenDisabled: false, eventCreatorFunction: (component, event) => new ClickEvent(component, event) },
    focus: { listName: "_focusListeners", domEventName: "focus", eventCreatorFunction: (component, event) => new FocusEvent(component, event) },
    blur: { listName: "_blurListeners", domEventName: "blur", eventCreatorFunction: (component, event) => new BlurEvent(component, event), validateFunction: "validateBlur" },
    keyDown: { listName: "_keyDownListeners", domEventName: "keydown", activeWhenDisabled: false, eventCreatorFunction: (component, event) => new KeyEvent(component, event) },
    keyUp: { listName: "_keyUpListeners", domEventName: "keyup", activeWhenDisabled: false, eventCreatorFunction: (component, event) => new KeyEvent(component, event) },
    dragStart: {
        listName: "_dragStartListeners", domEventName: "dragstart", activeWhenDisabled: false, eventCreatorFunction: (component, event) => new DragEvent(component, event),
        getTargetFunction: (component) => {
            return component instanceof Component ? component.getDragTarget() : component.getEventTarget();
        }
    },
    dragEnd: { listName: "_dragEndListeners", domEventName: "dragend", activeWhenDisabled: false, eventCreatorFunction: (component, event) => new DragEvent(component, event) },
    dragEnter: { listName: "_dragEnterListeners", domEventName: "dragenter", activeWhenDisabled: false, eventCreatorFunction: (component, event) => new DragEvent(component, event) },
    dragLeave: { listName: "_dragLeaveListeners", domEventName: "dragleave", activeWhenDisabled: false, eventCreatorFunction: (component, event) => new DragEvent(component, event) },
    dragOver: { listName: "_dragOverListeners", domEventName: "dragover", activeWhenDisabled: false, eventCreatorFunction: (component, event) => new DragEvent(component, event) },
    dragDrop: { listName: "_dragDropListeners", domEventName: "drop", eventCreatorFunction: (component, event) => new DragEvent(component, event) },
    mouseDown: { listName: "_mouseDownListeners", domEventName: "mousedown", activeWhenDisabled: false, eventCreatorFunction: (component, event) => new MouseEvent(component, event) },
    mouseUp: { listName: "_mouseUpListeners", domEventName: "mouseup", activeWhenDisabled: false, eventCreatorFunction: (component, event) => new MouseEvent(component, event) },
    mouseEnter: { listName: "_mouseEnterListeners", domEventName: "mouseenter", eventCreatorFunction: (component, event) => new MouseEvent(component, event) },
    mouseLeave: { listName: "_mouseLeaveListeners", domEventName: "mouseleave", eventCreatorFunction: (component, event) => new MouseEvent(component, event) },
    mouseMove: { listName: "_mouseMoveListeners", domEventName: "mousemove", eventCreatorFunction: (component, event) => new MouseEvent(component, event) },
    resize: { listName: "_resizeListeners", eventCreatorFunction: (component, event) => new ResizeEvent(component, event) },
}
