import {
    Button, ClickEvent, Component, DataDisplayEvent, DataSource, DataSourceAction, DataSourceExecutionEvent,
    DataSourceMode, DropdownItem, DropdownSelectionEvent, Label, Snackbar, TableRow, TableRowDisplayEvent,
    Textbox, Toast, ValidationResult
} from "@mcleod/components";
import {
    Api,
    Color,
    CompanySettings,
    ConfigSettings,
    DateUtil,
    DynamicLoader,
    getLogger,
    LicenseSettings,
    ModelRow,
    Navigation,
    StringUtil,
    UserSettings as CoreUserSettings,
    GeneralSettings
} from "@mcleod/core";
import { AutogenLayoutUserSettings } from "./autogen/AutogenLayoutUserSettings";
import { CommonDialogs } from "./CommonDialogs";
import { ValidationUtil } from "@mcleod/components/src/databinding/ValidationUtil";

interface TimeFormatItem extends DropdownItem {
    format: string;
}

const log = getLogger("common.UserSettings");

export class UserSettings extends AutogenLayoutUserSettings {
    private _evenRowColor: Color;
    private _oddRowColor: Color;

    onLoad() {
        this.sourceCurrentUserAsset.preventFocusFirstField = true;
        this.sourceCurrentUserPowerbroker.preventFocusFirstField = true;
        this.sourceSettings.preventFocusFirstField = true;
        this.sourceUserRecognizedDevice.preventFocusFirstField = true;
        this.sourcePbwLoadboardServices.preventFocusFirstField = true;

        this.buttonSave.addClickListener((event: ClickEvent) => this.buttonSaveOnClick(event));
        const civFormat = { caption: "Civilian", value: "C", format: "hh:mma" };
        const milFormat = { caption: "Military", value: "M", format: "HHmm" };
        this.textTimeFormat.items = [civFormat, milFormat];

        this.buttonSave.extraDataSources = [
            this.sourceUsers,
            this.sourceSettings,
            this.sourceCurrentUserPowerbroker,
            this.sourceCurrentUserAsset
        ];

        this.sourceSettings.data = [new ModelRow("common/user-system-config", false, { ...ConfigSettings.get(), user_id: CoreUserSettings.getUserId() })];
        this.sourceSettings.rowIndex = 0;

        this.sourceUserRecognizedDevice.search({ user_id: CoreUserSettings.getUserId() });

        this.textboxDATService.addBeforeLookupModelSearchListener((event) => {
            event.filter.service_type = 'C';
        });
        this.textboxTruckstopRESTService.addBeforeLookupModelSearchListener((event) => {
            event.filter.service_type = 'R';
        });
        this.textboxSegAllocCode.addBeforeLookupModelSearchListener((event) =>{
            event.filter.alloc_type = 'M'
        })

        this.textboxConfTemplate.addBeforeLookupModelSearchListener((event) =>{
            event.filter.document_type='Z';
            event.filter.template_number = ">0";
        });

        this.textboxQuoteTemplate.addBeforeLookupModelSearchListener((event) =>{
            event.filter.document_type='Q';
            event.filter.template_number = ">0";
        });

        this.textboxLtlQuoteTemplate.addBeforeLookupModelSearchListener((event) =>{
            event.filter.document_type='Y';
            event.filter.template_number = ">0";
        });
        this.showHideCompanyTypeBasedComponents();
        if(!this.showFieldsBasedOnLicense()){
            this.textboxSmsMessageLimit.visible = false;
            this.textboxMessageVisibility.visible = false;
        }
    }

    async buttonSaveOnClick(event) {
        const validationResults:ValidationResult[] = this.validate(true, true);
        if (ValidationUtil.checkValidationResults(validationResults)) {
            this.buttonSave.busy = true;
            if (this.sourceCurrentUserPowerbroker.activeRow.get("loadboard_dat_id") == null) {
                this.sourceCurrentUserPowerbroker.activeRow.set("dat_user_id", null);
                this.sourceCurrentUserPowerbroker.activeRow.set("dat_password", null);
                this.textboxDatUserId.text = null;
                this.textboxDatPassword.text = null;
            }

            Promise.all([
                this.sourceUsers.post(),
                this.sourceSettings.post(),
                this.sourceCurrentUserPowerbroker.post(),
                this.sourceCurrentUserAsset.post()
            ]).then(() => {
                Navigation.reloadCurrentPage(true).then(() => {
                    Toast.showToast("You have successfully updated your User Settings!")
                })
            })
                .catch(error => CommonDialogs.showError(error))
                .finally(() => this.buttonSave.busy = false)
        } else {
            ValidationUtil.showFailedValidationDialog(validationResults.filter(result => !result.isValid))
        }
    }

    textDateFormatOnChange(event) {
        this.updateSample();
    }

    textTimeFormatOnChange(event) {
        this.updateSample();
    }

    updateSample() {
        const date = new Date();
        date.setFullYear(date.getFullYear(), 11, 31);
        date.setHours(16);
        date.setMinutes(35);
        let format;
        if (this.textDateFormat.selectedItem != null)
            format = (<TimeFormatItem>this.textDateFormat.selectedItem).caption;
        else
            format = "MM/dd/yyyy";
        format += " ";
        if (this.textTimeFormat.selectedItem != null)
            format += (<TimeFormatItem>this.textTimeFormat.selectedItem).format;
        else
            format += "hh:mma";
        this.labelSample.caption = DateUtil.formatDateTime(date, format);
    }

    sourceCurrentUserPowerbrokerAfterExecution(event: DataSourceExecutionEvent) {
        if (event.getAction() == DataSourceAction.SEARCH && this.sourceCurrentUserPowerbroker.activeRow == null) {
            this.sourceCurrentUserPowerbroker.mode = DataSourceMode.ADD;
        }
        if (this.sourceCurrentUserPowerbroker.activeRow) {
            const [datUser, datPass, datError, truckstopTokensExist] = [
                this.sourceCurrentUserPowerbroker.activeRow.get("dat_user_id"),
                this.sourceCurrentUserPowerbroker.activeRow.get("dat_password"),
                this.sourceCurrentUserPowerbroker.activeRow.get("dat_error"),
                this.sourceCurrentUserPowerbroker.activeRow.get("truckstop_tokens_exist"),
            ]

            if (!StringUtil.isEmptyString(datUser) && !StringUtil.isEmptyString(datPass)) {
                if (StringUtil.isEmptyString(datError)) {
                    this.labelDATResult.imageName = "circleCheck";
                    this.labelDATResult.imageColor = "success";
                } else {
                    this.labelDATResult.imageName = "circleX";
                    this.labelDATResult.imageColor = "error";
                    this.labelDATResult.tooltip = datError;
                }
            }
            if (truckstopTokensExist) {
                this.labelTruckstopResult.imageName = "circleCheck";
                this.labelTruckstopResult.imageColor = "success";
            } else {
                this.labelTruckstopResult.imageName = "circleX";
                this.labelTruckstopResult.imageColor = "error";
                this.labelTruckstopResult.tooltip = "Tokens not set for this user";
            }
        }
    }

    sourceCurrentUserAssetAfterExecution(event: DataSourceExecutionEvent) {
        if (event.getAction() == DataSourceAction.SEARCH && this.sourceCurrentUserAsset.activeRow == null) {
            this.sourceCurrentUserAsset.mode = DataSourceMode.ADD;
        }
    }

    /** This is an event handler for the onClick event of buttonToggleDeviceApproval.  */
    buttonToggleDeviceApprovalOnClick(event: ClickEvent) {
        const tableRow = TableRow.getContainingTableRow(event.target as Component);
        tableRow.data.set("approved", tableRow.data.get("approved") === "N" ? "Y" : "N");
        tableRow.data.post().then(() => this.tableUserDevices.redisplaySingleRow(tableRow.index, tableRow.data, false, false));
    }

    /** This is an event handler for the onClick event of buttonAuthorize.  */
    buttonAuthorizeOnClick(event: ClickEvent) {
        this.labelTruckstopResult.imageColor = "primary";
        this.labelTruckstopResult.busy = true;
        const [serviceId, userId, password] = [
            this.textboxTruckstopRESTService.getDataValue(),
            this.textboxTruckstopUserId.text,
            this.textboxTruckstopPassword.text
        ];

        if (StringUtil.isEmptyString(serviceId)
            || StringUtil.isEmptyString(userId)
            || StringUtil.isEmptyString(password)) {
            this.labelTruckstopResult.imageName = null;
            this.labelTruckstopResult.imageColor = "primary";
            this.labelTruckstopResult.busy = false;
            this.labelTruckstopResult.tooltip = null;
            Snackbar.showWarningSnackbar("Please provide all Truckstop REST fields (Service, Truckstop User ID, Password");
        } else {
            Api.post("lme/powerbroker/truckstop-user-auth", {
                "service_id": serviceId,
                "user_id": userId,
                "password": password
            }).then(response => {
                this.labelTruckstopResult.busy = false;
                if (response.data[0].success) {
                    this.sourceCurrentUserPowerbroker.activeRow.set("truckstop_access_token", response.data[0].access);
                    this.sourceCurrentUserPowerbroker.activeRow.set("truckstop_refresh_token", response.data[0].refresh);
                    this.sourceCurrentUserPowerbroker.activeRow.set("truckstop_token_expires", response.data[0].expire);

                    this.labelTruckstopResult.imageName = "circleCheck";
                    this.labelTruckstopResult.imageColor = "success";
                    this.textboxTruckstopPassword.clear();
                    Snackbar.showSnackbar("Success: Access tokens saved, Truckstop password not saved");
                } else {
                    this.labelTruckstopResult.imageName = "circleX";
                    this.labelTruckstopResult.imageColor = "error";
                    this.labelTruckstopResult.tooltip = response.data[0].error ? response.data[0].error : "There was an unexpected error Authorizing access.";
                }
            }).catch(reason => this.labelTruckstopResult.busy = false);
        }
    }

    /** This is an event handler for the onRowDisplay event of tableUserDevices.  */
    tableUserDevicesOnRowDisplay(event: TableRowDisplayEvent) {
        const button = event.getTableRow().findComponentById("buttonToggleDeviceApproval") as Button;
        if (event.getTableRow().data.get("approved") === "N")
            button.setProps({ caption: "Unblock this Device", color: "success" });
        else
            button.setProps({ caption: "Block this Device", color: "warning" });
    }

    /** This is an event handler for the onDataDisplay event of labelDeviceApproved.  */
    labelDeviceApprovedOnDataDisplay(event: DataDisplayEvent) {
        const label = event.target as Label;
        const tableRow = TableRow.getContainingTableRow(label);
        const thisDeviceId = localStorage.getItem("deviceId");
        if (tableRow.data.get("approved") === "N")
            label.setProps({ caption: "Blocked", backgroundColor: "warning", color: "warning.reverse" });
        else if (tableRow.data.get("device_id") === thisDeviceId)
            label.setProps({ caption: "This Device", backgroundColor: "success", color: "success.reverse" });
        else
            label.setProps({ caption: "Approved", backgroundColor: "success", color: "success.reverse" });
    }

    /** This is an event handler for the onDataDisplay event of label1.  */
    labelDeviceLocationOnDataDisplay(event: DataDisplayEvent) {
        const label = event.target as Textbox;
        const tableRow = TableRow.getContainingTableRow(label);
        if (StringUtil.isEmptyString(tableRow.data.get("last_ip_location")))
            label.text = "Unknown location";
        label.tooltip = "IP address: " + tableRow.data.get("last_ip_address");
    }

    showHideCompanyTypeBasedComponents() {
        const companySettings = CompanySettings.get();
        const isAsset = companySettings?.is_asset;
        const isBrokerage = companySettings?.is_brokerage;
        const hasEarlyAdoptersAccess = GeneralSettings.hasEarlyAdoptersAccess();

        this.textboxDriverManagerProfile.visible = isAsset;
        this.textboxOrderPlanningProfile.visible = isAsset;
        this.textboxOrderPlanningProfile.required = hasEarlyAdoptersAccess ? isAsset : false;
        this.textboxAssetOrderNextAction.visible = isAsset;

        this.textboxBpProfile.visible = isBrokerage;
        this.textboxBpProfile.required = isBrokerage;
        this.textboxTmProfile.visible = isBrokerage;
        this.textboxDefaultDistanceMarginPercentage.visible = isBrokerage;
        this.textboxBrokerageOrderNextAction.visible = isBrokerage;
        this.tabLoadboardServices.visible = isBrokerage;

        if (isAsset && isBrokerage) {
            this.textboxBrokerageOrderNextAction.caption = "PowerBroker New Order Next Action";
            this.textboxAssetOrderNextAction.caption = "Loadmaster New Order Next Action";
        }
    }

     showFieldsBasedOnLicense() {
        return !!LicenseSettings.getSingleton()?.getLicensedPackages()?.find(license => license === "com.tms.client.loadmaster.sms");
    }

    sourceUsersAfterModeChange(event){
        if ((event.newMode == DataSourceMode.SEARCH || event.newMode == DataSourceMode.NONE) && event.newMode != event.oldMode) {
            const allocationEnabled = this.sourceUsers.activeRow?.get("seg_alloc_enabled");
            this.textboxSegAllocCode.visible = allocationEnabled;
            delete this.sourceUsers.activeRow?.data["seg_alloc_enabled"];
        }
    }

    /** This is an event handler for the onClick event of buttonEvenColor. */
    async buttonEvenColorOnClick(event: ClickEvent) {
        const selectedColor = await this.promptForColor();
        log.debug("Selected even row color: %o", selectedColor);
        this.evenRowColor = selectedColor;
    }

    /** This is an event handler for the onClick event of buttonOddColor. */
    async buttonOddColorOnClick(event: ClickEvent) {
        const selectedColor = await this.promptForColor();
        log.debug("Selected odd row color: %o", selectedColor);
        this.oddRowColor = selectedColor;
    }

    /** This is an event handler for the onClick event of buttonClearEvenColor. */
    buttonClearEvenColorOnClick(event: ClickEvent) {
        this.evenRowColor = null;
    }

    /** This is an event handler for the onClick event of buttonClearOddColor. */
    buttonClearOddColorOnClick(event: ClickEvent) {
        this.oddRowColor = null;
    }

    private get evenRowColor(): Color {
        return this._evenRowColor;
    }

    private set evenRowColor(value: Color) {
        if (value === this._evenRowColor)
            return;
        this._evenRowColor = value;
        this.sourceUsers.activeRow.set("table_even_row_color", this.evenRowColor);
        //don't set color when in the designer...we don't want to serialize that color as part of the layout
        if (this._designer == null)
            this.panelEvenColorDisplay.backgroundColor = this._evenRowColor ?? "";
    }

    private get oddRowColor(): Color {
        return this._oddRowColor;
    }

    private set oddRowColor(value: Color) {
        if (value === this._oddRowColor)
            return;
        this._oddRowColor = value;
        this.sourceUsers.activeRow.set("table_odd_row_color", this.oddRowColor);
        //don't set color when in the designer...we don't want to serialize that color as part of the layout
        if (this._designer == null)
            this.panelOddColorDisplay.backgroundColor = this._oddRowColor ?? "";
    }

    private async promptForColor(): Promise<string> {
        const cls = DynamicLoader.getClassForPath("designer/ui/ColorSelector");
        const colorSelector = await Promise.resolve(new cls());
        const props = { height: 600, width: 620, caption: colorSelector.caption, ...colorSelector.dialogProps };
        const dialog = await CommonDialogs.showDialog(colorSelector, props);
        if (dialog.wasCancelled !== true) {
            const result = colorSelector.getValue();
            if (result !== undefined)
                return result;
            return null;
        }
        return undefined;
    }

    /** This is an event handler for the afterExecution event of sourceUsers. */
    sourceUsersAfterExecution(event: DataSourceExecutionEvent) {
        if (event.getAction() === DataSourceAction.SEARCH) {
            this.evenRowColor = this.sourceUsers.activeRow.get("table_even_row_color", undefined);
            this.oddRowColor = this.sourceUsers.activeRow.get("table_odd_row_color", undefined);
        }
        else if (event.getAction() === DataSourceAction.ADD || event.getAction() === DataSourceAction.UPDATE)
            CoreUserSettings.getSingleton().updateEvenOddRowColors(this.evenRowColor, this.oddRowColor);
    }
}
