import { Alignment, getApiMetadataFromCache } from "@mcleod/core";
import { Layout } from "../components/layout/Layout";
import { MouseEvent } from "../events/MouseEvent";
import { StringOrPropsOrComponent } from "../page/getComponentFromStringOrPropsOrComponent";
import { Component } from "./Component";
import { getRelevantModelRow } from "./ComponentDataLink";

export class QuickInfo extends Component {
    private _quickInfoLayout: string;

    getMixinClassName() {
        return "QuickInfo";
    }

    get quickInfoLayout(): string {
        if (this._quickInfoLayout == null && this._boundField?.lookupModel != null)
            return getApiMetadataFromCache(this._boundField.lookupModel)?.quickInfoLayout;
        return this._quickInfoLayout;
    }

    set quickInfoLayout(value: string) {
        this._quickInfoLayout = value;
        this._syncHoverCallback();
    }

    protected _syncHoverCallback() {
        const quickInfoLayout = this.quickInfoLayout;
        if (quickInfoLayout != null) {
            this.tooltipCallback = this._quickInfoTooltipCallback;
            return;
        }
        if (this.tooltipCallback != null)
            this.tooltipCallback = null;
    }

    protected async _quickInfoTooltipCallback(baseTooltip: Component, originatingEvent: MouseEvent): Promise<Component> {
        const keyValue = this._getKeyValue();
        if (keyValue == null)
            return null;
        const layout = this.getQuickInfoLayout();
        if (layout == null)
            return null;
        layout.onLoad = () => {
            if (layout.mainDataSource != null)
                layout.mainDataSource.search({ search: keyValue }).then(r => {
                    this["_tooltipInstance"] = this._internalShowTooltip(layout, originatingEvent);
                });
        };
        //seems odd to return null here, but we know that the value is really going to be set within the
        //onLoad() above, after the data has been queried and the tooltip has been displayed
        return null;
    }

    protected _internalShowTooltip(tooltip: StringOrPropsOrComponent, originatingEvent: MouseEvent): Component {
        const options = {
            position: this.tooltipPosition == null ? Alignment.BOTTOM : this.tooltipPosition,
            pointerColor: position => position === Alignment.RIGHT ? "warning.dark" : "background5"
        };
        const props = {
            themeKey: "quickInfo",
            color: null
        };
        return this.showTooltip(tooltip, options, props);
    }

    private getQuickInfoLayout(): Layout {
        if (this.quickInfoLayout != null)
            return Layout.getLayout(this.quickInfoLayout);
        return null;
    }

    private _getKeyValue(): any {
        const row = getRelevantModelRow(this);
        const value = row?.get(this.field);
        return value;
    }

    protected getQuickInfoLayoutDefaultValue(): string {
        if (this._boundField?.lookupModel != null)
            return getApiMetadataFromCache(this._boundField.lookupModel)?.quickInfoLayout;
    }
}
