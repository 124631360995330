import { AutogenLayoutTenant } from "./autogen/AutogenLayoutTenant";
import { ClickEvent, DataSourceMode, DataSourceModeChangeEvent, Layout, SlideoutDecorator, TableRowCreationEvent, Toast, LookupModelSelectionEvent, DataDisplayEvent, Label, TableSelectionEvent, TableRow } from "@mcleod/components";

export class Tenant extends AutogenLayoutTenant {
    tableUsersOnRowCreate(event: TableRowCreationEvent) {
        event.getTableRow().data.set("tenant_id", this.sourceTenant.data[0].get("id"));
    }

    /** This is an event handler for the onClick event of buttonInviteUser.  */
    buttonInviteUserOnClick(event: ClickEvent) {
        this.sendInvites("SendUserInvitation", 540);
    }

    sourceTenantAfterModeChange(event: DataSourceModeChangeEvent) {
        if ((event.oldMode === DataSourceMode.UPDATE || event.oldMode === DataSourceMode.ADD)
            && event.newMode === DataSourceMode.NONE) {
            this.sourceTenantBranding.post();
        }
        else {
            if (event.newMode === DataSourceMode.UPDATE && this.sourceTenantBranding.data.length === 0) {
                this.sourceTenantBranding.mode = DataSourceMode.ADD;
            }
            else
                this.sourceTenantBranding.mode = event.newMode;
        }
    }

    /** This is an event handler for the onClick event of buttonInviteMultiple. */
    buttonInviteMultipleOnClick(event: ClickEvent) {
        this.sendInvites("BatchInvite", 600);
    }

    private sendInvites(layoutName: string, width: number) {
        const layout = Layout.getLayout("auth/" + layoutName);
        layout.addLayoutLoadListener(() => {
            layout.mainDataSource.addBeforeExecutionListener(event => {
                event.data.set("tenant_id", this.sourceTenant.data[0].get("id"));
            });
        });
        SlideoutDecorator.decorateAndSlideIn(layout, { fillVerticalSpace: true, width, title: "User Invitation" });
    }

    /** This is an event handler for the onLookupModelSelection event of textboxName. */
    textboxNameOnLookupModelSelection(event: LookupModelSelectionEvent) {
        this.sourceTenant.data[0].set("auth_user.name", event.selectedRow.get("name"));
        this.sourceTenant.data[0].set("auth_user.email_address", event.selectedRow.get("email_address"));
    }

    /** This is an event handler for the onClick event of buttonNewAccessToken. */
    async buttonNewAccessTokenOnClick(event: ClickEvent) {
        const layout = Layout.getLayout("auth/NewTenantAccessToken");
        layout.addLayoutLoadListener(() => {
            layout.mainDataSource.addBeforeExecutionListener(event => {
                event.data.set("tenant_id", this.sourceTenant.data[0].get("id"));
            });

            layout.mainDataSource.addAfterExecutionListener(event => {
                this.sourceTenantAccessTokens.search();
            });
        });
        await SlideoutDecorator.decorateAndSlideIn(layout, { fillVerticalSpace: true, width: 600 });
    }

    /** This is an event handler for the onDataDisplay event of textboxIsActive. */
    textboxIsActiveOnDataDisplay(event: DataDisplayEvent) {
        const label = event.target as Label;
        const active = event.rowData.getBoolean("is_active", false);
        const expired = new Date() < event.rowData.getDateTime("expiration_date");
        if (expired) {
            label.setProps({ caption: "Expired", backgroundColor: "subtle" });
        } else if (active) {
            label.setProps({ caption: "Active", backgroundColor: "success" });
        } else {
            label.setProps({ caption: "Revoked", backgroundColor: "warning" });
        }
        label.color = label.backgroundColor + ".reverse";
    }

    /** This is an event handler for the onClick event of buttonRevoke. */
    async buttonRevokeOnClick(event: ClickEvent) {
        const data = this.tableAccessTokens.selectedRow?.data;
        if (data != null) {
            data.set("is_active", !data.getBoolean("is_active", false));
            await data.post();
            this.tableAccessTokensOnSelect(null);
        }
    }

    /** This is an event handler for the onSelect event of tableAccessTokens. */
    tableAccessTokensOnSelect(event: TableSelectionEvent) {
        const data = this.tableAccessTokens.selectedRow?.data;
        this.buttonRevoke.enabled = data != null;
        const active = data?.getBoolean("is_active", false);
        if (active === false) {
            this.buttonRevoke.setProps({
                imageName: "circleCheckEmpty", color: "success",
                tooltip: "Reactivate this token that has previously been revoked."
            });
        }
        else if (active === true) {
            this.buttonRevoke.setProps({
                imageName: "unavailable", color: "warning",
                tooltip: "Revoke this token so it can no longer be used.  This token can be reactivated if desired."
            });
        }
    }

    /** This is an event handler for the onDataDisplay event of textboxLastUsedDateTime. */
    textboxLastUsedDateTimeOnDataDisplay(event: DataDisplayEvent) {
        const label = event.target as Label;
        const lastUsedDateTime = event.rowData.getDateTime("last_used_date_time");
        if (lastUsedDateTime == null) {
            label.setProps({ caption: "This token has never been used.", color: "subtle", fontSize: "small" });
            label.style.fontStyle = "italic";
        }
    }

    /** This is an event handler for the onDataDisplay event of labelAddressHeading. */
    labelAddressHeadingOnDataDisplay(event: DataDisplayEvent) {
        (event.target as Label).visible = !event.rowData.isNull("last_used_ip_address");
    }
}
