import {
    Button, ButtonVariant, ChangeEvent, Checkbox, ClickEvent, Component, DataDisplayEvent, DesignableObject,
    DesignerInterface, Dialog, FocusEvent, KeyEvent, Panel, PropType, Table, TableRow, Textbox
} from "@mcleod/components";
import {
    ComponentPropDefinition, ComponentPropDefinitions, ComponentProps, getColorEditor
} from "@mcleod/components/src/base/ComponentProps";
import { ReflectiveDialogs } from "@mcleod/components/src/base/ReflectiveDialogs";
import { helpLinkEditor } from "@mcleod/components/src/components/layout/LayoutProps";
import { TableCellProps } from "@mcleod/components/src/components/table/TableCellProps";
import { fieldListSelector, layoutSelector, stringArrayEditor } from "@mcleod/components/src/components/textbox/TextboxProps";
import { tailorExtensionEditor } from "@mcleod/components/src/page/TailorExtensionButtonProps";
import { Alignment, Collection, Keys, StringUtil, VerticalAlignment, getLogger, getThemeColor } from "@mcleod/core";
import { DefaultValuePropertyEditor } from "./DefaultValuePropertyEditor";
import { DesignerDataSource } from "./DesignerDataSource";
import { designerCheckForPropChange } from "./UIDesignerUtil";
import { McLeodTailor } from "./custom/McLeodTailor";
import { ImagePropDefinitions } from "@mcleod/components/src/components/image/ImageProps";

const log = getLogger("designer/ui/PropertiesTable");

interface EditorPanel extends Panel {
    editor: Component;
}

const captionPropsDefault: Partial<TableCellProps> = { fontBold: false, color: null }
const captionPropsNotDefault: Partial<TableCellProps> = { fontBold: true, color: "primary" }
const captionPropsModified: Partial<TableCellProps> = { fontBold: true, color: "#ad12b0" };

export class PropertiesTable extends Table {
    designer: DesignerInterface;
    expandedSections: Collection<boolean>;
    _selectedTableRow: TableRow;
    selectedComponents: Component[];
    clearing: boolean;

    constructor(designer: DesignerInterface, props?) {
        super({
            id: "tableProps",
            fillRow: true, fillHeight: true, padding: 0, headerVisible: false, rowAlign: VerticalAlignment.CENTER,
            expanderAlignment: Alignment.LEFT, rowSpacing: 0,
            columns: [
                { headingDef: { caption: "Property", paddingLeft: 4 }, cell: { field: "prop_name", height: 34, paddingLeft: 4, tooltip: cell => { if (cell.row.data.prop != null) return cell.row.data.prop.description; } } },
                {
                    headingDef: { caption: "Value", paddingLeft: 8 },
                    cell: (row) => {
                        return row.editor = this.createPropValueCell(row, "default");
                    }
                }
            ],
            printableToggleEnabled: false,
            ...props
        });
        this.addRowCreateListener(rowCreationEvent => this.onPropRowCreate(rowCreationEvent.getTableRow()));
        this.addRowDisplayListener(rowDisplayEvent => this.onPropRowDisplay(rowDisplayEvent.getTableRow()));
        this.addRowExpandListener(rowExpansionEvent => this._trackExpandedSections(rowExpansionEvent.getTableRow(), true));
        this.addRowCollapseListener(rowExpansionEvent => this._trackExpandedSections(rowExpansionEvent.getTableRow(), false));
        this.designer = designer;
        this.expandedSections = {};
    }

    private createPropValueCell(row: TableRow, filterCategory: string): Panel {
        const prop = row.data.prop;
        const result = new Panel({ id: "editor" + row.index, fillRow: true, padding: 0, borderLeftWidth: 1, borderLeftColor: "strokeSecondary", verticalAlign: VerticalAlignment.CENTER }) as EditorPanel;
        if (filterCategory != null && (prop == null || prop.category !== filterCategory))
            return result;
        let editorComponent: Component;
        const editorComponents: Component[] = [];
        if ("defaultDataValue" == row.data.prop_name) {
            editorComponent = new DefaultValuePropertyEditor(this, row).component;
            if (editorComponent != null) {
                result.add(editorComponent)
                result.editor = editorComponent;
            }
            return result;
        } else if (prop.type === PropType.bool)
            editorComponent = this.createPropertyCheckbox(row);
        else
            editorComponent = this.createPropertyTextbox(row, prop);
        editorComponent.rowBreak = false;
        editorComponents.push(editorComponent);
        result.add(editorComponent);
        const button = this.createSpecialtyEditorButton(editorComponent as Textbox, row, prop);
        if (button != null) {
            editorComponents.push(button);
            result.add(button);
        }
        this.designer.disablePropertyEditors(prop, editorComponents, this.selectedComponents[0]);
        (editorComponent as any).index = row.index; //why are we doing this?
        editorComponent.field = "value";
        editorComponent.addFocusListener(() => this.selectedTableRow = row);
        editorComponent.addKeyUpListener((event: KeyEvent) => this.keyUp(event));
        result.editor = editorComponent;
        return result;
    }

    private createPropertyCheckbox(row: TableRow): Checkbox {
        const checkbox = new Checkbox({ paddingLeft: 8, rowBreak: false, fillRow: true });
        checkbox.addChangeListener((event: ChangeEvent) => {
            if (event.userInitiatedChange) {
                this.designer.applyChangeToSelectedComponents(row.data, checkbox.checked);
                const oldValue = row.data.value || false;
                const newValue = checkbox.checked;
                designerCheckForPropChange(this.designer, row.data.prop_name, newValue, oldValue);
            }
        });
        return checkbox;
    }

    private createPropertyTextbox(row: TableRow, prop): Textbox {
        const textbox = new Textbox({
            padding: 0,
            captionVisible: false,
            borderWidth: 0,
            borderRadius: 0,
            height: "unset",
            fillRow: true,
            rowBreak: false
        });
        textbox.setProps({ paddingTop: 0, paddingBottom: 0, paddingLeft: 4 });
        textbox.addDataDisplayListener((event: DataDisplayEvent) => {
            const value = event.rowData?.["value"];
            // these shouuld be ModelRows, but they aren't.  This was created before we standardized ModelRow.
            // so we reference the "value" directly
            if (value?.id != null)
                (event.target as Textbox).text = value.id;
        });

        const items = prop.dropdownProps?.items;

        if (items) {
            if (typeof items === "function")
                textbox.items = () => items(this.designer.getActiveTab());
            else
                textbox.items = items;
            if (prop.dropdownProps.allowDropdownBlank)
                textbox.allowDropdownBlank = prop.dropdownProps.allowDropdownBlank;
        }

        if (textbox.items == null) {
            textbox.addBlurListener((event: FocusEvent) => {
                this.submitTextboxCheckForPropChange(textbox, row);
            });
        }

        textbox.addChangeListener((event: ChangeEvent) => {
            if (event.userInitiatedChange && event.newValue !== event.oldValue) {
                const newValue = this.getPropertyTextboxValue(textbox);
                this.designer.applyChangeToSelectedComponents(row.data, newValue);

                if (textbox.items)
                    this.submitTextboxCheckForPropChange(textbox, row);
                else if (this.designer instanceof McLeodTailor) {
                    //if the user is typing and the value is changed back to the original value, then changes to baseVersionProps need to be reverted
                    this.designer.checkForPropChange(event, row.data);
                }
            }
        });
        if (prop.category === "Events")
            textbox.addDblClickListener((event: ClickEvent) => this.designer.addEventHandlerFunction(this.designer.selectedComponents[0], row.data.prop_name));
        if (prop.editor == layoutSelector)
            textbox.addChangeListener(() => this.syncOpenLayoutButton(textbox, row));
        return textbox;
    }

    private syncOpenLayoutButton(textbox: Textbox, row: TableRow): void {
        const cell = textbox.parent;
        if (cell == null) {
            return;
        }

        const buttonOpenLayoutExists = !!row.data.buttonOpenLayout;
        const textboxIsEmpty = textbox.isEmpty();

        if (!textboxIsEmpty && !buttonOpenLayoutExists) {
            row.data.buttonOpenLayout = new Button({
                imageName: "detail",
                color: "primary",
                variant: ButtonVariant.round,
                tooltip: "Open layout in new tab.",
                tooltipPosition: Alignment.LEFT,
                onClick: () => this.designer.openTab({ path: textbox.text })
            });
            cell.add(row.data.buttonOpenLayout);
        } else if (textboxIsEmpty && buttonOpenLayoutExists) {
            cell.remove(row.data.buttonOpenLayout);
            row.data.buttonOpenLayout = null;
        }
    }

    private submitTextboxCheckForPropChange(textbox: Textbox, row: TableRow) {
        const newValue = this.getPropertyTextboxValue(textbox);
        const oldValue = StringUtil.isEmptyString(row.data.value) ? null : row.data.value;
        designerCheckForPropChange(this.designer, row.data.prop_name, newValue, oldValue);
    }

    private getPropertyTextboxValue(textbox: Textbox): string {
        const value = textbox.selectedItem?.value ?? textbox.text;
        return StringUtil.isEmptyString(value) ? null : value;
    }

    private createSpecialtyEditorButton(textbox: Textbox, row: TableRow, prop): Button {
        if (prop.editor == null)
            return null;
        if (prop.editor === stringArrayEditor || prop.editor === helpLinkEditor ||
            prop.editor === tailorExtensionEditor || prop.editor === getColorEditor ||
            prop.editor === ImagePropDefinitions.imagePropertyEditor) {
            if (prop.editorButtonImage == null)
                prop.editorButtonImage = "ellipsis";
            if (prop.editor !== getColorEditor && prop.editor !== ImagePropDefinitions.imagePropertyEditor)
                textbox.enabled = false;
        }
        else if (prop.editor === fieldListSelector)
            textbox.enabled = false;
        else if (prop.editor === layoutSelector)
            prop.editorButtonImage = "ellipsis";
        const button = new Button({
            imageName: prop.editorButtonImage || "chevron",
            variant: ButtonVariant.round,
            color: "subtle.light",
            margin: 0,
            rowBreak: false
        });
        button.addClickListener(async (event: ClickEvent) => {
            const dialogContents = await prop.editor(textbox.text, prop);
            if (dialogContents != null) {
                ReflectiveDialogs.showDialog(dialogContents, { height: 600, width: 620, caption: dialogContents.caption, ...dialogContents.dialogProps })
                    .then((response) => {
                        if ((response as Dialog).wasCancelled !== true) {
                            const newValue = dialogContents.getValue();
                            if (newValue !== undefined) {
                                const oldValue = row.data.value;
                                this.designer.applyChangeToSelectedComponents(row.data, newValue);
                                textbox.text = newValue;
                                this.designer.displayDataSourceTools();
                                designerCheckForPropChange(this.designer, row.data.prop_name, newValue, oldValue);
                            }
                        }
                    });
            }
        });
        return button;
    }

    set selectedTableRow(row: TableRow) {
        if (this._selectedTableRow != row) {
            if (this._selectedTableRow) {
                this._selectedTableRow.selected = false;
                this.setRowEditorProps(this._selectedTableRow, { borderWidth: 0 });
            }
            this._selectedTableRow = row;
            if (this._selectedTableRow) {
                this._selectedTableRow.selected = true;
                this.setRowEditorProps(this._selectedTableRow, { borderWidth: 0 });
            }
        }
    }

    get selectedTableRow(): TableRow {
        return this._selectedTableRow;
    }

    private onPropRowCreate(row) {
        row.expandable = row.data.subRows != null;
        row.expandComponent = (table, row, expandComponent, expanding) => { return this.onPropRowExpand(table, row, expandComponent, expanding) };
    }

    private _trackExpandedSections(row: TableRow, isExpanding: boolean) {
        if (!this.clearing)
            this.expandedSections[row.data.prop_name] = isExpanding;
    }

    private onPropRowExpand(table: Table, row: TableRow, expandComponent: Component, expanding: boolean) {
        if (!expanding)
            return;
        const result = new Table({
            id: "tableProps",
            printableToggleEnabled: false,
            fillRow: true, padding: 0, headerVisible: false, columnHeadingsVisible: false,
            rowAlign: VerticalAlignment.CENTER, rowSpacing: 0, virtualized: false,
            columns: [
                { headingDef: { caption: "Property" }, cell: { field: "prop_display", height: 34, paddingLeft: 4, tooltip: cell => { if (cell.row.data.prop != null) return cell.row.data.prop.description; } } },
                { headingDef: { caption: "Value" }, cell: (row) => { row.editor = this.createPropValueCell(row, null); row.data.editor = row.editor; return row.editor } }
            ]
        });
        result.addRowDisplayListener(rowDisplayEvent => this.onPropRowDisplay(rowDisplayEvent.getTableRow()));
        for (const subRow of row.data.subRows) {
            const subTableRow = result.addRow(subRow, null, { display: true }).row as any;
            subTableRow.parentRow = row;
        }
        return result;
    }

    private categorizeProps(keys: string[], props) {
        const result = {} as any;
        for (let i = 0; i < keys.length; i++) {
            const prop = props[keys[i]];
            if (prop != null) {
                if (prop.category == null)
                    prop.category = "default";
                if (result[prop.category] == null)
                    result[prop.category] = {};
                result[prop.category][keys[i]] = prop;
            }
        }
        return result;
    }

    /**
     * Returns the property names that all the selected components have in common.
     * @param {*} props
     * @returns
     */
    private getCommonProperties(props: ComponentPropDefinitions) {
        const keys = Object.keys(props);
        for (let compIndex = 1; compIndex < this.selectedComponents.length; compIndex++)
            for (let i = keys.length - 1; i >= 0; i--)
                if (!(keys[i] in this.getSelectedComponent(compIndex)))
                    keys.splice(i, 1);
        return keys;
    }

    /**
     * Returns the values that all the selected components have in common.
     * @param {*} keys
     * @returns
     */
    private getCommonValues(keys: string[]) {
        const values = {};
        for (const key of keys) {
            values[key] = this.getSelectedComponent(0)[key];
            for (let compIndex = 1; compIndex < this.selectedComponents.length; compIndex++)
                for (const inner of keys)
                    if (values[inner] !== this.getSelectedComponent(compIndex)[inner])
                        values[inner] = null;
        }
        return values;
    }

    displayProperties(selectedComponents) {
        this.selectedComponents = selectedComponents;
        try {
            this.clearing = true; // set this flag to short circuit the expansion listener
            this.clearRows();
        } finally {
            this.clearing = false;
        }
        if (this.selectedComponents.length === 0 || this.designer.getActiveTab() == null)
            return;
        const firstSel = this.getSelectedComponent(0);
        if (firstSel.getPropertyDefinitions == null) {
            log.info("The selected component", firstSel, "doesn't have a getPropertyDefinitions() function.");
            throw new Error("The selected component doesn't have a getPropertyDefinitions() function.  Check the console for more detailed info.");
        }
        const props = { ...firstSel.getPropertyDefinitions() };
        if (this.selectedComponents.length > 1 && props.defaultDataValue) {
            delete props.defaultDataValue;
        }
        const keys = this.getCommonProperties(props);
        const values = this.getCommonValues(keys);
        if (this.selectedComponents.length == 1 && firstSel !== this.designer.getActiveTab().designerPanel)
            keys.unshift("id");
        this.designer.filterProps(props, selectedComponents[0]);
        const cats = this.categorizeProps(keys, props);
        if (cats.default != null && Object.keys(cats.default).length > 0) {
            for (const key of Object.keys(cats.default).sort(this.idFirstSort)) {
                this.addRow({ prop_name: key, value: values[key], prop: cats.default[key] }, null, { display: true });
            }
        }
        for (const key of Object.keys(cats).sort()) {
            if (key !== "default") {
                const subRows = [];
                const subProps = cats[key];
                for (const subKey of Object.keys(subProps))
                    subRows.push({ prop_name: subKey, prop_display: props[subKey].displayName || subKey, value: values[subKey], prop: props[subKey] });
                this.addRow({ prop_name: key, value: values[key], subRows: subRows }, null, { display: true });
            }
        }
        for (const sectionName of Object.keys(this.expandedSections)) {
            if (this.expandedSections[sectionName] !== true)
                continue;
            for (const row of this.rows) {
                if (row.data["prop_name"] === sectionName) {
                    row.expanded = true;
                    break;
                }
            }
        }
    }

    redisplayProp(propName: string, value: any): TableRow {
        const selRow = this.getTableRow(propName) as any;
        const editorComponent = selRow?.editor?.editor as Component;
        if (selRow != null) {
            selRow.data.value = value;
        }
        if (editorComponent)
            editorComponent?.displayData(selRow.data, null, null);
        return selRow;
    }

    getSelectedComponent(index: number): DesignableObject {
        let result = this.selectedComponents[index] as DesignableObject;
        if (result instanceof DesignerDataSource)
            result = result.designerDataSource;
        return result;
    }

    private idFirstSort(item1: string, item2: string): number {
        if (item1 === "id")
            return -1;
        else if (item2 === "id")
            return 1;
        else
            return item1.localeCompare(item2)
    }

    private onPropRowDisplay(row: TableRow) {
        const subRows = row.data.subRows;
        if (subRows != null) {
            row._element.style.backgroundColor = getThemeColor("table.headingRowBackgroundColor");
            row._element.style.color = getThemeColor("table.headingRowColor");
        }
        if (row.data.prop != null)
            this.setPropertyCaptionProps(row);
        else
            this.setExpandRowCaptionProps(row);
    }

    syncRowCaptionStyle(propName: string, tableRow?: TableRow) {
        if (tableRow == null)
            tableRow = this.getTableRow(propName);
        const parentRow = (tableRow as any)?.parentRow ?? this.getParentRow(propName);
        if (tableRow?.populatedDOM)
            this.setPropertyCaptionProps(tableRow);
        if (parentRow?.populatedDOM)
            this.setExpandRowCaptionProps(parentRow);
    }

    private getParentRow(propName: string): TableRow {
        return this.rows.find(row => row.data.subRows?.find(sub => sub.prop_name === propName));
    }

    private setPropertyCaptionProps(row: TableRow) {
        let props = captionPropsDefault;
        if (!this.isDefaultValue(row.data.value, row.data.prop))
            props = captionPropsNotDefault;
        if (this.hasBaseVersionProp(row.data.prop_name))
            props = captionPropsModified;
        row.cells[0].setProps(props)
    }

    private setExpandRowCaptionProps(row: TableRow) {
        let props: Partial<TableCellProps> = captionPropsDefault;
        const subRows = row.data.subRows;
        if (subRows?.some(sub => !this.isDefaultValue(sub.value, sub.prop)))
            props = captionPropsNotDefault;
        if (subRows?.some(sub => this.hasBaseVersionProp(sub.prop_name)))
            props = captionPropsModified;
        row.cells[1].setProps(props);
    }

    private hasBaseVersionProp(propName: string) {
        return this.designer instanceof McLeodTailor
            && this.getSelectedComponent(0).baseVersionProps != null
            && propName in this.getSelectedComponent(0).baseVersionProps;
    }

    private isDefaultValue(value, prop: ComponentPropDefinition) {
        if (value === undefined || StringUtil.isEmptyString(value))
            return true;
        const defaultValue = this.selectedComponents[0].getPropertyDefaultValue(prop);
        if (value === false && defaultValue === undefined)
            return true;
        return value === defaultValue;
    }

    applyKeyToProp(key, prop) {
        const updRow = this.getTableRow(prop)
        const editor = this.getRowEditorTextbox(updRow);
        if (updRow != null && editor != null) {
            // This didn't work when copying updRow. Each object is copied by reference. Would need to deep clone. Could do that using JSON.parse/stringify, but doesn't work in this situation.
            // object assign is a shallow copy.
            const updRowOrigData = Object.assign({}, updRow.data);
            editor.focus();
            if (key != null) {
                editor.text = key;
                updRow.data.value = key;
                this.designer.applyChangeToSelectedComponents(updRow.data, updRow.data.value);
                this.submitTextboxCheckForPropChange(editor, updRow);
            }
        }
        else
            log.info("Null editor - trying to find this condition", this, prop, editor);
    }

    private getTableRow(propName: string, rows: TableRow[] = this.rows): TableRow {
        if (this.selectedTableRow?.data?.prop_name === propName)
            return this.selectedTableRow;

        for (const row of rows) {
            const expandComp = row.getExpansionComponent();
            if (expandComp instanceof Table)
                return this.getTableRow(propName, expandComp.rows);
            else if (row.data.prop_name === propName)
                return row;
        }
    }

    getRowData(propName: string): any {
        if (this.selectedTableRow?.data?.prop_name === propName)
            return this.selectedTableRow.data;

        for (const row of this.rows) {
            if (row.data.prop_name === propName)
                return row.data;
            if (row.data.subRows != null)
                for (const sub of row.data.subRows)
                    if (sub.prop_name === propName)
                        return sub;
        }
    }

    private setRowEditorProps(row: TableRow, props: Partial<ComponentProps>) {
        const comp = this.getRowEditorComponent(row);
        if (comp)
            comp.setProps({ ...props });
    }

    private getRowEditorTextbox(row: TableRow): Textbox {
        const editComp = this.getRowEditorComponent(row);
        if (editComp instanceof Textbox)
            return editComp;
    }

    private getRowEditorComponent(row: TableRow): Component {
        return (row as any)?.editor?.editor;
    }

    keyUp(event: KeyEvent) {
        const editor = this.getRowEditorTextbox(this.selectedTableRow);
        if (event.key === Keys.ESCAPE && editor != null) {
            this.designer.applyChangeToSelectedComponents(this.selectedTableRow.data, this.selectedTableRow.data.value);
            if (this.selectedTableRow.data.value != null)
                editor.text = this.selectedTableRow.data.value;
            else
                editor.clear();
        }
    }
}
