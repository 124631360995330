/**
 * This defines the theme properties for the basic Component and its subclasses.
 *
 * Since this is a common theme constant, nothing here should reference colors directly and should
 * only refer to palette members.
 *
 * Random note: elements here can do any of the following... (maybe need to better distiniguish which are which)
 *   - Be applied to a Component (so we are able to set any number of properties that are valid for Component and/or the subclass that is using the theme)
 *   - Be applied to a specific Component property (so we provide just the property value for a single property of Component and/or the subclass that is using the theme)
 *   - Be applied to an HTMLElement's style (so we are able to set any number of CSSStyleDeclaration members)
 *   - Be applied to a specific HTMLElement's style property (so we provide the value for a single member of CSSStyleDeclaration)
 */

import { Alignment, HorizontalAlignment, VerticalAlignment } from "../../constants/Alignment";

export const ThemeCommonComponents = {
    button: {
        hoverColor: "primary.lightest",
        focusColor: "primary.lightest",
        rippleColor: "primary.lighter",
        toggleBackgroundColor: "subtle.lighter",
    },
    checkbox: {
        caption: {},
        image: {
            checkColor: "component.palette.checkbox.color",
            minHeight: 28,
            minWidth: 28,
            padding: 4
        },
        disabled: {
            checkColor: "subtle.light"
        },
        styleHoverCircle: { // styleHoverCircle is applied to an HTMLElement's style, not a Component (so Component props are not valid)
            backgroundColor: "component.palette.checkbox.hover.backgroundColor",
            borderRadius: "50%"
        }
    },
    list: {
        selection: {
            base: "primary.light",
            reverse: "primary.reverse"
        }
    },
    label: {
        pageTitle: {
            fontBold: true,
            fontSize: "xlarge",
            color: "subtle.darker",
            fillHeight: true,
            padding: 12
        },
        sectionHeading: {
            fontBold: true,
            fontSize: "xlarge",
            color: "McLeodPrimary"
        }
    },
    numberEditor: {
        buttons: {
            color: "primary.reverse",
            backgroundColor: "primary"
        }
    },
    slideoutDecorator: {
        closeButton: {
            imageName: "x",
            variant: "round", //can't use ButtonVariant.ROUND because it's in components...we could move it...
            imageHeight: 28,
            imageWidth: 28
        },
        contentPanel: {
            fillRow: true,
            fillHeight: true,
            padding: 0
        },
        headerPanel: {
            fillRow: true,
            backgroundColor: "primary.darker",
            paddingLeft: 10,
            color: "primary.reverse"
        },
        outerPanel: {
            backgroundColor: "backgroundSubtle",
            fillRow: true,
            padding: 0,
            borderShadow: true
        },
        titleLabel: {
            imageColor: "primary.light",
            imageMarginRight: 4,
            align: HorizontalAlignment.LEFT,
            fontBold: true,
            fontSize: "large",
        }
    },
    textbox: {
        button: {
            color: "component.palette.textbox.button.color"
        },
        disabled: "component.palette.textbox.disabled",
        enabled: "component.palette.textbox.enabled",
    },
    editRowDecorator: {
        deleteButton: {
            imageName: "delete",
            color: "primary.light",
            marginRight: 16,
            variant: "round", //can't use ButtonVariant.ROUND because it's in components...we could move it...
            imageWidth: 28,
            imageHeight: 28
        },
        multiButton: {
            backgroundColor: "primary"
        },
        outerPanel: {
            paddingBottom: 8,
        },
        primaryButton: {
            width: 100
        }
    },
    snackbar: {
        backgroundColor: "component.palette.snackbar.backgroundColor",
        color: "component.palette.snackbar.color",
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 12,
        paddingBottom: 12
    },
    switch: {
        caption: {
            fontSize: "small",
            padding: 0,
            marginBottom: 4,
            allowSelect: false
        },
        captions: {
            fontSize: "small",
            padding: 0,
            allowSelect: false,
            margin: "auto"
        }
    },
    splitter: {
        divider: {
            backgroundColor: "primary.darker",
            color: "primary.reverse"
        },
        buttonContainer: {
            borderRadius: 4,
            padding: 2,
            color: "primary.reverse",
            backgroundColor: "primary.darker"
        },
        buttons: {
            imageName: "chevron",
            imageWidth: 16,
            imageHeight: 16,
            padding: 1,
            margin: 0
        }
    },
    starRating: {
        all: {
            stroke: "warning"
        },
        filledColor: "warning.light",
        unfilledColor: "transparent"
    },
    table: {
        editRow: {
            textbox: {
                backgroundColor: "component.palette.editRow.textbox.backgroundColor",
            }
        },
        headingRow: {
            backgroundColor: "component.palette.table.headingRow.backgroundColor",
            color: "component.palette.table.headingRow.color",
        },
        selectionBackground: "component.palette.table.selection.backgroundColor",
        hoverBackground: "component.palette.table.hover.backgroundColor",
        sortSelectorFieldLabel: {
            height: 24,
            paddingRight: 0,
            marginRight: 0,
            color: "McLeodSecondary"
        },
        sortSelectorImageLabel: {
            height: 24,
            padding: 0,
            marginLeft: 8,
            marginRight: 0,
            imageHeight: 16,
            imageWidth: 16,
            color: "McLeodSecondary"
        },
        sortSelectorOrderLabel: {
            padding: 0,
            color: "McLeodLinkSpecial",
            fontSize: "xxsmall"
        }
    },
    tableRow: {
        slideoutButton: {
            imageName: "pencil",
            color: "primary",
            variant: "round", //can't use ButtonVariant.ROUND because it's in components...we could move it...
            imageHeight: 18,
            imageWidth: 18,
            focusable: false,
            margin: 4,
            padding: 0
        },
        deleteButton: {
            imageName: "circleX2",
            variant: "round", //can't use ButtonVariant.ROUND because it's in components...we could move it...
            focusable: false,
            margin: 4,
            padding: 0,
            color: "subtle.darker"
        },
        dragHandler: {
            name: "hamburger",
            margin: 4,
            padding: 0,
            color: "subtle.darker",
            cursor: "grab" //can't use Cursor.GRAB because it's in components...we could move it...
        },
        quickAddButton: {
            imageProps: {
                name: "circlePlusFilled",
                stroke: "primary.reverse",
                fill: "primary"
            },
            variant: "round", //can't use ButtonVariant.ROUND because it's in components...we could move it...
            focusable: false,
            margin: 4,
            padding: 0,
            color: "subtle.darker",
        },
        expanderButtonLeft: {
            caption: "+",
            width: 18
        },
        expanderButtonRight: {
            imageName: "chevron",
            imageWidth: 35,
            imageHeight: 35,
            imageRotation: 90,
            color: "strokePrimary",
            margin: 0,
            padding: 0
        }
    },
    tabset: {
        selectedTab: {
            color: "primary",
            backgroundColor: "defaultBackground"
        },
        unselectedTab: {
            color: "subtle.darker",
            backgroundColor: "backgroundSubtle"
        }
    },
    toast: {
        backgroundColor: "primary",
        color: "primary.reverse",
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 12,
        paddingBottom: 12,
        defaultLabel: {
            margin: 0,
            padding: 0,
            fontSize: "large"
        },
        closeImage: {
            name: "x",
            height: 24,
            width: 24,
            padding: 0,
            margin: 0,
            marginLeft: 6,
            verticalAlign: VerticalAlignment.CENTER
        },
        success: {
            backgroundColor: "primary",
            color: "primary.reverse",
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 12,
            paddingBottom: 12,
            image: {
                height: 40,
                width: 40,
                color: "primary.reverse",
                marginRight: 8
            },
            titleLabel: {
                margin: 0,
                padding: 0,
                fontSize: "xxlarge"
            },
            messageLabel: {
                fontSize: "medium",
                margin: 0,
                marginTop: 6,
                padding: 0
            }
        }
    },
    clock: {
        backgroundColor: "component.palette.clock.backgroundColor",
        hand: {
            backgroundColor: "component.palette.clock.hand.backgroundColor",
        },
        selection: {
            backgroundColor: "component.palette.clock.selection.backgroundColor",
        },
        numbers: {
            color: "component.palette.clock.numbers.color",
        }
    },
    radio: {
        caption: {},
        image: {
            checkColor: "component.palette.radio.color",
            minHeight: 28,
            minWidth: 28,
            padding: 4
        },
        disabled: {
            checkColor: "subtle.light"
        },
        styleHoverCircle: { // styleHoverCircle is applied to an HTMLElement's style, not a Component (so Component props are not valid)
            backgroundColor: "component.palette.radio.hover.backgroundColor",
            borderRadius: "50%"
        }
    },
    multiSwitch: {
        backgroundColorSelected: "primary",
        backgroundColorUnselected: "transparent",
        colorSelected: "primary.reverse",
        colorUnselected: "subtle.darker",
        panel: {
            borderRadius: 4, 
            borderWidth: 1, 
            borderColor: "subtle.darker", 
            padding: 4, 
            backgroundColor: "background4"
        },
        caption: {
            fontSize: "small", 
            padding: 0, 
            marginBottom: 4
        },
        item: {
            padding: 12,
            borderRadius: 4
        }
    }
}
