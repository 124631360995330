import { Collection, Color, JSUtil, ModelRow, StringUtil, getThemeForKey } from "@mcleod/core";
import { Component, DataSourceMode, PermissionsDefinition, PrintableEvent, PrintableListener } from "../..";
import { Captioned } from "../../base/CaptionedComponent";
import { getCurrentDataSourceMode, getRelevantModelRow } from "../../base/ComponentDataLink";
import { ComponentTypes } from "../../base/ComponentTypes";
import { ListenerListDef } from "../../base/ListenerListDef";
import { Printable, printableListenerDef } from "../../base/PrintableComponent";
import { Image } from "../../components/image/Image";
import { ChangeEvent, ChangeListener } from "../../events/ChangeEvent";
import { Event } from "../../events/Event";
import { KeyEvent } from "../../events/KeyEvent";
import { Label } from "../label/Label";
import { CheckboxPropDefinitions, CheckboxProps } from "./CheckboxProps";
import { CheckboxStyles } from "./CheckboxStyles";

const _changeListenerDef: ListenerListDef = { listName: "_changeListeners" };

export class Checkbox extends Component implements CheckboxProps {
    private _image: Image;
    private _captionLabel: Label;
    private _printLabel: Label;
    private _checkColor: Color;
    public valueChecked: any = "Y";
    public valueUnchecked: any = "N";
    private _allowIndeterminate: boolean = false;
    private _checked: boolean;
    private _enabledCheckColor: Color;
    private _focusable: boolean;
    private _defaultDataValue: boolean;

    constructor(props?: Partial<CheckboxProps>) {
        super("div", props);
        this._captionLabel = new Label({ themeKey: "checkbox.caption", paddingLeft: 0, marginLeft: 3 });
        this._captionLabel.addClickListener(event => {
            this.userClicked(event);
            if (this.focusable && !this.printable) {
                this.focus();
            }
            if (this._designer != null)
                event.shouldAutomaticallyStopPropagation = false;
        });
        this._captionLabel.setClassIncluded(CheckboxStyles.captionLabel);
        this._element.classList.add(CheckboxStyles.checkBase);
        this._element.appendChild(this._captionLabel._element);
        this._addChild();
        this.checked = false;
        this.setProps(props);
    }

    override get defaultDataValue(): boolean {
        return this._defaultDataValue;
    }

    public set defaultDataValue(value: boolean) {
        this._defaultDataValue = value;
    }

    override setProps(props: Partial<CheckboxProps>) {
        super.setProps(props);
    }

    override getFocusTarget(): HTMLElement {
        return this._image?._element;
    }

    override displayData(data: ModelRow, allData: ModelRow[], rowIndex: number) {
        if (data == null || this.field == null)
            this.checked = false;
        else {
            let value = (data instanceof ModelRow) ? data.get(this.field) : data[this.field];
            if (typeof value === "string") {
                if (this.valueChecked == null)
                    value = value === "Y" || value === "true";
                else
                    value = value === this.valueChecked;
            }
            if (this._allowIndeterminate)
                this.checked = value;
            else
                this.checked = value === true;
        }
    }

    get caption(): string {
        return this["_mixin-Captioned-caption"];
    }

    set caption(value: string) {
        if (this["captionValueMatches"](value) === true) {
            return;
        }
        this["_mixin-Captioned-caption"] = value;
        this.syncCaption();
    }

    syncCaption() {
        this._captionLabel.visible = !StringUtil.isEmptyString(this.caption);
        if (this.printable === true) {
            this._captionLabel.caption = this._captionLabel.caption + ":";
        } else {
            this._captionLabel.caption = this["getPrefixedCaption"]();
        }
    }


    private userClicked(event: Event) {
        if (this.enabled && this._designer == null)
            this.userToggled(event);
    }

    protected userToggled(event: Event) {
        if (this._allowIndeterminate && this.checked === false)
            this.checked = undefined;
        else
            this._internalSetChecked(!this.checked, event);
    }

    public toggleChecked(): boolean {
        this.checked = !this.checked;
        return this.checked;
    }

    get checked() {
        return this._checked;
    }

    set checked(value: boolean) {
        this._internalSetChecked(value, null);
    }

    get checkSize(): number {
        return this._image?.minHeight as number;
    }

    set checkSize(value: number) {
        this._image.minHeight = value;
        this._image.minWidth = value;
    }

    get valueAsString(): string {
        return this.checked.toString();
    }

    set valueAsString(value: string) {
        this.checked = value === "true";
    }

    private _internalSetChecked(value: boolean, originatingEvent: Event) {
        if (this._checked === value)
            return;
        this._checked = value;
        this._internalUpdateBoundData();
        this.storeUserChoiceIfRemembered();
        const changeEvent = new ChangeEvent(this, !value, value, originatingEvent == null ? null : originatingEvent.domEvent);
        this.fireListeners(_changeListenerDef, changeEvent);
        this._displayValue();
    }

    get checkColor() {
        return this._checkColor;
    }

    set checkColor(value) {
        this._checkColor = value;
        this._addChild();
        if (this._image != null)
            this._image.color = value || "primary";
    }

    get focusable(): boolean {
        return this._focusable != null ? this._focusable : true;
    }

    set focusable(value: boolean) {
        this._focusable = value;
        this._image._element.setAttribute("tabindex", value === true ? "0" : "-1");
    }

    override _applyEnabled(value: boolean): void {
        if (this._enabledCheckColor == null &&
            (this._image != null && this._image._element.classList.contains(CheckboxStyles.imageDisabled) !== true)) {
            this._enabledCheckColor = this.checkColor;
        }
        if (value && this._image != null) {
            this._image._element.removeAttribute("disabled");
            this._image._element.setAttribute("tabindex", this.focusable === true ? "0" : "-1");
            this._image._element.classList.remove(CheckboxStyles.imageDisabled);
            this._image._element.classList.add(CheckboxStyles.image);
            this.checkColor = this._enabledCheckColor;
        }
        else {
            if (this._image != null) {
                this._image._element.setAttribute("disabled", "true");
                this._image._element.setAttribute("tabindex", "-1");
                this._image._element.classList.remove(CheckboxStyles.image);
                this._image._element.classList.add(CheckboxStyles.imageDisabled);
                this.setProps({ ...getThemeForKey("checkbox.disabled") });
            }
        }
    }

    get printable(): boolean {
        return this["_mixin-Printable-printable"];
    }

    set printable(value: boolean) {
        this["_mixin-Printable-printable"] = value;
    }

    get printableDuringAdd(): boolean {
        return this["_mixin-Printable-printableDuringAdd"];
    }

    set printableDuringAdd(value: boolean) {
        this["_mixin-Printable-printableDuringAdd"] = value;
    }

    get printableDuringSearch(): boolean {
        return this["_mixin-Printable-printableDuringSearch"];
    }

    set printableDuringSearch(value: boolean) {
        this["_mixin-Printable-printableDuringSearch"] = value;
    }

    get printableDuringUpdate(): boolean {
        return this["_mixin-Printable-printableDuringUpdate"];
    }

    set printableDuringUpdate(value: boolean) {
        this["_mixin-Printable-printableDuringUpdate"] = value;
    }

    get allowIndeterminate(): boolean {
        return this._allowIndeterminate;
    }

    set allowIndeterminate(value: boolean) {
        this._allowIndeterminate = value;
    }

    private _addChild() {
        if (this.printable === true) {
            if (this._image != null)
                this._element.removeChild(this._image._element);
            this._image = null;
            this._captionLabel.caption = this.caption + ":";
            this._addPrintableLabel();
            this._displayValue();
        } else {
            let reattachListeners = false;
            this._captionLabel.caption = this.caption;
            if (this._printLabel != null) {
                this._element.removeChild(this._printLabel._element);
                this._printLabel = null;
                reattachListeners = true;
            }
            this._addImage(reattachListeners);
        }
    }

    private _addPrintableLabel() {
        if (this._printLabel == null) {
            this._printLabel = new Label();
            this._element.appendChild(this._printLabel._element);
        }
    }

    public override getEventTarget(): HTMLElement {
        return this._image?._element;
    }

    private _addImage(reattachListeners: boolean = false) {
        if (this._image == null) {
            this._image = new Image({ themeKey: "checkbox.image", marginRight: 0 });
            if (this.checkColor != null)
                this._image.color = this.checkColor;
            this._image._element.tabIndex = 0;
            this._image._element.classList.add(CheckboxStyles.image);
            this._syncEnabled();

            if (reattachListeners === true)
                this.reattachListeners();
            this._image.addClickListener(event => {
                this.userClicked(event);
                if (this._designer != null)
                    event.shouldAutomaticallyStopPropagation = false;
            });
            this._image.addKeyDownListener(event => this._imageKeyPressed(event));
            this._displayValue();
            if (this._element.contains(this._captionLabel._element))
                this._element.insertBefore(this._image._element, this._captionLabel._element);
            else
                this._element.appendChild(this._image._element);
        }
    }

    private _imageKeyPressed(event: KeyEvent) {
        if (event.key === ' ' && this.enabled && this.__designer == null) {
            this.userToggled(event);
            event.consume();
        }
    }

    private _displayValue() {
        this.setElementStyleForAutomation();
        if (this._printLabel != null) {
            if (this.checked === undefined)
                this._printLabel.caption = "Unknown";
            else
                this._printLabel.caption = this.checked ? "Yes" : "No";
        }
        else if (this._image != null) {
            if (this.checked === undefined)
                this._image.name = "checkboxIndeterminate";
            else
                this._image.name = this.checked ? "checkboxChecked" : "checkboxUnchecked";
        }
    }

    private setElementStyleForAutomation() {
        if (this.checked === undefined) {
            this._element.classList.remove(CheckboxStyles.checked);
            this._element.classList.remove(CheckboxStyles.unchecked);
            this._element.classList.add(CheckboxStyles.indeterminate);
        }
        else if (this.checked) {
            this._element.classList.remove(CheckboxStyles.unchecked);
            this._element.classList.remove(CheckboxStyles.indeterminate);
            this._element.classList.add(CheckboxStyles.checked);
        }
        else {
            this._element.classList.remove(CheckboxStyles.checked);
            this._element.classList.remove(CheckboxStyles.indeterminate);
            this._element.classList.add(CheckboxStyles.unchecked);
        }
    }

    addChangeListener(value: ChangeListener): Checkbox {
        return this.addEventListener(_changeListenerDef, value) as Checkbox;
    }

    removeChangeListener(value: ChangeListener) {
        return this.removeEventListener(_changeListenerDef, value) as Checkbox;
    }

    public addPrintableListener(value: PrintableListener) {
        this.addEventListener(printableListenerDef, value);
    }

    public removePrintableListener(value: PrintableListener) {
        this.removeEventListener(printableListenerDef, value);
    }

    protected _getDefaultEventProp(): string {
        return "onChange";
    }

    private _internalUpdateBoundData() {
        const row = getRelevantModelRow(this);
        const mode = getCurrentDataSourceMode(this);
        this.updateBoundData(row, mode);
    }

    override updateBoundData(row: ModelRow, mode: DataSourceMode) {
        if (this.field != null) {
            if (this.checked == true)
                row?.set(this.field, this.valueChecked, this);
            else if (this.checked === false)
                row?.set(this.field, this.valueUnchecked, this);
            else
                row?.set(this.field, undefined, this);
        }
    }

    override dataSourceModeChanged(mode: DataSourceMode) {
        super.dataSourceModeChanged(mode);
        if (mode === DataSourceMode.SEARCH) {
            this._allowIndeterminate = true;
            this.checked = undefined;
        }
        else
            this._allowIndeterminate = false;
        this["_syncPrintable"]();
    }

    protected _applyPrintable(value: boolean) {
        this._addChild();
        this.fireListeners(printableListenerDef, new PrintableEvent(this, this._printLabel));
    }

    override getPropertyDefinitions() {
        return CheckboxPropDefinitions.getDefinitions();
    }

    override getSearchValues(): string[] {
        const result = [];
        result.push(this.checked ? "Yes" : "No");
        return result;
    }

    removeLabel() {
        this._element.removeChild(this._captionLabel._element);
    }

    override get serializationName() {
        return "checkbox";
    }

    override get properName(): string {
        return "Checkbox";
    }

    override getListenerDefs(): Collection<ListenerListDef> {
        return {
            ...super.getListenerDefs(),
            "change": { ..._changeListenerDef },
            "printable": { ...printableListenerDef}
        };
    }

    override getBasicValue(): any {
        return this.checked;
    }

    getPermissionsTypes(): PermissionsDefinition[] {
        return [
            ...super.getPermissionsTypes(),
            {
                permsType: "E",
                description: "Edit security",
                availableToAllDescription: "Everyone can edit this item",
                availableToNoneDescription: "This item is read-only to everyone"
            }
        ];
    }
}

JSUtil.applyMixins(Checkbox, [Captioned, Printable]);
ComponentTypes.registerComponentType("checkbox", Checkbox.prototype.constructor);
