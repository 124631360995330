import { getLogger, Head, MapSettings } from "@mcleod/core";
import { getSVGUrl } from "@mcleod/images";
import TrimbleMaps, { Marker } from "@trimblemaps/trimblemaps-js";
import MapMenus from "@trimblemaps/trimblemaps-mapmenus";
import { Panel } from "../panel/Panel";
import { Map, VendorMap } from "./Map";
import { MapPin } from "./MapPin";
import { MapVendor, VendorMapProps } from "./MapProps";
import { ReflectiveSnackbars } from "../../base/ReflectiveSnackbars";

const log = getLogger("components/Map");
const defaultZoom = 3;

export class TrimbleMap extends Panel implements VendorMap {
    map: TrimbleMaps.Map;
    parentMap: Map;
    route: TrimbleMaps.Route;
    markers: TrimbleMaps.Marker[];

    constructor(props?: Partial<VendorMapProps>) {
        super({ borderRadius: 4, ...props });
        if (props.parentMap == null)
            throw new Error("TrimbleMap needs to have its parentMap property set.");
        this.markers = [];
        this.parentMap.vendor = MapVendor.TRIMBLE;
    }

    async init() {
        try {
            TrimbleMaps.APIKey = MapSettings.getSingleton().getTrimbleApiKey();
        }
        catch (error) {
            log.info(error);
        }
        if (TrimbleMaps.APIKey) {
            this.initHeader();
        }
        else {
            ReflectiveSnackbars.showSnackbar("No API key found for Trimble. Map cannot be shown");
        }
    }

    private initHeader(): void {
        const cssUrl = "https://maps-sdk.trimblemaps.com/v3/trimblemaps-3.11.1.css";
        Head.removeLink(cssUrl);
        const link = Head.addLink(cssUrl);
        const linkPromise = new Promise<void>((resolve, reject) => {
            link.onload = () => {
                log.debug("Trimble CSS was initialized successfully");
                resolve();
            };

            link.onerror = () => {
                log.debug("An error occurred during css initialization");
                reject();
            };
        });

        const menuCSSurl = "https://maps-sdk.trimblemaps.com/addon/trimblemaps-mapmenus-1.0.6.css";
        Head.removeLink(menuCSSurl);
        const menuLink = Head.addLink(menuCSSurl);
        const menuLinkPromise = new Promise<void>((resolve, reject) => {
            menuLink.onload = () => {
                log.debug("Trimble Menu CSS was initialized successfully");
                resolve();
            };

            menuLink.onerror = () => {
                log.debug("An error occurred during menu css initialization");
                reject();
            };
        });

        const jsUrl = "https://maps-sdk.trimblemaps.com/v3/trimblemaps-3.11.1.js";
        Head.removeScript(jsUrl);
        const script = Head.addScript(jsUrl);
        const scriptPromise = new Promise<void>((resolve, reject) => {
            script.onload = () => {
                log.debug("Map JS initialized successfully");
                resolve();
            };

            script.onerror = () => {
                log.debug("An error occurred during map initialization");
                reject();
            };
        });

        Promise.all([linkPromise, scriptPromise, menuLinkPromise]).then(r => this.initMap());
    }

    private initMap() {
        this.map = new TrimbleMaps.Map({
            container: this._element,
            center: new TrimbleMaps.LngLat(-96, 35),
            zoom: 3
        });

        this.map.addControl(new TrimbleMaps.NavigationControl());
        const scale = new TrimbleMaps.ScaleControl({
            maxWidth: 80,
            unit: 'imperial'
        });

        const addLegendsToMap = new MapMenus({
            hideStyles: ['Satellite']
        });
        this.map.addControl(addLegendsToMap, "top-left");
        this.map.addControl(scale);
        this.map.resize();
    }

    createRoute(routeData: any[]) {
        const data = this.formatTrimbleRouteData(routeData);
        if (this.route) {
            this.route.remove();
        }
        this.route = new TrimbleMaps.Route({
            routeId: "myRoute",
            stops: data
        });
        this.route.addTo(this.map);
    }

    setStopZoomLevel(isTrafficEnabled: string, routeData: any[]) {
        const data = this.formatTrimbleRouteData(routeData);
        const zoomInToOriginStop = data[0];
        const zoomToTrafficLevel = 10;
        this.map.setZoom(isTrafficEnabled === "Y" ? zoomToTrafficLevel : defaultZoom);
        this.map.setCenter(new TrimbleMaps.LngLat(zoomInToOriginStop.lng, zoomInToOriginStop.lat));
        this.map.setTrafficVisibility(true);
        this.route.update({ frameRoute: false });
    }

    clearRoute() {
        if (this.route) {
            this.route.remove();
            this.map.setZoom(defaultZoom);
            this.map.setCenter(new TrimbleMaps.LngLat(-96, 35));
        }
    }

    private formatTrimbleRouteData(routeData: any[]): TrimbleMaps.LngLat[] {
        const coordinatesList = [];
        routeData.forEach(stop => {
            const lat = Number(stop.latitude);
            const lng = Number(Math.abs(stop.longitude) * -1);
            coordinatesList.push(new TrimbleMaps.LngLat(lng, lat));
        });
        return coordinatesList;
    }

    removeAllPins() {
        if (this.markers != null)
            for (const marker of this.markers)
                marker.remove();
        this.markers = [];
    }

    addPin(pin: MapPin) {
        if (this.map == null)
            return;

        const el = document.createElement('div');
        el.classList.add('cust-marker');

        if (pin.caption == null) {
            pin.caption = "";
        }

        pin.caption = pin.caption + "  "; // Adding padding so the text can be centered in the marker

        if (pin.data?._modelPath.includes("location")) {
            pin.tooltip = pin.data.data?.id + " - " + pin.data.data?.name + "\n"
                + pin.data.data?.address1 + "\n"
                + pin.data.data?.city_name + ", " + pin.data.data?.state + " " + pin.data.data?.zip_code;
            pin.image = "map-pin-location";
            pin.layoutName = "lme/dispatch/LocationMapDetail";
        }

        el.title = pin.tooltip;

        const htmlContent =
            '<div  id="image" style="background: url(' + getSVGUrl(pin.image) + ') no-repeat; height: 36px; width: 36px;"/>' +
            '<div><pre style="color:' + pin.captionColor + '">' + '<h4 style="text-align:center">' + pin.caption + '</h4></pre></div>';

        el.innerHTML = htmlContent;

        const marker = new Marker({ anchor: "bottom", element: el });
        marker.setLngLat({ lat: pin.latitude, lon: pin.longitude });
        marker.addTo(this.map);

        this.markers.push(marker);
        if (this.parentMap.pinNeedsClickListener(pin))
            el.addEventListener('click', (event) => this.parentMap.pinClicked(pin, event));

    }

    focusOnPin(pin: MapPin, mapPinIndex:number)  {

            const htmlContent =
            '<div  id="image" style="background: url(' + getSVGUrl("map-pin-location-blue") + ') no-repeat; height: 36px; width: 36px;"/>' +
            '<div><pre style="color:' + pin.captionColor + '">' + '<h4 style="text-align:center">' + pin.caption + '</h4></pre></div>';

        if (this.markers != null)
            this.markers[mapPinIndex].getElement().innerHTML = htmlContent;

    }

    defocusOnPin(pin: MapPin, mapPinIndex:number)  {

        const htmlContent =
            '<div  id="image" style="background: url(' + getSVGUrl(pin.image) + ') no-repeat; height: 36px; width: 36px;"/>' +
            '<div><pre style="color:' + pin.captionColor + '">' + '<h4 style="text-align:center">' + pin.caption + '</h4></pre></div>';

    if (this.markers != null)
        this.markers[mapPinIndex].getElement().innerHTML = htmlContent;

}

    fitPins() {
        if (this.map == null)
            return;
        this.map.resize();

        this.setCenter(null, null);
        this.zoomAll();
    }

    zoomAll() {
        if (this.markers.length == 1) {
            this.setZoom(10);
        }
        else if (this.markers.length > 1) {
            const latLngMax = { lng: (-1) * this.getLngMax(), lat: this.getLatMax() };
            const latLngMin = { lng: (-1) * this.getLngMin(), lat: this.getLatMin() };
            const bounds = this.map.getBounds();
            bounds.setNorthEast(latLngMin);
            bounds.setSouthWest(latLngMax);
            this.map.fitBounds(bounds, { padding: 50 });

            if (this.map.getZoom() > 10)
                this.setZoom(10);
        }
    }

    setZoom(level: number) {
        if (this.map != null)
            this.map.zoomTo(level);
    }

    setCenter(latitude: number, longitude: number) {
        if (this.markers.length == 0)
            return;
        latitude = latitude ?? (this.getLatMax() + this.getLatMin()) / 2;
        longitude = (-1) * ( longitude ??  (this.getLngMax() + this.getLngMin()) / 2);
        this.map.setCenter(new TrimbleMaps.LngLat(longitude, latitude));
    }

    getLatMax() {
        let latMax = 0;
        for (const marker of this.markers) {
            if (latMax < marker.getLngLat().lat)
                latMax = marker.getLngLat().lat;
        }
        return (latMax);
    }
    getLatMin() {
        let latMin = 500;
        for (const marker of this.markers) {
            if (latMin > marker.getLngLat().lat)
                latMin = marker.getLngLat().lat;
        }
        return (latMin);
    }
    getLngMax() {
        let lngMax = 0;
        for (const marker of this.markers) {
            if (lngMax < (-1) * marker.getLngLat().lng)
                lngMax = (-1) * marker.getLngLat().lng;
        }
        return (lngMax);
    }
    getLngMin() {
        let lngMin = 500;
        for (const marker of this.markers) {
            if (lngMin > (-1) * marker.getLngLat().lng)
                lngMin = (-1) * marker.getLngLat().lng;
        }
        return (lngMin);
    }

}
