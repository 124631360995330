import { getLogger } from "../Logger";
import { ObjectUtil } from "../ObjectUtil";

const log = getLogger("core/settings/LocalUserSettings");

export class LocalUserSettings {
    /**
     * The set() and getXXXX() methods are used to persists and retrieve user settings.
     * For now, they are written to local storage.  In the future, we may decide to persist them remotely,
     * but this abstraction gives us the ability to change that (or maybe even make it variable whether
     * this is persisted locally or remotely.)
     * @param key
     * @returns
     */
    public static getString(key: string): string {
        return localStorage.getItem(key);
    }

    public static getBoolean(key: string, valueIfNull = false): boolean {
        const value = localStorage.getItem(key);
        if (value === "true")
            return true;
        else if (value === "false")
            return false;
        return valueIfNull;
    }

    public static getNumber(key: string, valueIfNull = 0): number {
        const value = localStorage.getItem(key);
        if (value == null)
            return valueIfNull;
        const result = parseInt(value);
        if (isNaN(result))
            return valueIfNull;
        return result;
    }

    public static getObject(key: string, valueIfNull = null): Record<string, unknown> {
        const value = localStorage.getItem(key);
        if (value == null)
            return valueIfNull;
        try {
            return JSON.parse(value);
        } catch (e) {
            log.error(`Failed to parse JSON for key ${key}:`, e);
            return valueIfNull;
        }
    }

    public static set(key: string, value: number | boolean | string | Record<string, unknown>): boolean {
        try {
            if (ObjectUtil.isEmptyObject(value)) {
                localStorage.removeItem(key);
            }
            else {
                const stringValue = typeof value === 'object' ? JSON.stringify(value) : value.toString();
                localStorage.setItem(key, stringValue);
            }
        } catch (e) {
            log.error("Failed to save user setting:", e);
            return false;
        }
        return true;
    }
}
