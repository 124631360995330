import { getLogger, ModelRow, StringUtil } from "@mcleod/core";
import { ComponentSearcher, ComponentSearchResult } from "./ComponentSearcher";
import { SingleFieldFilter } from "./SingleFieldFilter";
import { Table } from "./Table";
import { TableRowSearchUtil } from "./TableRowSearchUtil";

const log = getLogger("components.table.TableRowStringSearcher");

export class TableRowStringSearcher {
    private componentSearchers: ComponentSearcher[] = [];

    constructor(table: Table) {
        for (const col of table._columns)
            if (col.cellDef?.def != null)
                this.addComponentSearchers(col.cellDef.def, col.headingCell.displayLabel || col.headingCell.caption);
        if (table.addlSearcherCallback != null) {
            const extraSearchers: ComponentSearcher[] = table.addlSearcherCallback();
            this.componentSearchers.push(...extraSearchers);
        }
    }

    private addComponentSearchers(def: any, columnHeadingCaption?: string) {
        if (def.field != null)
            this.componentSearchers.push(...this.getSearchersForComponent(def, columnHeadingCaption));
        if (def.components != null) {
            for (const comp of def.components) {
                this.addComponentSearchers(comp, def.components.length === 1 ? columnHeadingCaption : null);
            }
        }
    }

    private getSearchersForComponent(def: any, providedAlias?: string): ComponentSearcher[] {
        const result: ComponentSearcher[] = [];
        const typeAsString = def.type + "";
        switch (typeAsString) {
            //need to do a better job about gathering these searchers from compound components.
            //could share logic with how we setup table column sorting for compound components.
            case "attachment":
                result.push(new ComponentSearcher(def.fileNameField, "filename"));
                break;
            case "citystate":
                //result.push(new ComponentSearcher(def.field, this.getSearchAliasFromDef(def, "cityId")));
                result.push(new ComponentSearcher(def.cityField, this.getSearchAliasFromDef(def)));
                result.push(new ComponentSearcher(def.stateField, this.getSearchAliasFromDef(def)));
                if (def.zipField != null)
                    result.push(new ComponentSearcher(def.zipField, this.getSearchAliasFromDef(def, "zip")));
                break;
            case "location":
                result.push(new ComponentSearcher(def.addressField, this.getSearchAliasFromDef(def)));
                //result.push(new ComponentSearcher(def.cityIdField, this.getSearchAliasFromDef(def, "cityId")));
                result.push(new ComponentSearcher(def.cityField, this.getSearchAliasFromDef(def)));
                result.push(new ComponentSearcher(def.locationIdField, this.getSearchAliasFromDef(def)));
                result.push(new ComponentSearcher(def.locationNameField, this.getSearchAliasFromDef(def)));
                result.push(new ComponentSearcher(def.latitudeField, this.getSearchAliasFromDef(def)));
                result.push(new ComponentSearcher(def.longitudeField, this.getSearchAliasFromDef(def)));
                result.push(new ComponentSearcher(def.stateField, this.getSearchAliasFromDef(def)));
                if (def.zipField != null)
                    result.push(new ComponentSearcher(def.zipField, this.getSearchAliasFromDef(def, "zip")));
                break;
            default:
                result.push(new ComponentSearcher(def.field, this.getSearchAliasFromDef(def, providedAlias)));
                break;
        }
        return result;
    }

    private getSearchAliasFromDef(def: any, defaultAlias?: string): string {
        if (StringUtil.isEmptyString(def.displayLabel) === false)
            return def.displayLabel;
        if (def.type !== "label" && StringUtil.isEmptyString(def.caption) === false)
            def.caption;
        return defaultAlias;
    }

    public rowContains(row: ModelRow, filterString: string): boolean {
        log.debug("Running table search with filter string %s", filterString);
        const singleFieldFilters = TableRowSearchUtil.parseFilterValue(filterString);
        log.debug("Parsed single-field filters %o", singleFieldFilters);
        FILTERS:
        for (const singleFieldFilter of singleFieldFilters) {
            if (StringUtil.isEmptyString(singleFieldFilter.searchValues.length === 0)) {
                singleFieldFilter.result = true;
                continue FILTERS;
            }
            for (const searchValue of singleFieldFilter.searchValues) {
                for (const singleComponentSearcher of this.componentSearchers) {
                    const searchResult: ComponentSearchResult = singleComponentSearcher.search(row);
                    if (this.searchResultMatchesSingleFieldFilter(searchResult, singleFieldFilter) === true) {
                        singleFieldFilter.result = true;
                        continue FILTERS;
                    }
                }
            }
            singleFieldFilter.result = false;
        }
        const result = TableRowSearchUtil.getOverallResult(singleFieldFilters);
        log.debug("Done evaluating filters, overall result %o", result);
        log.debug("Single-field filter results: %o", singleFieldFilters);
        return result;
    }

    private searchResultMatchesSingleFieldFilter(searchResult: ComponentSearchResult, singleFieldFilter: SingleFieldFilter): boolean {
        return this.searchResultIsForSingleFieldFilter(searchResult, singleFieldFilter) === true &&
            this.searchResultContainsValue(searchResult, singleFieldFilter) === true;
    }

    private searchResultIsForSingleFieldFilter(searchResult: ComponentSearchResult, singleFieldFilter: SingleFieldFilter): boolean {
        const filterFieldNameOrAlias = singleFieldFilter.fieldNameOrAlias?.toLowerCase();
        //if single field filter doesn't specify what field should be searched, return true, because we can use any component's searcher
        if (StringUtil.isEmptyString(filterFieldNameOrAlias) === true)
            return true;
        //otherwise, make sure the single field filter wants to use this component's searcher
        //this could be based on the alias or the field name
        const searchResultFieldName = searchResult.fieldName.toLowerCase();
        const searchResultAlias = searchResult.alias?.toLowerCase();
        return (StringUtil.isEmptyString(searchResultAlias) === true && TableRowSearchUtil.getSimpleFieldName(searchResultFieldName) === filterFieldNameOrAlias) ||
            (StringUtil.isEmptyString(searchResultAlias) !== true && (searchResultAlias === filterFieldNameOrAlias || searchResultFieldName === filterFieldNameOrAlias));
    }

    private searchResultContainsValue(searchResult: ComponentSearchResult, singleFieldFilter: SingleFieldFilter): boolean {
        if (searchResult.value == null)
            return false;
        for (const singleFieldValue of singleFieldFilter.searchValues) {
            const filterValue = singleFieldValue.toLowerCase();
            let contains = false;
            if (typeof searchResult.value === "string") {
                contains = searchResult.value.toLowerCase().includes(filterValue);
                if (!contains) {
                    continue;
                }
                return true;
            }
            for (const value of searchResult.value) {
                if (value?.toLowerCase().includes(filterValue))
                    return true;
            }
        }
        return false;
    }
}
