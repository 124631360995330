import { Button, Checkbox, DataSource, HorizontalSpacer, Image, Label, Layout, Panel, SaveButton, Switch, Tab, Table, Tabset, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutUserSettings extends Layout {
    buttonAuthorize: Button;
    buttonClearEvenColor: Button;
    buttonClearOddColor: Button;
    buttonEvenColor: Button;
    buttonOddColor: Button;
    buttonSave: SaveButton;
    checkboxAllowNewDevices: Checkbox;
    checkboxSelTextOnFocus: Checkbox;
    horizontalspacer2: HorizontalSpacer;
    labelDATResult: Label;
    labelDevices: Label;
    labelEvenColorCaption: Label;
    labelOddColorCaption: Label;
    labelSample: Label;
    labelSampleDateTime: Label;
    labelTruckstopResult: Label;
    labelUserSettings: Label;
    panelDATFields: Panel;
    panelEvenColorDisplay: Panel;
    panelEvenColorOuter: Panel;
    panelOddColorDisplay: Panel;
    panelOddColorOuter: Panel;
    panelSampleDateTime: Panel;
    panelTruckstopFields: Panel;
    sourceCurrentUserAsset: DataSource;
    sourceCurrentUserPowerbroker: DataSource;
    sourcePbwLoadboardServices: DataSource;
    sourceSettings: DataSource;
    sourceUserRecognizedDevice: DataSource;
    sourceUsers: DataSource;
    switchGoogleMapLayer: Switch;
    switchGoogleShowDisclaimer: Switch;
    switchTrimbleMapTraffic: Switch;
    tabGeneral: Tab;
    tabLoadboardServices: Tab;
    tabLogin: Tab;
    tabOperations: Tab;
    tabTemplates: Tab;
    tableUserDevices: Table;
    tabsetUsers: Tabset;
    textDateFormat: Textbox;
    textTheme: Textbox;
    textTimeFormat: Textbox;
    textboxAssetOrderNextAction: Textbox;
    textboxBpProfile: Textbox;
    textboxBrokerageOrderNextAction: Textbox;
    textboxCellPhone: Textbox;
    textboxConfTemplate: Textbox;
    textboxDATService: Textbox;
    textboxDatPassword: Textbox;
    textboxDatUserId: Textbox;
    textboxDefaultDispatchLayout: Textbox;
    textboxDefaultDistanceMarginPercentage: Textbox;
    textboxDefaultWirecode: Textbox;
    textboxDriverManagerProfile: Textbox;
    textboxEmailAddress: Textbox;
    textboxExtension: Textbox;
    textboxGoogleMapTypes: Textbox;
    textboxLtlQuoteTemplate: Textbox;
    textboxMessageVisibility: Textbox;
    textboxOperationsUserId: Textbox;
    textboxOrderPlanningProfile: Textbox;
    textboxPhone: Textbox;
    textboxQuoteTemplate: Textbox;
    textboxRevenueCodeId: Textbox;
    textboxSegAllocCode: Textbox;
    textboxSmsMessageLimit: Textbox;
    textboxTmProfile: Textbox;
    textboxTruckstopPassword: Textbox;
    textboxTruckstopRESTService: Textbox;
    textboxTruckstopUserId: Textbox;
    titleImage: Image;
    titlePanel: Panel;
}
