import { ArrayUtil, Collection, Color, Keys, StringUtil, getLogger, getThemeColor, makeStyles } from "@mcleod/core";
import { ClickEvent, ListItem, MouseEvent } from "../..";
import { BorderType } from "../../base/BorderType";
import { Component } from "../../base/Component";
import { ComponentTypes } from "../../base/ComponentTypes";
import { ListenerListDef } from "../../base/ListenerListDef";
import { SelectionMode } from "../../base/SelectionMode";
import { DomEvent } from "../../events/DomEvent";
import { KeyEvent } from "../../events/KeyEvent";
import { SelectionEvent, SelectionListener } from "../../events/SelectionEvent";
import { StringOrPropsOrComponent } from "../../page/getComponentFromStringOrPropsOrComponent";
import { Panel } from "../panel/Panel";
import { ListPropDefinitions, ListProps } from "./ListProps";
import { ListType } from "./ListType";

const _changeListenerDef: ListenerListDef = { listName: "_changeListeners" };
const handledKeys = [Keys.ARROW_DOWN, Keys.ARROW_UP, Keys.PAGE_DOWN, Keys.PAGE_UP, Keys.HOME, Keys.END];
const classes = makeStyles("list", {
    root: {
        outline: "none"
    }
});

const log = getLogger("components.list.List");
export type ListItemType = (StringOrPropsOrComponent | ListItem);
type ItemFilter = (item: ListItem) => boolean;

export class List extends Component implements ListProps {
    public static SEPARATOR = "__list_separator";
    private _allItems: ListItem[] = [];
    private _displayedItems: ListItem[] = [];
    private _itemsCreator: (list: List) => ListItemType[];
    private _itemFilter: ItemFilter;
    private _selectedIndexes: number[];
    private _scrollY: boolean;
    private selectedBackgroundColor: Color = "list.selection.base";
    private selectedColor: Color = "list.selection.reverse";
    private _type: ListType;
    private _selectionMode: SelectionMode;
    private itemDefaultClickListener = (event: ClickEvent) => this.defaultItemOnClickListener(event);
    private itemMouseEnterListener = (event: MouseEvent) => this.itemOnMouseEnterListener(event);
    private itemMouseLeaveListener = (event: MouseEvent) => this.itemOnMouseLeaveListener(event);

    constructor(props: Partial<ListProps>) {
        super("div", props);
        this._element.classList.add(classes.root);
        this._element.tabIndex = 0;
        this._selectedIndexes = [];
        this.setProps(props);
        this._element.onkeydown = (event: KeyboardEvent) => this._internalKeydown(event);
        if (props == null || props.scrollY === undefined)
            this.scrollY = true;
        this.backgroundColor ??= getThemeColor("defaultBackground")
        this.addMountListener(() => this.scrollToSelection());
        this.addUnmountListener(() => this.cleanupSuppliedComponents());
    }

    setProps(props: Partial<ListProps>) {
        super.setProps(props);
    }

    get type(): ListType {
        return this._type ?? this.getPropertyDefinitions().type.defaultValue;
    }

    set type(value: ListType) {
        this._type = value;
    }

    protected isPopupType(): boolean {
        return this.type === ListType.POPUP;
    }

    protected isPersistentType(): boolean {
        return this.type === ListType.PERSISTENT;
    }

    private _internalKeydown(event: KeyboardEvent) {
        if (this.sendKey(event)) {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    _setDesigner(value: any) {
        if (value != null && value.addDesignerContainerProperties != null)
            value.addDesignerContainerProperties(this, 80, 48, () => false);
    }

    private displayItems() {
        const selected = this.selectedItem;
        if (selected != null)
            this.selectedIndex = -1;
        this._displayedItems = [];
        this._element.innerHTML = "";
        for (const item of this.items) {
            this._internalAddItem(item);
        }
        this.selectedItem = selected;
    }

    private _internalAddItem(item: ListItem) {
        const component = item.renderedComponent;
        if (this.itemFilter(item) === true){
            item.setOriginalColor();
            item.setOriginalBackgroundColor();
            this._displayedItems.push(item);
            component.addClickListener(this.itemDefaultClickListener);
            if (this.isPopupType() && item.selectable === true) {
                component.addMouseEnterListener(this.itemMouseEnterListener);
                component.addMouseLeaveListener(this.itemMouseLeaveListener);
            }
            this._element.appendChild(component._element);
        }
    }

    private defaultItemOnClickListener(event: ClickEvent) {
        const component = event.target as Component;
        const index = this.getDisplayedIndexOfRendered(component);
        if (this.isPersistentType())
            this.selectedIndex = index;
        this.fireSelect(index, event.domEvent);
    }

    private itemOnMouseEnterListener(event: MouseEvent) {
        const component = event.target as Component;
        const index = this.getDisplayedIndexOfRendered(component);
        if (this.selectionMode !== SelectionMode.NONE)
            this.selectedIndex = index;
    }

    private itemOnMouseLeaveListener(event: MouseEvent) {
        this.selectedIndex = -1;
    }

    /**
     * This method removes things that were added to rendered components by this list, in case the rendered component
     * was supplied as input.  This is necessary because components could be placed in a list more than once.  One
     * example of this is a component that's used in a nested list; that component could be rendered as part of the
     * nested list, then that list is dismissed, then it is rendered again.
     */
    private cleanupSuppliedComponents() {
        for (let index = 0; index < this._allItems.length; index++) {
            this.updateItemColor(index, false);
            const item = this._allItems[index];
            const comp = item.renderedComponent;
            comp.removeClickListener(this.itemDefaultClickListener);
            comp.removeMouseEnterListener(this.itemMouseEnterListener);
            comp.removeMouseLeaveListener(this.itemMouseLeaveListener);
        }
    }

    public static createSeparator(): ListItem {
        const panel = List.createSeparatorPanel();
        const item = new ListItem(panel);
        item.selectable = false;
        return item;
    }

    static createSeparatorPanel(): Panel {
        return new Panel({
            height: 1,
            borderBottomColor: "subtle.lighter",
            borderBottomWidth: 1,
            borderBottomType: BorderType.SOLID
        });
    }

    private fireSelect(index: number, domEvent: DomEvent) {
        const component = this.getDisplayedItem(index)?.suppliedInput;
        this.fireListeners(_changeListenerDef, () => {
            return new SelectionEvent(this, component, index, component, index, domEvent);
        });
    }

    private relativeSelect(offset: number) {
        const max = this._displayedItems.length - 1;
        let index = this.selectedIndex + offset;
        while (index >= 0 && index <= max) {
            if (this._displayedItems[index].selectable === false)
                index += offset > 0 ? 1 : -1;
            else
                break;
        }
        if (index >= 0 && index <= max)
            this.selectedIndex = index;
    }

    filter(value: string | ItemFilter) {
        this._itemFilter = typeof value === "string" ? this.createFilterFromString(value) : value;
        if (this.itemsCreator != null)
            this.fireItemsCreator();
        else
            this.displayItems();
    }

    private createFilterFromString(filterString: string): ItemFilter {
        return (item: ListItem) => StringUtil.isEmptyString(filterString) || item.renderedComponent?.containsText(filterString);
    }

    private get itemFilter(): ItemFilter {
        return this._itemFilter ?? (() => true);
    }

    sendKey(event: KeyEvent | KeyboardEvent) {
        const code = event.key;
        log.debug("Processing key [%s] in list of type %o", code, this.type);
        const domEvent = event instanceof KeyEvent ? event.domEvent : event;
        if (handledKeys.includes(code)) {
            if (code === Keys.ARROW_DOWN)
                this.relativeSelect(1);
            else if (code === Keys.ARROW_UP)
                this.relativeSelect(-1);
            else if (code === Keys.PAGE_UP)
                this.relativeSelect(-5);
            else if (code === Keys.PAGE_DOWN)
                this.relativeSelect(5);
            else if (code === Keys.HOME)
                this.selectedIndex = 0;
            else if (code === Keys.END)
                this.selectedIndex = this.items.length - 1;
            if (this.isPersistentType())
                this.fireSelect(this.selectedIndex, domEvent);
            return true;
        }
        if (code === Keys.ENTER && this.isPopupType()) {
            log.debug("Selecting list item with Enter key: %o", this.selectedItem);
            this.fireSelect(this.selectedIndex, domEvent);
            return true;
        }
        return false;
    }

    search(value: string): boolean {
        for (let i = 0; i < this._displayedItems.length; i++) {
            const item = this._displayedItems[i].suppliedInput;
            // need to handle non-string items
            if (typeof item === "string" && item.startsWith(value)) {
                this.selectedIndex = i;
                return true;
            }
        }
        return false;
    }

    get items(): ListItem[] {
        return this._allItems;
    }

    set items(itemInputArray: ListItemType[]) {
        this._allItems = [];
        if (itemInputArray != null) {
            for (const itemInput of itemInputArray) {
                this._allItems.push(this.resolveSingleItem(itemInput));
            }
        }
        this.displayItems();
    }

    sortItems(compareFn: (a: ListItem, b: ListItem) => number) {
        this._allItems.sort(compareFn);
        this.displayItems();
    }

    get itemsCreator(): (list: List) => ListItemType[] {
        return this._itemsCreator;
    }

    set itemsCreator(value: (list: List) => ListItemType[]) {
        this._itemsCreator = value;
        this.fireItemsCreator();
    }

    private fireItemsCreator() {
        if (this._itemsCreator != null)
            this.items = this._itemsCreator(this);
    }

    private resolveSingleItem(value: ListItemType): ListItem {
        const listItem = (value instanceof ListItem) ? value : new ListItem(value);
        listItem.list = this;
        return listItem;
    }

    addItem(value: ListItemType): Component {
        if (value == null)
            return;
        const item = this.resolveSingleItem(value);
        this._allItems.push(item);
        this._internalAddItem(item);
        return item.renderedComponent;
    }

    removeItem(item: ListItemType) {
        let index = -1;
        if (item instanceof ListItem)
            index = this._allItems.indexOf(item);
        else
            index = this.getIndexOfSupplied(item);
        if (index < 0)
            return;
        const listItem = this._allItems[index];
        const displayedIndex = this._displayedItems.indexOf(listItem);
        if (displayedIndex === this.selectedIndex)
            this.selectedIndex = -1;
        this._allItems.splice(index, 1);
        this._displayedItems.splice(displayedIndex, 1);
        this._element.removeChild(listItem.renderedComponent._element);
    }

    private getDisplayedIndexOfSupplied(value: StringOrPropsOrComponent) {
        return this.getIndexOf(this._displayedItems, false, value);
    }

    private getDisplayedIndexOfRendered(value: StringOrPropsOrComponent) {
        return this.getIndexOf(this._displayedItems, true, value);
    }

    private getIndexOfSupplied(value: StringOrPropsOrComponent) {
        return this.getIndexOf(this._allItems, false, value);
    }

    private getIndexOfRendered(value: StringOrPropsOrComponent) {
        return this.getIndexOf(this._allItems, true, value);
    }

    private getIndexOf(itemsArray: ListItem[], useRendered: boolean, value: any) {
        if (value != null) {
            for (let x = 0; x < itemsArray.length; x++) {
                const listItem = itemsArray[x];
                const valueFromArray = useRendered ? listItem.renderedComponent : listItem.suppliedInput;
                if (this.itemsEqual(valueFromArray, value))
                    return x;
            }
        }
        return -1;
    }

    get selectedIndex(): number {
        if (this.selectedIndexes.length === 0)
            return -1;
        else
            return this.selectedIndexes[0];
    }

    set selectedIndex(value: number) {
        if (value < 0)
            this.selectedIndexes = [];
        else
            this.selectedIndexes = [value];
    }

    public selectFirstItem() {
        if (ArrayUtil.isEmptyArray(this._displayedItems) === false) {
            this.selectedIndex = 0;
            this.fireSelect(0, null);
        }
    }

    get selectedIndexes(): number[] {
        return this._selectedIndexes;
    }

    set selectedIndexes(value: number[]) {
        if (this.selectionMode !== SelectionMode.NONE) {
            for (const index of this._selectedIndexes) {
                if (!value.includes(index))
                    this.updateItemColor(index, false);
            }
        }
        this._selectedIndexes = value;
        if (this.selectionMode !== SelectionMode.NONE) {
            for (const index of this._selectedIndexes) {
                this.updateItemColor(index, true);
            }
            this.scrollToSelection();
        }
    }

    private updateItemColor(index: number, selected: boolean) {
        const item = this._displayedItems[index];
        if (item != null && item.selectable === true) {
            const comp = item.renderedComponent;
            comp.color = selected ? this.selectedColor : item.originalColor;
            comp.backgroundColor = selected ? this.selectedBackgroundColor : item.originalBackgroundColor;
        }
    }

    private scrollToSelection() {
        if (this._selectedIndexes.length === 1)
            this._displayedItems[this._selectedIndexes[0]].renderedComponent.scrollIntoView({ smooth: false });
    }

    get selectionMode(): SelectionMode {
        return this._selectionMode;
    }

    set selectionMode(value: SelectionMode) {
        this._selectionMode = value;
    }

    get selectedItem(): Component {
        return this._displayedItems[this.selectedIndex]?.renderedComponent;
    }

    set selectedItem(component: Component) {
        const index = this.getDisplayedIndexOfRendered(component);
        if (index >= 0)
            this.selectedIndex = index;
    }

    public setSelectedItemFromSuppliedInput(value: StringOrPropsOrComponent) {
        const index = this.getDisplayedIndexOfSupplied(value);
        if (index >= 0)
            this.selectedIndex = index;
    }

    get selectedListItem(): ListItem {
        return this.getDisplayedItem(this.selectedIndex);
    }

    private itemsEqual(item1: any, item2: any) {
        return item1 === item2 ||
            (item1 != null && item2 != null && item1.caption != null && item1.caption == item2.caption);
    }

    private getDisplayedItem(index: number) {
        if (index < 0)
            return null;
        if (index >= this._displayedItems.length) {
            const message = "Cannot get List item at index  " + index + " because it only has " +
                this._displayedItems.length + " items.";
            throw new Error(message);
        }
        return this._displayedItems[index];
    }

    get scrollY(): boolean {
        return this._scrollY
    }

    set scrollY(value: boolean) {
        this._scrollY = value;
        if (value !== false)
            this._element.style.overflowY = "auto";
        else
            this._element.style.overflowY = "";
    }

    public addSelectionListener(value: SelectionListener) {
        this.addEventListener(_changeListenerDef, value);
    }

    public removeSelectionListener(value: SelectionListener) {
        this.removeEventListener(_changeListenerDef, value);
    }

    override getPropertyDefinitions() {
        return ListPropDefinitions.getDefinitions();
    }

    override get serializationName() {
        return "list";
    }

    override get properName(): string {
        return "List";
    }

    override getListenerDefs(): Collection<ListenerListDef> {
        return {
            ...super.getListenerDefs(),
            "change": { ..._changeListenerDef }
        };
    }

    override getBasicValue(): any {
        return this.selectedItem;
    }
}

ComponentTypes.registerComponentType("list", List.prototype.constructor);
