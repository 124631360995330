import { DataSourceMode, TableRow } from "..";
import { Component } from "./Component";
import { ListenerListDef } from "./ListenerListDef";

export const printableListenerDef: ListenerListDef = { listName: "_printableListeners"};

export class Printable extends Component {
    private _printable: boolean;
    private _printableDuringAdd: boolean;
    private _printableDuringSearch: boolean;
    private _printableDuringUpdate: boolean;

    getMixinClassName() {
        return "Printable";
    }

    get printable(): boolean {
        const mode = this.getCurrentDataSourceMode();
        if ((mode === DataSourceMode.ADD && this.printableDuringAdd)
            || (mode === DataSourceMode.UPDATE && this.printableDuringUpdate)
            || (mode === DataSourceMode.SEARCH && this.printableDuringSearch)) {
            return true;
        }
        return this._printable == null ? false : this._printable;
    }

    set printable(value: boolean) {
        if (this.printableValueMatches(value) === true) {
            return;
        }
        this._printable = value;
        this._syncPrintable();
    }

    printableValueMatches(value: boolean) {
        return this._printable === value;
    }

    get printableDuringAdd(): boolean {
        return this._printableDuringAdd == null ? false : this._printableDuringAdd;
    }

    set printableDuringAdd(value: boolean) {
        this._printableDuringAdd = value;
        this._syncPrintable();
    }

    get printableDuringSearch(): boolean {
        return this._printableDuringSearch == null ? false : this._printableDuringSearch;
    }

    set printableDuringSearch(value: boolean) {
        this._printableDuringSearch = value;
        this._syncPrintable();
    }

    get printableDuringUpdate(): boolean {
        return this._printableDuringUpdate == null ? false : this._printableDuringUpdate;
    }

    set printableDuringUpdate(value: boolean) {
        this._printableDuringUpdate = value;
        this._syncPrintable();
    }

    protected _syncPrintable() {
        this._applyPrintable(this.printable);
        this.syncCaptionIfPossible();
    }

    protected _applyPrintable(value: boolean) {
        //leave blank for components that don't change, such as Container
    }
}

/*
import { Component } from "./Component";

type Constructor<T> = new (...args: any[]) => T;

export function Printable<T extends Constructor<Component>>(anything: T) {
  return class extends anything {
    private _printable: boolean = false;
    constructor(...args: any[]) {
      super(...args);
    }

    get printable(): boolean {
      return this._printable;
    }

    set printable(value: boolean) {
      if (this._printable !== value) {
        this._printable = value;
      }
      this.syncPrintable();
    }

    someOtherMethod() {}

    syncPrintable() {}
  };
}
export const PrintableComponent = Printable(Component);
*/
