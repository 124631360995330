import { getThemeColor, makeStyles } from "@mcleod/core";

export const OverlayStyles = makeStyles("ovr", () => {
    return {
        overlay: {
            position: "absolute",
            left: "0px",
            top: "0px",
            height: "100%",
            width: "100%",
            display: "none",
            outline: "none",
            overflow: "hidden",
            zIndex: 2000 //this is a fallback default in case we can't compute a new zIndex
        },
        overlayBelowPageHeader: {
            position: "absolute",
            left: "0px",
            top: "60px", //defaulting to standard 60 pixels, but will override when overlay is created
            height: "calc(100% - " + 60 + "px)", //defaulting to standard 60 pixels, but will override when overlay is created
            width: "100%",
            display: "none",
            outline: "none",
            overflow: "hidden",
            zIndex: 2000 //this is a fallback default in case we can't compute a new zIndex
        },
        popup: {
            position: "absolute",
            zIndex: 2001, //this is a fallback default in case we can't compute a new zIndex
            color: getThemeColor("defaultText"),
            backgroundColor: getThemeColor("defaultBackground"),
            overflowY: "auto",
            overflowX: "hidden",
            outline: "none",
            border: "1px solid " + getThemeColor("strokeSecondary"),
            boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)"
        },
        centered: {
            margin: 0,
            position: "absolute",
            top: "50%",
            left: "50%",
            MsTransform: "translate(-50%, -50%)",
            transform: "translate(-50%, -50%)",
            maxWidth: "90%",
            maxHeight: "calc(100% - 64px)",
            boxShadow: "0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)",
            borderRadius: 4
        },
        overlayContent: {
            display: "inline-block"
        }
    };
});