import { ResolvablePromise } from "./ResolvablePromise";
import { UrlUtil } from "./UrlUtil";

/**
 * This class has utility methods that will open the external login page from the 
 * SSO provider (onemcleod.com by default), wait for the user to log in, and then
 * return the token that was generated by the SSO provider.
 */
export class ExternalLoginPage {
    private static loginPromise: ResolvablePromise<string> = null;
    private static loginIFrame: HTMLIFrameElement = null;

    public static async showLogin(): Promise<string> {
        this.loginPromise = new ResolvablePromise<string>();
        const  iframe = document.createElement("iframe");
        Object.assign(iframe.style, {
            position: "absolute",
            top: "0px",
            left: "0px",
            width: "100%",
            height: "100%",
            border: "none",
        });
        iframe.src = this.getLoginPage() + "?origin=" + window.location.origin;
        this.loginIFrame = iframe;
        window.addEventListener("message", (event) => this.receiveMessage(event));
        document.body.appendChild(iframe);
        return this.loginPromise.promise;
    }

    private static getLoginPage(): string {
        return (UrlUtil.getSearchParamFromUrl("loginPage") || "https://onemcleod.com/sso-login.html").toLowerCase();
    }

    private static receiveMessage(event: MessageEvent) {
        if (typeof (event.data) === "object" && 
            this.getLoginPage().startsWith(event.origin.toLowerCase() + "/")) {
            if (event.data.token != null) {
                window.removeEventListener("message", this.receiveMessage);
                document.body.removeChild(this.loginIFrame);
                this.loginPromise.resolve(event.data.token);
            }
        }
    }
}