import { AuthType, DynamicLoader, HelpLink } from "@mcleod/core";
import { Component, DataSource } from "../..";
import { ComponentPropDefinitionUtil, ComponentPropDefinitions } from "../../base/ComponentProps";
import { PropType } from "../../base/PropType";
import { ImagePropDefinitions } from "../image/ImageProps";
import { PanelPropDefinitions, PanelProps } from "../panel/PanelProps";
import { layoutSelector } from "../textbox/TextboxProps";

export interface LayoutProps extends PanelProps {
    allowDecoratorOverride: boolean;
    auth: string | string[];
    defaultDecorator: string;
    helpLinks: string;
    isNested: boolean;
    videoItemHelpLinks: HelpLink[];
    displayingFromToolbox: boolean;
    layoutId: string;
    layoutName: string;
    mainDataSource: DataSource;
    needsServerLayout: boolean;
    applyFieldLevelPermissions: boolean;
    applyFieldLevelLicensing: boolean;
    applyFieldLevelCompanyType: boolean;
    applyFieldLevelLtlType: boolean;
    applyFieldLevelExperiment: boolean;
    applyFieldLevelMulitCurrency: boolean;
    title: string;
    titleImage: string;
    titleAddModeSuffix: string;
    titleEditModeSuffix: string;
    titlePlural: string;
    titleSearchModeSuffix: string;
    titleSingular: string;
    hideTitle: boolean;
    hideSuffix: boolean;
    inheritParentDataSource: string;
    renderAsWidget: boolean;
    displayRefresh: boolean;
    widgetGroup: string;
    homePageSize: string;
}

let layoutPropDefs: ComponentPropDefinitions;

export class LayoutPropDefinitions {
    static homePageSizes: string[] = [];
    static {
        const cells = [1, 2, 3, 4, 5];
        cells.forEach(width => this.homePageSizes.push(...cells.map(height => width + "x" + height)));
    }

    public static getDefinitions(): ComponentPropDefinitions {
        if (layoutPropDefs == null) {
            layoutPropDefs = {
                ...PanelPropDefinitions.cloneDefs(),
                auth: {
                    type: PropType.string,
                    dropdownProps: { items: Object.keys(AuthType) },
                    description: "This specifies which user type(s) have access to this page."
                },
                allowDecoratorOverride: {
                    type: PropType.bool,
                    defaultValue: true,
                    description: "This toggles whether the decorator for the page can be changed by providing a 'mode' parameter in the browser's URL."
                },
                displayingFromToolbox: {
                    type: PropType.bool,
                    defaultValue: false,
                    description: "This property allows the onLoad of a layout to behave differently if the layout being loaded is being displayed from the toolbox.",
                    visibleInDesigner: false
                },
                inheritParentDataSource: {
                    type: PropType.string,
                    dropdownProps: { items: ComponentPropDefinitionUtil.getDataSourceList },
                    description: "Allows a nested layout to inherit a DataSource from the parent layout. The parent DataSource selected will be the nested layout's mainDataSource."
                },
                isNested: {
                    type: PropType.bool,
                    visibleInDesigner: false,
                    description: "This specifies whether a layout is nested in another layout.  It is usually set automatically and shouldn't be set manually"
                },
                helpLinks: {
                    type: PropType.string,
                    editor: helpLinkEditor,
                    description: "This specifies a list of help links that are available for this layout.  These would be available to the user via the lightbulb icon in the page header.",
                    visibleInMcLeodTailor: true,
                    editableInMcLeodTailor: { baseComponent: false, customComponent: true }
                },
                layoutName: {
                    type: PropType.string,
                    editor: layoutSelector,
                    visibleInMcLeodTailor: true,
                    editableInMcLeodTailor: { baseComponent: false, customComponent: true },
                    description: "The name for this layout when nested."
                },
                mainDataSource: {
                    type: PropType.string,
                    dropdownProps: { items: ComponentPropDefinitionUtil.getDataSourceList },
                    description: "This specifies which of the DataSources in this layout is considered the 'main' DataSource.  The 'main' DataSource will be attached to the toolbar or the list component when the page is decorated."
                },
                needsServerLayout: {
                    type: PropType.bool,
                    visibleInDesigner: false,
                    defaultValue: true
                },
                defaultDecorator: {
                    type: PropType.string,
                    dropdownProps: { items: ["none", "list", "add", "search", "update", "advanced"] },
                    defaultValue: "none",
                    description: "This specifies which 'decorator' will be used by default when this page is loaded.  Decorators can be changed if the URL for the page also includes a 'mode' parameter."
                },
                title: {
                    type: PropType.string,
                    affectsProps: ["titleAddModeSuffix", "titleEditModeSuffix", "titlePlural", "titleSearchModePrefix", "titleSingular"],
                    visibleInMcLeodTailor: true,
                    description: "Specifies the title that will appear in the browser's window when this page is loaded.  Field names from the mainDataSource may be used (enclose in {})."
                },
                titleAddModeSuffix: {
                    type: PropType.string,
                    visibleInMcLeodTailor: true,
                    description: "Specifies text to append to the title when the screen is displayed in add mode.  Field names from the mainDataSource may be used (enclose in {})."
                },
                titleEditModeSuffix: {
                    type: PropType.string,
                    visibleInMcLeodTailor: true,
                    description: "Specifies text to append to the title when the screen is displayed in edit mode.  Field names from the mainDataSource may be used (enclose in {})."
                },
                titleImage: {
                    type: PropType.string,
                    editor: ImagePropDefinitions.imagePropertyEditor,
                    visibleInMcLeodTailor: true,
                    description: "Specifies the image that will be used to represent this layout when it is shown in the menu and the title bar when shown in a dialog."
                },
                titlePlural: {
                    type: PropType.string,
                    affectsProps: ["titleAddModeSuffix", "titleEditModeSuffix", "titlePlural", "titleSearchModePrefix", "titleSingular"],
                    visibleInMcLeodTailor: true,
                    description: "Specifies the title of this screen when it should appear in plural form."
                },
                titleSearchModeSuffix: {
                    type: PropType.string,
                    visibleInMcLeodTailor: true,
                    description: "Specifies text to append to the title when the screen is displayed in search mode.  Field names from the mainDataSource may be used (enclose in {})."
                },
                titleSingular: {
                    type: PropType.string,
                    affectsProps: ["titleAddModeSuffix", "titleEditModeSuffix", "titlePlural", "titleSearchModePrefix", "titleSingular"],
                    visibleInMcLeodTailor: true,
                    description: "Specifies the title of this screen when it should appear in singular form."
                },
                hideTitle: {
                    type: PropType.bool,
                    defaultValue: false,
                    visibleInMcLeodTailor: true,
                    description: "If selected, the title will not be added to the title panel. The title settings will only be used for the document title."
                },
                hideSuffix: {
                    type: PropType.bool,
                    defaultValue: false,
                    visibleInMcLeodTailor: true,
                    description: "If selected, the suffix will not be displayed in the title panel."
                },
                renderAsWidget: {
                    category: "Widget",
                    type: PropType.bool,
                    defaultValue: false,
                    visibleInMcLeodTailor: true,
                    editableInMcLeodTailor: { baseComponent: false, customComponent: true },
                    description: "Determines if this layout should be displayed as a widget. If true, a title bar and refresh button will be added to the layout."
                },
                displayRefresh: {
                    category: "Widget",
                    type: PropType.bool,
                    defaultValue: false,
                    visibleInMcLeodTailor: true,
                    description: "Specifies if the default refresh button will be displayed in the widget's default title bar."
                },
                widgetGroup: {
                    category: "Widget",
                    type: PropType.string,
                    visibleInMcLeodTailor: true,
                    editableInMcLeodTailor: { baseComponent: false, customComponent: true },
                    description: "Specifies the menu item this widget will appear on the Home Page Editor menu item. For example, Dispatch, DataFusion"
                },
                homePageSize: {
                    category: "Widget",
                    type: PropType.string,
                    description: "Specifies the size this widget will appear on the homepage",
                    dropdownProps: { items: this.homePageSizes },
                    defaultValue: this.homePageSizes[0],
                    visibleInMcLeodTailor: true
                },
            };
            layoutPropDefs.fillHeight.defaultValue = true;
            layoutPropDefs.fillRow.defaultValue = true;
            layoutPropDefs.scrollX.defaultValue = true;
            layoutPropDefs.scrollY.defaultValue = true;
            layoutPropDefs.padding.defaultValue = 0;
            delete layoutPropDefs.id;
            for (const [propName, prop] of Object.entries(layoutPropDefs))
                if (["Data"].includes(prop.category))
                    delete layoutPropDefs[propName];
            ComponentPropDefinitionUtil.populateComponentPropNames(layoutPropDefs);
        }
        return layoutPropDefs;
    }
}

export function helpLinkEditor(value: string, prop): Promise<Component> {
    // can't make a direct reference to HelpLinkEditor at compile time
    const cls = DynamicLoader.getClassForPath("designer/ui/HelpLinkEditor");
    return Promise.resolve(new cls(null, value));
}
