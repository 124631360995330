import { Component, Label, Layout, Overlay } from "@mcleod/components";
import { Alignment, ArrayUtil, DOMUtil, ExternalLink } from "@mcleod/core";
import { CommonDialogs } from "./CommonDialogs";
import { PageHeaderProvider } from "./PageHeaderProvider";

export class ExternalLinkLauncher {
    static showLinks(layouts: Layout[], anchorComponent: Component) {
        const dropdownItems: Label[] = [];
        const links = ExternalLinkLauncher.discoverExternalLinks(layouts);
        for (const link of links) {
            dropdownItems.push(new Label({ caption: link.caption, onClick: () => link.open().then(success => this.evaluateLinkSuccess(success)) }));
        }
        Overlay.showDropdown(anchorComponent, dropdownItems, null, { width: null }, { align: Alignment.RIGHT });
    }

    static discoverExternalLinks(layouts: Layout[]): ExternalLink[] {
        const result: ExternalLink[] = [];
        if (PageHeaderProvider.get().shouldDisplayHelpLinks() === true) {
            const topMostLayout = layouts?.[0];
            const helpLinks = topMostLayout?.getAllHelpLinks();
            if (ArrayUtil.isEmptyArray(helpLinks) === false)
                result.push(...helpLinks);
        }
        const addlLinks = PageHeaderProvider.get().getAddlExternalLinks();
        if (ArrayUtil.isEmptyArray(addlLinks) === false)
            result.push(...addlLinks);
        for (let x = result.length-1; x >= 0; x--) {
            const link = result[x];
            if (link.relatedElement != null && DOMUtil.isOrContains(document.body, link.relatedElement) !== true)
                result.splice(x, 1);
        }
        return result;
    }

    //this is only here because we can't access CommonDialogs from ExternalLink (because it's in core)
    private static evaluateLinkSuccess(success: boolean) {
        if (success !== true)
            CommonDialogs.showDialog("We're sorry, this link is not accessible at this time.");
    }
}
