import { Model } from "@mcleod/core";
import { SelectionEvent } from "../../events/SelectionEvent";
import { Button } from "../button/Button";
import { ButtonVariant } from "../button/ButtonVariant";
import { Label } from "../label/Label";
import { List, ListItemType } from "../list/List";
import { ListItem } from "../list/ListItem";
import { Panel } from "../panel/Panel";
import { Table } from "./Table";
import { TableConfig } from "./TableConfig";
import { ClickEvent } from "../../events/ClickEvent";
import { ReflectiveDialogs } from "../../base/ReflectiveDialogs";
import { TableConfigurations } from "./TableConfigurations";
import { LabelProps } from "../label/LabelProps";

export class TableToolsConfigOptions {
    private table: Table;
    private savedTableConfigs: TableConfig[];
    private commonLabelProps: Partial<LabelProps>;

    constructor(table: Table, commonLabelProps: Partial<LabelProps>) {
        this.table = table;
        this.commonLabelProps = commonLabelProps;
    }

    async getConfigDropdownItem(): Promise<ListItem> {
        const labelEditConfig = new Label({
            ...this.commonLabelProps,
            id: "labelEditConfig",
            caption: "Table Configurations",
            fillRow: true,
            rowBreak: false,
            imageName: "settings"
        });
        this.savedTableConfigs = await this.getSavedTableConfigs();
        const children: ListItemType[] = [this.getCreateNewConfigPanel()];
        for (const savedConfig of this.savedTableConfigs) {
            children.push(this.getTableConfigDropdownItem(savedConfig));
        }
        const result = new ListItem(labelEditConfig, children, (event: SelectionEvent) => {
            this.handleConfigItemSelect(event);
        });
        return result;
    }

    private handleConfigItemSelect(event: SelectionEvent) {
        if (event.domEvent.defaultPrevented === true)
            return;
        const selectedIndex = (event.target as List).selectedIndex;
        if (selectedIndex === 0) {
            //user selected to create new configuration
            this.showConfigDialog();
        }
        else
            this.table.configInUse = this.savedTableConfigs[selectedIndex - 1];
    }

    private getCreateNewConfigPanel(): Panel {
        const result = new Panel({ fillRow: true, padding: 0 });
        const labelCreateNewConfig = new Label({
            ...this.commonLabelProps,
            id: "labelCreateNewConfig",
            caption: "Manage Configurations",
            fillRow: true,
            rowBreak: false,
            imageName: "settings"
        });
        const buttonPlaceholder = new Button({
            variant: ButtonVariant.round,
            color: "subtle.light",
            imageName: "delete",
            preventCollapse: true,
            visible: false
        });
        result.add(labelCreateNewConfig, buttonPlaceholder);
        return result;
    }

    private async getSavedTableConfigs(): Promise<TableConfig[]> {
        this.savedTableConfigs = [];
        const filter = {
            layout_path: this.table.getParentLayout()?.layoutName,
            table_id: this.table.id
        };
        const response = await Model.search("common/table-config", filter);
        const result: TableConfig[] = [];
        for (const configRow of response?.modelRows) {
            result.push(new TableConfig(configRow));
        }
        return result;
    }

    private getTableConfigDropdownItem(tableConfig: TableConfig): Panel {
        const result = new Panel({ fillRow: true, padding: 0 });
        const name = tableConfig.name;
        const labelUseConfig = new Label({
            ...this.commonLabelProps,
            rowBreak: false,
            fillRow: true,
            caption: name
        });
        if (tableConfig.id === this.table.configInUse?.id) {
            labelUseConfig.imageName = "run";
            result.color = "primary";
        }
        else {
            const padding = labelUseConfig.paddingLeft != null ? labelUseConfig.paddingLeft : 4;
            labelUseConfig.paddingLeft = padding + 29;
        }
        const editButton = new Button({
            rowBreak: false,
            marginRight: tableConfig.isCurrentUsersConfig()? 0 : 29,
            variant: ButtonVariant.round,
            color: "subtle.light",
            imageName: "pencil"
        });
        editButton.addClickListener(async (event: ClickEvent) => {
            this.updateClickEvent(event);
            this.showConfigDialog(tableConfig);
        });
        result.add(labelUseConfig, editButton);

        if (tableConfig.isCurrentUsersConfig()) {
            const deleteButton = new Button({
                variant: ButtonVariant.round,
                marginLeft: 0,
                color: "subtle.light",
                imageName: "delete"
            });
            deleteButton.addClickListener(async (event: ClickEvent) => {
                this.updateClickEvent(event);
                const message = `Are you sure you want to delete table configuration '${name}'?`;
                if (await ReflectiveDialogs.showDestructive(message, "Delete Table Configuration?"))
                    await tableConfig.delete();
            });
            result.add(deleteButton);
        }
        return result;
    }

    private async showConfigDialog(configToSelect?: TableConfig) {
        const parentLayoutPath = this.table.getParentLayout()?.layoutName;
        const layout = new TableConfigurations(this.table, parentLayoutPath, configToSelect);
        const dialog = ReflectiveDialogs.createDialog(layout, layout.getDialogProps());
        layout.closeMethod = () => dialog.close();
        await dialog.show();
        const selectedConfig = layout.activeConfig;
        if (selectedConfig != null)
            this.table.configInUse = selectedConfig;
        else
            this.table.useBaseConfig();
    }

    private updateClickEvent(event: ClickEvent) {
        //don't stop the event so that the containing panel will get the event and close the overlay
        event.shouldAutomaticallyStopPropagation = false;
        //do prevent default so that the list's selection listener can ignore the selection event that
        //clicking the button will fire
        event.preventDefault();
    }
}
