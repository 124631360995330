import { Alignment, DOMUtil, LocalUserSettings, UserSettings, getLogger } from "@mcleod/core";
import { Container, Layout, SlideoutDecorator } from "..";
import { Component } from "./Component";
import { Cursor } from "./Cursor";

const log = getLogger("components/SlideoutSizeManager");
const LOCAL_SETTINGS_KEY = "slideout.settings";

interface SlideoutSettings {
    width: number;
}

export class SlideoutSizeManager {
    private component: Component;
    private resizeDiv: HTMLDivElement;
    private isResizing: boolean = false;
    private startX: number;
    private dragEdge: Alignment.RIGHT | Alignment.LEFT;
    private resizeListener: (event: MouseEvent) => void;
    private stopResizingListener: (event: MouseEvent) => void;
    private originalProps: Partial<CSSStyleDeclaration>;
    private maxWidth: number;
    private minWidth: number;
    private baseWidth: number     // the width of the component prior to applying saved widths or resizing
    private updatedWidth: number; // the width of the component after resizing

    constructor(component: Component, direction: Alignment) {
        this.component = component;
        this.dragEdge = this.determineResizeEdge(direction);
        if (this.dragEdge != null) {
            this.setupUserWidthPreferences();
            this.component.addMountListener(() => this.doAfterSlideIn());
        }
    }

    setupUserWidthPreferences() {
        if (UserSettings.get()?.user_save_screen_sizes !== true)
            return;

        this.baseWidth = this.getComponentWidth();
        const settingsKey = UserSlideoutSettings.determineSettingsKey(this.component);
        if (settingsKey != null) {
            this.component.addUnmountListener(() =>  this.saveUserWidth(settingsKey));
            this.applyUserSavedWidth(settingsKey);
        }
    }

    private getComponentWidth(): number {
        return this.component._element.offsetWidth;
    }

    private determineResizeEdge(direction: Alignment): Alignment.RIGHT | Alignment.LEFT {
        if (direction === Alignment.RIGHT) {
            return Alignment.LEFT;
        } else if (direction === Alignment.LEFT) {
            return Alignment.RIGHT;
        }
    }

    private doAfterSlideIn() {
        this.resizeListener = this.resize.bind(this);
        this.stopResizingListener = this.stopResizing.bind(this);
        this.createResizeDiv();
        this.maxWidth = DOMUtil.getStyleAttrAsNumber(this.component.maxWidth) || Number.MAX_SAFE_INTEGER;
        this.minWidth = DOMUtil.getStyleAttrAsNumber(this.component.minWidth) || 100;
    }

    private createResizeDiv() {
        this.resizeDiv = document.createElement("div");
        this.resizeDiv.id = "slideoutResizeDiv";
        const resizeStyles: Partial<CSSStyleDeclaration> = {
            position: "absolute",
            top: "0",
            width: "15px",
            height: "100%",
            cursor: Cursor.EW_RESIZE,
            zIndex: "999",
            [this.dragEdge === Alignment.RIGHT ? "right" : "left"]: "0"
        };
        Object.assign(this.resizeDiv.style, resizeStyles);
        this.resizeDiv.addEventListener('mousedown', this.startResizing.bind(this));
        this.component._element.prepend(this.resizeDiv);
    }

    private startResizing(event: MouseEvent): void {
        event.preventDefault();
        this.isResizing = true;
        this.startX = event.clientX;
        this.setTemporaryStyles();
        window.addEventListener('mousemove', this.resizeListener, false);
        window.addEventListener('mouseup', this.stopResizingListener, false);
    }

    private stopResizing(event: MouseEvent): void {
        this.isResizing = false;
        this.revertTemporaryStyles();
        this.component.width = DOMUtil.getElementWidthPercentage(this.component._element) + "%";
        this.updatedWidth = this.getComponentWidth();
        window.removeEventListener('mousemove', this.resizeListener, false);
        window.removeEventListener('mouseup', this.stopResizingListener, false);
    }

    private setTemporaryStyles() {
        this.originalProps = {
            cursor: document.body.style.cursor,
            userSelect: this.component._element.style.userSelect
        };
        document.body.style.cursor = this.resizeDiv.style.cursor;
        this.component.style.userSelect = "none";
    }

    private revertTemporaryStyles() {
        if (this.originalProps) {
            document.body.style.cursor = this.originalProps.cursor;
            this.component._element.style.userSelect = this.originalProps.userSelect;
        }
    }

    private resize(event: MouseEvent): void {
        if (!this.isResizing) return;
        const currentWidth = this.getComponentWidth();
        const deltaX = event.clientX - this.startX;
        let newWidth = this.dragEdge === Alignment.LEFT ? currentWidth - deltaX : currentWidth + deltaX;
        // Apply the min and max constraints to the new width.
        newWidth = Math.max(this.minWidth, Math.min(newWidth, this.maxWidth));
        // Only update if the new width is within the allowed range.
        if (newWidth != currentWidth) {
            this.component.width = newWidth;
            this.startX = event.clientX;
        }
    }

    private applyUserSavedWidth(settingsKey: string) {
        if(settingsKey == null)
            return;
        const savedWidth = UserSlideoutSettings.getSettingsForComponent(settingsKey)?.width;
        if (this.isValidPercentage(savedWidth)) {
            this.component.width = savedWidth + "%";
        }
    }

    private saveUserWidth(settingsKey: string) {
        if (settingsKey == null || this.updatedWidth == null) {
            return;
        }

        // If the updated width hasn't changed from the base width, remove any saved settings
        if (this.updatedWidth === this.baseWidth) {
            UserSlideoutSettings.removeSettings(settingsKey);
            log.debug(`Width reverted to base. Removing saved width for ${settingsKey}.`);
            return;
        }

        if (typeof this.component.width !== "string" || !this.component.width.endsWith("%")) {
            log.debug("Could not save width for slideout. Width is not a percentage.");
            return null;
        }

        const widthToSave = parseFloat(this.component.width);
        if (this.isValidPercentage(widthToSave)) {
            log.debug(`Saving width:${widthToSave} for slideout ${settingsKey}`);
            UserSlideoutSettings.saveSettingsForComponent(settingsKey, { width: widthToSave });
        } else {
            log.debug(`Width:${this.component.width} is not a valid percentage for ${settingsKey}.`);
        }
    }

    private isValidPercentage(width: number): boolean {
       return width > 0 && width <= 100;
    }
}

class UserSlideoutSettings {

    static getAllSettings(): Record<string, SlideoutSettings> {
        return LocalUserSettings.getObject(LOCAL_SETTINGS_KEY, {}) as Record<string, SlideoutSettings>;
    }

    static saveSettingsForComponent(settingsKey: string, settings: SlideoutSettings): void {
        if (settingsKey) {
            const allSettings = this.getAllSettings();
            allSettings[settingsKey] = settings;
            LocalUserSettings.set(LOCAL_SETTINGS_KEY, allSettings);
        } else {
            log.debug("Could not determine unique identifier for component.");
        }
    }

    static getSettingsForComponent(settingsKey: string): SlideoutSettings {
        if (settingsKey) {
            return this.getAllSettings()[settingsKey] || null;
        }
        return null;
    }

    static removeSettings(settingsKey: string): void {
        const allSettings = this.getAllSettings();
        delete allSettings[settingsKey];
        LocalUserSettings.set(LOCAL_SETTINGS_KEY, allSettings);
    }

    static determineSettingsKey(component: Component): string {
        if (component instanceof Layout)
            return component.layoutName;
        if (component instanceof SlideoutDecorator && component.layout)
            return component.layout.layoutName;
        return this.findLayout(component)?.layoutName;
    }

    private static findLayout(component: Component) : Layout {
        if (component instanceof SlideoutDecorator)
            return component.layout;
        if (component instanceof Layout)
            return component;
        if (component instanceof Container)
            return this.findMainLayout(component);
    }

    // Finds the main layout in the slideout Container. If multiple layouts are found,
    // the first layout is returned if all other layouts are nested within it.
    private static findMainLayout(wrapper: Container): Layout {
        const layouts = [];

        wrapper.forEveryChildComponent(child => {
            if (child instanceof Layout && child.isNested !== false)
                layouts.push(child);
        });

        if (layouts.length === 0) return null;
        if (layouts.length === 1) return layouts[0];

        const mainLayout = layouts[0];

        for (let i = 1; i < layouts.length; i++) {
            if (!DOMUtil.isOrContains(mainLayout._element, layouts[i]._element))
                return null;
        }
        return mainLayout;
    }
}
