import { DialogProps, Label, Panel, Textbox } from "@mcleod/components";
import { StringUtil } from "@mcleod/core";

export class MultiLineEditor extends Panel {
    returnAsArray: boolean;
    textbox: Textbox;
    dialogProps: Partial<DialogProps> = { okDefault: false, title: "Multiple Value Entry", cursor: null };

    constructor(props) {
        super({ fillRow: true, fillHeight: true, minWidth: 500, minHeight: 400, ...props });
        const label = new Label({ caption: "Enter one value on each line:", fontBold: true });
        this.textbox = new Textbox({ multiline: true, captionVisible: false, text: props?.value, fillRow: true, fillHeight: true });
        this.add(label, this.textbox)
    }

    getValue() {
        if (StringUtil.isEmptyString(this.textbox.text))
            return null;
        if (this.returnAsArray === true)
            return this.textbox.text.split("\n");
        else
            return this.textbox.text;
    }
}
