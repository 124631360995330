import { Collection } from "@mcleod/core";
import { Instance } from "./AnalyzerTypes";

interface ClassFunction {
    [key: string]: (instanceMap: Collection<Instance>, instance: Instance) => any;
}

interface ClassBriefInfo {
    caption: string;
    tooltip: string;
}
interface ClassBriefFunction {
    [key: string]: (instanceMap: Collection<Instance>, instance: Instance) => ClassBriefInfo;
}

export class HeapClassTypes {
    private static classTextMap: ClassFunction = {
        "com.tms.server.lib.MultiCompanyHashtable": (instanceMap, instance) => this.getMap(instanceMap, instance.f["aliases"]),
        "com.tms.common.lib.data.QueryMetaDataHash": (instanceMap, instance) => this.getMap(instanceMap, instance.f["hash"]),
        "com.tms.common.lib.data.QueryMetaData": (instanceMap, instance) => this.getArray(instanceMap, instance.f["fields"]),
        "com.tms.common.lib.data.Field": (instanceMap, instance) => this.getFields(instanceMap, instance),
        "java.util.Hashtable": (instanceMap, instance) => this.getMap(instanceMap, instance.a),
        "java.util.Properties": (instanceMap, instance) => this.getMap(instanceMap, instance.f["map"]),
        "java.util.ArrayList": (instanceMap, instance) => this.getArray(instanceMap, instance.f["elementData"]),
        "java.util.Vector": (instanceMap, instance) => this.getArray(instanceMap, instance.f["elementData"]),
        "com.tms.common.lib.data.RowData": (instanceMap, instance) => this.getRowData(instanceMap, instance),
        "com.tms.common.lib.data.FieldValue$StringFieldValue": (instanceMap, instance) => this.getInstanceObject(instanceMap, instance.f["value"]),
        "com.tms.common.lib.data.FieldValue$MemoFieldValue": (instanceMap, instance) => this.getInstanceObject(instanceMap, instance.f["value"]),
    }

    private static classBriefMap: ClassBriefFunction = {
        "java.util.zip.ZipFile$Source": (instanceMap, instance) => this.getZipFileSourceBriefText(instanceMap, instance),
    }

    public static getInstanceBriefText(instanceMap: Collection<Instance>, instance: Instance): ClassBriefInfo {
        if (instance == null)
            return { caption: "--", tooltip: undefined };
        else if (typeof instance === "string")
            return { caption: instance, tooltip: undefined };
        const fn = this.classBriefMap[instance.c];
        const result = { caption: instance.c + " @ " + instance.a, tooltip: undefined };
        if (fn != null) {
            const info = fn(instanceMap, instance);
            if (info != null) {
                result.caption += " (" + info.caption + ")";
                result.tooltip = info.tooltip;
            }
        } else if (instance.v != null) {
            result.caption += " " + instance.v;
        }
        return result;
    }

    public static getInstanceText(instanceMap: Collection<Instance>, instanceAddressOrValue: string): string {
        return JSON.stringify(this.getInstanceObject(instanceMap, instanceAddressOrValue), null, "  ");
    }

    public static hasClassHandler(className: string): boolean {
        return this.classTextMap[className] != null;
    }

    private static getFields(instanceMap: Collection<Instance>, instance: Instance): any {
        const result = {};
        for (const fieldName of Object.keys(instance.f)) {
            result[fieldName] = this.getInstanceObject(instanceMap, instance.f[fieldName]);
        }
        return result;
    }

    private static getInstanceObject(instanceMap: Collection<Instance>, instanceAddressOrValue: string): any {
        if (instanceAddressOrValue == null || !instanceAddressOrValue.startsWith("0x"))
            return instanceAddressOrValue;
        const instance = instanceMap[instanceAddressOrValue];
        const className = instance.c;
        const fn = this.classTextMap[className];
        if (fn != null) {
            return fn(instanceMap, instance);
        } else if (instance.f?.["rowData"] != null) {
            return this.getRowData(instanceMap, instanceMap[instance.f["rowData"]]);
        } else if (instance.v != null) {
            if (instance.c === "java.lang.String")
                return instance.v;
            else
                return instance.c + " @ " + instance.a + " " + instance.v;
        } else {
            return instance.c + " @ " + instance.a;
        }
    }

    private static getRowData(instanceMap: Collection<Instance>, instance: Instance): any {
        const result = {};
        const metadataAddress = instance.f["tableRowMetaData"];
        const metadata = this.getInstanceObject(instanceMap, metadataAddress);
        const data = instanceMap[instance.f["data"]];
        for (const key of Object.keys(data.f)) {
            const index = parseInt(key.substring(1, key.length - 1));
            const fieldName = metadata[index].name;
            const value = data.f[key];
            result[fieldName] = this.getInstanceObject(instanceMap, value);
        }
        return result;
    }

    private static getArray(instanceMap: Collection<Instance>, instanceAddress: string): any[] {
        const instance = instanceMap[instanceAddress];
        const result = [];
        for (const entry of Object.values(instance.f)) {
            result.push(this.getInstanceObject(instanceMap, entry));
        }
        return result;
    }

    private static getMap(instanceMap: Collection<Instance>, mapAddress: string): any {
        const result = {};
        const hashtable = instanceMap[mapAddress];
        const table = instanceMap[hashtable.f["table"]];
        for (const entryAddress of Object.values(table.f)) {
            this.putMapValue(instanceMap, result, entryAddress);
        }
        return result;
    }

    private static putMapValue(instanceMap: Collection<Instance>, map: any, entryAddress: string) {
        const entry = instanceMap[entryAddress];
        const key = entry.f["key"];
        const value = entry.f["value"];
        const keyObject = this.getInstanceObject(instanceMap, key);
        map[keyObject] = this.getInstanceObject(instanceMap, value);
        const next = entry.f["next"];
        if (next != null) {
            this.putMapValue(instanceMap, map, next);
        }
    }

    private static getZipFileSourceBriefText(instanceMap: Collection<Instance>, instance: Instance):  ClassBriefInfo {
        const key = instanceMap[instance.f?.["key"]];
        const file = instanceMap[key?.f["file"]];
        const path = instanceMap[file?.f["path"]];
        return {
            caption: "Class data from " + path?.v, 
            tooltip: "This object holds binary data for class files in the classpath.  It is common for these to be fairly large."
        };
    }
}