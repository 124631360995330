import { Alignment, Collection, Color, getThemeColor, JSUtil, ModelRow, StringUtil } from "@mcleod/core";
import { ExtendedDataType } from "@mcleod/core/src/ApiMetadata";
import { ImageName } from "@mcleod/images";
import { Component, Image, Label, PermissionsDefinition, PrintableEvent, PrintableListener } from "../..";
import { Captioned } from "../../base/CaptionedComponent";
import { getCurrentDataSourceMode, getRelevantModelRow } from "../../base/ComponentDataLink";
import { ComponentPropDefinition, ComponentPropDefinitions } from "../../base/ComponentProps";
import { ComponentTypes } from "../../base/ComponentTypes";
import { ListenerListDef } from "../../base/ListenerListDef";
import { Printable, printableListenerDef } from "../../base/PrintableComponent";
import { DataSourceMode } from "../../databinding/DataSource";
import { ChangeEvent, ChangeListener } from "../../events/ChangeEvent";
import { DomEvent } from "../../events/DomEvent";
import { ImageProps } from "../image/ImageProps";
import { SwitchPropDefinitions, SwitchProps } from "./SwitchProps";
import { SwitchStyles } from "./SwitchStyles";

const _changeListenerDef: ListenerListDef = { listName: "_changeListeners" };

type SwitchValueType = string | boolean | number;

export class Switch extends Component implements SwitchProps {
    private _switchRow: HTMLDivElement;
    private _switchContainer: HTMLDivElement;
    private _track: HTMLSpanElement;
    private _thumb: HTMLDivElement;
    private _thumbInner: HTMLDivElement;
    private _captionLabel: Label;
    private _captionAlignment: Alignment.LEFT | Alignment.TOP;
    private _checked: boolean;
    private _allowIndeterminate: boolean = false;
    private _nextSearchValue: boolean = true;
    private _leftImageName: ImageName;
    private _leftImageProps: ImageProps;
    private _leftLabel: Label;
    private _leftValue: SwitchValueType;
    private _printLabel: Label;
    private _rightImageName: ImageName;
    private _rightImageProps: ImageProps;
    private _rightLabel: Label;
    private _rightValue: SwitchValueType;
    private _captionVisible: boolean;
    private _defaultDataValue: boolean;

    constructor(props: Partial<SwitchProps>) {
        super("div", props);
        this.setupElements(props);
        this.initCheckedToFalse();
        this.setProps(props);
    }

    override get defaultDataValue(): boolean {
        return this._defaultDataValue;
    }

    public set defaultDataValue(value: boolean) {
        this._defaultDataValue = value;
    }

    private setupElements(props: Partial<SwitchProps>) {
        this._switchRow = document.createElement("div");
        this._switchRow.classList.add(SwitchStyles.root);
        this._captionLabel = new Label({ fontSize: "small", padding: 0, marginBottom: 4, themeKey: "switch.caption", color: getThemeColor("subtle.darker") });
        this._leftLabel = new Label({ caption: props?.leftCaption, themeKey: "switch.captions", fontBold: true, color: getThemeColor("subtle.darkest") });
        this._rightLabel = new Label({ caption: props?.rightCaption, themeKey: "switch.captions", color: getThemeColor("subtle.darkest") });
        this._switchContainer = document.createElement("div");
        this._switchContainer.classList.add(SwitchStyles.switchContainer);
        this._track = document.createElement("span");
        this._track.setAttribute("tabindex", "0");
        this._track.classList.add(SwitchStyles.track);
        this._thumb = document.createElement("div");
        this._thumb.classList.add(SwitchStyles.thumb);
        this._thumbInner = document.createElement("div");
        this._thumbInner.classList.add(SwitchStyles.thumbInner);
        this._addTrackListeners();
        this._element.appendChild(this._captionLabel._element);
        this._element.appendChild(this._switchRow);
        this._thumb.appendChild(this._thumbInner);
        this._switchContainer.appendChild(this._track);
        this._track.appendChild(this._thumb);
        this._switchRow.appendChild(this._switchContainer);
        this.syncLeftCaptionLabel();
        this.syncRightCaptionLabel();
    }

    override setProps(props: Partial<SwitchProps>) {
        super.setProps(props);
    }

    toggle(event: Event) {
        if (this._designer != null) {
            return;
        }
        if (this._allowIndeterminate !== true) {
            this._internalSetChecked(!this.checked, event);
        }
        else {
            if (this.checked !== undefined) {
                this._nextSearchValue = !this.checked;
                this._internalSetChecked(undefined, event);
            }
            else {
                this._internalSetChecked(this._nextSearchValue, event);
            }
        }
    }

    /**
     * We need to init the switch to the left position (checked = false), but we don't want to fire off change listeners
     * or change bound data.  So call _internalSetChecked() with an override that skips those actions.
     */
    private initCheckedToFalse() {
        this._internalSetChecked(false, null, true);
    }

    get checked() {
        return this._checked;
    }

    set checked(value) {
        this._internalSetChecked(value, null);
    }

    _setCheckedProps(value: boolean) {
        if (value) {
            this._thumb.classList.add(SwitchStyles.checked);
            this._rightLabel.fontBold = true;
            this._leftLabel.fontBold = false;
            this._track.removeAttribute("unchecked");
            this._track.setAttribute("tabindex", "0");
            this._track.classList.remove(SwitchStyles.trackUnchecked);
            this._track.classList.add(SwitchStyles.track);
        }
        if (!value) {
            this._thumb.classList.remove(SwitchStyles.checked);
            this._rightLabel.fontBold = false;
            this._leftLabel.fontBold = true;
            this._track.setAttribute("unchecked", "true");
            this._track.classList.remove(SwitchStyles.track);
            this._track.classList.add(SwitchStyles.trackUnchecked);
        }
    }

    _internalSetChecked(value: boolean, originatingEvent: DomEvent, initialSetup: boolean = false) {
        if (this._checked === value)
            return;
        const oldValue = this._checked;
        this._checked = value;
        if (initialSetup !== true) {
            this._internalUpdateBoundData();
            this.storeUserChoiceIfRemembered();
            const event = new ChangeEvent(this, oldValue, value, originatingEvent);
            this.fireListeners(_changeListenerDef, event)
        }
        if (this._allowIndeterminate !== true) {
            this._setCheckedProps(value);
        }
        else {
            if (value === true) {
                this._thumb.classList.remove(SwitchStyles.neutral);
                this._setCheckedProps(value);
            }
            else if (value === false) {
                this._thumb.classList.remove(SwitchStyles.neutral);
                this._setCheckedProps(value);
            }
            else {
                this._thumb.classList.remove(SwitchStyles.checked);
                this._thumb.classList.add(SwitchStyles.neutral);
                this._rightLabel.fontBold = false;
                this._leftLabel.fontBold = false;
            }
        }
        this.handleImage();

        //this seems gross; the checked value can be set from the data after we've already set printable = true
        //so we have to update the printable's label here too (if it exists)
        if (this._printLabel != null) {
            if (value)
                this._printLabel.caption = this._rightLabel.caption;
            else
                this._printLabel.caption = this._leftLabel.caption;
        }
    }

    get valueAsString(): string {
        return this.checked.toString();
    }

    set valueAsString(value: string) {
        this.checked = value === "true";
    }

    get caption(): string {
        return this["_mixin-Captioned-caption"];
    }

    set caption(value: string) {
        const oldValue = this.caption;
        if (this["captionValueMatches"](value) === true) {
            return;
        }
        this["_mixin-Captioned-caption"] = value;
        this.syncCaption();
        this._matchIdToValue(oldValue, value);
    }

    syncCaption() {
        if (this.caption == null) {
            //a switch doesn't need the required * in front the caption if there is no caption; the switch will always have a value
            this._captionLabel.caption = "";
        }
        else {
            this._captionLabel.caption = this["getPrefixedCaption"]();
        }
    }

    get leftCaption() {
        return this._leftLabel.caption;
    }

    set leftCaption(value) {
        this._leftLabel.caption = value;
        this.syncLeftCaptionLabel();
    }

    get rightCaption() {
        return this._rightLabel.caption;
    }

    set rightCaption(value) {
        this._rightLabel.caption = value;
        this.syncRightCaptionLabel();
    }

    set captionAlignment(value: Alignment.LEFT | Alignment.TOP) {
        this._captionAlignment = value;
        if (value === Alignment.LEFT) {
            this._element.style.display = "flex";
            this._element.style.flexDirection = "row";
            this._element.style.alignItems = "center";
            this._captionLabel.marginRight = "auto";
            this._captionLabel.marginBottom = 0;
        }
        else {
            this._element.style.display = "unset";
            this._element.style.alignItems = "";
            this._captionLabel.marginRight = 0;
            this._captionLabel.marginBottom = 4;
        }
    }

    get captionAlignment(): Alignment.LEFT | Alignment.TOP {
        if (this._captionAlignment == null)
            return Alignment.TOP;
        else
            return this._captionAlignment;
    }

    private syncLeftCaptionLabel() {
        if (StringUtil.isEmptyString(this._leftLabel.caption) === false) {
            this._switchContainer.style.marginLeft = "8px";
            if (this._switchRow.contains(this._leftLabel._element) === false)
                this._switchRow.insertBefore(this._leftLabel._element, this._switchContainer);
        }
        else {
            this._switchContainer.style.marginLeft = "";
            if (this._switchRow.contains(this._leftLabel._element) === true)
                this._switchRow.removeChild(this._leftLabel._element);
        }
    }

    private syncRightCaptionLabel() {
        if (StringUtil.isEmptyString(this._rightLabel.caption) === false) {
            this._switchContainer.style.marginRight = "8px";
            if (this._switchRow.contains(this._rightLabel._element) === false)
                this._switchRow.insertBefore(this._rightLabel._element, this._switchContainer.nextSibling);
        }
        else {
            this._switchContainer.style.marginRight = "";
            if (this._switchRow.contains(this._rightLabel._element) === true)
                this._switchRow.removeChild(this._rightLabel._element);
        }
    }

    set leftImageName(value) {
        this._leftImageName = value;
        this.handleImage();
    }

    get leftImageName() {
        return this._leftImageName;
    }

    set rightImageName(value) {
        this._rightImageName = value;
        this.handleImage();
    }

    get rightImageName() {
        return this._rightImageName;
    }

    set rightImageProps(value) {
        this._rightImageProps = value;
        this.handleImage();
    }

    get rightImageProps() {
        return this._rightImageProps;
    }

    set leftImageProps(value) {
        this._leftImageProps = value;
        this.handleImage();
    }

    get leftImageProps() {
        return this._leftImageProps;
    }

    get printable(): boolean {
        return this["_mixin-Printable-printable"];
    }

    set printable(value: boolean) {
        this["_mixin-Printable-printable"] = value;
    }

    get printableDuringAdd(): boolean {
        return this["_mixin-Printable-printableDuringAdd"];
    }

    set printableDuringAdd(value: boolean) {
        this["_mixin-Printable-printableDuringAdd"] = value;
    }

    get printableDuringSearch(): boolean {
        return this["_mixin-Printable-printableDuringSearch"];
    }

    set printableDuringSearch(value: boolean) {
        this["_mixin-Printable-printableDuringSearch"] = value;
    }

    get printableDuringUpdate(): boolean {
        return this["_mixin-Printable-printableDuringUpdate"];
    }

    set printableDuringUpdate(value: boolean) {
        this["_mixin-Printable-printableDuringUpdate"] = value;
    }

    handleImage() {
        let image: Image;
        // if (this._allowIndeterminate === true && this.checked === undefined) {
        //   image = new Image({ name: "dash", maxWidth: 14, marginTop: -2, color: "primary.reverse", ...this.rightImageProps });
        // }
        // else {
        if (this.checked && (this.rightImageName != null || this.rightImageProps))
            image = new Image({ name: this.rightImageName, maxWidth: 14, marginTop: -2, color: "primary.reverse", ...this.rightImageProps });
        else if (!this.checked && (this.leftImageName != null || this.leftImageProps))
            image = new Image({ name: this.leftImageName, maxWidth: 14, marginTop: -2, color: "primary.reverse", ...this.leftImageProps });
        //}
        const child = this._thumbInner.firstChild;
        if (image != null) {
            if (child == null)
                this._thumbInner.appendChild(image._element);
            else
                this._thumbInner.replaceChild(image._element, child);
        }
        else if (child != null)
            this._thumbInner.innerHTML = "";
    }

    addChangeListener(value: ChangeListener) {
        this.addEventListener(_changeListenerDef, value);
    }

    removeChangeListener(value: ChangeListener) {
        this.removeEventListener(_changeListenerDef, value);
    }

    public addPrintableListener(value: PrintableListener) {
        this.addEventListener(printableListenerDef, value);
    }

    public removePrintableListener(value: PrintableListener) {
        this.removeEventListener(printableListenerDef, value);
    }

    override getPropertyDefinitions(): ComponentPropDefinitions {
        return SwitchPropDefinitions.getDefinitions();
    }

    override getSearchValues(): string[] {
        const result = [];
        result.push(this._checked ? this._rightLabel.caption : this._leftLabel.caption);
        return result;
    }

    get leftValue(): SwitchValueType {
        return this._leftValue;
    }

    set leftValue(value: SwitchValueType) {
        this._leftValue = value;
    }

    get rightValue(): SwitchValueType {
        return this._rightValue;
    }

    set rightValue(value: SwitchValueType) {
        this._rightValue = value;
    }

    override displayData(data: ModelRow, allData: ModelRow[], rowIndex: number) {
        if (data == null || this.field == null) {
            this.checked = false;
            return;
        }

        const value = data != null ? ((data instanceof ModelRow) ? data.get(this.field) : data[this.field]) : null;
        if (value == null && this.allowIndeterminate === true) {
            this.checked = undefined;
            return;
        }
        if (typeof value === "string") {
            this._setCheckedFromString(value);
        }
        else if (this.rightValue != null) {
            this.checked = value === this.rightValue;
        }
        else if (this.leftValue != null) {
            this.checked = value !== this.leftValue;
        }
        else {
            this.leftValue = false;
            this.rightValue = true;
            this.checked = value === this.rightValue;
        }
    }

    private _setCheckedFromString(value: string) {
        if (value === "Y" || value === "N") {
            if (this.leftValue == null) {
                this.leftValue = "N";
            }
            if (this.rightValue == null) {
                this.rightValue = "Y";
            }
            this.checked = this.rightValue === value;
        }
        else if (value === "true" || value === "false") {
            if (this.leftValue == null) {
                this.leftValue = false;
            }
            if (this.rightValue == null) {
                this.rightValue = true;
            }
            this.checked = value === "true";
        }
        else if (this.rightValue != null) {
            this.checked = value === this.rightValue;
        }
        else if (this.leftValue != null) {
            this.checked = value !== this.leftValue;
        }
        else {
            this.leftValue = false;
            this.rightValue = true;
            this.checked = false;
        }
        this._setCheckedProps(this.checked);
    }


    protected override _fieldBindingChanged(): void {
        super._fieldBindingChanged();
        if ((this.leftValue != null && this.rightValue != null)) {
            return;
        }
        if (this._boundField?.extDataType === ExtendedDataType.YES_NO) {
            this.leftValue = "N";
            this.rightValue = "Y";
        }
    }

    private _internalUpdateBoundData() {
        const row = getRelevantModelRow(this);
        const mode = getCurrentDataSourceMode(this);
        this.updateBoundData(row, mode);
    }

    override updateBoundData(row: ModelRow, mode: DataSourceMode) {
        if (this.field != null && row != null) {
            if (this.checked === true)
                row.set(this.field, this.rightValue, this);
            else if (this.checked === false)
                row.set(this.field, this.leftValue, this);
        }
    }

    override dataSourceModeChanged(mode: DataSourceMode) {
        super.dataSourceModeChanged(mode);
        if (mode === DataSourceMode.SEARCH) {
            this._allowIndeterminate = true;
            this._nextSearchValue = true;
            this.checked = undefined;
        } else {
            this._allowIndeterminate = false;
        }
        this["_syncPrintable"]();
    }

    protected _applyPrintable(value: boolean) {
        if (value) {
            if (this._printLabel == null && this._element.contains(this._switchRow)) {
                this._element.removeChild(this._switchRow);
                if (this._checked)
                    this._printLabel = new Label({ padding: 0, caption: this._rightLabel.caption });
                else
                    this._printLabel = new Label({ padding: 0, caption: this._leftLabel.caption });
                this._element.appendChild(this._printLabel._element);
            }
        }
        else {
            if (this._printLabel != null && this._element.contains(this._printLabel._element))
                this._element.removeChild(this._printLabel._element);
            this._printLabel = null;
            this._element.appendChild(this._switchRow);
            this._syncEnabled();
                    }

        this.fireListeners(printableListenerDef, new PrintableEvent(this, this._printLabel));
    }

    get allowIndeterminate(): boolean {
        return this._allowIndeterminate;
    }

    set allowIndeterminate(value: boolean) {
        this._allowIndeterminate = value;
    }

    get captionVisible(): boolean {
        return this._captionVisible == null ? true : this._captionVisible;
    }

    set captionVisible(value: boolean) {
        this._captionVisible = value;
        const contains = this._element.contains(this._captionLabel._element);
        if (value && !contains)
            this._element.insertBefore(this._captionLabel._element, this._switchRow);
        else if (!value && contains)
            this._element.removeChild(this._captionLabel._element);
        if (value)
            this._element.classList.remove(SwitchStyles.noCaption);
        else
            this._element.classList.add(SwitchStyles.noCaption);
    }

    private _trackColor: Color;

    public get trackColor(): Color {
        return this._trackColor;
    }

    public set trackColor(value: Color) {
        this._trackColor = value;
        this._track.style.backgroundColor = getThemeColor(value);
    }

    protected _getDefaultEventProp(): string {
        return "onChange";
    }

    override get serializationName() {
        return "switch";
    }

    override get properName(): string {
        return "Switch";
    }

    public override getEventTarget(): HTMLElement {
        return this._switchContainer;
    }

    override getListenerDefs(): Collection<ListenerListDef> {
        return {
            ...super.getListenerDefs(),
            "change": { ..._changeListenerDef },
            "printable": { ...printableListenerDef}
         };
    }

    override getBasicValue(): any {
        return this.checked;
    }

    override _applyEnabled(value: boolean): void {
        if (value) {
            this._track.removeAttribute("disabled");
            this._track.setAttribute("tabindex", "0");
            this._track.classList.remove(SwitchStyles.trackDisabled);
            this._thumb.classList.remove(SwitchStyles.thumbDisabled);
            this._thumbInner.classList.remove(SwitchStyles.thumbInnerDisabled);
            this._thumb.classList.add(SwitchStyles.thumb);
            this._thumbInner.classList.add(SwitchStyles.thumbInner);
            this._addTrackListeners();
            this._setCheckedProps(this.checked);
        }
        else {
            this._track.setAttribute("disabled", "true");
            this._track.setAttribute("tabindex", "-1");
            this._track.classList.remove(SwitchStyles.track);
            this._thumb.classList.remove(SwitchStyles.thumb);
            this._thumbInner.classList.remove(SwitchStyles.thumbInner);
            this._track.classList.add(SwitchStyles.trackDisabled);
            this._thumb.classList.add(SwitchStyles.thumbDisabled);
            this._thumbInner.classList.add(SwitchStyles.thumbInnerDisabled);
            this._clearTrackListeners();
        }
    }

    private _addTrackListeners() {
        if (this._track.onclick == null) {
            this._track.onclick = event => {
                this.toggle(event);
            };
        }
        if (this._track.onkeydown == null) {
            this._track.onkeydown = (event) => {
                if (event.key === " ") {
                    this.toggle(event);
                    event.preventDefault();
                }
            };
        }
    }

    private _clearTrackListeners() {
        this._track.onclick = null;
        this._track.onkeydown = null;
    }

    protected _initialDropInDesigner() {
        this.leftCaption = "No";
        this.rightCaption = "Yes";
    }

    getPermissionsTypes(): PermissionsDefinition[] {
        return [
            ...super.getPermissionsTypes(),
            {
                permsType: "E",
                description: "Edit security",
                availableToAllDescription: "Everyone can edit this item",
                availableToNoneDescription: "This item is read-only to everyone"
            }
        ];
    }


    override getPropertyDefaultValue(prop: ComponentPropDefinition): any {
        if (prop.name === "leftValue" && this._boundField?.extDataType === ExtendedDataType.YES_NO)
            return "N";
        else if (prop.name === "rightValue" && this._boundField?.extDataType === ExtendedDataType.YES_NO) {
            return "Y";
        }
        return super.getPropertyDefaultValue(prop);
    }
}

JSUtil.applyMixins(Switch, [Captioned, Printable]);
ComponentTypes.registerComponentType("switch", Switch.prototype.constructor);
