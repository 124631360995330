import { getThemeColor, makeStyles } from "@mcleod/core";

export const TableRowStyles = makeStyles("row", () => {
    const hoverBackground = getThemeColor("table.hoverBackground");
    const result = {
        base: {
            display: "table",
            width: "100%",
            tableLayout: "fixed",
            borderBottom: "1px solid #AAA",
            minHeight: "32px",
            padding: "0px",
            borderSpacing: "0px",
            backgroundPosition: "center",
            transition: "background 300ms",
        },
        hoverShade: {
            "&:hover": {
                background: hoverBackground + " radial-gradient(circle, transparent 1%, " + hoverBackground + " 1%) center/15000%"
            },
        }
    }
    return result;

});
