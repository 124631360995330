import { DynamicLoader, getLogger } from "@mcleod/core";
import { Component } from "../base/Component";
import { DesignableObject } from "../base/DesignableObject";
import { ListenerListDef } from "../base/ListenerListDef";
import { ClickEvent } from "./ClickEvent";
import { DomEvent, DomEventListener } from "./DomEvent";
import { DragEvent } from "./DragEvent";
import { Event, EventListener } from "./Event";

const log = getLogger("components.events.EventListenerList");

export class EventListenerList {
    private object: DesignableObject;
    private domEventListener: DomEventListener;
    private _listeners: EventListener[];
    private firingListeners: boolean;
    private eventDef: ListenerListDef;

    constructor(object: DesignableObject, eventDef?: ListenerListDef) {
        this.object = object;
        this.eventDef = eventDef;
    }

    public hasListener(listener: EventListener): boolean {
        return this.indexOf(listener) >= 0;
    }

    public indexOf(listener: EventListener) {
        const search = listener["wrapped"] || listener;
        for (let i = 0; i < this._listeners?.length; i++)
            if (this.listeners[i] === search || this.listeners[i]?.["wrapped"] === search)
                return i;
        return -1;
    }

    public add(listener: EventListener) {
        if (this._listeners == null)
            this._listeners = [];
        if (this.indexOf(listener) < 0) {
            this._listeners.push(listener);
            this.syncDomEvent();
        }
    }

    public insert(listener: EventListener, index: number) {
        if (this._listeners == null)
            this._listeners = [];
        if (this.indexOf(listener) >= 0)
            return;
        const i = index >= 0 ? index : 0;
        if (i >= this._listeners.length)
            this._listeners.push(listener);
        else
            this._listeners.splice(i, 0, listener);
        this.syncDomEvent();
    }

    public remove(listener: EventListener) {
        const index = this.indexOf(listener);
        if (index >= 0) {
            this._listeners.splice(index, 1);
            this.syncDomEvent();
        }
    }

    public removeAll() {
        this._listeners = null;
        this.syncDomEvent();
    }

    public isEmpty(): boolean {
        return this._listeners == null || this._listeners.length === 0;
    }

    public get length(): number {
        return this._listeners == null ? 0 : this._listeners.length;
    }

    public get listeners(): EventListener[] {
        return this._listeners;
    }

    private _allowedToFireListeners(eventCreator: Event | (() => Event)): boolean {
        if (this.isEmpty())
            return false;
        if (this.firingListeners === true) // prevent firing a given list of listeners while it is already being fired (infinite recursion)
            return false;
        if (this.object.getDesigner() == null && this.object._interactionEnabled !== false)
            return true;
        if (eventCreator instanceof DragEvent) // need a better way to handle drag events in the designer
            return true;
        if (["tabHeading", "designerTabHeading"].includes(this.object['id']) && eventCreator instanceof ClickEvent) // this is worse than how we are handling drag events...and I made it even worse
            return true;
        return false;
    }

    fireListeners<EventType extends Event = Event>(eventCreator: Event | (() => Event)): EventType {
        if (!this._allowedToFireListeners(eventCreator))
            return undefined;
        let event = eventCreator;
        if (typeof event === "function")
            event = event();
        const validatorResult = this._validateBeforeFiring(event);
        if (!validatorResult) {
            event.preventDefault();
            return event as EventType;
        }

        if (this._listeners != null) {
            this.firingListeners = true;
            try {
                let listenersThatRunOnce: EventListener[];
                for (const listener of this._listeners) {
                    if (listener.runsOnce === true) {
                        if (listenersThatRunOnce == null)
                            listenersThatRunOnce = [];
                        listenersThatRunOnce.push(listener);
                    }
                    listener(event);
                    if (event.stoppedImmediatePropagation)
                        break;
                }
                if (listenersThatRunOnce != null) {
                    for (const listener of listenersThatRunOnce)
                        this.remove(listener);
                }
            } finally {
                delete this.firingListeners;
            }
        }
        return event as EventType;
    }

    private _validateBeforeFiring(event: Event): boolean {
        const validator = this.eventDef?.validateFunction;
        if (validator != null) {
            if (typeof validator === "string") {
                const validatorFunc = event.target[validator];
                if (validatorFunc != null && typeof validatorFunc === "function")
                    event.target[validator](event);
            }
            else if (typeof validator === "function")
                return validator(event);
        }
        return true;
    }

    private syncDomEvent() {
        if (this.eventDef?.domEventName != null && this.eventDef?.eventCreatorFunction != null) {
            const needsListener = !this.isEmpty();
            const target = this.eventDef?.getTargetFunction == null ? this.object.getEventTarget() : this.eventDef?.getTargetFunction(this.object);
            if (needsListener) {
                if (this.domEventListener == null)
                    this.domEventListener = (event: DomEvent) => this.fireListenersFromDomEvent(event);
                target?.addEventListener(this.eventDef?.domEventName, this.domEventListener);
            } else if (this.domEventListener != null)
                target?.removeEventListener(this.eventDef?.domEventName, this.domEventListener);
        }
    }

    private fireListenersFromDomEvent(domEvent: DomEvent) {
        if (this.object instanceof Component && !this.object.domEventFiringListeners(domEvent, this.eventDef))
            return;
        const event = this.eventDef?.eventCreatorFunction(this.object, domEvent)
        this.fireListeners(event);

        if (event.shouldAutomaticallyStopPropagation)
            event.stopPropagation();
    }

    public copyFor(designableObject: DesignableObject): EventListenerList {
        const copy = new EventListenerList(designableObject, this.eventDef);
        if (this.listeners != null)
            for (const l of this.listeners)
                copy.add(l);
        return copy;
    }
}

export function getOwnerFunction(object: DesignableObject, eventName: string, functionName: string): (Event) => void {
    if (object.owner == null)
        throw new Error("Could not locate " + eventName + " event because object has no owner.  Function " + functionName);
    const event = object.owner[functionName];
    if (event == null)
        eventMissing(object.owner, eventName, functionName);
    return event.bind(object.owner);
}

function eventMissing(owner: any, eventName: string, functionName) {
    let ownerClassName: string;
    if (owner.constructor != null)
        ownerClassName = owner.constructor.name;
    else
        ownerClassName = "(owner class unknown)";
    let message = "Could not locate the event handler function " + functionName + " for the event " + eventName;
    // if we are looking for an owner function (an event handler) and our owner class is a Layout, that means we didn't find the owner class
    if (ownerClassName === "Layout") {
        message += " because the owner object is an instance of Layout instead of a Layout subclass.";
        if (owner["layoutName"] != null)
            message += "  Maybe the " + owner["layoutName"] + ".ts class is missing.";
        // dump the class map to debug to help figure out why the class couldn't be found    
        log.debug("DynamicLoader.getPathClassMap():", DynamicLoader.getPathClassMap());
        throw new Error(message);
    }
    throw new Error(message + " in owner object " + ownerClassName + ".");
}
