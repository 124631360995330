import { Alignment, DOMUtil, ObjectUtil, Padding, PaddingResolver, WindowTitle } from "@mcleod/core";
import { Label, Panel } from "../..";
import { Component } from "../../base/Component";
import { McLeodMainPageUtil } from "../../base/McLeodMainPageUtil";
import { TransitionOptions } from "../../base/TransitionOptions";
import { DataHeader } from "../../components/dataheader/DataHeader";
import { DataHeaderProps } from "../../components/dataheader/DataHeaderProps";
import { DataSourceMode } from "../../databinding/DataSource";
import { OverlayProps } from "../OverlayProps";
import { CrudDecoratorProps } from "./CrudDecoratorProps";
import { Decorator } from "./Decorator";

export class CrudDecorator extends Decorator implements CrudDecoratorProps {
    public key: string;
    providedApiData: any | (() => any);
    private dataHeader: DataHeader;
    public mode: DataSourceMode;
    private _headerProps: Partial<DataHeaderProps>;
    private _overlayWrapper: Panel;
    private additionalSaveButtonLabels: Label[] = [];
    private _doAfterSlideIn: (decorator: CrudDecorator) => void;
    private _doAfterSlideOut: (decorator: CrudDecorator) => void;
    private _documentTitleBefore: string;

    constructor(props: Partial<CrudDecoratorProps>) {
        super(props);
        this.fillHeight = true;
        this._documentTitleBefore = WindowTitle.get();
    }

    override layoutLoaded() {
        this._setupDataHeader();
        this.layout.doAfterDataHeaderSetup(this.dataHeader);
        const dataSource = this.layout.mainDataSource;
        this.dataHeader.dataSource = dataSource;
        this.dataHeader.setSaveButtonAddlActions(this.additionalSaveButtonLabels);
        if (dataSource != null) {
            if (this.providedApiData == null || this.layout["loadFromApiData"] == null) {
                if (this.key != null && this.mode === DataSourceMode.UPDATE) {
                    const md = dataSource.getMetadataFromCache();
                    const keyField = md?.keyFields?.[0] || "id";
                    dataSource.search({ [keyField]: this.key }).then(result => {
                        this.layout.setAllDataSourcesToMode(DataSourceMode.UPDATE);
                    });
                }
                else if (this.mode != null) {
                    this.layout.setAllDataSourcesToMode(this.mode);
                }
            }
            else
                this.layout["loadFromApiData"](this.providedApiData, this.mode);
        }
    }

    private _setupDataHeader() {
        if (this.layout.dataHeader == null) {
            this.dataHeader = new DataHeader({ layout: this.layout });
            //need to insert DataHeader as quickly as possible so that it can update key listeners as it is built (needs to be tied back to its parent panel)
            this.insert(this.dataHeader, 0);
            this.dataHeader.setProps(this.headerProps);
            this.dataHeader.setSaveButtonAddlActions(this.additionalSaveButtonLabels);
        }
        else {
            this.dataHeader = this.layout.dataHeader;
            //need to insert DataHeader as quickly as possible so that it can update key listeners as it is built (needs to be tied back to its parent panel)
            this.insert(this.dataHeader, 0);
            this.dataHeader.layout = this.layout;
            if (ObjectUtil.isEmptyObject(this.headerProps) !== true)
                this.dataHeader.setProps(this.headerProps);
        }
        this.defaultDataHeaderCloseActions();
    }

    get headerProps(): Partial<DataHeaderProps> {
        return this._headerProps;
    }

    public set headerProps(value: Partial<DataHeaderProps>) {
        this._headerProps = value;
        this.dataHeader?.setProps(value);
        this.dataHeader?.setSaveButtonAddlActions(this.additionalSaveButtonLabels);
    }

    /**
     * If props didn't specify a close action (for either the close X button or the Save & Close),
     * assume we should slide the CrudDecorator out.  This makes it so we don't have to specify these options
     * for every CrudDecorator that is constructed.
     *
     * If a CrudDecorator does provide onClose or onSaveButtonClose props to the DataHeader, those functions
     * must slide out the CrudDecorator.
     */
    private defaultDataHeaderCloseActions() {
        if (this.dataHeader.onClose == null)
            this.dataHeader.onClose = () => this.slideOut();
        if (this.dataHeader.onSaveButtonClose == null)
            this.dataHeader.onSaveButtonClose = () => this.slideOut();
    }

    get doAfterSlideIn(): (decorator: CrudDecorator) => void {
        return this._doAfterSlideIn;
    }

    set doAfterSlideIn(value: (decorator: CrudDecorator) => void) {
        this._doAfterSlideIn = value;
    }

    get doAfterSlideOut(): (decorator: CrudDecorator) => void {
        return this._doAfterSlideOut;
    }

    set doAfterSlideOut(value: (decorator: CrudDecorator) => void) {
        this._doAfterSlideOut = value;
    }

    public override async slideIn(options?: TransitionOptions, displayOverlay?: boolean, overlayProps?: Partial<OverlayProps>, paddingOverride?: Partial<Padding>): Promise<any> {
        if (options == null)
            options = {};
        if (options.direction == null)
            options.direction = Alignment.BOTTOM;
        if (displayOverlay == null)
            displayOverlay = true;
        if (overlayProps == null)
            overlayProps = {};
        if (overlayProps.closeOnClickOff == null)
            overlayProps.closeOnClickOff = false;
        if (overlayProps.coverPageHeader == null)
            overlayProps.coverPageHeader = false;
        if (paddingOverride == null)
            paddingOverride = {};
        this._createOverlayWrapper(paddingOverride);
        return this._overlayWrapper.slideIn(options, displayOverlay, overlayProps).then(() => {
            if (this.doAfterSlideIn != null)
                this.doAfterSlideIn(this);
        });
    }

    public override async slideOut(options?: TransitionOptions, componentToFocusOnClose?: Component): Promise<any> {
        return this._overlayWrapper.slideOut(options, componentToFocusOnClose).then(() => {
            //remove the CrudDecorator from the overlay wrapper in case we slide it in again (which would be in a new wrapper)
            this._overlayWrapper.remove(this);

            WindowTitle.set(this._documentTitleBefore);
            if (this.doAfterSlideOut != null)
                this.doAfterSlideOut(this);
        });
    }

    private _createOverlayWrapper(paddingOverride?: Partial<Padding>) {
        const paddingOverrideLeft = PaddingResolver.getPaddingLeft(paddingOverride);
        const routerPanelPaddingLeft = paddingOverrideLeft == null ? DOMUtil.convertStyleAttrToNumber(DOMUtil.getComputedStyle("padding-left", McLeodMainPageUtil.getRouterPanel()._element)) : paddingOverrideLeft;
        const paddingOverrideRight = PaddingResolver.getPaddingRight(paddingOverride);
        const routerPanelPaddingRight = paddingOverrideRight == null ? DOMUtil.convertStyleAttrToNumber(DOMUtil.getComputedStyle("padding-right", McLeodMainPageUtil.getRouterPanel()._element)) : paddingOverrideRight;
        const paddingOverrideTop = PaddingResolver.getPaddingTop(paddingOverride);
        const routerPanelPaddingTop = paddingOverrideTop == null ? DOMUtil.convertStyleAttrToNumber(DOMUtil.getComputedStyle("padding-top", McLeodMainPageUtil.getRouterPanel()._element)) : paddingOverrideTop;
        const widthToSubtract = routerPanelPaddingLeft + routerPanelPaddingRight;
        this._overlayWrapper = new Panel({
            id: "overlay-wrapper",
            padding: 0,
            width: "calc(100% - " + widthToSubtract + "px)",
            height: "calc(100% - " + routerPanelPaddingTop + "px)",
            marginLeft: routerPanelPaddingLeft,
            marginRight: routerPanelPaddingRight,
            backgroundColor: "backgroundSubtle",
            borderColor: "strokeSecondary",
            borderRadius: 4,
            borderWidth: 1,
            borderShadow: true,
            scrollX: true,
            scrollY: true
        });
        this._overlayWrapper.add(this);
    }

    public addOptionToSaveButton(caption: string, onClick: () => void) {
        this.additionalSaveButtonLabels.push(new Label({ caption: caption, onClick: onClick }));
        this.dataHeader?.setSaveButtonAddlActions(this.additionalSaveButtonLabels);
    }
}
