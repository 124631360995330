import {
    Alignment, DynamicLoader, HorizontalAlignment, Keys, ModelRow, Navigation, NavOptions, PermissionsUtil, UrlUtil
} from "@mcleod/core";
import {
    Button, ButtonVariant, Component, DataSourceMode, Layout, List, ListItemType, PanelProps, StringOrPropsOrComponent,
    TableRow
} from "../..";
import { SelectionMode } from "../../base/SelectionMode";
import { ClickEvent } from "../../events/ClickEvent";
import { CrudDecorator } from "../../page/decorators/CrudDecorator";
import { CrudDecoratorProps } from "../../page/decorators/CrudDecoratorProps";
import { getComponentFromStringOrPropsOrComponent } from "../../page/getComponentFromStringOrPropsOrComponent";
import { ButtonProps } from "../button/ButtonProps";
import { Panel } from "../panel/Panel";
import { Table } from "./Table";
import { PinnedSearch } from "./PinnedSearch";
import { TableToolsExportOptions } from "./TableToolsExportOptions";
import { TableToolsConfigOptions } from "./TableToolsConfigOptions";
import { ReflectiveDialogs } from "../../base/ReflectiveDialogs";
import { ReflectiveSnackbars } from "../../base/ReflectiveSnackbars";

export enum DetailMode {
    SAME_TAB,
    NEW_TAB_NO_SWITCH,
    NEW_TAB_WITH_SWITCH,
    NEW_WINDOW
}

const basicDropdownProps: Partial<ButtonProps> = {
    borderWidth: 0,
    borderRadius: 0,
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    minHeight: 34,
    align: HorizontalAlignment.LEFT,
    fontSize: "medium"
};

export class TableToolsPanel extends Panel {
    private table: Table;
    private _addCaption: string;
    private stdLeftTools: Panel;
    private stdRightTools: Panel;
    private postStdTools: Panel;
    leftTools: Panel;
    rightTools: Panel;
    private toolSpacer: Panel;
    private buttonAdvancedSearch: Button;
    private buttonDelete: Button;
    private buttonAdd: Button;
    private buttonShare: Button;
    private buttonDefaultDetail: Button;
    private buttonDetailDropdown: Button;
    private buttonEllipsis: Button;
    private crudPanel: Panel;
    public determineDetailLayout: () => string;
    public overrideUrlParams: (tableRow?: TableRow) => any;

    constructor(table: Table, props?: Partial<PanelProps>) {
        super({ padding: 0, fillRow: true, ...props });
        this.table = table;
        this.stdLeftTools = new Panel({ id: "stdLeftTools", rowBreak: false });
        this.leftTools = new Panel({ id: "leftTools", rowBreak: false, rowBreakDefault: false, _designer: table._designer });
        this.postStdTools = new Panel({ id: "postStdTools", rowBreak: false });
        this.toolSpacer = new Panel({ id: "toolSpacer", rowBreak: false, fillRow: true });
        this.rightTools = new Panel({ id: "rightTools", _designer: table._designer, rowBreak: false });
        this.stdRightTools = new Panel({ id: "stdRightTools" });
        this.createButtons();
        this.add(this.stdLeftTools, this.leftTools, this.postStdTools, this.toolSpacer, this.rightTools, this.stdRightTools);
        this.addMountListener(() => this.syncAddCaption());
    }

    addTool(tool: StringOrPropsOrComponent, addToLeftSection: boolean = true) {
        const target = addToLeftSection ? this.leftTools : this.rightTools;
        return target.add(getComponentFromStringOrPropsOrComponent(tool));
    }

    removeTool(...tools: Component[]) {
        if (tools != null)
            for (const tool of tools) {
                this.leftTools.remove(tool);
                this.rightTools.remove(tool);
            }
    }

    private createButtons() {
        const buttonProps = { rowBreak: false, color: "primary", imageWidth: 20, imageHeight: 20 };
        this.buttonAdvancedSearch = new Button({
            ...buttonProps,
            variant: ButtonVariant.round,
            imageName: "magnifyingGlassPage",
            hotkey: "Alt-S",
            onClick: () => this.showSearch(),
            tooltip: "Show advanced search options (Alt-S)",
        });
        this.buttonAdvancedSearch.addHandlerForKey(Keys.S, { altKey: true });
        this.buttonDelete = new Button({
            ...buttonProps,
            variant: ButtonVariant.round,
            enabled: false,
            imageName: "delete",
            onClick: () => this.handleDelete(),
            tooltip: "Delete the selected rows"
        });
        this.buttonShare = new Button({
            ...buttonProps,
            variant: ButtonVariant.round,
            enabled: false,
            imageName: "share",
            onClick: () => this.showShare(),
            tooltip: "Share the selected row"
        });
        this.buttonDefaultDetail = new Button({
            ...buttonProps,
            enabled: false,
            hotkey: "Alt-D",
            padding: 4,
            borderWidth: 0,
            margin: 0,
            marginRight: 0,
            paddingRight: 0,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            onClick: () => this.showDefaultDetail(),
            ...this.getDetailButtonProps(this.getDefaultDetailMode())
        });
        this.buttonDetailDropdown = new Button({
            ...buttonProps,
            enabled: false,
            imageName: "arrow",
            imageWidth: 12,
            imageHeight: 12,
            padding: 8,
            borderWidth: 0,
            margin: 0,
            paddingLeft: 0,
            marginLeft: 0,
            paddingRight: 2,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            dropdownProps: { align: Alignment.RIGHT, width: 208 },
            dropdownListProps: { selectionMode: SelectionMode.NONE },
            dropdownItems: () => this.getDetailDropdownItems(),
            tooltip: "Show other options for drilling down to the selected row in this table"
        });
        this.buttonEllipsis = new Button({
            ...buttonProps,
            enabled: true,
            variant: ButtonVariant.round,
            imageName: "ellipsis",
            tooltip: "Show more options",
            dropdownListProps: { maxHeight: 800 },
            dropdownItems: () => this.getEllipsisDropdownOptions()
        });
        this.buttonAdd = new Button({
            id: "buttonAdd",
            caption: "Add",
            imageName: "add",
            color: "primary",
            hotkey: "Alt-A",
            onClick: () => this.showAdd(),
            tooltip: "Add a new record",
        });
    }

    handleRowDblClick(event: ClickEvent) {
        if (event.hasModifiers({ ctrlKey: true, shiftKey: true }))
            this.showDetail(DetailMode.NEW_TAB_WITH_SWITCH);
        else if (event.hasModifiers({ shiftKey: true }))
            this.showDetail(DetailMode.NEW_WINDOW);
        else if (event.hasModifiers({ ctrlKey: true }))
            this.showDetail(DetailMode.NEW_TAB_NO_SWITCH);
        else
            this.showDetail(DetailMode.SAME_TAB);
    }

    public get addCaption(): string {
        return this._addCaption == null ? "Add" + this.getLayoutTitleSuffix() : this._addCaption;
    }

    public set addCaption(value: string) {
        this._addCaption = value;
        this.syncAddCaption();
    }

    private getDefaultDetailMode(): DetailMode {
        return DetailMode.SAME_TAB;
    }

    private getDetailButtonProps(mode: DetailMode): Partial<ButtonProps> {
        switch (mode) {
            case DetailMode.NEW_TAB_NO_SWITCH: // when clicking from the toolbar, there doesn't seem like a reason to not switch to it
            case DetailMode.NEW_TAB_WITH_SWITCH: return { imageName: "tab", tooltip: "Drill down to the selected row in a new browser tab" };
            case DetailMode.NEW_WINDOW: return { imageName: "overlappingWindows", tooltip: "Drill down to the selected row in a new browser window" };
            default: return { imageName: "detail", tooltip: "Drill down to the selected row in this table in this browser tab" };
        }
    }

    private getDetailDropdownItems(): Button[] {
        const result: Button[] = [];
        this.addDetailDropdownItem(result, DetailMode.SAME_TAB, "Drill down in this tab");
        this.addDetailDropdownItem(result, DetailMode.NEW_TAB_WITH_SWITCH, "Drill down in new tab");
        this.addDetailDropdownItem(result, DetailMode.NEW_WINDOW, "Drill down in new window");
        return result;
    }

    private addDetailDropdownItem(array: Button[], mode: DetailMode, caption: string) {
        const defaultMode = this.getDefaultDetailMode();
        if (mode != defaultMode) {
            array.push(new Button({
                ...basicDropdownProps,
                marginTop: 0,
                marginBottom: 0,
                paddingTop: 4,
                paddingLeft: 8,
                paddingRight: 4,
                paddingBottom: 4,
                caption: caption,
                fillRow: true,
                ...this.getDetailButtonProps(mode),
                onClick: () => this.showDetail(mode)
            }));
        }
    }

    public syncTools() {
        // ideally, we want to execute this only once during initialization of the Table from a .layout
        this.stdLeftTools.removeAll();
        this.stdRightTools.removeAll();

        this.internalAddTool(this.table.allowAdvancedSearch, this.stdLeftTools, this.buttonAdvancedSearch);
        this.internalAddTool(this.table.allowDelete, this.stdLeftTools, this.buttonDelete);
        this.internalAddTool(this.table.allowShare, this.stdLeftTools, this.buttonShare);

        if (this.table.allowDetail) {
            this.internalAddTool(true, this.stdLeftTools, this.buttonDefaultDetail);
            this.internalAddTool(true, this.stdLeftTools, this.buttonDetailDropdown);
        }

        this.internalAddTool(this.table.allowConfig || this.table.allowPin || this.table.allowExport, this.postStdTools, this.buttonEllipsis);
        this.internalAddTool(this.table.allowAdd, this.stdRightTools, this.buttonAdd);
    }

    private internalAddTool(condition: boolean, toolParent: Panel, tool: Component) {
        if (condition) {
            toolParent.add(tool);
            tool._designer = this.table._designer;
        }
    }

    private closeCrud() {
        this.crudPanel.slideOut();
    }

    public showShare() {

    }

    public async showAdd() {
        const url = "/" + this.table.addLayout + "?mode=add";
        if (await Navigation.pseudoNavigateTo(url, () => this.closeCrud())) {
            this.showCrud(DataSourceMode.ADD, null, (addedRow: ModelRow) => {
                this.table.dataSource.addRow(addedRow, 0);
            });
        }
    }

    public getPopoutNavOptions(): Partial<NavOptions> {
        const windowSize = {
            width: 1400,
            height: 700,
        };
        const loc = {
            left: ((window.screen as any).availLeft + (window.screen.availWidth / 2)) - (windowSize.width / 2),
            top: ((window.screen as any).availTop + (window.screen.availHeight / 2)) - (windowSize.height / 2)
        };
        return { left: loc.left, top: loc.top, height: 700, width: 1400, newTab: true, windowDecorators: false };
    }

    public showDefaultDetail(): void {
        this.showDetail(DetailMode.SAME_TAB);
    }

    public async showDetail(detailMode: DetailMode) {
        const row = this.table?.selectedRow?.data as ModelRow;
        if (row == null)
            return;
        const keyData = row.getKeyData();
        const keys = Object.keys(keyData);
        const key = keyData[keys[0]] as string;
        this.table.detailLayout = this.determineDetailLayout != undefined ? this.determineDetailLayout() : this.table.detailLayout;
        const urlParam = { mode: "update", ...(this.overrideUrlParams != null ? this.overrideUrlParams(this.table.selectedRow) : { key }) }
        const url = "/" + this.table.detailLayout + UrlUtil.buildQueryString(urlParam);
        if (detailMode === DetailMode.SAME_TAB) {
            if (await Navigation.pseudoNavigateTo(url, () => this.closeCrud())) {
                this.showCrud(DataSourceMode.UPDATE, { key: key }, async (updatedRow: ModelRow) => {
                    this.table.resolveRowEdit(this.table.selectedRow.data, updatedRow);
                });
            }
        }
        else if (detailMode === DetailMode.NEW_WINDOW)
            Navigation.navigateTo(url, this.getPopoutNavOptions());
        else if (detailMode === DetailMode.NEW_TAB_NO_SWITCH) {
            Navigation.navigateTo(url, { newTab: true });
            window.focus();
        }
        else
            Navigation.navigateTo(url, { newTab: true });
    }

    public showSearch() {
        this.showCrud(DataSourceMode.SEARCH, null, (filterValues: ModelRow) => this.table.dataSource.search(filterValues));
    }

    public showCrud(mode: DataSourceMode, crudDecoratorProps: Partial<CrudDecoratorProps>, onExecute: (row: ModelRow) => void) {
        let layout: Layout;
        switch (mode) {
            case DataSourceMode.ADD:
                layout = Layout.getLayout(this.table.addLayout);
                break;
            case DataSourceMode.UPDATE:
                layout = Layout.getLayout(this.table.detailLayout);
                break;
            case DataSourceMode.SEARCH:
                layout = Layout.getLayout(this.table.searchLayout);
                break;
            default:
                layout = Layout.getLayout(this.table.generalLayout);
                break;
        }
        this.crudPanel = new CrudDecorator({
            layout: layout,
            mode: mode,
            headerProps: {
                showClose: true,
                showSaveAndClose: true,
                onClose: () => {
                    if (mode === DataSourceMode.SEARCH)
                        this.closeCrud();
                    else
                        Navigation.navigateBack();
                },
                onSaveButtonClose: () => Navigation.navigateBack(),
                onExecute: onExecute
            },
            ...crudDecoratorProps
        });
        this.crudPanel.slideIn({ speed: 200 });
    }

    selectionChanged() {
        const sel = this.table.selectedRows.length === 1;
        this.buttonDelete.enabled = sel;
        this.buttonDefaultDetail.enabled = sel;
        this.buttonDetailDropdown.enabled = sel;
        this.buttonShare.enabled = sel;
    }

    async getEllipsisDropdownOptions(): Promise<ListItemType[]> {
        const result: ListItemType[] = [];
        if (this.table.allowPin === true) {
            const pinnedSearch = new PinnedSearch(DynamicLoader.getRouteFromURL(), this.table.dataSource, basicDropdownProps);
            const pinnedSearchItem = await pinnedSearch.getDropdownItem();
            result.push(pinnedSearchItem);
        }
        if (this.table.allowConfig === true && PermissionsUtil.isUserDeniedAction("System.Update grid configurations") !== true) {
            this.addSeparator(result);
            const configOptions = new TableToolsConfigOptions(this.table, basicDropdownProps);
            const configItem = await configOptions.getConfigDropdownItem();
            result.push(configItem);
        }
        if (this.table.allowExport === true) {
            this.addSeparator(result);
            const exportItem = new TableToolsExportOptions(this.table, basicDropdownProps).getDropdownExportItem();
            result.push(exportItem);
        }
        return result;
    }

    private addSeparator(array: ListItemType[]) {
        if (array.length > 0) {
            const item = List.createSeparator();
            item.renderedComponent.paddingTop = 0;
            item.renderedComponent.paddingBottom = 0;
            array.push(item);
        }
    }

    private syncAddCaption() {
        this.buttonAdd.caption = this.addCaption;
    }

    private getLayoutTitleSuffix(): string {
        let parent = this.parent;
        while (parent != null && !(parent instanceof Layout))
            parent = parent.parent;
        const title = (parent as Layout)?.titleSingular?.toString();
        return title == null ? "" : " " + title;
    }

    async handleDelete() {
        const sel = this.table.selectedRows;
        if (sel.length === 0) {
            ReflectiveDialogs.showMessage("No records are selected to delete.");
            return;
        }
        const prompt = "Are you sure you want to delete the selected " + (sel.length === 1 ? "record" : "records") + "?";
        if (await ReflectiveDialogs.showYesNo(prompt, "Confirm Delete")) {
            for (let i = sel.length - 1; i >= 0; i--) {
                await sel[i].data.delete();
                this.table.removeRow(sel[i].index);
            }
            ReflectiveSnackbars.showSnackbar("Selected " + (sel.length === 1 ? "record has" : "records have") + " been deleted.");
        }
    }
}
