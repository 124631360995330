import { getLogger } from "./Logger";
import { UUID } from "./UUID";

const log = getLogger("core.InstanceIdentifier");
const LOCAL_STORAGE_KEY = "instanceIdentifier";

export class InstanceIdentifier {
    public static get() {
        let result = sessionStorage.getItem(LOCAL_STORAGE_KEY);
        if (result == null) {
            result = UUID.randomUUID();
            sessionStorage.setItem(LOCAL_STORAGE_KEY, result);
            log.debug("Set instance identifer value: %o", result);
        }
        return result;
    }

    public static _clear() {
        log.debug("Clearing instance identifer value, was %o", sessionStorage.getItem(LOCAL_STORAGE_KEY));
        sessionStorage.removeItem(LOCAL_STORAGE_KEY);
    }
}