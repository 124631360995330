import { Label, Layout } from "@mcleod/components";
import { AuthType, getLogger } from "@mcleod/core";
import { diagnosticData } from "./DiagnosticData";
import { SupportPrompt } from "./SupportPrompt";

const log = getLogger("common.Login");

export class SupportDiagnostic extends Layout {
    constructor(props?: any) {
        super({
            needsServerLayout: false,
            auth: AuthType.UNAUTH,
            ...props
        });
        const labelHeading = new Label({
            caption: "Customer reports a performance problem",
            color: "primary",
            fontSize: "xxxlarge",
            marginBottom: 8,
            paddingBottom: 8,
            borderBottomWidth: 1,
            borderBottomColor: "default.lightest",
            fontBold: true
        });
        this.add(labelHeading);
        this.addStep("performance-problem/entry");
    }

    addStep(path: string) {
        const data = diagnosticData[path];
        const prompt = new SupportPrompt(data, answer => this.addStep(answer.next));
        this.add(prompt);
    }
}
