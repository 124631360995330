import { DOMUtil, getLogger, getThemeColor, Keys, StringUtil, VerticalAlignment } from "@mcleod/core";
import { Image, Panel } from "..";
import { Component } from "../base/Component";
import { Label } from "../components/label/Label";
import { PanelProps } from "../components/panel/PanelProps";
import { ScreenStack } from "../components/panel/ScreenStack";
import { KeyHandler } from "../events/KeyHandler";
import {
    getComponentFromStringOrPropsOrComponent, StringOrPropsOrComponent
} from "./getComponentFromStringOrPropsOrComponent";

export interface SnackbarOptions {
    persist?: boolean;
    millisUntilDimissal?: number;
    warningTitle?: string;
    onDismiss?: () => void;
    id?: string;
    targetPanel?: Panel;
    //dismissButtonCaption?: string;
}

export interface AddedSnackBarInfo {
    parentPanelBottom: number;
    parentPanelLeft: number;
    previousSnackHeight?: number;
    previousSnackBottom?: number;
    zIndex: number;
}

const _log = getLogger("components.page.Snackbar");

export class Snackbar extends Panel {
    private _alreadyDismissed = false;
    private _dismissTimeoutIds: number[];
    private _onDismiss: () => void;
    private _snackbarId: string;
    //private _dismissButtonCaption: string;

    constructor(snackbarOptions?: SnackbarOptions, panelProps?: Partial<PanelProps>, callSetProps: boolean = true) {
        super(panelProps, callSetProps);
        this._onDismiss = snackbarOptions?.onDismiss;
        this._snackbarId = snackbarOptions?.id;
        //this._dismissButtonCaption = snackbarOptions?.dismissButtonCaption;
        this.fillKeyHandlerGroup();
    }

    public get snackbarId(): string {
        return this._snackbarId
    }

    private static _shouldDisplay(snackbarId: string): boolean {
        if (StringUtil.isEmptyString(snackbarId))
            return true;
        if (ScreenStack.getOldestSnackbar(snackbarId) != null) {
            _log.debug(() => ["Not displaying Snackbar, Snackbar with ID ", snackbarId, " already in view"]);
            return false;
        }
        return true;
    }

    public static showSnackbar(message: StringOrPropsOrComponent, options?: SnackbarOptions): Snackbar {
        const shouldDisplay = Snackbar._shouldDisplay(options?.id);
        if (shouldDisplay === false)
            return;
        const panel = Snackbar.createSnackbarPanel(message, null, options);
        Snackbar.displaySnackbar(panel, options);
        return panel;
    }

    public static showWarningSnackbar(message: StringOrPropsOrComponent, options?: SnackbarOptions): Snackbar {
        const shouldDisplay = Snackbar._shouldDisplay(options?.id);
        if (shouldDisplay === false)
            return;
        const warningPanel = new Panel({ fillRow: true, wrap: false });
        const headerPanel = new Panel({ padding: 0, margin: 0, marginBottom: 4, fillRow: true });
        headerPanel.add(new Image({ name: "warning", height: 24, width: 24, rowBreak: false }));
        headerPanel.add(new Label({ caption: options?.warningTitle || "Warning", margin: 0, marginLeft: 8, padding: 0, fontSize: "large" }));
        warningPanel.add(headerPanel);
        const providedContent = Snackbar.createComponentFromStringOrPropsOrComponent(message);
        providedContent.padding = 0;
        providedContent.margin = 0;
        providedContent.marginLeft = 32;
        warningPanel.add(providedContent);
        const snackbarPanel = Snackbar.createSnackbarPanel(warningPanel, { backgroundColor: getThemeColor("warning") }, options);
        Snackbar.displaySnackbar(snackbarPanel, options);
        return snackbarPanel;
    }

    public static showDownloadSnackbar(title: string, message: StringOrPropsOrComponent, options?: SnackbarOptions): Snackbar {
        const shouldDisplay = Snackbar._shouldDisplay(options?.id);
        if (shouldDisplay === false)
            return;
        const downloadPanel = new Panel({ padding: 0, margin: 0, fillRow: true, wrap: false, verticalAlign: VerticalAlignment.TOP });
        downloadPanel.add(new Label({ caption: title, margin: 0, padding: 0, fontSize: "large" }));
        downloadPanel.add(new Image({ name: "spinner", rotate: true, color: "primary", padding: 0, margin: 0, marginLeft: 8, marginRight: 8, height: 28, width: 28, rowBreak: false }));
        const providedContent = Snackbar.createComponentFromStringOrPropsOrComponent(message);
        providedContent.padding = 0;
        providedContent.margin = 0;
        providedContent.rowBreak = false;
        downloadPanel.add(providedContent);
        const snackbarPanel = Snackbar.createSnackbarPanel(downloadPanel, undefined, options);
        this.displaySnackbar(snackbarPanel, options);
        return snackbarPanel;
    }

    private static createSnackbarPanel(message: StringOrPropsOrComponent, panelProps: Partial<PanelProps> = {}, options?: SnackbarOptions): Snackbar {
        const panel = new Snackbar(options, {
            themeKey: "snackbar",
            borderRadius: 4,
            minWidth: 280,
            maxWidth: 600,
            zIndex: 2001
        });  //zIndex value '2001' is a fallback default in case we can't compute a new zIndex
        panel.setProps(panelProps);
        const component = Snackbar.createComponentFromStringOrPropsOrComponent(message);
        const closeButtonContainer = new Panel({ maxWidth: 24, padding: 0, margin: 0, marginLeft: 15, verticalAlign: VerticalAlignment.TOP, fillHeight: true });
        closeButtonContainer.add(new Image({ name: "x", height: 24, width: 24 }));
        closeButtonContainer.addClickListener(() => panel.dismiss());
        panel.add(component);
        panel.add(closeButtonContainer);
        /*
        const toolsPanel = new Panel({ padding: 0, margin: 0, marginLeft: 15, fillHeight: true, align: HorizontalAlignment.RIGHT });
        const closeButtonContainer = new Panel({ maxWidth: 24, padding: 0, margin: 0 });
        closeButtonContainer.add(new Image({ name: "x", height: 24, width: 24 }));
        closeButtonContainer.addClickListener(() => panel.dismiss());
        const dismissButtonContainer = new Panel({ padding: 0, margin: 0 });
        if (StringUtil.isEmptyString(options?.dismissButtonCaption) === false) {
          const dismissButton = new Button({ caption: options?.dismissButtonCaption });
          dismissButton.addClickListener(() => panel.dismiss());
          dismissButtonContainer.add(dismissButton);
        }
        toolsPanel.add(closeButtonContainer, new Panel({ fillHeight: true }), dismissButtonContainer);
        panel.add(component);
        panel.add(toolsPanel);
         */
        return panel;
    }

    /**
     * Dismisses a Snackbar, both in user-dismissed scenarios (when the Snackbar should be dismissed immediately),
     * and in auto-dismissed scenarios (when the Snackbar will be displayed for some number of milliseconds and then dismissed automatically).
     * In user-dismissed scenarios, the timeToWait value will be zero (indicating that the Snackbar should be dismissed right away).
     *
     * @param timeToWait A number that indicates the time to wait before dismissing the Snackbar (in milliseconds).
     */
    dismiss(timeToWait: number = 0, animate: boolean = true) {
        if (this._alreadyDismissed === true)
            return;

        //clear any existing timeouts.  this would come into play when a user clicks X to close a non-persisted snackbar
        //in that case we'd want to not run dismiss again in a few seconds (based on the timed dismissal)
        this._clearDismissTimeouts();

        this._dismissTimeoutIds = [];
        //remove the snackbar, then move any newer snackbars down one spot
        this._dismissTimeoutIds.push(setTimeout(() => {
            this._alreadyDismissed = true;
            ScreenStack.popSnackbar(this)
        }, timeToWait));
        if (animate === true) {
            this._dismissTimeoutIds.push(setTimeout(() => this.left = 0 - this._element.clientWidth, timeToWait));
            this._dismissTimeoutIds.push(setTimeout(() => this._element.style.opacity = "0", timeToWait));
        }
        this._dismissTimeoutIds.push(setTimeout(() => {
            if (document.body.contains(this._element))
                document.body.removeChild(this._element);
            if (this._onDismiss != null)
                this._onDismiss();
        }, timeToWait + (animate === true ? 1000 : 0)));
    }

    private _clearDismissTimeouts() {
        if (this._dismissTimeoutIds == null)
            return;
        for (const timeoutId of this._dismissTimeoutIds) {
            clearTimeout(timeoutId);
        }
    }

    /**
     * Displays a Snackbar within the 'current Snackbar target', which is the frontmost panel that can house a Snackbar (typically any Panel except a Dialog).
     * Snackbars slide in from the left-hand side of the screen, and stack newest on top.
     * @param panel The Snackbar to display.
     * @param options A SnackbarOptions object.
     */
    private static displaySnackbar(panel: Snackbar, options?: SnackbarOptions) {
        panel._element.style.position = "absolute";
        panel.left = -9999;
        const addedSnackInfo = ScreenStack.pushSnackbar(panel, options?.targetPanel);

        document.body.appendChild(panel._element);

        let heightOfLastPanel = 0;
        let bottomOfLastPanel = 16 + addedSnackInfo.parentPanelBottom;
        if (addedSnackInfo.previousSnackBottom != null) {
            heightOfLastPanel = addedSnackInfo.previousSnackHeight;
            bottomOfLastPanel = addedSnackInfo.previousSnackBottom;
        }

        let transitionString = "left 300ms, opacity 300ms, bottom 300ms";
        if (addedSnackInfo.parentPanelLeft === 0)
            panel.zIndex = addedSnackInfo.zIndex;
        else {
            panel.zIndex = -100;
            transitionString += ", z-index 300ms";
        }
        // panel.left = addedSnackInfo.parentPanelLeft - panel._element.clientWidth;
        panel.bottom = bottomOfLastPanel + heightOfLastPanel + 2;
        panel._element.style.transition = transitionString;
        setTimeout(() => {
            panel.left = addedSnackInfo.parentPanelLeft + 16;
            panel.zIndex = addedSnackInfo.zIndex;
        }, 10);
        if (options?.persist !== true) {
            const millisUntilDimissal = Snackbar.getMillisUntilDimissal(options);
            panel.dismiss(millisUntilDimissal);
        }
    }

    /**
     * When a Snackbar is being removed, move any Snackbars newer than the one being removed (which display above it) down a spot
     *
     * @param snackStack The Snackbar array listing Snackbars for a Panel
     * @param startingAtIndex A number value that indicates the position (in the array) of the first Snackbar that should be lowered
     * @param moveDownBy A number value indicating the number of pixels that each Snackbar will be lowered
     */
    static moveNewerSnackbarsDown(snackStack: Panel[], startingAtIndex: number, moveDownBy: number) {
        for (let i = startingAtIndex; i < snackStack.length; i++) {
            Snackbar._moveSnackbarDown((snackStack[i] as Snackbar), moveDownBy);
        }
    }

    /**
     * Moves a single Snackbar down by a specified number of pixels, and decrements its arrayIndex value by one.
     *
     * @param snackToMove The Snackbar that will be moved down
     * @param moveDownBy A number value indicating the number of pixels by which the Snackbar will be lowered
     */
    static _moveSnackbarDown(snackToMove: Snackbar, moveDownBy: number) {
        snackToMove.bottom = DOMUtil.getStyleAttrAsNumber(snackToMove.bottom) - moveDownBy - 2;
    }

    private static createComponentFromStringOrPropsOrComponent(message: StringOrPropsOrComponent): Component {
        const component = getComponentFromStringOrPropsOrComponent(message);
        component.fillRow = true;
        component.rowBreak = false;
        return component;
    }

    /**
     * Get the amount of time that a Snackbar should be visible.
     * This should only be used when the Snackbar is set to not persist (when it is user-dismissed).
     * If the provided SnackbarOptions object is null or does not contain a millisUntilDimissal value, the default value of 7000 milliseconds will be returned.
     *
     * @param options A SnackbarOptions object, which may or may not contain a millisUntilDimissal value
     * @returns A number, which is the amount of time that the Snackbar should be visible (in milliseconds).
     */
    private static getMillisUntilDimissal(options: SnackbarOptions): number {
        return options?.millisUntilDimissal || 7000;
    }

    getKeyHandlers(): KeyHandler[] {
        return [{ key: Keys.ESCAPE, listener: (event) => this.dismiss(), element: this._element }];
        /*
        const result = [{ key: Keys.ESCAPE, listener: (event) => this.dismiss(), element: this._element }];
        if (StringUtil.isEmptyString(this._dismissButtonCaption) === false)
          result.push({ key: Keys.ENTER, listener: (event) => this.dismiss(), element: this._element });
        return result;
         */
    }
}
