import { GeneralSettings } from ".";
import { getLogger } from "./Logger";

const modules = {};
const pathClassMap = {};

const log = getLogger("core/DynamicLoader");


export class DynamicLoader {
    /**
     * This puts all the routable classes from the supplied npm package into the routing table under the provided root.  Example use would be from
     * the "master" project to load each of the routable packages like this:
     *
     * mapRoutesToClasses("designer", require.context("@mcleod/designer/src", true));
     * mapRoutesToClasses("demo", require.context("@mcleod/component-demo/src", true));
     * mapRoutesToClasses("portal", require.context("@mcleod/portal/src", true));
    
     * @param {} root
     * @param {*} packageName
     */
    static mapPathsToClasses(root, context): void {
        const keys = context.keys();
        for (const key of keys) {
            if (!key.endsWith(".js") && !key.endsWith(".ts")) {
                const module = context(key);
                const className = key.substring(key.lastIndexOf("/") + 1);
                modules[root + key.substring(1)] = module;
                const c = module[className];
                if (c != null) // see if there is a class inside this module that has the same name as the module - a requirement for a routable module
                    // we could also support some special function (getOverrideRoute()) that could be defined in a class or module that could override the route that the class is mapped to.  Not needed yet.
                    pathClassMap[root + key.substring(1)] = c;
            }
        }
    }

    /**
     * * Returns the part of specified URL that determines which route we should choose.
     *
     * @param {string} url If undefined, this will use the browser's current URL
     * @returns
     */
    static getRouteFromURL(url?: string): string {
        if (url === undefined)
            url = window.location.pathname;
        let result: string = url;
        const baseUrlOffset = GeneralSettings.get().base_url_offset;
        if (result.startsWith("/" + baseUrlOffset))
            result = result.substring(baseUrlOffset.length + 1);
        if (result.startsWith("/"))
            result = result.substring(1);
        const queryIndex = result.indexOf("?");
        if (queryIndex >= 0)
            result = result.substring(0, queryIndex);
        while (result.endsWith("/"))
            result = result.substring(0, result.length - 1);
        return result;
    }

    static getClassForPath(path: string) {
        return pathClassMap[path];
    }

    static getPathClassMap() {
        return pathClassMap;
    }

    static getModuleByName(name) {
        return modules[name];
    }
}
