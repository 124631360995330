import { createClass, makeStyles } from "@mcleod/core";

createClass("@keyframes rotation", "from { transform: rotate(0deg); }  to { transform: rotate(359deg); }", true);

export const ButtonStyles = makeStyles("btn", () => {
    return {
        base: {
            borderRadius: "4px",
            margin: "4px", fontSize: "0.75rem",
            outline: "none",
            border: "1px solid currentcolor",
            backgroundColor: "transparent",
            padding: "8px 19px",
            // transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            // "&:hover:enabled": { backgroundColor: getThemeColor("button.hoverColor") },
            // "&:focus": { backgroundColor: getThemeColor("button.focusColor") },
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontFamily: "inherit",
            color: "inherit"
        },
        round: {
            borderRadius: "50%",
            border: "none",
            padding: "4px"
        },
        text: {
            border: "none"
        },
        withImage: { padding: "6px 19px" },
        "size-small": { width: "75px", height: "34px" },
        "size-medium": { width: "150px", height: "34px" },
        "size-large": { width: "200px", padding: "6px 19px", height: "34px", fontSize: "0.875rem" },
        "size-largest": { width: "250px", padding: "6px 19px", height: "34px", fontSize: "0.875rem" }
    }
});
