import { Label, Panel, PanelProps, Splitter, SplitterOrientation, Switch, Textbox } from "@mcleod/components";
import { DOMUtil, ExtendedDataType, HorizontalAlignment, MetadataField } from "@mcleod/core";
import { AbstractUIDesigner } from "./AbstractUIDesigner";
import { DesignerTool } from "./DesignerTool";

export class DesignerToolsPanel extends Panel {
    inputTableSearch: Textbox;
    inputFieldSearch: Textbox;
    switchDragMode: Switch;
    splitter: Splitter;
    panelMainTools: Panel;
    panelDataSourceTools: Panel;
    panelSplitBottom: Panel;
    designer: AbstractUIDesigner;
    private _selectedTool: DesignerTool;

    constructor(designer: AbstractUIDesigner, props?: Partial<PanelProps>) {
        super({ padding: 0, rowBreak: false, borderRightWidth: 1, borderRightColor: "strokeSecondary", fillHeight: true, scrollY: true, ...props });
        this.designer = designer;
        this.splitter = new Splitter({ id: "splitter", fillRow: true, fillHeight: true, orientation: SplitterOrientation.vertical, position: "50%" });
        this.panelMainTools = new Panel({ id: "panelMainTools", fillRow: true, fillHeight: true, scrollY: true });
        this.add(new Label({ caption: "Tools", backgroundColor: "primary", align: HorizontalAlignment.CENTER, fillRow: true, color: "primary.reverse" }));

        designer.allowedDesignerTools.forEach((serializationName: string) => {
            const tool = designer.getDesignerToolByName(serializationName);
            if (tool != null) {
                this.addToolListeners(tool);
                this.panelMainTools.add(tool);
            }
        });

        this.panelSplitBottom = new Panel({ fillRow: true, fillHeight: true });
        this.inputTableSearch = new Textbox({ id: "inputTableSearch", captionVisible: false, placeholder: "Search table", fillRow: true, marginLeft: 8, marginRight: 8, rowBreak: false });
        this.inputTableSearch.addChangeListener(event => this.displayDataSourceTools());
        this.panelSplitBottom.add(this.inputTableSearch);

        this.inputFieldSearch = new Textbox({ id: "inputFieldSearch", captionVisible: false, placeholder: "Search field", fillRow: true, marginRight: 8 });
        this.inputFieldSearch.addChangeListener(event => this.displayDataSourceTools());
        this.panelSplitBottom.add(this.inputFieldSearch);
        this.switchDragMode = new Switch({ id: "switchDrag", caption: "Drag mode", leftCaption: "Editable", rightCaption: "Read-only", color: "subtle.darker", marginLeft: 12, marginBottom: 8, fillRow: true, marginRight: 8 });
        this.switchDragMode.addChangeListener(event => this.displayDataSourceTools());
        this.panelSplitBottom.add(this.switchDragMode);
        this.panelSplitBottom.add(new Panel({ height: 1, padding: 0, margin: 8, fillRow: true, backgroundColor: "subtle.light" }));
        this.panelDataSourceTools = new Panel({ id: "dsTools", fillRow: true, padding: 0, fillHeight: true, scrollX: false, scrollY: true });
        this.panelSplitBottom.add(this.panelDataSourceTools);
        this.splitter.add(this.panelMainTools);
        this.splitter.add(this.panelSplitBottom);
        this.add(this.splitter);
    }

    public get selectedTool(): DesignerTool {
        return this._selectedTool;
    }

    public set selectedTool(value: DesignerTool) {
        if (this.selectedTool != null)
            this.selectedTool.setProps({ color: "subtle.darker", backgroundColor: "defaultBackground" });
        if (value != null)
            value.setProps({ color: "primary.reverse", backgroundColor: "primary.light" });
        this._selectedTool = value;
    }

    displayDataSourceTools() {
        const tab = this.designer.getActiveTab();
        this.panelDataSourceTools.removeAll();
        if (tab == null || tab.lastSelectedDataSource == null)
            this.panelDataSourceTools.add(new Label({ caption: "Select a datasource to see available fields", color: "subtle.light", align: HorizontalAlignment.CENTER }));
        else {
            tab.lastSelectedDataSource.getMetadata().then(metadata => {
                if (metadata != null) {
                    const fields = metadata.output;
                    const searchTable = this.inputTableSearch.text.trim().toLowerCase();
                    const searchField = this.inputFieldSearch.text.trim().toLowerCase();
                    const sorted = Object.keys(fields).sort();
                    for (const fieldName of sorted) {
                        const props = fields[fieldName];
                        const matchesTable = searchTable.length === 0
                            || (props.tableAlias?.toLowerCase().indexOf(searchTable) >= 0)
                            || (props.tableName?.toLowerCase().indexOf(searchTable) >= 0);
                        const matchesField = searchField.length === 0 || (fieldName.toLowerCase().indexOf(searchField) >= 0);
                        if (matchesTable && matchesField) {
                            const field = fields[fieldName];
                            const serializationName = field.extDataType === ExtendedDataType.YES_NO ? "checkbox" : "textbox";
                            const tool = new DesignerTool(this.designer, serializationName, fieldName,
                                new MetadataField({ ...props }), null, null, this.switchDragMode.checked);
                            this.addToolListeners(tool);
                            this.panelDataSourceTools.add(tool);
                        }
                    }
                }
            });
        }
    }

    private addToolListeners(tool: DesignerTool) {
        tool.addDragStartListener(event => {
            this.selectedTool = tool;
            const imageElement = tool.image._element;
            const height = DOMUtil.getElementHeight(imageElement);
            const width = DOMUtil.getElementWidth(imageElement);
            event.domEvent.dataTransfer.setDragImage(imageElement, width, height / 2);
            this.designer.dragDropHandler.dragStartListener(event);
        });
        tool.addDragEndListener(this.designer.dragDropHandler.dragEndListener);
        tool.addClickListener(() => this.selectedTool = tool);
        tool.addDblClickListener(() => this.designer.addToolToSelectedContainer(tool));
    }
}
