import { ArrayUtil } from "@mcleod/core";
import { Layout } from "../../components/layout/Layout";
import { Panel } from "../../components/panel/Panel";
import { Spinner } from "../../components/spinner/Spinner";
import { EventListener } from "../../events/Event";
import { DecoratorProps } from "./DecoratorProps";

export class Decorator extends Panel implements DecoratorProps {
    public layout: Layout;
    private _layoutLoadListeners: EventListener[];
    private spinner: Panel;

    constructor(props: Partial<DecoratorProps>) {
        super(props, false);
        if (props?.layout == null)
            throw new Error("A decorator must specify a layout to decorate.")
        this.spinner = new Spinner();
        this.add(this.spinner);
        this._addFirstLoadListener(props);
        this.setProps({ fillRow: true, padding: 0, ...props });
        this.setupPanels(props);
        this._fireLayoutLoadedListeners();
    }

    /**
     * This method ensures that the layout load listener added by the Decorator is the first listener to run.
     * This is why the layout load listeners are provided as a prop instead of being added to the layout before calling
     * new Decorator()...the Decorator's layout load listener should always run first.
     * @param props 
     */
    private _addFirstLoadListener(props: Partial<DecoratorProps>) {
        const loadListeners = props?.layoutLoadListeners != null ? ArrayUtil.getAsArray(props.layoutLoadListeners) : [];
        loadListeners.splice(0, 0, () => {
            this.remove(this.spinner);
            delete this.spinner;
            this.layoutLoaded();
            this.addLayout();
        });
        props.layoutLoadListeners = loadListeners;
    }

    /**
     * Override this method in sub-classes to control how panels are configured within each type of Decorator.
     * @param props A partial DecoratorProps object (or any sub-class thereof)
     */
    setupPanels(props: Partial<DecoratorProps>) {
    }

    /**
     * Override this method in sub-classes to control what happens when the layout loads.
     * This method is called after the layout is loaded (as part of the first 'layout loaded' listener),
     * after the layout is added to the decorator itself.
     */
    layoutLoaded() {
    }

    /**
     * Override this method in sub-classes to control the provided layout is added to the decorator's content.  This method
     * is called after the layout is loaded (as part of the first 'layout loaded' listener).
     * The default behavior is for the decorator's panel to contain only the provided layout.
     */
    addLayout() {
        this.add(this.layout);
    }

    set layoutLoadListeners(value: EventListener | EventListener[]) {
        this._layoutLoadListeners = ArrayUtil.getAsArray(value);
    }

    private _fireLayoutLoadedListeners() {
        if (this._layoutLoadListeners == null)
            return;
        for (const l of this._layoutLoadListeners) {
            this.layout.addLayoutLoadListener(l);
        }
    }
}
