import { UserSettings } from "./src";
import { getLog } from "./src/Logger";

export class ProcessInfo {
    private executionLog?: string; //appears to be unused, but it can be included in serialized data sent to the server
    private programDisplayName: string;

    constructor(detail: boolean = false) {
        this.programDisplayName = this.programName + " " + UserSettings.get().company_id;
        // TODO: populate properties and diagnostic tabs of client admin
        if (detail === true)
            this.executionLog = getLog();
    }

    public get programName(): string {
        return "//web";
    }
}
