import { Api, ApiMethod, Clipboard, Model, ModelSearchResult, ObjectUtil, ServiceAddresses, StringUtil, getThemeColor } from "@mcleod/core";
import { AutogenLayoutApiExplorer } from "./autogen/AutogenLayoutApiExplorer";
import { RowApiDoc } from "@mcleod/core/src/endpoints/ModelApiDoc";
import { Checkbox, Label, Panel, Textbox, TreeNode, TreeNodeProps, ClickEvent, Snackbar, Button, ButtonVariant } from "@mcleod/components";
import { LabelStyles } from "@mcleod/components/src/components/label/LabelStyles";

interface Service {
    name: string;
    node: TreeNode;
    apiData: RowApiDoc[];
}

export class ApiExplorer extends AutogenLayoutApiExplorer {
    private selectedEndpoint: RowApiDoc;
    private selectedService: string;
    private services: Service[] = [];
    private methodColors = { GET: "caution", POST: "primary", PUT: "warning", PATCH: "success", DELETE: "error" };
    private _requestMethod: ApiMethod;

    onLoad() {
        this.panelEndpointAddress.backgroundColor = "#eeeeff";
        this.labelCodeSample.style.overflowX = "auto";
        this.treeApisOnChange();
        this.panelEndpointHeader.add(this.createSaveApiButton())
        this.addService("mcleod-api-auth");
        this.addService("mcleod-api-lme");
        this.addService("mcleod-api-imaging");
        this.addService("mcleod-api-notification");
        this.addService("mcleod-api-security");
    }

    private addService(apiName: string) {
        const serviceRoot = new TreeNode({
            caption: apiName,
            rightComponent: this.createSaveApiButton(),
            onlyShowRightComponentWhenHovered: true,
            imageName: "cloudFilled",
            imageColor: "#d812ea"
        });
        const service: Service = {
            name: apiName,
            node: serviceRoot,
            apiData: null
        };
        this.services.push(service);
        Model.search(apiName + ":lib/api-doc").then((response: ModelSearchResult) => {
            service.apiData = response.modelRows;
            this.displayTreeData();
        }).catch(error => {
            serviceRoot.caption = "Error loading " + apiName;
            serviceRoot.tooltip = error.toString();
        });
    }

    displayTreeData() {
        let count = 0;
        this.treeApis.getRootNode().removeAllChildren();
        const searchText = this.textboxSearch.text.toLowerCase();
        for (const service of this.services) {
            if (service.apiData == null)
                service.node.caption = "Loading...";
            else {
                count += service.apiData.length;
                service.node.caption = service.name;
                service.node.removeAllChildren();
                for (const row of service.apiData) {
                    if (searchText.length === 0 || row.get("endpoint").toLowerCase().includes(searchText))
                        this.addApi(row, service.node, service.name);
                }
            }
            this.sortNodes(service.node);
            if (service.node.children.length > 0 || service.apiData == null)
                this.treeApis.getRootNode().addChild(service.node);
        }
        this.textboxSearch.placeholder = "Search " + count + " endpoints";

    }

    addApi(row: RowApiDoc, root: TreeNode, serviceName: string) {
        const endpoint = row.get("endpoint");
        const tokens = endpoint.split("\/");
        let parentNode = root;
        for (let i = 0; i < tokens.length - 1; i++) {
            const node = parentNode.getChildWithCaption(tokens[i] + "/");
            if (node == null) {
                parentNode = parentNode.addChild(
                    new TreeNode({
                    caption: tokens[i] + "/",
                    rightComponent: this.createSaveApiButton(),
                    onlyShowRightComponentWhenHovered: true,
                    expandedImageName: "chevron",
                    collapsedImageName: "chevron",
                    expandedImageProps: { rotation: 180 },
                }));
            } else {
                parentNode = node;
            }
        }
        const apiNode = new ApiTreeNode({
            caption: tokens[tokens.length - 1],
            rightComponent: this.createSaveApiButton(),
            onlyShowRightComponentWhenHovered: true,
            imageName: "apiDot",
            imageColor: "#9747ff",
            imageWidth: 14,
            data: row
        });
        apiNode.serviceName = serviceName;
        parentNode.addChild(apiNode)
    }

    private createSaveApiButton() {
        const button = new Button({ imageName: "ellipsis", variant: ButtonVariant.round, margin: 0, color: "subtle.light", tooltip: "Show other options for this api" });
        const labelProps = { padding: 8, fontSize: "large" };
        button.dropdownItems = [
            new Label({ caption: "Save as Postman collection", imageName: "postman", ...labelProps }),
            new Label({ caption: "Save as OpenAPI spec", imageName: "openapi", ...labelProps }),
            new Label({ caption: "Save as Swagger spec", imageName: "swagger", ...labelProps }),
            new Label({ caption: "Save as RAML spec", imageName: "raml", ...labelProps }),
            new Label({ caption: "Save as WSDL spec", imageName: "wsdl", ...labelProps })
        ];
        button.dropdownOverlayProps = { width: 280, maxHeight: 300 };
        return button;
    }

    sortNodes(parentNode: TreeNode) {
        if (parentNode.children.length === 1 && this.textboxSearch.text.length > 0)
            parentNode.expanded = true;
        parentNode.children.sort((a, b) => {
            if (a.getChildCount() > 0 && b.getChildCount() === 0)
                return -1;
            if (b.getChildCount() > 0 && a.getChildCount() === 0)
                return 1;
            return a.caption.toLowerCase().localeCompare(b.caption.toLowerCase());
        });
        parentNode.children.forEach(child => this.sortNodes(child));
    }

    /** This is an event handler for the onChange event of textboxSearch. */
    textboxSearchOnChange(event) {
        this.displayTreeData();
    }

    /** This is an event handler for the onChange event of treeApis. */
    treeApisOnChange() {
        this.panelBody.removeAll();
        const node = this.treeApis.selectedNode;
        this.panelEndpointContent.visible = node instanceof ApiTreeNode;
        this.labelDetailStatus.visible = !(node instanceof ApiTreeNode);
        if (node instanceof ApiTreeNode) {
            const apiDoc = node.data;
            this.selectedEndpoint = apiDoc;
            this.selectedService = node.serviceName;
            this.labelEndpointName.caption = StringUtil.stringAfterLast(apiDoc.get("endpoint"), "/");
            this.labelEndpointPath.caption = apiDoc.get("endpoint");
            this.labelEndpointDoc.caption = apiDoc.get("description");
            this.addSourceLinks(apiDoc);
            this.addMethodLabels(apiDoc.get("methods"));
            this.addParams(apiDoc.get("input"), this.panelBody, this.panelLiveRequestContent);
            this.addParams(apiDoc.get("output"), this.panelResponse, null);
            let origin = ServiceAddresses.get(node.serviceName);
            if (!origin.endsWith("/"))
                origin += "/";
            this.labelEndpointOrigin.caption = origin;
            this.displaySampleRequest();
            this.displaySampleResponse();
        }
    }

    private addSourceLinks(apiDoc: RowApiDoc) {
        this.panelSourceLinks.removeAll();
        const sourceLinks = apiDoc.get("source_links");
        if (!ObjectUtil.isEmptyObject(sourceLinks)) {
            this.panelSourceLinks.add(new Label({ caption: "Source Links", fontBold: true, marginRight: 8, rowBreak: false }));
            for (const [key, value] of Object.entries(apiDoc.get("source_links"))) {
                const label = new Label({ caption: key, rowBreak: false, marginRight: 8 });
                const v = value as string;
                if (v.startsWith("http:")) {
                    label.setClassIncluded(LabelStyles.link, true);
                    label.addClickListener(event => fetch(v).then(response => Snackbar.showSnackbar("Opened source in IDE")));
                }
                else
                    label.link = v;
                this.panelSourceLinks.add(label);
            }
        }
    }

    private addMethodLabels(methods: ApiMethod[]) {
        this.panelEndpointMethods.removeAll();
        if (methods == null)
            return;
        for (const method of methods) {
            const label = new Label({ 
                caption: method, 
                backgroundColor: getThemeColor(this.methodColors[method]) + "99",
                borderColor: "default.light",
                color: this.methodColors[method] + ".reverse", 
                borderRadius: 4, 
                marginRight: 4,
                rowBreak: false,
                padding: 8,
                onClick: event => this.requestMethod = method
            });
            this.panelEndpointMethods.add(label);
        }
        this.requestMethod = methods?.[0];
    }

    get requestMethod() {
        return this._requestMethod;
    }

    set requestMethod(method: ApiMethod) {
        this._requestMethod = method;
        for (const comp of this.panelEndpointMethods.components) {
            const compMethod = (comp as Label).caption;
            const color = getThemeColor(this.methodColors[compMethod]);
            comp.backgroundColor = compMethod === method ? color : color + "50";
        }
    }

    addParams(input: any, target: Panel, sampleTarget: Panel) {
        target.removeAll();
        sampleTarget?.removeAll();
        for (const param of input) {
            const labelParamName = new Label({ caption: param.param, minWidth: 200, fontBold: true, rowBreak: false });
            const labelParamType = new Label({ caption: param.type, minWidth: 88, rowBreak: false });
            const labelParamRequired = new Label({ caption: param.required ? "Required" : "", rowBreak: false });
            const labelParamDescription = new Label({ caption: param.description });
            target.add(labelParamName, labelParamType, labelParamRequired, labelParamDescription);

            if (sampleTarget != null) {
                const labelParamName = new Label({ caption: param.param, minWidth: 160, color: "default.reverse", rowBreak: false });
                let editor;
                if (param.type === "BOOLEAN")
                    editor = new Checkbox({ field: param.param, color: "default.reverse" });
                else
                    editor = new Textbox({
                        field: param.param,
                        color: "default.reverse",
                        captionVisible: false,
                        fillRow: true,
                        borderColor: "default.light",
                        borderWidth: 1
                    });
                editor.addChangeListener(event => this.displaySampleRequest());
                sampleTarget.add(labelParamName, editor);
            }
        }

    }

    /** This is an event handler for the onClick event of buttonSendRequest. */
    buttonSendRequestOnClick(event: ClickEvent) {
        Api.call(this.selectedService + ":" + this.selectedEndpoint.get("endpoint"), this.requestMethod, this.getSampleRequestBody())
            .then(response => {
                this.labelResponse.caption = JSON.stringify(response, null, 4);
            });
    }

    displaySampleRequest() {
        let text = "";
        const method = "PATCH";
        const bodyString = JSON.stringify(this.getSampleRequestBody(), null, 4);
        if (bodyString !== "{}")
            text += "String body = " + bodyString + ";\n";
        text += "HttpRequest request = HttpRequest.newBuilder()\n";
        text += "    .uri(URI.create(\"http://localhost:3000/" + this.selectedEndpoint.get("endpoint") + "\"))\n";
        text += "    .header(\"Accept\", \"application/json\")\n";
        text += "    .method(\"" + method + "\", HttpRequest.BodyPublishers.";
        if (bodyString === "{}")
            text += "noBody())\n";
        else
            text += "ofString(body))\n";
        text += "    .build();\n";
        text += "HttpResponse<String> response = HttpClient.newHttpClient().send(request, HttpResponse.BodyHandlers.ofString());\n";
        text += "System.out.println(response.body());\n";
        this.labelCodeSample.caption = text;
    }

    private displaySampleResponse() {
        const response = {};
        if (this.selectedEndpoint != null) {
            const output = this.selectedEndpoint.get("output");
            for (const param of output) {
                if (param.type === "BOOLEAN")
                    response[param.param] = true;
                else if (param.type === "NUMBER")
                    response[param.param] = 123;
                else if (param.type === "LIST")
                    response[param.param] = [];
                else if (param.type === "OBJECT")
                    response[param.param] = {};
                else
                    response[param.param] = "abc";
            }
        }
        this.labelResponse.caption = JSON.stringify(response, null, 4);
    }

    private getSampleRequestBody(): any {
        if (this.requestMethod === "GET")
            return null;
        const result = {};
        for (const comp of this.panelLiveRequestContent.components) {
            if (comp.field != null) {
                const value = comp.getDataValue();
                if (!StringUtil.isEmptyString(value))
                    result[comp.field] = value;
            }
        }
        return result;
    }


    /** This is an event handler for the onClick event of buttonCopy. */
    buttonCopyOnClick(event: ClickEvent) {
        Clipboard.copyText(this.labelCodeSample.caption);
        Snackbar.showSnackbar("Copied to clipboard");
    }

    /** This is an event handler for the onClick event of buttonCopyResponse. */
    buttonCopyResponseOnClick(event: ClickEvent) {
        Clipboard.copyText(this.labelResponse.caption);
        Snackbar.showSnackbar("Copied to clipboard");
    }
}

class ApiTreeNode extends TreeNode {
    data: RowApiDoc;
    serviceName: string;

    constructor(props: Partial<TreeNodeProps & { data: RowApiDoc }>) {
        super(props);
    }
}