import { DOMUtil } from "@mcleod/core";
import { Component } from "../base/Component";

let _allowedComponent: Component;

/**
 * The purpose of this class is to designate a single component whose related DataSources are allowed to execute a search.
 * This is used in cases like a scrolling tabset, where we might want to scroll past several tabs without loading the data 
 * from their related DataSources.
 */
export class AllowedToSearch {
    public static set(component: Component) {
        _allowedComponent = component;
    }

    public static clear() {
        _allowedComponent = null;
    }

    public static isAllowed(target: Component | Element): boolean {
        if (_allowedComponent == null)
            return true;
        const element = target instanceof Element ? target : target._element;
        return (DOMUtil.isOrContains(_allowedComponent._element, element) === true);
    }
}