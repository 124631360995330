import { DOMUtil, FileUtil } from "@mcleod/core";
import { ClickEvent, Label, Snackbar } from "../..";
import { Overlay } from "../../page/Overlay";
import { Button } from "../button/Button";
import { ButtonVariant } from "../button/ButtonVariant";
import { Image } from "../image/Image";
import { ImageType } from "../image/ImageType";
import { Panel } from "../panel/Panel";
import { PanelProps } from "../panel/PanelProps";
import { OverlayProps } from "../../page/OverlayProps";
import { ImageNavControls } from "./ImageNavControls";

export interface ImageViewerProps extends PanelProps {
    imageBase64: string;
    downloadFileName: string;
    title: string;
}

export class ImageViewer extends Panel {
    private _imageBase64: string;
    private _panelHeader: Panel;
    private _labelTitle: Label;
    private navControls: ImageNavControls;
    private _buttonDownload: Button;
    private _buttonClose: Button;
    private _image: Image;
    public downloadFileName: string;
    private _overlay: Overlay;

    constructor(props?: Partial<ImageViewerProps>) {
        super(props, false);
        this._panelHeader = new Panel({ fillRow: true, backgroundColor: "background3", color: "#ddd" });
        this._labelTitle = new Label({ fontSize: "xlarge", fontBold: true, paddingRight: 12, fillRow: true, rowBreak: false });
        this._buttonDownload = new Button({ variant: ButtonVariant.round, tooltip: "Download this image", imageName: "download", rowBreak: false })
        this._buttonDownload.addClickListener(event => {
            FileUtil.downloadBase64AsFile(this.imageBase64, this.downloadFileName);
            Snackbar.showSnackbar("Downloaded " + this.downloadFileName);
        });
        this._buttonClose = new Button({ variant: ButtonVariant.round, tooltip: "Close this image preview", imageName: "x" })
        this._buttonClose.addClickListener((event: ClickEvent) => Overlay.hideOverlay(this._overlay));
        this._panelHeader.add(this._labelTitle, this._buttonDownload, this._buttonClose);
        this.createImage(props?.downloadFileName);
        this.add(this._panelHeader, this._image);
        this.setProps({ padding: 0, ...props });
    }

    private createImage(downloadFileName: string) {
        const imageType = downloadFileName?.endsWith(ImageType.TIFF) ? ImageType.TIFF : ImageType.IMG;
        this._image = new Image({ imageType: imageType, fillRow: true, fillHeight: true, preserveAspectRatio: true });
    }

    private syncMultiPageControls() {
        if (this._image?.isMultiPage === true) {
            this.initNavControls();
            this._panelHeader.insertBefore(this.navControls, this._buttonDownload);
        }
        else if (this.navControls == null)
            this._panelHeader.remove(this.navControls);
    }

    private initNavControls() {
        if (this.navControls == null) {
            this.navControls = new ImageNavControls({ marginRight: 20, rowBreak: false });
            this.navControls.image = this._image;
        }
        else
            this.navControls.reset();
    }

    setProps(props: Partial<ImageViewerProps>) {
        super.setProps(props);
    }

    get imageBase64(): string {
        return this._imageBase64;
    }

    set imageBase64(value: string) {
        this._imageBase64 = value;
        this._image.imageBytes = value;
        this.syncMultiPageControls();
    }

    get title(): string {
        return this._labelTitle.caption;
    }

    set title(value: string) {
        this._labelTitle.caption = value;
    }

    public showAsOverlay(overlayProps: Partial<OverlayProps>, sizeImageToOverlay: boolean = true) {
        this._overlay = Overlay.showInOverlay(this, {
            style: {
                width: "90%",
                height: "90%",
                position: "absolute",
                left: "5%",
                top: "5%",
                backgroundColor: "#eee",
                borderRadius: "4px"
            },
            ...overlayProps
        });

        if (sizeImageToOverlay === true) {
            //ensure the image doesn't overrun the bounds of the overlay
            this.sizeImageToOverlay();
            this._overlay.getOverlayContent().addResizeListener(() => {
                this.sizeImageToOverlay();
            });
        }
    }

    private sizeImageToOverlay() {
        const overlayContentElement = this._overlay.getOverlayContent()._element;
        this.height = DOMUtil.getElementHeightString(overlayContentElement);
        this.width = DOMUtil.getElementWidthString(overlayContentElement);
    }

    public static eligibleToView(fileName: string) {
        return fileName.endsWith(".jpg") || fileName.endsWith(".png") || fileName.endsWith(".gif") ||
            fileName.endsWith(".tiff");
    }

    public static viewImage(imageBase64: string, downloadFileName: string,
        overlayProps?: Partial<OverlayProps>, sizeImageToOverlay: boolean = true) {
        ImageViewer.getStandardViewer(imageBase64, downloadFileName).showAsOverlay(overlayProps, sizeImageToOverlay);
    }

    private static getStandardViewer(imageBase64: string, downloadFileName: string): ImageViewer {
        return new ImageViewer({
            width: "100%",
            height: "100%",
            imageBase64: imageBase64,
            borderShadow: true,
            downloadFileName: downloadFileName,
            title: downloadFileName
        });
    }
}
