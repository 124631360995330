import { Collection, DatePart, DateRange, DateUtil, DisplayType, HorizontalAlignment } from "@mcleod/core";
import { Button, DomEvent, Label, Panel, Textbox } from "../..";
import { Cursor } from "../../base/Cursor";
import { ListenerListDef } from "../../base/ListenerListDef";
import { Snackbar } from "../Snackbar";
import { DatePicker, DatePickerProps } from "./DatePicker";
import { DateTimeChangePart, DateTimePickerChangeEvent } from "./DateTimePickerChangeEvent";

const labelProps = {
    width: 36, height: 36, padding: 7, margin: 0, align: HorizontalAlignment.CENTER,
    fontSize: "small", allowSelection: false
};
const _changeListenerDef: ListenerListDef = { listName: "_changeListeners" };

export class DateRangePicker extends DatePicker {
    firstSel: Date;
    secondSel: Date;
    monthChanged: boolean = false;
    daysBack: Textbox;
    daysOut: Textbox;
    selectedButton: Button = null;
    buttonYesterday: Button;
    buttonToday: Button;
    buttonTomorrow: Button;

    constructor(props?: Partial<DatePickerProps>) {
        super({width: 600, ...props});
        const sidePanel = new Panel({ fillRow: true, fillHeight: true, rowBreak: false, maxWidth: 300 });
        const panelDateSpecific = new Panel({ borderBottomWidth: 1, borderBottomColor: "strokeSecondary", fillRow: true, paddingBottom: 9 });
        this.buttonYesterday = new Button({ fillRow: true, caption: "Yesterday", backgroundColor: "primary.reverse", color: "primary", rowBreak: false, marginLeft: 0, marginRight: 0 });
        this.buttonYesterday.addClickListener(event => {
            this.setSelectedButton(this.buttonYesterday);
            this.resetSelections();
            const yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);
            this.select(DateUtil.justDate(yesterday), event);
        })
        this.buttonToday = new Button({ fillRow: true, caption: "Today", backgroundColor: "primary.reverse", color: "primary", rowBreak: false, marginLeft: 0, marginRight: 0 });
        this.buttonToday.addClickListener(event => {
            this.setSelectedButton(this.buttonToday);
            this.resetSelections();
            this.select(DateUtil.justDate(new Date()), event);
        })
        this.buttonTomorrow = new Button({ fillRow: true, caption: "Tomorrow", backgroundColor: "primary.reverse", color: "primary", rowBreak: true, marginLeft: 0, marginRight: 0 });
        this.buttonTomorrow.addClickListener(event => {
            this.setSelectedButton(this.buttonTomorrow);
            this.resetSelections();
            const tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            this.select(DateUtil.justDate(tomorrow), event);
        })

        this.daysBack = new Textbox({ caption: "Days back", fillRow: true, rowBreak: false, placeholder: "0" });
        this.daysBack.displayType = DisplayType.INTEGER;
        this.daysBack._element.oninput = (event) => {
            this.daysBack.validateSimple();
            this.resetSelections();
            this.figureOutRange();
        }

        this.daysOut = new Textbox({ caption: "Days out", fillRow: true, rowBreak: true, placeholder: "0" });
        this.daysOut.displayType = DisplayType.INTEGER;
        this.daysOut._element.oninput = (event) => {
            this.daysOut.validateSimple();
            this.resetSelections();
            this.figureOutRange();
        }

        const buttonThisWeek = new Button({ fillRow: true, caption: "This Week", backgroundColor: "primary.reverse", color: "primary", marginTop: 12 });
        buttonThisWeek.addClickListener(event => {
            this.setSelectedButton(buttonThisWeek);
            this.resetSelections();
            const curr = new Date();
            const first = curr.getDate() - curr.getDay();
            const last = first + 6;
            let lastDay: Date;
            let firstDay: Date;
            //When week is between two months and current day is in new month, need to swap setting first and last day
            if (first <= 0) {
                lastDay = new Date(curr.setDate(last));
                firstDay = new Date(curr.setDate(first));
            }
            else {
                firstDay = new Date(curr.setDate(first));
                lastDay = new Date(curr.setDate(last));
            }
            this.firstSel = DateUtil.justDate(firstDay);
            this.select(DateUtil.justDate(lastDay), event);
        })

        const buttonThisMonth = new Button({ fillRow: true, caption: "This Month", backgroundColor: "primary.reverse", color: "primary" });
        buttonThisMonth.addClickListener(event => {
            this.setSelectedButton(buttonThisMonth);
            this.resetSelections();
            const date = new Date();
            const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            this.firstSel = DateUtil.justDate(firstDay);
            this.select(DateUtil.justDate(lastDay), event);
        })

        const buttonLastMonth = new Button({ fillRow: true, caption: "Last Month", backgroundColor: "primary.reverse", color: "primary" });
        buttonLastMonth.addClickListener(event => {
            this.setSelectedButton(buttonLastMonth);
            this.resetSelections();
            const date = new Date();
            const firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
            const lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
            this.firstSel = DateUtil.justDate(firstDay);
            this.select(DateUtil.justDate(lastDay), event);
        })

        panelDateSpecific.add(this.buttonYesterday, this.buttonToday, this.buttonTomorrow, this.daysBack, this.daysOut);
        sidePanel.add(panelDateSpecific, buttonThisWeek, buttonThisMonth, buttonLastMonth);
        this.insert(sidePanel, 0);
    }

    resetSelections() {
        this.firstSel = null;
        this.secondSel = null;
    }

    setSelectedButton(buttonSelected: Button) {
        if (this.selectedButton != null) {
            this.selectedButton.backgroundColor = "primary.reverse";
            this.selectedButton.color = "primary";
            this.daysBack.text = "";
            this.daysOut.text = "";
        }
        this.selectedButton = buttonSelected;
        if (this.selectedButton != null) {
            this.selectedButton.backgroundColor = "primary";
            this.selectedButton.color = "primary.reverse";
        }
    }

    figureOutRange() {
        let daysBackValue = "0"
        if (this.daysBack.text != "") {
            daysBackValue = this.daysBack.text;
        }

        let daysOutValue = "0"
        if (this.daysOut.text != "") {
            daysOutValue = this.daysOut.text;
        }

        let selectedDate;
        if (this.selectedButton === this.buttonYesterday) {
            selectedDate = new Date().getDate() - 1;
        }
        else if (this.selectedButton === this.buttonToday) {
            selectedDate = new Date().getDate();
        }
        else if (this.selectedButton === this.buttonTomorrow) {
            selectedDate = new Date().getDate() + 1;
        }
        else {
            this.daysBack.text = "";
            this.daysOut.text = "";
            Snackbar.showSnackbar("Select Yesterday, Today, or Tomorrow first before editing days back or days out.");
            return;
        }

        const daysBackDate = new Date();
        daysBackDate.setDate(selectedDate - parseInt(daysBackValue));
        this.firstSel = DateUtil.justDate(daysBackDate);

        const daysOutDate = new Date();
        daysOutDate.setDate(selectedDate + parseInt(daysOutValue));
        this.select(DateUtil.justDate(daysOutDate), event);
    }

    override get value(): Date {
        return super.value;
    }

    override set value(date: Date) {
        if (date instanceof DateRange) {
            this.firstSel = date.beginningDate;
            this._value = date.endDate;
            this.displayCalendar();
            this.addDateLabels();
        } else {
            super.value = date;
        }
    }

    override addDateLabels() {
        this.panelDays.removeAll();
        const justDateValue = DateUtil.justDate(this.value);
        if (this.firstSel != null && this.secondSel != null && this.secondSel.getTime() != justDateValue.getTime()) {
            this.resetSelections();
        }

        if (this.firstSel == null)
            this.firstSel = justDateValue;
        else if (this.firstSel != null) {
            if (this.firstSel > justDateValue) {
                this.secondSel = this.firstSel;
                this.firstSel = justDateValue;
            }
            else if (this.firstSel.getTime() !== justDateValue.getTime())
                this.secondSel = justDateValue;
        }
        const thisMonth = this.displayMonth.getMonth();
        const lastDay = this.getLastDateToDisplay(this.displayMonth);
        let curr = this.getFirstDateToDisplay(this.displayMonth);
        while (curr <= lastDay) {
            const thisDate = DateUtil.justDate(new Date(curr));
            // What's going on here, is you select your first date and highlight all of the dates bewtween it and your second selected date
            // As of right now, only works with simple mouse clicks
            let colors;
            if ((this.firstSel != null && thisDate.getTime() === this.firstSel.getTime())
                || (this.secondSel != null && thisDate.getTime() === this.secondSel.getTime()))
                colors = { backgroundColor: "primary", color: "primary.reverse", hoverBackground: "primary.light", hoverColor: "primary.reverse" };
            else if (this.firstSel != null && this.secondSel != null && thisDate.getTime() >= this.firstSel.getTime() && thisDate.getTime() <= this.secondSel.getTime())
                colors = { backgroundColor: "primary.light", color: "primary.reverse", hoverBackground: "primary", hoverColor: "primary.reverse" };
            else if (thisMonth === thisDate.getMonth())
                colors = { backgroundColor: "", color: "", hoverBackground: "subtle.light", hoverColor: "subtle.reverse" };
            else
                colors = { backgroundColor: "", color: "subtle.darker", hoverBackground: "subtle.light", hoverColor: "subtle.reverse" };
            const label = new Label({ ...labelProps, ...colors, cursor: Cursor.POINTER, caption: thisDate.getDate(), rowBreak: thisDate.getDay() === 6 });
            label.addMouseEnterListener(event => event.target.backgroundColor = colors.hoverBackground);
            label.addMouseLeaveListener(event => event.target.backgroundColor = colors.backgroundColor);
            label.addClickListener(event => {
                if (this.selectedButton != null) {
                    this.selectedButton.backgroundColor = "primary.reverse";
                    this.selectedButton.color = "primary";
                    this.selectedButton = null;
                    this.daysBack.text = "";
                    this.daysOut.text = "";
                }
                this.select(thisDate, event);
            });
            this.panelDays.add(label);
            curr = DateUtil.dateAdd(DatePart.DAY, curr, 1);
        }
    }

    override select(date, domEvent: DomEvent) {
        const oldValue = this._value;
        this._value = date;
        this.addDateLabels();
        const newValue = new DateRange(this.firstSel, this.secondSel);
        this.fireListeners(_changeListenerDef, new DateTimePickerChangeEvent(this, oldValue, newValue, domEvent, DateTimeChangePart.Date));
    }

    override getListenerDefs(): Collection<ListenerListDef> {
        return { ...super.getListenerDefs(), "change": { ..._changeListenerDef } };
    }
}

