import { ComponentPropDefinitions, ComponentProps, ComponentPropDefinitionUtil } from "../../base/ComponentProps";
import { McLeodTailorComponentsProps } from "../../base/McLeodTailorPropertyEvaluator";
import { PropType } from "../../base/PropType";
import { stringArrayEditor } from "../textbox/TextboxProps";
import { IFrameLoadingType } from "./IFrameLoadingType";

export interface IFrameProps extends ComponentProps {
    loading: string;
    name: string;
    referrerPolicy: string;
    sandbox: boolean;
    sandboxAttributes: string[];
    src: string;
    title: string;
}

let iframePropDefs: ComponentPropDefinitions;

export class IFramePropDefinitions {
    public static getDefinitions(): ComponentPropDefinitions {
        const customOnly: McLeodTailorComponentsProps = { baseComponent: false, customComponent: true };
        if (iframePropDefs == null) {
            iframePropDefs = {
                ...ComponentPropDefinitionUtil.getComponentPropertyDefinitions(),
                "loading": { type: PropType.string, defaultValue: IFrameLoadingType.LAZY, dropdownProps: { items: [IFrameLoadingType.EAGER, IFrameLoadingType.LAZY] }, visibleInMcLeodTailor: true, editableInMcLeodTailor: customOnly, description: "Specifies the timing of when the iframe content should load." }, 
                "name": { type: PropType.string, visibleInMcLeodTailor: true, editableInMcLeodTailor: customOnly, description: "The name of the iframe." },
                "referrerPolicy": { type: PropType.string, category: "Security", visibleInMcLeodTailor: true, editableInMcLeodTailor: customOnly, description: "Specifies if the referrer policy for the iframe.  See the HTML standard for valid values and definitions of each." },
                "sandbox": { type: PropType.bool, defaultValue: false, category: "Security", visibleInMcLeodTailor: true, editableInMcLeodTailor: customOnly, description: "Specifies if the iframe is run in sandbox mode.  See the HTML standard for information regarding sandbox mode." },
                "sandboxAttributes": { type: PropType.string, editor: stringArrayEditor, category: "Security", visibleInMcLeodTailor: true, editableInMcLeodTailor: customOnly, description: "Specifies sandbox attributes to assign to the iframe.  See the HTML standard for valid values and definitions of each." },
                "src": { type: PropType.string, visibleInMcLeodTailor: true, editableInMcLeodTailor: customOnly, description: "This is the URL that should be loaded in the iframe.  It will usually be a full URL, but it can also be entered in the format `serviceKey:path` (e.g. mcleod-ui-auth:common/Login) where the serviceKey will be replaced with the host/domain that should be used for the given environment." },
                "title": { type: PropType.string, visibleInMcLeodTailor: true, editableInMcLeodTailor: customOnly, description: "The iframe's title." }
            };
        }
        return iframePropDefs;
    }
}
