import { ChangeEvent, DropdownItem, Panel, Textbox, Tree, TreeNode } from "@mcleod/components";
import { DatePart, DateUtil, Model } from "@mcleod/core";
import { CommonDialogs } from "../CommonDialogs";

export class LogReaderLogFileTextbox {
    private static handleFileBrowse(event: ChangeEvent) {
        if (event.newValue === "Browse..." && event.domEvent != null) { // without the event.domEvent check, we will fire this twice.  It'd be nice if the library didn't fire it twice.  Not sure what condition causes it.
            Model.search("common/log-reader/available-logs").then(response => {
                const tree = new Tree({ height: 300, width: 400, borderWidth: 1, borderRadius: 4, color: "subtle.dark", borderColor: "strokeSecondary", nodeLeafImageName: "document" });
                const nodes = this.getNodes(response.modelRows.map(row => row.data));
                tree.getRootNode().setChildren(nodes);
                const panel = new Panel({ components: [tree] });
                CommonDialogs.showDialog(panel, { title: "Choose Log To Analyze" }).then(() => {
                    const caption = this.getBrowseNodeText(tree.selectedNode);
                    (event.target as Textbox).selectedItem = { caption, value: caption };
                });
            });
        }
    }

    public static getBrowseNodeText(node: TreeNode): string {
        if (node == null)
            return "";
        let result = node.caption;
        while (!node.parent.isRoot()) {
            result = node.parent.caption + "/" + result;
            node = node.parent;
        }
        return result;
    }

    public static getNodes(data: any): TreeNode[] {
        const result: TreeNode[] = [];
        for (const row of data) {
            const node = new TreeNode({ caption: row.name });
            result.push(node);
            const children = row.children;
            if (children != null)
                node.setChildren(this.getNodes(children));
        }
        return result;
    }


    public static getLogFileOptions(): DropdownItem[] {
        const result: DropdownItem[] = [];
        let date = new Date();
        result.push({ caption: "Today", value: DateUtil.formatDate(date, "yyyy-MM-dd") });
        date = DateUtil.dateAdd(DatePart.DAY, date, -1);
        result.push({ caption: "Yesterday", value: DateUtil.formatDate(date, "yyyy-MM-dd") });
        for (let i = 0; i < 5; i++) {
            date = DateUtil.dateAdd(DatePart.DAY, date, -1);
            result.push({ caption: DateUtil.formatDate(date, "EEEE MM/dd"), value: DateUtil.formatDate(date, "yyyy-MM-dd") });
        }
        result.push({ caption: "Browse..." });
        return result;
    }

    public static initializeFileNameTextbox(textLogFile: Textbox) {
        textLogFile.addChangeListener((event: ChangeEvent) => this.handleFileBrowse(event));
        textLogFile.items = this.getLogFileOptions();
        textLogFile.selectedItem = textLogFile.items[0];
    }
}