import { Button, ButtonVariant, ChartDataset, ClickEvent, Component, DataDisplayEvent, HorizontalSpacer, Label, Panel, Table, TableColumn, TableSelectionEvent } from "@mcleod/components";
import { AutogenLayoutHeapAnalyzer } from "./autogen/AutogenLayoutHeapAnalyzer";
import { Alignment, Clipboard, HorizontalAlignment, ModelRow, StringUtil, UUID, getLogger, getThemeColor } from "@mcleod/core";
import { AnalyzerUtil } from "./AnalyzerUtil";
import { InstanceTree } from "./InstanceTree";
import { HeapInfo } from "./AnalyzerTypes";
import { CommonDialogs } from "@mcleod/common";

const log = getLogger("HeapAnalyzer");

export class HeapAnalyzer extends AutogenLayoutHeapAnalyzer {
    private _heapInfo: HeapInfo;
    private treeHistorgramInstances: InstanceTree;
    private treeLargeObjects: InstanceTree;

    onLoad() {
        this.treeHistorgramInstances = new InstanceTree({ fillHeight: true, fillRow: true, maxHeight: 500 });
        this.splitHistogram.add(this.treeHistorgramInstances);
        this.treeLargeObjects = new InstanceTree({ fillHeight: true, fillRow: true, maxHeight: 552 });
        this.treeLargeObjects.tree.maxHeight = 508;
        this.panelLargeObjectContent.add(this.treeLargeObjects);

        this.setupChart();
        if (this._heapInfo != null)
            this.displayHeapInfo(this._heapInfo);
    }

    get heapInfo(): HeapInfo {
        return this._heapInfo;
    }

    set heapInfo(heapInfo: string | HeapInfo) {
        if (typeof heapInfo === "string")
            heapInfo = JSON.parse(heapInfo) as HeapInfo;
        this._heapInfo = heapInfo;
        heapInfo.instanceMap = {};
        heapInfo.instances?.forEach(instance => this._heapInfo.instanceMap[instance.a] = instance);
        this.displayHeapInfo(heapInfo);
    }

    private displayHeapInfo(heapInfo: HeapInfo) {
        if (this.textClassCount == null)
            return;
        this.treeHistorgramInstances.instanceMap = this.heapInfo?.instanceMap;
        this.treeHistorgramInstances.totalHeapSize = heapInfo?.usedHeap;
        this.treeLargeObjects.instanceMap = this.heapInfo?.instanceMap;
        this.treeLargeObjects.totalHeapSize = heapInfo?.usedHeap;
        this.displayChartData(heapInfo);
        const instances = [];
        heapInfo.largeInstances.forEach(instanceId => instances.push(this.heapInfo.instanceMap[instanceId]));
        this.treeLargeObjects.instances = instances;

        const props = heapInfo.systemProperties;
        this.textClassCount.text = heapInfo?.classCount?.toLocaleString();
        this.textHeapSize.text = AnalyzerUtil.getSizeString(heapInfo.usedHeap);
        this.textInstances.text = heapInfo?.instanceCount?.toLocaleString();
        this.textSummaryInstances.text = heapInfo?.instances?.length.toLocaleString();
        this.textHeapDate.text = heapInfo?.creationDate?.toLocaleString();
        this.textUser.text = props["user.name"];
        this.textApplication.text = this.getProgramDisplayName(props["loadmaster.mainClass"]);
        this.textInstance.text = props["loadmaster.instance"];
        this.textSCAC.text = props["loadmaster.scac"];
        this.textLMEVersion.text = "24.2.0";
        this.textJavaVersion.text = props["java.version"];
        this.textCommitId.caption = heapInfo.commitId?.substring(0, 8);
        if (heapInfo.commitId == null)
            this.textCommitId.link = "https://dev.azure.com/mcleoddev/LoadMaster/_git/loadmaster";
        else
            this.textCommitId.link = "https://dev.azure.com/mcleoddev/LoadMaster/_git/loadmaster?version=GC" + this.heapInfo.commitId;

        this.textHeapFileName.text = heapInfo?.heapFileName;
        this.textHost.text = heapInfo?.hostName;
        this.displayDupStrings(heapInfo);

        for (const classInfo of heapInfo.histogram) {
            const row = new ModelRow(null, false, classInfo);
            this.tableUsageByClass.addRow(row, null, { display: true, addToData: false });
        }
    }

    private getProgramDisplayName(mainClassName: string): string {
        if ("com.tms.client.lib.Menu" === mainClassName) {
            return "Client menu";
        } else if ("com.tms.tomcat.McLeodTomcat" === mainClassName) {
            return "Web server";
        }
        mainClassName = StringUtil.stringAfterLast(mainClassName, ".");
        if (mainClassName?.endsWith("Main")) {
            mainClassName = mainClassName.substring(0, mainClassName.length - 4);
        }
        return mainClassName;
    }

    public tableUsageByClassOnSelect(event: TableSelectionEvent) {
        const instances = [];
        const instanceIds = event.newRow.data.get("instances");
        if (instanceIds != null) {
            instanceIds.forEach(instanceId => instances.push(this.heapInfo.instanceMap[instanceId]));
        }
        this.treeHistorgramInstances.instances = instances;
    }

    public buttonToggleHistogramOnClick(event: ClickEvent) {
        this.toggleSection(this.splitHistogram, this.panelHistogram, this.labelHistorgramHeader, 600);
    }

    public buttonToggleLargeObjectsOnClick(event: ClickEvent) {
        this.toggleSection(this.panelLargeObjectContent, this.panelLargeObjects, this.labelLargeObjects, 600);
    }

    private toggleSection(componentToHideShow: Component, componentToResize: Component, headerLabel: Label, largeHeight?: number) {
        componentToHideShow.visible = !componentToHideShow.visible;
        if (componentToHideShow.visible) {
            componentToResize.height = largeHeight;
            headerLabel.fontSize = "xlarge";
        }
        else {
            componentToResize.height = 40;
            headerLabel.fontSize = "small";
        }
    }

    private displayChartData(heapInfo: HeapInfo) {
        const dataset = new ChartDataset();
        const data = [];
        let otherHeapSize = heapInfo.usedHeap;

        const largeByClass = {};
        for (let i = 0; data.length < 10 && i < heapInfo.largeInstances.length; i++) {
            const instance = heapInfo.instanceMap[heapInfo.largeInstances[i]];
            if (instance != null) {
                let classInfo = largeByClass[instance.c];
                if (classInfo == null) {
                    classInfo = { size: 0, count: 0 };
                    largeByClass[instance.c] = classInfo;
                }
                classInfo.size += instance.s + instance.ss;
                classInfo.count++;
                if (classInfo.count == 1) {
                    classInfo.label = instance.c + " @ " + instance.a;
                } else {
                    classInfo.label = classInfo.count + " instances of " + instance.c;
                }
            }
        }

        const large: any[] = Object.values(largeByClass);
        for (let i = 0; data.length < 10 && i < large.length; i++) {
            data.push({ x: large[i].label, y: large[i].size, tooltipValue: AnalyzerUtil.getSizeString(large[i].size) + " (" + AnalyzerUtil.getSizePercentage(large[i].size, heapInfo.usedHeap) + ")" });
            otherHeapSize -= large[i].size;
        }
        data.push({ 
            x: "Other", 
            y: otherHeapSize, 
            backgroundColor: getThemeColor("subtle.light"), 
            tooltipValue: AnalyzerUtil.getSizeString(otherHeapSize) + " (" + AnalyzerUtil.getSizePercentage(otherHeapSize, heapInfo.usedHeap) + ")",
        });
        dataset.chartData = data;
        dataset.label = "Size";
        this.chartHeap.removeAllDatasets();
        this.chartHeap.addDataset(dataset);
        this.chartHeap.renderChart();
    }

    private setupChart() {
        this.chartHeap.legendPosition = "none";
        this.chartHeap.scaleProps.x.display = false;
        this.chartHeap.scaleProps.x.grid = { drawOnChartArea: false };
        this.chartHeap.scaleProps.y.display = false;
        this.chartHeap.scaleProps.y.grid = { drawOnChartArea: false };

        this.chartHeap.renderDefaultData = false; // this prop will go away once we take the default data out of Chart
    }

    public buttonToggleSummaryOnClick(event: ClickEvent) {
        this.toggleSection(this.panelSummaryContent, this.panelSummary, this.labelHeapSummary);
    }

    public labelClassSizeOnDataDisplay(event: DataDisplayEvent) {
        (event.target as Label).caption = AnalyzerUtil.getSizeString(event.rowData.get("size"));
    }

    public labelClassSizePercentOnDataDisplay(event: DataDisplayEvent) {
        (event.target as Label).caption = AnalyzerUtil.getSizePercentage(event.rowData.get("size"), this.heapInfo.usedHeap);
    }

    public textDupStringsOnClick(event: ClickEvent) {
        const table = new Table({ fillHeight: true, fillRow: true });
        table.addColumn(new TableColumn({ headingDef: { caption: "Value", paddingLeft: 4 }, cell: { field: "value" } }), true, false);
        table.addColumn(new TableColumn({ headingDef: { caption: "Count", width: 80, align: HorizontalAlignment.RIGHT }, cell: { field: "count", fillRow: true, align: HorizontalAlignment.RIGHT, paddingRight: 12 } }), false, true);
        table.addColumn(new TableColumn({ headingDef: { caption: "Size", width: 100, align: HorizontalAlignment.RIGHT }, cell: { field: "size", fillRow: true, align: HorizontalAlignment.RIGHT} }), false, true);
        for (const dupString of this.heapInfo.dupStrings) {
            table.addRow({ value: dupString.value, count: dupString.count, size: AnalyzerUtil.getSizeString(dupString.size) }, null, { display: true, addToData: false});
        }
        CommonDialogs.showDialog(table, { title: "Strings with over " + this.heapInfo.settings.dupStringMinimumCount + " duplicates", width: 800, height: 600, resizable: true });
    }

    public displayDupStrings(heapInfo: HeapInfo) {
        this.labelDupStringCaption.color = "component.palette.textbox.caption.color";
        this.textDupStrings.caption = AnalyzerUtil.getSizeString(heapInfo?.dupStringSize);
        this.textDupStrings.tooltipPosition = Alignment.RIGHT;
        const dupPercent = heapInfo?.dupStringSize / heapInfo?.usedHeap * 100;
        this.textDupStrings.tooltip = "There are " + heapInfo?.dupStringCount?.toLocaleString() + " duplicated strings (" + 
            dupPercent.toFixed(1) + "% of the heap).\nClick here to see which strings are duplicated.";
        if (dupPercent > 10) {
            this.textDupStrings.color = "warning";
        }
    }

    public buttonSysPropsOnClick(event: ClickEvent) {
        let text = "";
        for (const key of Object.keys(this.heapInfo.systemProperties)) {
            text += key + "=" + this.heapInfo.systemProperties[key] + "\n";
        }
        HeapAnalyzer.techDialog("System Properties", text);
    }

    public buttonThreadsOnClick(event: ClickEvent) {
        let text = "";
        for (const thread of this.heapInfo.threads) {
            for (const line of thread) {
                text += line + "\n";
            }
            text += "\n";
        }
        HeapAnalyzer.techDialog("Threads", text);
    }

    static techDialog(heading: string, text: string) {
        const panel = new Panel({ fillRow: true, fillHeight: true });
        const spacer = new HorizontalSpacer();
        const buttonCopy = new Button({ imageName: "copy", variant: ButtonVariant.round, tooltip: "Copy text to clipboard", padding: 4, margin: 0 });
        buttonCopy.addClickListener(() => {
            Clipboard.copyText(text);
            buttonCopy.showTooltip("Text copied to clipboard", { timeout: 5000 });
        });
        const label = new Label({ caption: text, padding: 16, fillRow: true, fontFamily: "courier, monospace", scrollY: true, marginTop: 8, backgroundColor: "background2" });
        label.fillHeight = true;
        label.height = "calc(100% - 32px)";
        label.style.display = "block";
        panel.add(spacer, buttonCopy, label)
        CommonDialogs.showMessage( panel, heading, { width: "80%", height: "80%", resizable: true, movable: true });
    }
}