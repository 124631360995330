import { getDayOfYear } from "date-fns";
import { DateFormat, DateUtil, ExtendedDateFormat } from "./Date";

const ONE_HOUR = 3600;
const ONE_DAY = 24 * ONE_HOUR;
const ONE_WEEK = ONE_DAY * 7;

const relativeDateListeners: InternalListener[] = [];
let timerId;

export interface RelativeDateDisplayer {
    _element?: HTMLElement;
    isActive?: () => boolean;
}

export interface RelativeDateListener {
    object: RelativeDateDisplayer;
    propertyToSet: string;
}

interface InternalListener {
    listener: RelativeDateListener;
    date: Date;
}

export function getRelativeDateString(date: Date | string, changeListener?: RelativeDateListener) {
    if (typeof date === "string")
        date = DateUtil.parseDateTime(date);
    addChangeListener(date, changeListener);
    let diffSeconds = (new Date().getTime() - date.getTime()) / 1000;
    if (diffSeconds >= 0) {
        if (diffSeconds < 60)
            return "Just now";
        else if (diffSeconds < ONE_WEEK)
            return getRelativeStringFromSeconds(date, diffSeconds, diffString => diffString + " ago");
        else
            return DateUtil.formatDateTime(date, DateUtil.getDateFormat(DateFormat.DATE_TIME));
    }
    else {
        diffSeconds *= -1;
        if (diffSeconds < 60)
            return "Now";
        else if (diffSeconds < ONE_WEEK)
            return getRelativeStringFromSeconds(date, diffSeconds, diffString => "in " + diffString);
        else
            return DateUtil.formatDateTime(date, DateUtil.getDateFormat(DateFormat.DATE_TIME));
    }
}

function getRelativeStringFromSeconds(date: Date, ageSeconds: number, displayFunction) {
    if (ageSeconds < ONE_HOUR) {
        const mins = Math.trunc(ageSeconds / 60);
        if (mins == 1)
            return displayFunction("1 minute");
        return displayFunction(mins + " minutes");
    }
    else if (ageSeconds < ONE_HOUR * 12) {
        const hours = Math.trunc(ageSeconds / ONE_HOUR);
        if (hours === 1)
            return displayFunction("an hour");
        else
            return displayFunction(hours + " hours");
    }
    else {
        const dateDayOfYear = getDayOfYear(date);
        const currentDayOfYear = getDayOfYear(new Date());
        const dateDiff = currentDayOfYear - dateDayOfYear;
        if (dateDiff === 0)
            return "Today at " + DateUtil.formatTime(date);
        else if (dateDiff === 1)
            return "Yesterday at " + DateUtil.formatTime(date);
        else if (dateDiff < 7) {
            return DateUtil.formatDateTime(date, "iiii") + " at " + DateUtil.formatDateTime(date, DateUtil.getUserTimeFormat());
        }
        else
            return DateUtil.formatDateTime(date, DateUtil.getDateFormat(DateFormat.DATE_TIME, ExtendedDateFormat.LONG));
    }
}

function addChangeListener(date: Date, changeListener: RelativeDateListener) {
    if (changeListener?.object == null)
        return;
    let found = false;
    for (const listener of relativeDateListeners)
        if (listener.listener.object === changeListener.object && listener.listener.propertyToSet === changeListener.propertyToSet) {
            listener.date = date;
            found = true;
            break;
        }
    if (!found)
        relativeDateListeners.push({ listener: changeListener, date: date });
    if (timerId == null) {
        timerId = setInterval(() => {
            const hasListeners = updateRelativeListeners();
            if (!hasListeners) {
                clearInterval(timerId);
                timerId = null;
            }
        }, 30000);
    }
}

function updateRelativeListeners() {
    for (let i = relativeDateListeners.length - 1; i >= 0; i--) {
        const l = relativeDateListeners[i].listener;
        let active: boolean;
        if (l.object.isActive != null)
            active = l.object.isActive();
        else
            active = (l.object._element !== undefined && document.body.contains(l.object._element))
        if (active)
            l.object[l.propertyToSet] = getRelativeDateString(relativeDateListeners[i].date);
        else
            relativeDateListeners.splice(i, 1);
    }
    return relativeDateListeners.length > 0;
}
