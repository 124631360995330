import { ModelRow } from "@mcleod/core";
import { Layout, PanelProps, PropType } from "../..";
import { ComponentPropDefinitionUtil, ComponentPropDefinitions } from "../../base/ComponentProps";
import { PanelPropDefinitions } from "../panel/PanelProps";

export interface DataHeaderProps extends PanelProps {
    layout: Layout;
    title: string;
    showClose: boolean;
    showExecute: boolean;
    showSave: boolean;
    showSaveAndClose: boolean;
    onClose: () => void;
    onExecute: (row: ModelRow<any>) => void;
    onSaveButtonClose: () => void;
}

let dataHeaderPropDefs: ComponentPropDefinitions;

export class DataHeaderPropDefinitions {
    public static getDefinitions(): ComponentPropDefinitions {
        if (dataHeaderPropDefs == null) {
            dataHeaderPropDefs = {
                ...PanelPropDefinitions.cloneDefs(),
                showClose: { type: PropType.bool, defaultValue: false, description: "Determines whether the default close button appears in the tools of this DataHeader." },
                showExecute: { type: PropType.bool, defaultValue: true, description: "Determines whether the search or save button (depending on the DataSource mode) appears in the tools of this DataHeader." },
                showSave: { type: PropType.bool, defaultValue: true, description: "Determines whether the save button includes the 'Save' option." },
                showSaveAndClose: { type: PropType.bool, defaultValue: false, description: "Determines whether the save button includes the 'Save and Close' option." },
                title: { type: PropType.string, description: "This specifies the text that will appear in the DataHeader.  If it is left blank, the title of the layout that the DataHeader is on will be used." },
            };
            dataHeaderPropDefs.fillRow.defaultValue = true;
            dataHeaderPropDefs.marginBottom.defaultValue = 12;
            dataHeaderPropDefs.padding.defaultValue = 0;
            ComponentPropDefinitionUtil.populateComponentPropNames(dataHeaderPropDefs);
        }
        return dataHeaderPropDefs;
    }
}
