import { ArrayUtil, Keys, getLogger } from "@mcleod/core";
import { List } from "../components/list/List";
import { ListProps } from "../components/list/ListProps";
import { DropdownSelectionEvent } from "../events/DropdownSelectionEvent";
import { SelectionListener } from "../events/SelectionEvent";
import { DropdownProps, Overlay } from "./Overlay";
import { Label } from "../components/label/Label";
import { OverlayStyles } from "./OverlayStyles";
import { Anchor, AnchorProps } from "./Anchor";
import { Component } from "../base/Component";
import { StringOrPropsOrComponent } from "./getComponentFromStringOrPropsOrComponent";
import { KeyEvent } from "../events/KeyEvent";
import { ListItem } from "../components/list/ListItem";

const log = getLogger("components.page.OverlayedList");

export class OverlayedList extends List {
    private _overlay: Overlay;
    private _parentList: OverlayedList;
    private listProps: Partial<ListProps>;
    private dropdownProps: Partial<DropdownProps>;

    constructor(props: Partial<ListProps>, onSelect: SelectionListener, listProps: Partial<ListProps>,
        dropdownProps: Partial<DropdownProps>) {
        super(props);
        this.listProps = listProps;
        this.dropdownProps = dropdownProps;
        this.addSelectionListener((event: DropdownSelectionEvent) => {
            if (onSelect != null)
                onSelect(event);
            if (!event.defaultPrevented)
                this.hideOverlayAndParentOverlay();
        });
        this.defaultMaxHeight();
    }

    get overlay(): Overlay {
        return this._overlay;
    }

    set parentList(value: OverlayedList) {
        this._parentList = value;
    }

    public isNestedList(): boolean {
        return this._parentList != null;
    }

    private hideOverlayAndParentOverlay() {
        Overlay.hideOverlay(this._overlay);
        if (this._parentList != null)
            this._parentList.hideOverlayAndParentOverlay();
    }

    private defaultMaxHeight() {
        if (this.maxHeight === undefined)
            this.maxHeight = 220;
    }

    display(anchor: AnchorProps, selectedItem: StringOrPropsOrComponent) {
        let comp: Component = this;
        if (ArrayUtil.isEmptyArray(this.items) === true) {
            comp = new Label({
                className: OverlayStyles.popup,
                caption: "No items",
                padding: 8,
                color: "subtle",
                allowSelect: false,
                ...this.listProps
            });
        }
        Anchor.sizeToAnchor(comp, anchor);
        this._overlay = Overlay.showInOverlay(comp, { anchor: anchor, ...this.dropdownProps });
        this.focus();
        if (selectedItem == null && this.items.length > 0)
            this.selectedIndex = 0;
    }

    override sendKey(event: KeyEvent | KeyboardEvent) {
        const code = event.key;
        log.debug("Processing key [%s] in overlayed list of type %o", code, this.type);
        if (this.isPopupType()) {
            if ((code === Keys.ARROW_LEFT || code === Keys.ESCAPE) && this.isNestedList()) {
                Overlay.hideOverlay(this.overlay);
                this._parentList.focus();
                return true;
            }
            const selectedListItem = this.selectedListItem;
            if ((code === Keys.ENTER || code === Keys.ARROW_RIGHT) && selectedListItem?.hasChildren() === true) {
                this.displayNestedList(code, selectedListItem);
                return true;
            }
        }
        return super.sendKey(event);
    }

    private displayNestedList(key: string, listItem: ListItem) {
        log.debug("Key %s firing display of nested list under list item %o", key, listItem);
        //assumes that list items were set up with click listeners...time will tell if this is adequate...
        listItem.renderedComponent.clicked();
    }
}
