import { ClickEvent, Label, Panel } from "@mcleod/components";
import { AutogenLayoutLogAnalyzer } from "./autogen/AutogenLayoutLogAnalyzer";
import { DurationFormat, ModelRow, StringUtil, getLogger, getThemeColor, makeStyles } from "@mcleod/core";
import { SourceLink } from "./SourceLink";
import { CommonDialogs } from "@mcleod/common";

const charWidth = 8.56;
const lineHeight = 16;

const classes = makeStyles("log", () => {
    return {
        pause: { borderTop: "3px solid " + getThemeColor("warning") },
        logLine: { height: lineHeight + "px", position: "absolute", width: "100%", whiteSpace: "pre" },
        tab: { marginRight: (charWidth * 4) + "px" },
        date: { color: getThemeColor("default.lighter"), fontWeight: 600 },
        thread: { color: getThemeColor("primary.light"), fontWeight: 600 },
        level: { color: getThemeColor("default.lighter") },
        source: { color: getThemeColor("success.light"), fontWeight: 600 },
        actionStart: { backgroundColor: getThemeColor("success.light"), color: getThemeColor("success.reverse") },
        actionEnd: { backgroundColor: getThemeColor("success.dark"), color: getThemeColor("success.reverse") },
        error: { backgroundColor: getThemeColor("error.lightest") + "33"},
        warning: { backgroundColor: getThemeColor("warning.lighter") },
        caution: { backgroundColor: getThemeColor("caution.lightest") },
        lineNumber: { backgroundColor: "#cecece", paddingLeft: "6px", paddingRight: "6px", marginRight: "4px" },
        selectedHighlight: { backgroundColor: getThemeColor("primary.lightest") + "aa" },
        searchResult: { position: "absolute", height: "16px", backgroundColor: "#fafa33" }
    }
});

enum ActionType {
    START, END
}

interface Line {
    raw: string;
    index: number;
    date?: Date;
    thread?: string;
    source?: string;
    message?: string;
    execTime?: number;
    error?: boolean;
    millisSinceLast?: number;
    actionPair?: number;
    actionType?: ActionType;
    element?: HTMLElement;
}

interface FindResult {
    line: number;
    startColumn: number;
    endColumn: number;
}

const log = getLogger("support/LogAnalyzer");

export class LogAnalyzer extends AutogenLayoutLogAnalyzer {
    private _contents: string;
    private lines: Line[] = [];
    private logContainer: HTMLElement;
    private visibleLines: Line[] = [];
    private lineNumberDigits = 1;
    // private caretPosition = { line: 0, column: 0 };
    private highlightedLines:number[] = [];
    private highlightIndex = -1;
    private findContainer: HTMLElement;
    private findResults: FindResult[] = [];
    private findIndex = -1;

    private timeFormat: DurationFormat = new DurationFormat()
        .dayFormat({ suffix: "d", separator: " " })
        .hourFormat({ suffix: "h", separator: " " })
        .minuteFormat({ suffix: "m", separator: " " })
        .secondFormat({ suffix: "s", separator: " " })
        .milliFormat({ suffix: "ms", separator: " "});

    onLoad() {
        if (localStorage.getItem("LogAnalyzer.summaryVisible") === "false") 
            this.buttonMinimizeSummaryOnClick(null);
        this.labelLog.style.display = "block";
        this.chartDBExecByTime.xAxisProps.title = { text: "Execution time(ms)", display: true };
        this.chartDBExecByTime.yAxisProps.title = { text: "Number of queries", display: true };
        const logElement = this.labelLog._element;
        this.logContainer = document.createElement("div");
        this.logContainer.id = "logContainer";
        this.logContainer.style.position = "absolute";
        this.logContainer.style.zIndex = "1";
        this.findContainer = document.createElement("div");
        this.findContainer.id = "findContainer";
        this.findContainer.style.position = "absolute";
        this.findContainer.style.zIndex = "0";
        logElement.appendChild(this.logContainer);
        logElement.appendChild(this.findContainer);
        new ResizeObserver(() => this.displayViewPortLines()).observe(logElement);
        logElement.addEventListener("scroll", () => this.displayViewPortLines());
        if (this.lines.length === 0)
            this.contents = this.contents;
    }

    get contents(): string {
        return this._contents;
    }

    set contents(contents: string) {
        this._contents = contents;
        if (this.labelLog != null && this.timeFormat != null) {
            let start = 0;
            let newLineIndex;
            // could use split("\n") but that would create a huge string array for large logs
            while ((newLineIndex = contents.indexOf("\n", start)) >= 0) {
                const line = contents.substring(start, newLineIndex);
                this.lines.push({ raw: StringUtil.rtrim(line), index: this.lines.length });
                start = newLineIndex + 1;
            }
            if (start < contents.length)
                this.lines.push({ raw: StringUtil.rtrim(contents.substring(start)), index: this.lines.length });
            this.analyze();
        }
    }

    private async analyze() {
        let startDate: Date, endDate: Date;
        let exceptionCount = 0, sqlCount = 0, execTime = 0;
        let inStackTrace = false;
        const execTimeBuckets = [0, 0, 0, 0, 0];
        let lastDate;
        let maxWidth = 0;
        const actionStack:number[] = [];
        this.lineNumberDigits = this.lines.length.toString().length;
        this.highlightedLines = [];
        for (let i = 0; i < this.lines.length; i++) {
            const line = this.lines[i];
            maxWidth = Math.max(maxWidth, line.raw.length);
            const trimmed = line.raw.trim();
            line.date = this.parseDate(trimmed);
            if (actionStack.length > 0) {
                line.actionPair = actionStack[actionStack.length - 1];
            }
            if (line.date != null) {
                if (startDate == null)
                    startDate = line.date;
                endDate = line.date;
            }
            if (actionStack.length > 0 && lastDate != null && line.date != null) {
                line.millisSinceLast = line.date.getTime() - lastDate.getTime();
            }
            if (trimmed.indexOf(" ERROR ") >= 0 || line.raw.indexOf(" SEVERE ") >= 0) {
                this.highlightedLines.push(line.index);
                inStackTrace = true;
                exceptionCount++;
                line.error = true;
            }
            else if (inStackTrace) {
                if (trimmed.length === 0 || trimmed.startsWith("at ") ||
                    trimmed.startsWith("Caused by: ") || trimmed.startsWith("...")) {
                    line.error = true;
                } else {
                    inStackTrace = false;
                }
            }
            if (!inStackTrace) {
                if (trimmed.indexOf("[TButton]") >= 0 || trimmed.indexOf("[UserAction]") >= 0) {
                    this.highlightedLines.push(line.index);
                    if (trimmed.indexOf("finished in") > 0) {
                        if (actionStack.length > 0) {
                            const startIndex = actionStack.pop();
                            line.actionPair = startIndex;
                            this.lines[startIndex].actionPair = i;
                        }
                        line.actionType = ActionType.END;
                    }
                    else {
                        actionStack.push(i);
                        line.actionType = ActionType.START;
                    }
                } else if (line.raw.indexOf("Execution time: ") >= 0) {
                    sqlCount++;
                    const millis = this.parseExecTime(StringUtil.stringAfter(trimmed, "Execution time: "));
                    if (isNaN(millis)) {
                        log.info("Failed to parse execution time: " + trimmed);
                    } else {
                        line.execTime = millis;
                        execTime += millis;
                        if (millis > 10)
                            this.highlightedLines.push(line.index);
                        if (millis > 1000) {
                            execTimeBuckets[4]++;
                        }
                        else if (millis > 500) {
                            execTimeBuckets[3]++;
                        }
                        else if (millis > 100) {
                            execTimeBuckets[2]++;
                        }
                        else if (millis > 10) {
                            execTimeBuckets[1]++;
                        } else {
                            execTimeBuckets[0]++;
                        }
                    }                        
                }
            }
            if (line.date != null)
                lastDate = line.date;
        }

        this.textLogCount.text = this.lines.length.toLocaleString();
        this.textDatabaseCount.text = sqlCount.toLocaleString();
        this.textDatabaseTime.text = this.timeFormat.format(execTime);
        this.textErrorCount.text = exceptionCount.toLocaleString();
        if (startDate != null && endDate != null)
            this.textLogSpan.text = this.timeFormat.format(endDate.getTime() - startDate.getTime());
        this.displayExecTimeChart(execTimeBuckets);
        this.logContainer.style.height = (this.lines.length * lineHeight) + "px";
        this.logContainer.style.width = (maxWidth * charWidth) + "px";
        this.labelHighlights.caption = "/ " + this.highlightedLines.length.toLocaleString();
        this.displayViewPortLines();
    }

    private displayViewPortLines() {
        if (this.logContainer == null)
            return; 
        const top = this.labelLog._element.scrollTop;
        const height = this.labelLog._element.clientHeight;
        for (const visibleLine of this.visibleLines) {
            if (visibleLine.element != null && (visibleLine.index * lineHeight < top || visibleLine.index * lineHeight > top + height)) {
                this.logContainer.removeChild(visibleLine.element);
                visibleLine.element = null;
            }
        }
        const startIndex = Math.floor(top / lineHeight);
        const endIndex = Math.floor(startIndex + (height / lineHeight));
        for (let index = startIndex; index <= endIndex && index < this.lines.length; index++) {
            const line = this.lines[index];
            if (line.element == null) {
                line.element = this.createLine(line);
                this.logContainer.appendChild(line.element);
                this.visibleLines.push(line);
            }
            if (line.index === this.highlightedLines[this.highlightIndex] || 
                line.index === this.findResults[this.findIndex]?.line)
                line.element.classList.add(classes.selectedHighlight);
            else 
                line.element.classList.remove(classes.selectedHighlight);
        }

        const gutterWidth = (this.lineNumberDigits * charWidth) + 16;
        this.findContainer.innerHTML = "";
        for (const findResult of this.findResults) {
            if (findResult.line >= startIndex && findResult.line < endIndex) {
                const span = document.createElement("span");
                span.style.top = (findResult.line * lineHeight) + "px";
                span.style.left = (gutterWidth + (findResult.startColumn * charWidth)) + "px";
                span.style.width = ((findResult.endColumn - findResult.startColumn) * charWidth) + "px";
                span.className = classes.searchResult;
                this.findContainer.appendChild(span);
            }
        }
    }

    private createLine(line: Line): HTMLElement {
        const result = new Label();
        const element = result._element;
        if (line.actionPair != null) {
            result.tooltip = "Action";
        }
        result.className = classes.logLine;
        result.style.top = (line.index * lineHeight) + "px";
        let text = line.raw;
        const lineNumber = (line.index + 1).toString().padStart(this.lineNumberDigits, " ");
        element.appendChild(this.span(lineNumber, classes.lineNumber));
        while (text.startsWith("\t") || text.startsWith(" ")) {
            const span = this.span(" ", text.startsWith("\t") ? classes.tab : classes.space);
            result._element.appendChild(span);
            text = text.substring(1);
        }
        text = text.trim();
        if (line.date != null) {
            text = this.addStandardLineElements(result.element, text, line);
        }
        if (line.execTime > 10) {
            this.addExecTimeElements(element, line.execTime, text);
        } else if (line.actionType === ActionType.START) {
            element.appendChild(this.span(text, classes.actionStart));
        } else if (line.actionType === ActionType.END) {
            element.appendChild(this.span(text, classes.actionEnd));
        } else if (line.error) {
            this.addErrorElements(element, text);
        } else {
            element.appendChild(this.span(text));
        }
        return element;
    }

    private addStandardLineElements(result: HTMLElement, text: string, line: Line): string {
        const dateString = text.substring(0, 24);
        const threadEnd = text.indexOf(" ", 24) + 1;
        const thread = text.substring(24, threadEnd)
        const levelEnd = text.indexOf(" ", threadEnd) + 1;
        const level = text.substring(threadEnd, levelEnd);
        const sourceStart = text.indexOf("[");
        const sourceEnd = text.indexOf("]") + 2;
        const source = text.substring(sourceStart, sourceEnd);
        text = text.substring(sourceEnd);
        const dateSpan = this.span(dateString, line.millisSinceLast > 500 ? classes.pause : classes.date);
        if (line.millisSinceLast > 500)
            dateSpan.title = "There is a " + line.millisSinceLast.toLocaleString() + "ms pause between these lines";
        result.appendChild(dateSpan);
        result.appendChild(this.span(thread, classes.thread));
        result.appendChild(this.span(level, classes.level));
        result.appendChild(this.span(source, classes.source));
        return text;
    }

    private addExecTimeElements(result: HTMLElement, execTime: number, text: string) {
        const prefix = text.substring(0, text.indexOf("Execution time: ") + 16);
        const suffix = text.substring(text.indexOf("Execution time: ") + 16);
        result.appendChild(this.span(prefix));
        result.appendChild(this.span(suffix, execTime > 100 ? classes.warning : classes.caution));
    }

    private addErrorElements(result: HTMLElement, text: string) {
        if (text.startsWith("at com.tms.")) {
            const span = document.createElement("span");
            span.className = classes.error;
            span.innerHTML = SourceLink.replaceSourceLink(text, "develop", true);
            result.appendChild(span);
        } else {
            result.appendChild(this.span(text, classes.error));
        }
    }

    private span(text: string, className: string = null) {
        const span = document.createElement("span");
        if (className != null)
            span.className = className;
        span.innerHTML = text;
        return span;
    }

    private displayExecTimeChart(execTimeBuckets: number[]) {
        const rows = [] as ModelRow[];
        rows.push(new ModelRow(null).setValues({ label: "0 - 10ms", value: execTimeBuckets[0] }));
        rows.push(new ModelRow(null).setValues({ label: "11 - 100ms", value: execTimeBuckets[1] }));
        rows.push(new ModelRow(null).setValues({ label: "101 - 500ms", value: execTimeBuckets[2] }));
        rows.push(new ModelRow(null).setValues({ label: "501 - 1000ms", value: execTimeBuckets[3] }));
        rows.push(new ModelRow(null).setValues({ label: "1000+ms", value: execTimeBuckets[4] }));
        this.chartDBExecByTime.displayData(rows[0], rows, 0);
    }

    private parseDate(line: string): Date {
        if (!line.startsWith("20") || line.length < 20)
            return null;
        const spaceIndex = line.indexOf(" ", line.indexOf(" ") + 1);
        if (spaceIndex >= 0) {
            const result = new Date(line.substring(0, spaceIndex));
            if (!isNaN(result.getTime()))
                return result;
        }
        return null;
    }

    private parseExecTime(text: string): number {
        const colonPos = text.indexOf(":");
        let result = parseInt(text.substring(0, colonPos)) * 1000;
        result += parseInt(text.substring(colonPos + 1, colonPos + 4));
        return result;
    }

    private highlight(line: string, backgroundColor: string) {
        return "<span style='background-color:" + backgroundColor + "'>" + line + "</span>";
    }

    private buttonMinimizeSummaryOnClick(event: ClickEvent) {
        if (this.panelSummaryContent.visible) {
            this.buttonMinimizeSummary.imageName = "restoreWindow";
            this.panelSummary.width = 140;
            this.labelSummaryHeader.fontSize = "small";
            this.buttonMinimizeSummary.width = 14;
            this.panelSummaryHeader.remove(this.buttonPopout);
        } else {
            this.buttonMinimizeSummary.imageName = "minimize";
            this.panelSummary.width = 600;
            this.labelSummaryHeader.fontSize = "large";
            this.buttonMinimizeSummary.width = 20;
            this.panelSummaryHeader.add(this.buttonPopout);
        }
        this.panelSummaryContent.visible = !this.panelSummaryContent.visible;
        localStorage.setItem("LogAnalyzer.summaryVisible", this.panelSummaryContent.visible ? "true" : "false");
    }

    private buttonPopoutOnClick(event: ClickEvent) {
        CommonDialogs.showDialog("Popout not implemented yet.");
    }

    private buttonPreviousSearchOnClick(event: ClickEvent) {
        this.scrollToFindResult(this.findIndex - 1);
    }

    private buttonNextSearchOnClick(event: ClickEvent) {
        this.scrollToFindResult(this.findIndex + 1);
    }

    private buttonPreviousHighlightOnClick(event: ClickEvent) {
        this.scrollToHighlight(this.highlightIndex - 1);
    }

    private buttonNextHighlightOnClick(event: ClickEvent) {
        this.scrollToHighlight(this.highlightIndex + 1);
    }

    private scrollToHighlight(index: number) {
        index = this.rollNumber(index, this.highlightedLines.length);
        this.highlightIndex = index;
        this.textHighlightIndex.text = (index + 1).toString();
        this.scrollToLine(this.highlightedLines[index])
    }

    private scrollToLine(line: number) {
        const top = Math.max(line * lineHeight - (this.labelLog._element.clientHeight / 2), 0);
        this.labelLog._element.scrollTo({ behavior: "smooth", top: top, left: 0 });
    }

    private rollNumber(value: number, max: number) {
        if (value < 0)
            return max - 1;
        else if (value >= max)
            return 0;
        else
            return value;
    }

    public textFindResultIndexOnChange(_event) {
        const index = parseInt(this.textFindResultIndex.text);
        if (!isNaN(index) && index > 0 && index <= this.findResults.length) {
            this.scrollToFindResult(index - 1);
        }
    }

    private scrollToFindResult(index: number) {
        index = this.rollNumber(index, this.findResults.length);
        this.findIndex = index;
        this.textFindResultIndex.text = (index + 1).toString();
        this.scrollToLine(this.findResults[index].line);
    }

    private buttonRegexOnClick(event: ClickEvent) {
        this.populateFindResults();
    }

    private buttonMatchCaseOnClick(event: ClickEvent) {
        this.populateFindResults();
    }

    public textHighlightIndexOnChange(_event) {
        const index = parseInt(this.textHighlightIndex.text);
        if (!isNaN(index) && index > 0 && index <= this.highlightedLines.length) {
            this.scrollToHighlight(index - 1);
        }        
    }

    public textSearchOnChange(_event) {
        this.populateFindResults();
    }

    private populateFindResults() {
        this.findResults = [];
        const searchText = this.textSearch.text;
        this.textSearch.captionProps = {color: null};
        if (searchText.length > 0) {
            if (this.buttonRegex.selected) {
                try {
                    const regex = new RegExp(searchText, "g" + (this.buttonMatchCase.selected ? "" : "i"));
                    for (let i = 0; i < this.lines.length; i++) {
                        const line = this.lines[i].raw;
                        let result;
                        while ((result = regex.exec(line)) != null) {
                            this.findResults.push({ line: i, startColumn: result.index, endColumn: regex.lastIndex });
                        }
                    }
                } catch (e) {
                    this.textSearch.captionProps = { color: "error" };
                }
            } else {
                const search = this.buttonMatchCase.selected ? searchText : searchText.toLowerCase();
                for (let i = 0; i < this.lines.length; i++) {
                    const line = this.buttonMatchCase.selected ? this.lines[i].raw : this.lines[i].raw.toLowerCase();
                    let index = 0;
                    let result;
                    while ((result = line.indexOf(search, index)) >= 0) {
                        this.findResults.push({ line: i, startColumn: result, endColumn: result + searchText.length });
                        index = result + searchText.length;
                    }
                }
            }
        }
        this.textFindResultIndex.text = "1";
        this.panelSearchActions.visible = this.textSearch.text.length > 0;
        this.textFindResultIndex.visible = this.findResults.length > 0;
        this.buttonNextSearch.visible = this.findResults.length > 1;
        this.buttonPreviousSearch.visible = this.findResults.length > 1;
        if (this.findResults.length > 0) {
            this.labelSearchResults.caption = "/ " + this.findResults.length.toLocaleString();
        } else {
            if (this.textSearch.text.length > 0) {
                this.labelSearchResults.caption = "No matches";
            } else {
                this.labelSearchResults.caption = "";
            }
        }

        this.displayViewPortLines();
    }
}