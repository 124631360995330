import { DialogProps } from "@mcleod/components";
import { AbstractPanelOpenTree } from "../AbstractPanelOpenTree";

export class PanelOpenPortalLayoutMenu extends AbstractPanelOpenTree {
    dialogProps: Partial<DialogProps> = { title: "Layout Selection", cursor: null };

    override getApiName(): string {
        return "tailor/portal-layout-list";
    }

    override getCustomizedTooltip(): string {
        return "This is a customized layout.";
    }
}
