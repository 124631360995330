import { getThemeColor, makeStyles } from "@mcleod/core";

export const progressBarStyles = makeStyles("pgb", () => {
    return {
        base: { position: "relative" },
        bar: { height: "100%", position: "absolute", left: 0, top: 0 },
        reflection: {
            width: "10px",
            height: "25%",
            borderRadius: "4px",
            position: "absolute",
            top: "20%",
            left: "60%",
            background: getThemeColor("primary.reverse") + "cc",
            boxShadow: "0 0 20px " + getThemeColor("primary.reverse"),
            filter: "blur(4px)"
        },
        text: {
            width: "100%",
            height: "100%",
            position: "absolute",
            left: 0,
            top: 0,
            zIndex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }
    };
});