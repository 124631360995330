import { makeStyles } from "@mcleod/core";

export const IFrameStyles = makeStyles("ifr", () => {
    return {
        base: {
            borderWidth: 0,
            width: "100%",
            height: "100%"
        },
    };
});