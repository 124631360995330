import { ComponentPropDefinitionUtil, ComponentPropDefinitions, ComponentProps } from "../../base/ComponentProps";
import { PropType } from "../../base/PropType";

export interface RadioProps extends ComponentProps {
    caption: string;
    selected: boolean;
    radioSize: number;
    defaultDataValue: boolean;
    printable: boolean;
    printableDuringAdd: boolean;
    printableDuringSearch: boolean;
    printableDuringUpdate: boolean;
    valueSelected: any;
}

let radioPropsDefs: ComponentPropDefinitions;

export class RadioPropDefinitions {
    public static getDefinitions(): ComponentPropDefinitions {
        if (radioPropsDefs == null) {
            radioPropsDefs = {
                ...ComponentPropDefinitionUtil.getComponentPropertyDefinitions(),
                ...ComponentPropDefinitionUtil.getDataBoundPropertyDefinitions(),
                caption: { type: PropType.string, visibleInMcLeodTailor: true, description: "This specifies the text that will appear next to the Radio." },
                selected: { type: PropType.bool, defaultValue: false, visibleInMcLeodTailor: true, description: "This toggles whether or not the radio is currently.  When the user clicks the Radio, this value is set to true and the selected property for all other Radios in the same group will be set to false." },
                radioColor: { type: PropType.string, defaultValue: "primary", visibleInMcLeodTailor: true, description: "This sets the color of the radio image (not including the caption).  The default is the current theme's primary color." },
                radioSize: { type: PropType.number, defaultValue: 28, visibleInMcLeodTailor: true, description: "This sets this pixel height and width of the circular radio image" },
                valueSelected: { type: PropType.string, visibleInMcLeodTailor: true, editableInMcLeodTailor: { baseComponent: false, customComponent: true }, description: "This is the data value that will be used when the Radio is selected." },
                onChange: { type: PropType.event, category: "Events", addListenerMethod: "addChangeListener", removeListenerMethod: "removeChangeListener", description: "This specifies a function to be called when the radio value changes." },
                focusable: { type: PropType.bool, defaultValue: true, visibleInMcLeodTailor: true, description: "This toggles whether or not this Radio can receive focus." },
                printable: {
                    type: PropType.bool,
                    defaultValue: false,
                    category: "Appearance",
                    visibleInMcLeodTailor: true,
                    description: "This toggles whether the Radio appears as user-editable radio (printable = false) or as a caption that indicates the current state of the radio (printable = true).  This is often changed at runtime.",
                },
                printableDuringAdd: {
                    type: PropType.bool,
                    defaultValue: false,
                    category: "Appearance",
                    visibleInMcLeodTailor: true,
                    description: "This toggles whether the Radio should appear in its printable state during add mode.",
                },
                printableDuringSearch: {
                    type: PropType.bool,
                    defaultValue: false,
                    category: "Appearance",
                    visibleInMcLeodTailor: true,
                    description: "This toggles whether the Radio should appear in its printable state during search mode.",
                },
                printableDuringUpdate: {
                    type: PropType.bool,
                    defaultValue: false,
                    category: "Appearance",
                    visibleInMcLeodTailor: true,
                    description: "This toggles whether the Radio should appear in its printable state during update mode.",
                },
                defaultDataValue: { type: PropType.bool, visibleInMcLeodTailor: true, category: "Data", source: "databound", description: "This specifies the default value when entering add mode." },
                onPrintable: { type: PropType.event, category: "Events", addListenerMethod: "addPrintableListener", removeListenerMethod: "removePrintableListener", eventSignature: "OnPrintable(event: PrintableEvent)", description: "This specifies a function to be called when the component's printable property has changed."}
            };
            ComponentPropDefinitionUtil.populateComponentPropNames(radioPropsDefs);
        }
        return radioPropsDefs;
    }
}

