import { Component, DomDragEvent, DragEvent, DropdownItem, Textbox } from "@mcleod/components";
import { DOMUtil, StringUtil, getLogger } from "@mcleod/core";
import { CommonDialogs } from "../CommonDialogs";

const log = getLogger("common.logreader.LogReaderFileDrop");

export class LogReaderFileDrop {
    public static intitializeDragDropArea(dropArea: Component, selectionCombo: Textbox, loadCallback?: (fileName: string, contents: string) => void) {
        dropArea.addDropListener((event: DragEvent) => this.fileDropped(event, selectionCombo, loadCallback));
        dropArea.addDragOverListener((event: DragEvent) => this.fileDragOver(event));
    }

    private static fileDropped(event: DragEvent, selectionCombo: Textbox, loadCallback: (fileName: string, contents: string) => void) {
        event.consume();
        const files = DOMUtil.getDraggedFiles(event.domEvent as DomDragEvent);
        if (files.length !== 1)
            return;
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = () => {
            const contents = StringUtil.stringAfter(reader.result as string, ",");
            const key = "_logReaderDragItem";
            selectionCombo[key] = { caption: files[0].name, value: contents, isDraggedFile: true };
            const items = selectionCombo.items as DropdownItem[];
            if (selectionCombo.items[0].isDraggedFile)
                items.splice(0, 1);
            selectionCombo.items = [selectionCombo[key], ...items];
            selectionCombo.selectedItem = selectionCombo[key];
            if (loadCallback != null)
                loadCallback(files[0].name, contents)
        };
        reader.onerror = error => {
            log.error("Error", error);
            if (error.total > 2 * 1024 * 1024 * 1024) {
                CommonDialogs.showDialog("The browser has a file size limit of 2GB.  Please use a smaller file.");
            } else {
                CommonDialogs.showDialog("Error uploading " + files[0].name);
            }
        }
    }

    /** This is an event handler for the onDragOver event of panelDragLog.  */
    private static fileDragOver(event: DragEvent) {
        const files = DOMUtil.getDraggedFiles(event.domEvent as DomDragEvent);
        if (files.length === 1) {
            event.consume();
            log.debug("Consumed", event, event.domEvent);
        }
    }
}