import { Component } from "../..";
import { DropTargetPanelProps } from "./DropTargetPanelProps";
import { Panel } from "./Panel";

export class DropTargetPanel extends Panel implements DropTargetPanelProps {
    private _managingComponent: Component;

    constructor(props: Partial<DropTargetPanelProps>) {
        super(props);
        this.setProps(props);
    }

    override setProps(props: Partial<DropTargetPanelProps>) {
        super.setProps(props);
    }

    get managingComponent(): Component {
        return this._managingComponent;
    }

    set managingComponent(value: Component) {
        this._managingComponent = value;
    }

    getSpecialDesignerDropTarget(): Panel {
        if (this._managingComponent != null && this._managingComponent["getSpecialDesignerDropTarget"] != null) {
            const specialContainer = this._managingComponent["getSpecialDesignerDropTarget"](this);
            if (specialContainer !== undefined)
                return specialContainer;
        }
        return this;
    }
}
