import { ArrayUtil, AuthToken, ServiceAddresses, StringUtil, UrlUtil } from "@mcleod/core";
import { Component } from "../../base/Component";
import { ComponentTypes } from "../../base/ComponentTypes";
import { IFramePropDefinitions, IFrameProps } from "./IFrameProps";
import { ComponentPropDefinition, ComponentPropDefinitions } from "../../base/ComponentProps";
import { IFrameStyles } from "./IFrameStyles";
import { IFrameLoadingType } from "./IFrameLoadingType";

export class IFrame extends Component implements IFrameProps {
    private _loading: IFrameLoadingType;
    private _name: string;
    private _src: string;
    private _title: string;
    private _iframe: HTMLIFrameElement;
    private _sandbox: boolean;
    private _sandboxAttributes: string[];

    constructor(props?: Partial<IFrameProps>) {
        super("div", props);
        this._iframe = document.createElement("iframe");
        this._iframe.className = IFrameStyles.base;
        this._element.appendChild(this._iframe);
        this.setProps({ padding: 0, ...props });
    }

    get loading(): IFrameLoadingType {
        return this._loading ?? this.getPropertyDefinitions().loading.defaultValue;
    }

    set loading(value: IFrameLoadingType) {
        this._loading = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get src(): string {
        return this._src;
    }

    set src(value: string) {
        this._src = value;
        if (StringUtil.isEmptyString(value) !== true) {
            if (UrlUtil.isAbsoluteUrl(value) === true)
                this._iframe.src = value;
            else {
                const address = ServiceAddresses.getEndpointUrl(value);
                const params = "_undecorated&_auth=" + AuthToken.get();
                this._iframe.src = address + "?" + params;
            }
        }
        else
            this._iframe.removeAttribute("src");
    }

    public get onload(): ((this: GlobalEventHandlers, ev: Event) => any) | null {
        return this._iframe.onload;
    }

    public set onload(value: ((this: GlobalEventHandlers, ev: Event) => any) | null) {
        this._iframe.onload = value;
    }

    public get onerror(): ((this: GlobalEventHandlers, ev: Event) => any) | null {
        return this._iframe.onerror;
    }

    public set onerror(value: ((this: GlobalEventHandlers, ev: Event) => any) | null) {
        this._iframe.onerror = value;
    }

    async waitForLoad() {
        return new Promise((resolve, reject) => {
            const checkIframeLoaded = setInterval(() => {
                if (this.iframe.contentDocument && this.iframe.contentDocument.readyState === 'complete') {
                    clearInterval(checkIframeLoaded);
                    resolve(this.iframe.contentDocument);
                }}, 50); // Check every 50ms
    
            // Also set up the onload and onerror handlers
            this.iframe.onload = () => {
                clearInterval(checkIframeLoaded);
                resolve(this.iframe.contentDocument);
            };
            this.iframe.onerror = (err) => {
                clearInterval(checkIframeLoaded);
                reject(new Error("Failed to load iframe " + err));
            };
        });
    }

    public postMessage(message: any, targetOrigin: string, transfer?: Transferable[]): void {
        this._iframe.contentWindow.postMessage(message, targetOrigin, transfer);
    }

    public get contentWindow():Window {
        return this._iframe.contentWindow;
    }

    get iframe(): HTMLIFrameElement {
        return this._iframe;
    }

    get title(): string {
        return this._title;
    }

    set title(value: string) {
        this._title = value;
    }

    get referrerPolicy(): string {
        return this._iframe.getAttribute("referrerpolicy");
    }

    set referrerPolicy(value: string) {
        if (StringUtil.isEmptyString(value) !== true)
            this._iframe.setAttribute("referrerpolicy", value);
        else
            this._iframe.removeAttribute("referrerpolicy");
    }

    get sandbox(): boolean {
        return this._sandbox ?? this.getPropertyDefinitions().sandbox.defaultValue;
    }

    set sandbox(value: boolean) {
        this._sandbox = value;
        this.syncSandboxAttributeValue();
    }

    get sandboxAttributes(): string[] {
        return this._sandboxAttributes;
    }

    set sandboxAttributes(value: string[]) {
        this._sandboxAttributes = value;
        this.syncSandboxAttributeValue();
    }

    private syncSandboxAttributeValue() {
        if (this._sandbox === true) {
            if (ArrayUtil.isEmptyArray(this._sandboxAttributes) !== true)
                this._iframe.setAttribute("sandbox", this._sandboxAttributes.join(" "));
            else
                this._iframe.toggleAttribute("sandbox", true);
        }
        else
            this._iframe.removeAttribute("sandbox");
    }

    get _designer() {
        return super._designer;
    }

    set _designer(value) {
        super._designer = value;
        if (value != null) {
            this._iframe.style.pointerEvents = "none";
            if (value.addDesignerContainerProperties != null)
                value.addDesignerContainerProperties(this, 100, 100, () => false);
        }
    }

    override get serializationName() {
        return "iframe";
    }

    override get properName(): string {
        return "IFrame";
    }

    override getPropertyDefaultValue(prop: ComponentPropDefinition): any {
        if (prop.name === "padding")
            return 0;
        return super.getPropertyDefaultValue(prop);
    }

    override getPropertyDefinitions(): ComponentPropDefinitions {
        return IFramePropDefinitions.getDefinitions();
    }
}

ComponentTypes.registerComponentType("iframe", IFrame.prototype.constructor);
