import { CommonDialogs } from "@mcleod/common";
import { Button, ChangeEvent, ClickEvent, DataSourceExecutionEvent, DataSourceMode, Image, SlideoutDecorator, Snackbar, Switch, TableRow, TableRowDisplayEvent } from "@mcleod/components";
import { DOMUtil, ModelRow, Navigation, StringUtil, getLogger } from "@mcleod/core";
import { AbstractModelDesigner } from "../model/AbstractModelDesigner";
import { ModelVersions } from "../model/ModelVersions";
import { AbstractUIDesigner } from "../ui/AbstractUIDesigner";
import { UILayoutVersions } from "../ui/UILayoutVersions";
import { ResourceDesignerTab } from "./ResourceDesignerTab";

const log = getLogger("designer.common.CustomVersionUtil");

type CustomVersionLayout = ModelVersions | UILayoutVersions;

export interface CustomVersionInterface  {
    switchListenersActive: boolean;
    positionedBaseComponentsFromRow: boolean;
    enclosingDesigner: AbstractUIDesigner | AbstractModelDesigner;
}

export class CustomVersionUtil {

    public static onLoad(layout: CustomVersionLayout) {
        layout.tableVersions.busy = true;
        layout.mainDataSource.mode = DataSourceMode.UPDATE;
    }

    static showSlideout(designer: AbstractUIDesigner | AbstractModelDesigner, layout: CustomVersionLayout) {
        layout.enclosingDesigner = designer;
        const type = StringUtil.toProperCase(layout.enclosingDesigner.getActiveTab().resourceType);
        layout.addLayoutLoadListener(() => {
            new SlideoutDecorator({
                layout: layout,
                width: Math.max(window.innerWidth * 0.75, 800),
                fillVerticalSpace: true,
                title: `Manage ${type} Versions - ${layout.enclosingDesigner.getActiveTab().caption}`,
                doAfterSlideIn: () => {
                    layout.mainDataSource.search({ name: layout.enclosingDesigner.getActiveTab().path }).then(() => {
                        CustomVersionUtil.positionBaseComponents(layout);
                    });
                }
            });
        });
    }

   public static doAfterExecute(event: DataSourceExecutionEvent, layout: CustomVersionLayout) {
        try {
            layout.switchListenersActive = false;
            CustomVersionUtil.syncBaseVersionActiveSwitch(layout);
        }
        finally {
            layout.switchListenersActive = true;
        }
    }

    public static syncBaseVersionActiveSwitch(layout: CustomVersionLayout) {
        for (const row of layout.tableVersions.allRows) {
            if (row.data.get("is_active", "N") === "Y") {
                layout.switchBaseVersionActive.checked = false;
                layout.switchBaseVersionActive.enabled = true;
                return;
            }
        }
        layout.switchBaseVersionActive.checked = true;
        layout.switchBaseVersionActive.enabled = false;
    }

    public static positionBaseComponents(layout: CustomVersionLayout) {
        let activeColumn = null;
        let actionsColumn = null;
        for (const column of layout.tableVersions.columns) {
            if (column.headingCell.id === "columnHeaderActive")
                activeColumn = column;
            else if (column.headingCell.id === "columnHeaderActions")
                actionsColumn = column;
        }

        const { labelBaseVersion, switchBaseVersionActive, buttonOpenBaseVersion } = layout;

        labelBaseVersion.left = -9999;
        labelBaseVersion.visible = true;
        const labelWidth = DOMUtil.getElementWidth(labelBaseVersion._element);
        labelBaseVersion.paddingRight = 0;
        labelBaseVersion.visible = false;
        labelBaseVersion.left = activeColumn.headingCell._element.offsetLeft - labelWidth;
        labelBaseVersion.visible = true;

        switchBaseVersionActive.left = activeColumn.headingCell._element.offsetLeft - labelWidth + 4;
        switchBaseVersionActive.visible = true;

        buttonOpenBaseVersion.left = actionsColumn.headingCell._element.offsetLeft - labelWidth - DOMUtil.getElementWidth(layout.switchBaseVersionActive._element);
        buttonOpenBaseVersion.visible = true;
    }

    public static switchIsActiveOnChange(event: ChangeEvent, layout: CustomVersionLayout) {
        if (layout.switchListenersActive !== true)
            return;
        const updatedSwitch = event.target as Switch;
        const changedRow = TableRow.getContainingTableRow(updatedSwitch);
        const changedRowData = changedRow.data as ModelRow;
        changedRowData.post().then(response => {
            try {
                layout.switchListenersActive = false;
                for (const row of layout.tableVersions.allRows) {
                    const switchInRow = row.findComponentById("switchIsActive") as Switch;
                    if (changedRow !== row) {
                        if (updatedSwitch.checked === true && switchInRow.checked === true)
                            switchInRow.checked = false;
                    }
                    row.findComponentById("imageDelete").visible = switchInRow.checked === false;
                }
                CustomVersionUtil.showActiveChangedSnackbar(changedRowData, layout, updatedSwitch);
            }
            finally {
                CustomVersionUtil.syncBaseVersionActiveSwitch(layout);
                layout.switchListenersActive = true;
            }
        }).catch(error => {
            log.debug("An error occurred while activating the version, resetting switches");
            layout.switchListenersActive = false;
            updatedSwitch.checked = !updatedSwitch.checked;
            layout.switchListenersActive = true;
        });
    }

    private static showActiveChangedSnackbar(row: ModelRow, layout: CustomVersionLayout, updatedSwitch: Switch) {
        const type =layout.enclosingDesigner.getActiveTab().resourceType;
        const descr = row.get("descr") ? StringUtil.singleQuote(row.get("descr")) : "";
        const suffix = `is ${updatedSwitch.checked ? "is now" : "is no longer"} the active version of this ${type}.`;
        Snackbar.showSnackbar(`${StringUtil.toProperCase(type)} with description ${descr} ${suffix}`);
    }

    public static async imageDeleteOnClick(event: ClickEvent, layout: CustomVersionLayout) {
        const affectedRow = TableRow.getContainingTableRow(event.target as Image);
        const affectedRowData = affectedRow.data as ModelRow;
        const resourceType = layout.enclosingDesigner.getActiveTab().resourceType;
        const message = `The version of custom ${resourceType} '${layout.enclosingDesigner.getActiveTab().caption}' with the description '${affectedRowData.get("descr")}' will be deleted.\n\nDo you wish to continue?`;
        const customId = affectedRowData.get("id");
        const type = StringUtil.toProperCase(layout.enclosingDesigner.getActiveTab().resourceType);
        if (await CommonDialogs.showDestructive(message, `Delete Custom ${type}?`)) {
            affectedRowData.delete().then(response => {
                layout.tableVersions.removeRow(affectedRow.index);
                Snackbar.showSnackbar(response.data[0].message);
                layout.enclosingDesigner.findOpenTabs((tab: ResourceDesignerTab) => customId === tab.customId)
                    .reverse()
                    .forEach((tab: ResourceDesignerTab) => tab.close());
            }).catch(error => {
                log.debug(`An error occurred while deleting the ${resourceType} version`);
            });
        }
    }

    public static tableVersionsOnRowDisplay(event: TableRowDisplayEvent, layout: CustomVersionLayout) {
        const row = event.target as TableRow;
        row.canBeDeleted = false;
        CustomVersionUtil.setImageWarningVisibility(row);
        CustomVersionUtil.setImageDeleteVisibility(row);
        CustomVersionUtil.setImageUpgradeResultsVisibility(row);
        layout.switchListenersActive = true;
        if (layout.positionedBaseComponentsFromRow === false) {
            CustomVersionUtil.positionBaseComponents(layout);
            layout.positionedBaseComponentsFromRow = true;
        }
    }


    public static imageEditOnClick(event: ClickEvent, layout: CustomVersionLayout) {
        const row = TableRow.getContainingTableRow(event.target as Button);
        const rowData = row.data as ModelRow;
        layout.enclosingDesigner.openTab({
            path: StringUtil.stringAfter(rowData.get("name"), "/"), //need to remove service project from path
            baseVersion: false,
            customId: rowData.get("id")
        }, true);
    }

    public static buttonOpenBaseVersionOnClick(event: ClickEvent, layout: CustomVersionLayout) {
        layout.enclosingDesigner.openTab({
            path: layout.enclosingDesigner.getActiveTab().path,
            baseVersion: true
        }, true);
    }



    public static setImageWarningVisibility(row: TableRow) {
        if (row.data.get("resource_outdated") === true) {
            const imageDelete = row.findComponentById("imageWarning");
            if (imageDelete != null)
                imageDelete.visible = true;
        }
    }

    public static setImageDeleteVisibility(row: TableRow) {
        if (row.data.get("is_active") === "Y") {
            const imageDelete = row.findComponentById("imageDelete");
            if (imageDelete != null)
                imageDelete.visible = false;
        }
    }

    public static setImageUpgradeResultsVisibility(row: TableRow) {
        const upgradeSuccessful = row.data.get("upgrade_successful", null);
        if (upgradeSuccessful != null) {
            const imageUpgradeResults = row.findComponentById("imageUpgradeResults");
            if (imageUpgradeResults != null) {
                imageUpgradeResults.visible = true;
                if (upgradeSuccessful === "N")
                    imageUpgradeResults.color = "error";
            }
        }
    }

    public static switchBaseVersionActiveOnChange(event: ChangeEvent, layout: CustomVersionLayout) {
        if (layout.switchListenersActive !== true)
            return;
        for (const row of layout.tableVersions.allRows) {
            const switchInRow = row.findComponentById("switchIsActive") as Switch;
            if (switchInRow.checked === true) {
                switchInRow.checked = false;
                return;
            }
        }
    }

    public static async imageUpgradeResultsOnClick(event: ClickEvent, upgradeResultsPath: string) {
        const row = TableRow.getContainingTableRow(event.target as Button);
        const rowData = row.data as ModelRow;
        Navigation.navigateTo(`${upgradeResultsPath}?id=${rowData.get("id")}`, { newTab: true });
    }
}
